<mat-toolbar color="accent" [class.phone]="_breakpointObserver.isMatched('(max-width: 599px)')" style="z-index: 1; box-shadow: 0 0 10px black">
    <mat-toolbar-row>
        <div style="width: 10px;"></div>
        <a href="https://www.sblended.com"><div class="wordmark unselectable"></div></a>
        <span class="stretch"></span>
        <nav *ngIf="!_phoneMode" mat-tab-nav-bar style="align-self: flex-end;">
            <a mat-tab-link routerLink="/explore" [active]="this._exploreActive">Explore</a>
            <a mat-tab-link routerLink="/create" [active]="this._createActive">Create</a>
        </nav>
        <span class="stretch"></span>
        <div style="display: flex;">
            <share-button
                #shareButton
                class="toolbar-button"
                buttonStyle="mat-flat-button"
                label="Share"
                url="https://sblended.com"
                shareTitle="Sblended"
                message="Sblended: The Supercharged Gradient Tool"
                twitter="sblendedDesign"
                tags="gradients,graphicdesign"
                title="Share"
                matTooltipShowDelay="750"
                style="color: white;">
            </share-button>
        </div>
        <profile-button style="margin-left: 10px;"></profile-button>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="_phoneMode" style="height: 40px;">
        <!-- <span class="stretch"></span> --><!-- uncomment to be centered -->
        <nav mat-tab-nav-bar style="align-self: flex-end;">
            <a mat-tab-link routerLink="/explore" [active]="this._exploreActive">Explore</a>
            <a mat-tab-link routerLink="/create" [active]="this._createActive">Create</a>
        </nav>
        <span class="stretch"></span>
    </mat-toolbar-row>
</mat-toolbar>
