import { Component, EventEmitter, ViewChild, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'like-button',
    templateUrl: './like-button.component.html',
    styleUrls: ['./like-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('_userLikesState', [
            state('true', style({
                color: 'red',
            })),
            state('false', style({
                color: 'gray',
            })),
            transition('false => true', [
                style({ transform: 'scale(1.5)' }),
                animate(100)
            ]),
            transition('true => false', [
                style({ transform: 'scale(0.8)' }),
                animate(100)
            ])
        ])
    ]
})
export class LikeButtonComponent {

    //--------------------------------------------------------------------------
    //
    //  Events
    //
    //--------------------------------------------------------------------------

    @Output()
    public liked: EventEmitter<boolean> = new EventEmitter();

    /**
     * @constructor
     */
    constructor(private _cd: ChangeDetectorRef) {}

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  numLikes
    //----------------------------------

    private _numLikes: number = 0;
    /**
     * @private - public for template
     */
    public _numLikesResolved: number = 0;

    @Input()
    public set numLikes(value: number) {
        if (this._numLikes !== value) {
            this._numLikes = value;
            this._numLikesResolved = value;
            this._cd.detectChanges();
        }
    }
    public get numLikes(): number {
        return this._numLikes;
    }

    //----------------------------------
    //  userLikes
    //----------------------------------

    private _userLikes: boolean = false;
    
    public _userLikesResolved: boolean = false;

    @Input()
    public set userLikes(value: boolean) {
        if (this._userLikes !== value) {
            this._userLikes = value;
            this._userLikesResolved = value;
            this._cd.detectChanges();
        }
    }
    public get userLikes(): boolean {
        return this._userLikes;
    }

    //----------------------------------
    //  enabled
    //----------------------------------

    @Input()
    public enabled: boolean;

    /**
     * @private;
     */
    public _clickHandler = async (event): Promise<void> => {
        if (this._userLikesResolved) {
            this._userLikesResolved = false;
            this._numLikesResolved -= 1;
            this._cd.detectChanges();
            this.liked.emit(false);
        }
        else {
            this._userLikesResolved = true;
            this._numLikesResolved += 1;
            this._cd.detectChanges();
            this.liked.emit(true);
        }
    }
}
