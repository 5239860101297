/// <reference path="../declarations.d.ts" />

import Rectangle = require('geom/rectangle');

/**
 * The Alert class provides a Dialog with optional buttons. 
 * It leverages the paper-action-dialog under the hood.
 */
class Alert {
    /**
     * Displays a Dialog with specified message, title, and buttons
     * and returns a promise that will be resolved once the Dialog is dismissed.
     */
    static show(message: string, title?: string, buttons?: any[], autoDismiss: boolean = true, shade: boolean = true, rect?: Rectangle, z?: number): Promise<number> {
        // HACK: workaround for dependency injection issues with static methods in Angular
        // this was set in app.component.ts
        // there's probably a better way to do with this but...time
        const alertComponent = window['Alert'];
        return alertComponent.show(message, title, buttons, autoDismiss, shade, rect, z);
    }
}
export = Alert;
