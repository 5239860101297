import { Component, Input, Output, EventEmitter, ViewChild, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

import { SliderComponent } from 'controls/slider.component';
import View = require('ui/view');

@Component({
  selector: 'rgb-color-picker',
  templateUrl: './rgb-color-picker.component.html',
  styleUrls: ['./rgb-color-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RGBColorPickerComponent extends View {

    //--------------------------------------------------------------------------
    //
    //  Events
    //
    //--------------------------------------------------------------------------

    @Output()
    change: EventEmitter<number> = new EventEmitter<number>();

    /**
     * @constructor
     */
    constructor() {
      super();
    }

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    @ViewChild('rSlider') rSlider: SliderComponent;
    @ViewChild('gSlider') gSlider: SliderComponent;
    @ViewChild('bSlider') bSlider: SliderComponent;

    private _dirty: boolean = false;

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------
    
    //----------------------------------
    //  r
    //----------------------------------

    private _r: number = 0;

    /**
     * The red value, from 0 to 255.
     */
    @Input()
    set r(value: number) {
      if (this._r !== value) {
        this._r = value;
        this._markForDispatch();
      }
    }
    get r(): number {
      return this._r;
    }

    //----------------------------------
    //  g
    //----------------------------------

    private _g: number = 0;

    /**
     * The green value, from 0 to 255.
     */
    @Input()
    set g(value: number) {
      if (this._g !== value) {
        this._g = value;
        this._markForDispatch();
      }
    }
    get g(): number {
      return this._g;
    }

    //----------------------------------
    //  b
    //----------------------------------

    private _b: number = 0;

    /**
     * The blue value, from 0 to 255.
     */
    @Input()
    set b(value: number) {
      if (this._b !== value) {
        this._b = value;
        this._markForDispatch();
      }
    }
    get b(): number {
      return this._b;
    }

    //----------------------------------
    //  dispatch
    //----------------------------------

    /**
     * Whether or not to dispatch events due to property changes.
     */
    dispatch: boolean = true;

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    layout(): void {
      super.layout();
      
      this.layoutChild(this.rSlider);
      this.layoutChild(this.gSlider);
      this.layoutChild(this.bSlider);
    }

    private _markForDispatch(): void {
      if (!this.dispatch) {
          return;
      }

      // notify external listeners to change to overall color
      // prevent multiple immediate changes from triggering multiple change events
      // saturation and brightness will both change simultaneously,
      // but there is no need to trigger two events
      if (!this._dirty) {
          this._dirty = true;
          
          window.requestAnimationFrame(() => {
              this._dirty = false;
              this.change.emit();
          });
        }
    }

    /* private (Angular-CLI #5621) */ _validateInput = (event, propertyName: string, min: number = 0, max: number = 100, factor: number = null) => {
      let value = event.target.value;
      let originalValue = value * factor;
      
      value = Math.round(value);

      // apply prior to enforcing min/max to minimize floating point issues
      // this means that the min/max should also be set in relation to the factor
      // e.g. an input that allows a user to enter values between 0 and 100 
      // though internally should result in values between 0.0 and 1.0
      // would have a factor of 100, min of 0, and max of 100.

      if (factor) {
        value = value * factor;
      }

      if (value < min) {
        value = min;  
      }
      else if (value > max) {
        value = max;
      }

      // force setting of input
      // otherwise the input element may not get updated since the underlying value may have already been clamped
      if (value !== originalValue) {
        let stringValue = factor ? value / factor : value;
        event.target.value = String(stringValue);
      }

      this[propertyName] = value;
    }
}