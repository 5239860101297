import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';

import ColorUtil = require('utils/color-util');
import View = require('ui/view')
import { HSBColorPickerComponent } from 'controls/hsb-color-picker.component';
import { RGBColorPickerComponent } from 'controls/rgb-color-picker.component';

@Component({
    selector: 'color-picker',
    templateUrl: './color-picker.component.html',
    // styleUrls: ['./hsb-color-picker.component.css'],
    animations: [
        trigger('_colorPickerState', [
            state('hsb', style({
                transform: 'translateY(0)'
            })),
            state('rgb', style({
                transform: 'translateY(-100%)'
            })),
            transition('* <=> *', animate('200ms ease-out'))
        ])
      ]
})
export class ColorPickerComponent extends View {

    //--------------------------------------------------------------------------
    //
    //  Events
    //
    //--------------------------------------------------------------------------

    @Output()
    change: EventEmitter<number> = new EventEmitter<number>();

    /**
     * @constructor
     */
    constructor() {
        super();
    }
    
    @ViewChild('rgb') rgb: RGBColorPickerComponent;
    @ViewChild('hsb') hsb: HSBColorPickerComponent;


    //----------------------------------
    //  colorPickerType
    //----------------------------------
    
    private _colorPickerType: string;

    @Input()
    set colorPickerType(value: string) {
        this._colorPickerType = value;

        // updates are only applied to current color picker for performance 
        // so need to apply to the color picker coming into view
        // this could be done only when known to be necessary
        this._updateCurrentColorPicker(this._color);

        // layout the color picker coming into view
        this.invalidateLayout();
    }
    get colorPickerType(): string {
        return this._colorPickerType;
    }

    //----------------------------------
    //  color
    //----------------------------------
    
    private _color: number;
    
    // private static _bindableColor = BindingUtil.bindable(StopDetailsComponent, 'color');
    
    /**
     * The color of the stop(s).
     */
    set color(value: number) {
        if (this._color !== value) {
            this._color = value;

            this._updateCurrentColorPicker(this._color);
        }
    }
    get color(): number {
        return this._color;
    }

    //----------------------------------
    //  dispatch
    //----------------------------------

    private _dispatch: boolean;
    
    /**
     * The color of the stop(s).
     */
    set dispatch(value: boolean) {
        if (this._dispatch !== value) {
            this._dispatch = value;

            this.hsb.dispatch = value;
            this.rgb.dispatch = value;
        }
    }
    get dispatch(): boolean {
        return this._dispatch;
    }

    initialize(): void {
        super.initialize();

        // must be done here rather than in declaration for reasons unknown
        this.colorPickerType = 'hsb';

        this.hsb.change.subscribe(() => {
            let rgba = ColorUtil.hsbToRGBA(this.hsb.h, this.hsb.s, this.hsb.b);

            // use internal variable rather than setter to prevent feeback
            this._color = ColorUtil.rgbToColor(rgba.r, rgba.g, rgba.b);
            this.change.emit(this.color);
        });

        this.rgb.change.subscribe(() => {
            // use internal variable rather than setter to prevent feeback
            this._color = ColorUtil.rgbToColor(this.rgb.r, this.rgb.g, this.rgb.b);
            this.change.emit(this.color);
        });
    }

    layout(): void {
        super.layout();

        switch (this.colorPickerType) {
            case 'hsb':
            default:
                this.layoutChild(this.hsb);
                break;
            case 'rgb':
                this.layoutChild(this.rgb);
                break;
        }
    }

    private _updateCurrentColorPicker(color: number): void {
        switch (this.colorPickerType) {
            case 'hsb':
                if (this.hsb) {
                    let hsb = ColorUtil.colorToHSB(color);
                    this.hsb.h = hsb.h;
                    this.hsb.s = hsb.s;
                    this.hsb.b = hsb.b;
                }
                break;
            case 'rgb':
                if (this.rgb) {
                    let rgb = ColorUtil.colorToRGB(color);
                    this.rgb.r = rgb.r;
                    this.rgb.g = rgb.g;
                    this.rgb.b = rgb.b;
                }
                break;
        }
    }
}
