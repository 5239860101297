import _ = require('underscore');
import $ = require('jquery');
import BlendRenderer = require('graphics/blend-renderer');
import CollectionEvent = require('events/collection-event');
import Direction = require('geom/direction');
import Environment = require('core/environment');
import Blend = require('graphics/blend');
import IList = require('collections/i-list');
import PropertyChangeEvent = require('events/property-change-event');
import Rectangle = require('geom/rectangle');
import Stamper = require('graphics/stamper');
import View = require('ui/view');

/**
 * View of a Blend.
 * Delegates to BlendRenderer for rendering, 
 * but handles painting to canvas and orientation itself.
 */
class BlendView extends View {
    
    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------
    
    static RESIZE_RULE_AUTO: string = 'auto';
    static RESIZE_RULE_ALWAYS: string = 'always';
    static RESIZE_RULE_NEVER: string = 'never';
    
    //--------------------------------------------------------------------------
    //
    //  Class methods
    //
    //--------------------------------------------------------------------------
    
    private static _rendererInstance: BlendRenderer;
    
    /**
     * Use single instance to render and draw back into canvas.
     * This avoids creation of too many WebGL contexts.
     */
    static getRendererInstance(): BlendRenderer {
        if (!BlendView._rendererInstance) {
            var instance: BlendRenderer = BlendView._rendererInstance = new BlendRenderer();
            var w: number = 100;
            var h: number = 100;
            instance.width = w;
            instance.height = h;
            $(instance.canvas).attr('width', w);
            $(instance.canvas).attr('height', h);
            $(instance.canvas).css('width', w);
            $(instance.canvas).css('height', h);
            $(instance.canvas).css('position', 'absolute');
            // $('body').append(instance.canvas);
        }
        return BlendView._rendererInstance;
    }
    
    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------
    
    private _canvasView: View;
    private _canvas: HTMLCanvasElement;
    private _orientation: string;
    private _debouncedBlendPathsChangeHandler;
    
    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor 
     */
    constructor() {
        super();
        
        this._debouncedBlendPathsChangeHandler = this._blendPathsChangeHandler;

        // this.el.style.position = 'relative';
        
        this.el.classList.add('blendView');

        this.width = '100%';
        this.height = '100%';
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------
	
    //----------------------------------
    //  blend
    //----------------------------------

    private _blend: Blend;

    set blend(value: Blend) {
        if (this._blend) {
            this._blend.paths.off(CollectionEvent.COLLECTION_CHANGE, this._debouncedBlendPathsChangeHandler);
            this._blend.off(PropertyChangeEvent.PROPERTY_CHANGE, this._debouncedBlendPathsChangeHandler);
        }

        this._blend = value;
        
        if (this._blend && this._watch) {
            this._blend.paths.off(CollectionEvent.COLLECTION_CHANGE, this._debouncedBlendPathsChangeHandler);
            this._blend.paths.on(CollectionEvent.COLLECTION_CHANGE, this._debouncedBlendPathsChangeHandler);
            this._blend.off(PropertyChangeEvent.PROPERTY_CHANGE, this._blendChangeHandler);
            this._blend.on(PropertyChangeEvent.PROPERTY_CHANGE, this._blendChangeHandler);
        }
        
        this.invalidateProperty('blend');
        this.invalidateLayout();
    }
    get blend(): Blend {
        return this._blend;
    }
    
    //----------------------------------
    //  rect
    //----------------------------------

    rect: Rectangle;

    //----------------------------------
    //  rotation
    //----------------------------------

    rotation: number;
    
    //----------------------------------
    //  watch
    //----------------------------------
    
    private _watch: boolean = false;
    
    /**
     * Whether or not to watch the Blend for changes
     * and automatically re-render as a result.
     */
    set watch(value: boolean) {
        if (this._watch !== value) {
            this._watch = value;
            this.invalidateProperty('watch');
        }
    }
    get watch(): boolean {
        return this._watch;
    }

    //----------------------------------
    //  debounce
    //----------------------------------

    private _debounce: number = -1;

    /**
     * Time, in milliseconds, to debounce changes before re-rendering.
     */
    set debounce(value: number) {
        this._debounce = value;
    }
    get debounce(): number {
        return this._debounce;
    }
    
    //----------------------------------
    //  renderer
    //----------------------------------
	
	private _renderer: BlendRenderer;
	
    /**
     * Low-level renderer to use.
     * Defaults to a shared 100x100 renderer
     * in order to avoid the creation of too many WebGL contexts.
     * Assign a specific renderer for a performance benefit
     * and to allow more control over the render, such as pixelation.  
     */
	set renderer(value: BlendRenderer) {
		this._renderer = value;
        // rotate from top-left to make translate simpler
        // this is also beneficial for the size transition so that the resize is along the bottom edge
        // otherwise, a gap appears between the stack view and the toolbar
        this.invalidateProperty('blend');
	}
	get renderer(): BlendRenderer {
		if (!this._renderer) {
            this._renderer = BlendView.getRendererInstance();
        }
        return this._renderer;
	}
    
    //----------------------------------
    //  ownRenderer
    //----------------------------------
    
    private _ownRenderer: boolean = false;
    
    /**
     * Whether or not we own the renderer,
     * or whether we need to draw to a separate canvas.
     */
    set ownRenderer(value: boolean) {
        this._ownRenderer = value;
        this.invalidateProperty('ownRenderer');
        
        if (this.renderer) {
            $(this.renderer.el).css('transition-property', 'transform');
            $(this.renderer.el).css('transition-duration', '300ms');
            $(this.renderer.el).css('transform-origin', '0% 0%');
        }
    }
    get ownRenderer(): boolean {
        return this._ownRenderer;
    }
    
    //----------------------------------
    //  rendererResizeRule
    //----------------------------------
    
    private _rendererResizeRule: string = BlendView.RESIZE_RULE_NEVER;
    
    /**
     * Rule indicating when the renderer should be resized.
     * AUTO only applies when ownRenderer is true.
     * 
     * use static RESIZE_RULE_ constants
     */
    set rendererResizeRule(value: string) {
        this._rendererResizeRule = value;
    }
    get rendererResizeRule(): string {
        return this._rendererResizeRule;
    }
    
    //----------------------------------
    //  canvas
    //----------------------------------

    /**
     * The underlying HTMLCanvasElement.
     * Useful for copying the already rendered content.
     */
    get canvas(): HTMLCanvasElement {
        if (this.ownRenderer) {
            return this.renderer.canvas;
        }
        else {
            return this._canvas;
        }
    }

    //----------------------------------
    //  context
    //----------------------------------

    /**
     * The underlying HTML context.
     * Userful for drawing into this.
     */
    private _context: CanvasRenderingContext2D;

    get context(): CanvasRenderingContext2D {
        return this._context;
    }

    //----------------------------------
    //  smoothing
    //----------------------------------

    private _smoothing: boolean = true;

    /**
     * Whether or not smoothing is applied to the output image.
     * Setting to false can result in a pixelated look 
     * when combined with a small BlendRenderer.
     * Note: this only applies when <code>ownRenderer</code> is false.
     */
    set smoothing(value: boolean) {
        this._smoothing = value;
        if (this._context) {
            this._context.imageSmoothingEnabled = this._smoothing;
        }
    

        // trigger repaint
        this.invalidateProperty('blend');
    }
    get smoothing(): boolean {
        return this._smoothing;
    }

    //----------------------------------
    //  stamp
    //----------------------------------

    stamp: boolean = true;

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * @inheritDoc
     */
    initialize(): void {
        super.initialize();
    }

    /**
     * @inheritDoc
     */
    layout(): void {
        super.layout();
        
        var rotated: boolean = (this._blend && (this._blend.orientation === Direction.VERTICAL));
        
        // size renderer in certain circumstance (sizeRenderer flag?)
        // should only resize automatically when we own the renderer
        if (this._rendererResizeRule === BlendView.RESIZE_RULE_AUTO) {
            if (this.ownRenderer && rotated) {
                if (this._invalidHorizontalLayout) {
                    this.renderer.height = this.pixelWidth;
                }
                if (this._invalidVerticalLayout) {
                    this.renderer.width = this.pixelHeight;
                }
            }
            else {
                if (this._invalidHorizontalLayout) {
                    this.renderer.width = this.pixelWidth;
                }
                if (this._invalidVerticalLayout) {
                    this.renderer.height = this.pixelHeight;
                }
            }
        }
        
        if (this.ownRenderer) {
            this.renderer.x = rotated ? this.pixelWidth : 0;
        }
        else {
            // update the target canvas size to match final resolution
            // this doesn't modify the dimensions of the source renderer
            // this step may be skipped when resize frequency is high
            // and/or when they don't need to be in sync
            // hard to see visible difference between target canvas of 200px vs. 100px
            // performance difference seemed negligible below large (>2000x2000px) canvases
            var canvas = this._canvas;
            if (this._invalidHorizontalLayout) {
                var w: number = (this.pixelWidth > 0) ? this.pixelWidth : 100;
                $(canvas).attr('width', w);
            }
            if (this._invalidVerticalLayout) {
                var h: number = (this.pixelHeight > 0) ? this.pixelHeight : 100;
                $(canvas).attr('height', h);
            }
            
            // repaint to new dimensions
            // avoid additional frame delay by manual validating
            this.validateProperties({blend: true});
        }
    }

    /**
     * @inheritDoc
     */
    validateProperties(changed?: any): void {
        super.validateProperties(changed);
        
        var allChanged: boolean = !changed;
        
        if (allChanged || changed.ownRenderer) {
            if (this.ownRenderer) {
                if (this._canvasView) {
                    this.removeChild(this._canvasView);
                }
                
                this.addChild(this.renderer);
            }
            else {
                // TODO: remove ownRenderer if present
                
                if (!this._canvas) {
                    var v = this._canvasView = new View('canvas');
                    // v.width = '100%';
                    // v.height = '100%';
                    
                    var canvas = this._canvas = (<HTMLCanvasElement>v.el);
                    var w: number = (this.pixelWidth > 0) ? this.pixelWidth : 100;
                    var h: number = (this.pixelHeight > 0) ? this.pixelHeight : 100;

                    $(canvas).attr('width', w);
                    $(canvas).attr('height', h);
                    
                    this._context = canvas.getContext('2d');
                    this._context.imageSmoothingEnabled = this._smoothing;
                }
                
                // set position relative so that canvas at 100% is relative to this and not an outer div
                if (!this.el.style.position) {
                    this.el.style.position = 'relative';
                }
                this.addChild(v);
            }
        }
        
        if (allChanged || changed.blend) {
            var renderer: BlendRenderer = this.renderer;
            renderer.blend = this._blend;
            
            var rotated: boolean = (this._blend && (this._blend.orientation === Direction.VERTICAL));
            
            if (this.ownRenderer) {
                this.renderer.rotation = rotated ? 90 : 0;
                
                // avoid validating here if layout is also invalid to avoid rendering twice
                if (!this._invalidLayout) {
                    renderer.validate();
                }
            }
            else {
                // renderer is not ours to keep
                // force validation and draw back into ourselves
                // console.log('render validate');
                renderer.validate();

                var canvas = this._canvas;
                var canvasW: number = Number(canvas.getAttribute('width'));
                var canvasH: number = Number(canvas.getAttribute('height'));
                this._context.clearRect(0, 0, canvasW, canvasH);

                // this needs to be here despite it being applied elsewhere for unknown reason
                this._context.imageSmoothingEnabled = this._smoothing;
                
                if (rotated) {
                    var angle: number = 90;
                    var angleInRadians = angle * Math.PI / 180;
                    
                    this._context.translate(canvasW/2, canvasH/2);
                    this._context.rotate(angleInRadians);
                    this._context.drawImage(renderer.canvas, 0, 0, renderer.canvas.width, renderer.canvas.height, -canvasH/2, -canvasW/2, canvasH, canvasW);
                    this._context.rotate(-angleInRadians);
                    this._context.translate(-canvasW/2, -canvasH/2);
                }
                else {
                    this._context.drawImage(renderer.canvas, 0, 0, canvasW, canvasH);
                }

                var stamp = ((Environment.demoMode || (Environment.context === Environment.CONTEXT_WEB)) && this.stamp) ? true : false;
                if (stamp) {
                    let stampRect: Rectangle = new Rectangle();
                    stampRect.width = canvasW;
                    stampRect.height = canvasH;
                    Stamper.stampDiagonalLines(this._context, stampRect, 15, 1, 'rgba(255, 255, 255, 0.15)');
                }
            }
        }

        if (allChanged || changed.debounce) {
            if (this._blend) {
                this._blend.paths.off(CollectionEvent.COLLECTION_CHANGE, this._debouncedBlendPathsChangeHandler);
            }
            if (this._debounce > 0) {
                this._debouncedBlendPathsChangeHandler = _.debounce(this._blendPathsChangeHandler, this._debounce);
            }
            else {
                this._debouncedBlendPathsChangeHandler = this._blendPathsChangeHandler;
            }
            
            // trigger re-bind of listener to new function below
            if (changed) {
                changed.watch = true;
            }
        }
        
        if (allChanged || changed.watch) {
            if (this._blend) {
                this._blend.paths.off(CollectionEvent.COLLECTION_CHANGE, this._debouncedBlendPathsChangeHandler);
                if (this._watch) {
                    this._blend.paths.on(CollectionEvent.COLLECTION_CHANGE, this._debouncedBlendPathsChangeHandler);
                }
            }
        }
    }

    destroy(): void {
        super.destroy();

        if (this._blend) {
            if (this._blend.paths) {
                this._blend.paths.off(CollectionEvent.COLLECTION_CHANGE, this._debouncedBlendPathsChangeHandler);
            }
            this._blend.off(PropertyChangeEvent.PROPERTY_CHANGE, this._blendChangeHandler);
        }
    }
	
    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------
    
    /**
     * Handler for when the gradient paths change.
     * Re-renders.
     */
    private _blendPathsChangeHandler = (event?: CollectionEvent): void => {
        var kind: string = event ? event.kind : null;
        switch (kind) {
            default:
                this.invalidateProperty('blend');
        }
    }
    
    private _blendChangeHandler = (event?: PropertyChangeEvent): void => {
        if (event && event.propertyName === 'orientation') {
            this.invalidateLayout();
            this.invalidateProperty('blend');
        }
    }
}

export = BlendView;