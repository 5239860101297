import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router/';

/**
 * Custom route reuse strategy which allows the user to navigate back
 * to the List view quickly and restore the previous state (e.g. scroll position).
 * This also reduces the server load since Blends are not refetched. 
 */
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
    private storedRoutes = new Map<string, DetachedRouteHandle>();

    /**
     * @inheritdoc
     */
    public shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const should: boolean = route.routeConfig.path === 'explore/:category';
        return should;
    }

    /**
     * @inheritdoc
     */
    public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        this.storedRoutes.set(route.routeConfig.path, detachedTree);
    }

    /**
     * @inheritdoc
     */
    public shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const should: boolean = this.storedRoutes.get(route.routeConfig?.path) ? true : false;
        return should;
    }

    /**
     * @inheritdoc
     */
    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return this.storedRoutes.get(route.routeConfig?.path);
    }

    /**
     * @inheritdoc
     */
    public shouldReuseRoute(previous: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        let should: boolean = previous.routeConfig === curr.routeConfig;

        if (curr.routeConfig?.path === 'explore/:category') {
            should = true;
        }
        return should;
    }
}
