import ArrayList = require('collections/array-list');
import BindingUtil = require('utils/binding-util');
import CollectionEvent = require('events/collection-event');
import EventDispatcher = require('events/event-dispatcher');
import IList = require('collections/i-list');
import ISerializable = require('core/i-serializable');
import BlendPath = require('graphics/blend-path');
import PropertyChangeEvent = require('events/property-change-event');
import SortUtil = require('utils/sort-util');

class Blend extends EventDispatcher implements ISerializable {

    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------

    //-- Events

    /**
     * Triggered when the effect has changed.
     */
    static EVENT_CHANGE: string = 'change';

    /**
     * Deserializes the plain object into an instance.
     */
    static deserialize(o: any): Blend {
        const instance: Blend = new Blend();
        if (o.paths) {
            instance.paths = ArrayList.deserialize(o.paths, BlendPath);
        }
        else if (o.entries) {
            // ensure previously serialized continue working
            instance.paths = ArrayList.deserialize(o.entries, BlendPath);
        }
        if (typeof o.bubbleChanges !== 'undefined') {
            instance.bubbleChanges = o.bubbleChanges;
        }
        if (o.orientation) {
            instance.orientation = o.orientation;
        }
        return instance;
    }

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
        super();

        this.on(PropertyChangeEvent.PROPERTY_CHANGE, (event?: PropertyChangeEvent) => {
            if (event && event.propertyName === 'orientation') {
                this.trigger(Blend.EVENT_CHANGE);
            }
        });
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  paths
    //----------------------------------

    private _paths: IList<BlendPath>;

    set paths(value: IList<BlendPath>) {
        if (this._paths) {
            this._paths.off(CollectionEvent.COLLECTION_CHANGE, this._collectionChangeHandler);
        }

        this._paths = value;
        if (this._paths) {
            this._paths.on(CollectionEvent.COLLECTION_CHANGE, this._collectionChangeHandler);
        }
        this._collectionChangeHandler();
    }
    get paths(): IList<BlendPath> {
        return this._paths;
    }

    //----------------------------------
    //  bubbleChanges
    //----------------------------------

    private _bubbleChanges: boolean = true;

    /**
     * Whether or not to bubble changes from <code>paths</code>.
     */
    set bubbleChanges(value: boolean) {
        this._bubbleChanges = value;

        if (this.paths) {
            this._paths.off(CollectionEvent.COLLECTION_CHANGE, this._collectionChangeHandler);
            if (value) {
                this._paths.on(CollectionEvent.COLLECTION_CHANGE, this._collectionChangeHandler);
            }
        }
    }
    get bubbleChanges(): boolean {
        return this._bubbleChanges;
    }

    //----------------------------------
    //  orientation
    //----------------------------------

    private static _bindableOrientation = BindingUtil.bindable(Blend, 'orientation');

    /**
     * Orientation of the stack.
     * Currently everything in same direction.
     * In future, each entry will be independent curve.
     */
    orientation: string;

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    //-- ISerializable

    /**
     * @inheritDoc
     */
    serialize(): any {
        const o: any = {};
        if (this.paths) {
            o.paths = this.paths.serialize();
        }
        o.bubbleChanges = this.bubbleChanges;
        o.orientation = this.orientation;
        return o;
    }

    /**
     * Returns a clone of itself.
     */
    clone(): Blend {
        return Blend.deserialize(this.serialize());
    }

    /**
     * Returns an array representing stats for this gradient stack.
     * Each entry in the array represents the type of line and the number of entries for that line.
     *
     * E.g. ['c2', 'a2', 'c3']
     * Represents a stack that has a line of 2 colors, followed by a line of 2 alphas, followed by a lilne of 3 colors.
     */
    stats(): string[] {
        try {
            const stats = [];
            const paths = this.paths;
            const len: number = paths ? paths.length : 0;
            for (let i: number = 0; i < len; i++) {
                const path: BlendPath = paths.getItemAt(i);
                const gradient = path.gradient;

                let s =  '';
                const numColorPaths: number = gradient.colorEntries ? gradient.colorEntries.length : 0;
                if (numColorPaths > 0) {
                    // colorStats.push(numColorPaths);
                    s += 'c' + numColorPaths;
                }
                const numAlphaPaths: number = gradient.alphaEntries ? gradient.alphaEntries.length : 0;
                if (numAlphaPaths > 0) {
                    // alphaStats.push(numAlphaEntries);
                    s += 'a' + numAlphaPaths;
                }
                stats.push({
                    offset: path.offset,
                    stats: s
                });
            }
            stats.sort(SortUtil.compareBy('offset'));

            // strip out offset
            const clean = stats.map(function(obj) {
                return obj.stats;
            });

            return clean;
        }
        catch (e) {
            console.error('Failed to retrieve stats for ', this);
            console.error(e.message);
        }
        return [];
    }

    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------

    private _collectionChangeHandler = (): void => {
        this.trigger(Blend.EVENT_CHANGE);
    }
}
export = Blend;
