<div style="padding: 0px;" style="position: relative;">
    <div style="position: relative;">
        <button mat-button [class]="buttonStyle" style="position: absolute; width: 100%; height: 100%;" (click)="_toggle()"></button>
        <div class="button-content">
            <div class="label" *ngIf="label">{{label}}</div>
            <div class="icon">
                <mat-icon svgIcon="sblended:share" [@iconState]="_menuState" style="position: absolute;"></mat-icon>
                <mat-icon [@closeIconState]="_menuState" style="position: absolute; opacity: 0;">close</mat-icon>
            </div>
        </div>
    </div>
    <div [@menuState]="_menuState" class="menu">
        <div style="display: flex; flex-direction: column;">
            <button mat-icon-button (click)="_facebookHandler()" class="menu-button" aria-label="Facebook" style="background-color: #3b5998;">
                <mat-icon svgIcon="sblended:facebook"></mat-icon>
            </button>
            <button mat-icon-button (click)="_twitterHandler()" class="menu-button" aria-label="Twitter" style="background-color: #00aced;">
                <mat-icon svgIcon="sblended:twitter"></mat-icon>
            </button>
            <button mat-icon-button (click)="_gPlusHandler()" class="menu-button" aria-label="Google Plus" style="background-color: #dd4b39;">
                <mat-icon svgIcon="sblended:gplus"></mat-icon>
            </button>
            <button mat-icon-button (click)="_mailHandler()" class="menu-button" aria-label="Email" style="background-color: #7d7d7d;">
                <mat-icon>email</mat-icon>
            </button>
        </div>
    </div>
</div>
