import { Component, ViewChild, ViewEncapsulation, ElementRef, Input } from '@angular/core';

import Blend = require('graphics/blend');
import BlendView = require('graphics/blend-view');
import View = require('ui/view');

@Component({
    selector: 'sblended-blend',
    template: '<div #holder style="width: 100%; height: 100%; position: relative;"></div>',
    styles: [':host { display: block; }']
})
/**
 * Component that renders a Blend.
 */
export class BlendComponent extends View {
    /**
     * @constructor
     */
    constructor() {
        super();

        this._instance = new BlendView();
    }

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------
    
    @ViewChild("holder", { read: ElementRef }) holder: ElementRef;

    private _instance: BlendView;

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------
	
    //----------------------------------
    //  blend
    //----------------------------------

    @Input()
    set blend(value: Blend) {
        console.log('set blend: ', value);
        this._instance.blend = value;
    }
    get blend(): Blend {
        return this._instance.blend;
    }

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * @inheritdoc
     */
    ngAfterViewInit() {
        this.holder.nativeElement.append(this._instance.el);
    }

    /**
     * @inheritdoc
     */
    layout() {
        super.layout();

        this.layoutChild(this._instance);
    }

    
    /**
     * @inheritdoc
     */
    ngOnDestroy(): void {
        if (this._instance) {
            this._instance.destroy();
        }
    }
}
