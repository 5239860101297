import ArrayList = require('collections/array-list');
import CollectionEvent = require('events/collection-event');
import ColorThumb = require('controls/color-thumb');
import Gradient = require('graphics/gradient');
import GradientEditorEvent = require('events/gradient-editor-event');
import GradientEntry = require('graphics/gradient-entry');
import GradientEntryType = require('graphics/gradient-entry-type');
import GradientSelection = require('graphics/gradient-selection');
import IList = require('collections/i-list');
import ISliderThumb = require('controls/i-slider-thumb');
import LinearGradientRenderer = require('graphics/linear-gradient-renderer');
import PropertyChangeEvent = require('events/property-change-event');
import RGBA = require('graphics/rgba');
import SelectableThumbSlider = require('controls/selectable-thumb-slider');
import Slider = require('controls/slider');
import View = require('ui/view');

const templateString = `
    <div class="advanced-gradient-editor">
        <slider data-part-id="alphaEntriesSlider" value="vvv" minimum="0" maximum="1"></slider>
        <slider data-part-id="colorEntriesSlider" value="vvv" minimum="0" maximum="1"></slider>
    </div>
`;

class AdvancedGradientEditor extends View {

    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------

    static EVENT_THUMB_DOWN: string = 'THUMB_DOWN';

    static STATE_NORMAL: string = 'normal';
    static STATE_ACTIVE: string = 'active';

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    private _alphaEntriesSlider: SelectableThumbSlider;
    private _colorEntriesSlider: SelectableThumbSlider;
    private _preview: LinearGradientRenderer;
    private _modifying: boolean = false;
    // private _alphaSelections: ArrayList<boolean>;
    // private _colorSelections: ArrayList<boolean>;

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
        super();

        this.templateString = templateString;

        // requires amd-dependency above
        //this.templatePath = 'text!templates/controls/advanced-gradient-editor.jst';

        //var 

        // this._alphaSelections = new ArrayList<boolean>();
        // this._colorSelections = new ArrayList<boolean>();
        //this.selections = new ArrayList<GradientSelection>();

        /*
        this._alphaSelections.on(CollectionEvent.COLLECTION_CHANGE, this._genericSelectionsChangeHandler);
        this._colorSelections.on(CollectionEvent.COLLECTION_CHANGE, this._genericSelectionsChangeHandler);
        this._genericSelectionsChangeHandler();
        */

        this.height = 20;
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  gradient
    //----------------------------------

    private _gradient: Gradient;

    /**
     * The <code>Gradient</code> to manipulate.
     */
    set gradient(value: Gradient) {
        // we are modifying gradient as a result of values change
        // return to prevent feedback loop since values are modified below
        if (this._modifying) {
            return;
        }
        
        if (this._gradient === value) {
            return;
        }

        this._gradient = value;

        if (this._alphaEntriesSlider) {
            this._alphaEntriesSlider.values = this._gradient ? this._gradient.alphaEntries : null;
        }
        if (this._colorEntriesSlider) {
            this._colorEntriesSlider.values = this._gradient ? this._gradient.colorEntries : null;
        }
        if (this._preview) {
            this._preview.gradient = this.gradient;
        }
        /*
        if (this._gradient) {
            // FIX: remove existing change handlers from old gradient
            if (this._gradient.alphaEntries) {
                this._gradient.alphaEntries.on(CollectionEvent.COLLECTION_CHANGE, this._alphasChangeHandler);
            }
            if (this._gradient.colorEntries) {
                this._gradient.colorEntries.on(CollectionEvent.COLLECTION_CHANGE, this._colorsChangeHandler);
            }
        }
    */
    }
    get gradient(): Gradient {
        return this._gradient;
    }

    //----------------------------------
    //  selectedStops
    //----------------------------------

    private _selectedStops: IList<GradientEntry>;

    set selectedStops(value: IList<GradientEntry>) {
        if (this._selectedStops) {
            this._selectedStops.off(CollectionEvent.COLLECTION_CHANGE, this._selectedStopsChangeHandler);
        }

        this._selectedStops = value;

        if (this._selectedStops) {
            this._selectedStops.on(CollectionEvent.COLLECTION_CHANGE, this._selectedStopsChangeHandler);
        }
        this._selectedStopsChangeHandler(null);
    }
    get selectedStops(): IList<GradientEntry> {
        return this._selectedStops;
    }

    //----------------------------------
    //  selections
    //----------------------------------

    /*
    private _selections: IList<GradientSelection>;

    set selections(value: IList<GradientSelection>) {
        if (this._selections) {
            this._selections.off(CollectionEvent.COLLECTION_CHANGE, this._selectionsChangeHandler);
        }

        this._selections = value;

        if (this._selections) {
            this._selections.on(CollectionEvent.COLLECTION_CHANGE, this._selectionsChangeHandler);
        }
        this._selectionsChangeHandler(null);
    }
    get selections(): IList<GradientSelection> {
        return this._selections;
    }
    */

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * @inheritDoc
     */
    initialize(): void {
        super.initialize();

        //var $element = $(this.el);
        // TODO: use template with directive and read from scope somehow

        this._preview = new LinearGradientRenderer();
        this._preview.name = 'preview';
        if (this._gradient) {
            this._preview.gradient = this._gradient;
        }
        this._preview.width = '100%';
        //$element.append(this._preview.el);
        this.addChild(this._preview);

        var alphaEntriesSlider = this._alphaEntriesSlider = new SelectableThumbSlider();
        alphaEntriesSlider.name = 'alphaEntriesSlider';
        alphaEntriesSlider.itemFactory = this._alphaItemFactory;
        alphaEntriesSlider.thumbFactory = this._alphaThumbFactory;
        alphaEntriesSlider.valuesProperty = 'offset';
        if (this._gradient) {
            alphaEntriesSlider.values = this._gradient.alphaEntries;
        }
        // alphaEntriesSlider.selections = this._alphaSelections;
        alphaEntriesSlider.maximum = 1.0;
        alphaEntriesSlider.width = '100%';
        alphaEntriesSlider.on(Slider.EVENT_THUMB_DOWN, this._alphaSliderThumbDownHandler);
        alphaEntriesSlider.on(Slider.EVENT_THUMB_TAP, this._alphaSliderThumbTapHandler);
        //$element.append(this._alphaEntriesSlider.el);
        this.addChild(this._alphaEntriesSlider);

        this._colorEntriesSlider = new SelectableThumbSlider();
        this._colorEntriesSlider.name = 'colorEntriesSlider';
        this._colorEntriesSlider.itemFactory = this._colorItemFactory;
        this._colorEntriesSlider.thumbFactory = this._colorThumbFactory;
        this._colorEntriesSlider.valuesProperty = 'offset';
        if (this._gradient) {
            this._colorEntriesSlider.values = this._gradient.colorEntries;
        }
        // this._colorEntriesSlider.selections = this._colorSelections;
        this._colorEntriesSlider.maximum = 1.0;
        this._colorEntriesSlider.width = '100%';
        this._colorEntriesSlider.bottom = 0;
        this._colorEntriesSlider.on(Slider.EVENT_THUMB_DOWN, this._colorSliderThumbDownHandler);
        this._colorEntriesSlider.on(Slider.EVENT_THUMB_TAP, this._colorSliderThumbTapHandler);
        //$element.append(this._colorEntriesSlider.el);
        this.addChild(this._colorEntriesSlider);

        this.invalidateLayout();
    }

    /**
     * @inheritDoc
     */
    layout(): void {
        super.layout();

        if (this._alphaEntriesSlider) {
            this._alphaEntriesSlider.layout();
        }
        if (this._colorEntriesSlider) {
            this._colorEntriesSlider.layout();
        }

        if (this._preview) {
            this._preview.top = this._alphaEntriesSlider.pixelHeight;
            this._preview.bottom = this._colorEntriesSlider.pixelHeight;
        }
    }
    
    private _alphaItemFactory = (index: number, offset: number): GradientEntry => {
        var entry: GradientEntry = new GradientEntry();
        entry.offset = offset;

        // TODO: get interpolated alpha
        entry.alpha = 1.0;

        return entry;
    }

    private _alphaThumbFactory = (index: number): ISliderThumb => {
        var alphaThumb = new ColorThumb();

        var entry: GradientEntry = this.gradient.alphaEntries.getItemAt(index);
        alphaThumb.rgba = new RGBA(entry.color, entry.alpha);

        return alphaThumb;
    }

    private _colorItemFactory = (index: number, offset: number): GradientEntry => {
        var entry: GradientEntry = new GradientEntry();
        entry.offset = offset;

        // TODO: get interpolated color
        entry.color = 0xFF0000;
        return entry;
    }

    private _colorThumbFactory = (index: number): ISliderThumb => {
        var colorThumb = new ColorThumb();

        var rgba: RGBA = colorThumb.rgba = new RGBA();

        // update whenever the entry changes
        // entry is obtained when thumbIndex is set below
        var entry: GradientEntry;
        var entryChangeHandler = () => {
            rgba.color = entry.color;

            // could do this in RGBA#a setter, but would introduce performance cost everywhere
            var defaultAlpha = 1;
            var a = entry.alpha;
            rgba.a = (typeof a !== 'undefined') ? a : defaultAlpha;
        };
        
        // a change to the thumb index means it is being used for a different stop (reorder, remove, etc.)
        var thumbIndexChangeHandler = (event: PropertyChangeEvent): void => {
            if (event.propertyName === 'index') {       // ISliderThumb.index
                if (entry) {
                    entry.off(PropertyChangeEvent.PROPERTY_CHANGE, entryChangeHandler);
                }
                var index: number = event.value;
                entry = this.gradient.colorEntries.getItemAt(index);
                if (entry) {
                    entry.on(PropertyChangeEvent.PROPERTY_CHANGE, entryChangeHandler);
                    entryChangeHandler();
                }
            }
        };
        colorThumb.on(PropertyChangeEvent.PROPERTY_CHANGE, thumbIndexChangeHandler);
        
        // hit existing handler via fake event
        var propertyChangeEvent = new PropertyChangeEvent();
        propertyChangeEvent.value = index;
        propertyChangeEvent.propertyName = 'index';     // ISliderThumb.index
        thumbIndexChangeHandler(propertyChangeEvent);

        colorThumb.on(View.EVENT_DESTROY, () => {
            if (entry) {
                entry.off(PropertyChangeEvent.PROPERTY_CHANGE, entryChangeHandler);
            }
            colorThumb.off(PropertyChangeEvent.PROPERTY_CHANGE, thumbIndexChangeHandler);
        });

        return colorThumb;
    }

    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------

    private _selectedStopsChangeHandler = (event: CollectionEvent) => {
        var alphaSelectionsSource = []
        var colorSelectionsSource = [];

        var selectedStops: IList<GradientEntry> = this._selectedStops;
        var len: number = selectedStops ? selectedStops.length : 0;
        for (var i: number = 0; i < len; i++) {
            var stop: GradientEntry = selectedStops.getItemAt(i);
            switch (stop.type) {
                case GradientEntryType.TYPE_ALPHA:
                    break;
                case GradientEntryType.TYPE_COLOR:
                    break;
            }
        }

        // this._alphaSelections.source = alphaSelectionsSource;
        // this._colorSelections.source = colorSelectionsSource;
    }

    /**
     * Handler for when the set of selections is modified externally.
     * Propagates to the children.
     */
    /*
    private _selectionsChangeHandler = (event: CollectionEvent) => {
        if (this._modifying) {
            return;
        }

        var alphaSelectionsSource = []
        var colorSelectionsSource = [];

        var selections: IList<GradientSelection> = this._selections;
        var len: number = selections ? selections.length : 0;
        for (var i: number = 0; i < len; i++) {
            var selection: GradientSelection = selections.getItemAt(i);
            var index: number = selection.index;
            switch (selection.type) {
                case GradientSelection.TYPE_ALPHA:
                    alphaSelectionsSource[index] = true;
                    break;
                case GradientSelection.TYPE_COLOR:
                    colorSelectionsSource[index] = true;
                    break;
            }
        }

        this._alphaSelections.source = alphaSelectionsSource;
        this._colorSelections.source = colorSelectionsSource;
    }
    */

    private _alphaSliderThumbDownHandler = (index: number) => {
        var event: GradientEditorEvent = new GradientEditorEvent();
        //event.entryType = GradientEntryType.ALPHA;
        //event.index = index;
        event.gradientEntry = this.gradient.alphaEntries.getItemAt(index);
        this.trigger(GradientEditorEvent.THUMB_DOWN, event);
    }
    private _alphaSliderThumbTapHandler = (index: number) => {
        var event: GradientEditorEvent = new GradientEditorEvent();
        //event.entryType = GradientEntryType.ALPHA;
        //event.index = index;
        event.gradientEntry = this.gradient.alphaEntries.getItemAt(index);
        this.trigger(GradientEditorEvent.THUMB_TAP, event);
    }

    private _colorSliderThumbDownHandler = (index: number) => {
        var event: GradientEditorEvent = new GradientEditorEvent();
        //event.entryType = GradientEntryType.COLOR;
        //event.index = index;
        event.gradientEntry = this.gradient.colorEntries.getItemAt(index);
        this.trigger(GradientEditorEvent.THUMB_DOWN, event);
    }
    private _colorSliderThumbTapHandler = (index: number) => {
        var event: GradientEditorEvent = new GradientEditorEvent();
        //event.entryType = GradientEntryType.COLOR;
        //event.index = index;
        event.gradientEntry = this.gradient.colorEntries.getItemAt(index);
        this.trigger(GradientEditorEvent.THUMB_TAP, event);
    }

    /*
    private _alphasChangeHandler = () => {
        console.log('alphas change. todo: update thumb here');
    }

    private _colorsChangeHandler = () => {
        console.log('colors change. todo: update thumb here');
        if (this._colorEntriesSlider) {
            this._colorEntriesSlider.thumbFactory = this._colorThumbFactory;
            //this._colorEntriesSlider.values = this._gradient ? this._gradient.colorEntries : null;
        }
    }
    */

    /**
     * Handler for when either the alpha or colors selections change.
     * Moves selection.
     * Bubbles up as our own.
     */
    /*
    private _genericSelectionsChangeHandler = (event?: CollectionEvent) => {
        this._modifying = true;

        var selectionsSource = [];
        var i: number;
        var len: number;
        var selected: boolean = false;

        console.log("look here too");
        // need to ensure that only one stop from this gradient is getting selected
        // clear the rest 

        var alphaSelections = this._alphaSelections;
        len = alphaSelections ? alphaSelections.length : 0;
        for (i = 0; i < len; i++) {
            selected = alphaSelections.getItemAt(i);
            if (selected) {
                selectionsSource.push(new GradientSelection(GradientSelection.TYPE_ALPHA, i));
            }
        }

        var colorSelections = this._colorSelections;
        len = colorSelections ? colorSelections.length : 0;
        for (i = 0; i < len; i++) {
            selected = colorSelections.getItemAt(i);
            if (selected) {
                selectionsSource.push(new GradientSelection(GradientSelection.TYPE_COLOR, i));
            }
        }

        // coerce to ArrayList to allow setting of source
        // we technically should not know it is an ArrayList since this property can be set externally
        (<ArrayList<GradientSelection>>this._selections).source = selectionsSource;

        this._modifying = false;
    }
     */
}

export = AdvancedGradientEditor;