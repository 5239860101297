import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouteReuseStrategy } from '@angular/router';

import { AlertComponent } from 'ui/alert.component';
import { AlertDialogComponent } from 'ui/alert-dialog.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlendComponent } from 'sblended/blend.component';
import { BlendCreateComponent } from './blend-create/blend-create.component';
import { BlendDetailsComponent } from './blend-details/blend-details.component';
import { BlendViewControllerComponent } from 'blend-view-controller.component'
import { ColorPickerComponent } from 'controls/color-picker.component';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { FooterComponent } from './footer/footer.component';
import { HSBColorPickerComponent } from 'controls/hsb-color-picker.component';
import { HueSliderComponent } from 'controls/colorPickers/hue-slider.component';
import { LikeButtonComponent } from './like-button/like-button.component';
import { LoginComponent } from './login/login.component';
import { LoginDialogComponent } from './login/login-dialog.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ProfileButtonComponent } from './profile-button/profile-button.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { RGBColorPickerComponent } from 'controls/rgb-color-picker.component';
import { SaturationBrightnessBoxComponent } from 'controls/colorPickers/saturation-brightness-box.component';
import { SharedBlendsComponent } from './shared-blends/shared-blends.component';
import { SharedBlendListItemComponent } from './shared-blend-list-item/shared-blend-list-item.component';
import { ShareButtonComponent } from 'controls/share-button.component';
import { SliderComponent } from 'controls/slider.component';
import { StopDetailsComponent } from 'controls/stop-details.component';

@NgModule({
  declarations: [
    AlertComponent,
    AlertDialogComponent,
    AppComponent,
    BlendComponent,
    BlendCreateComponent,
    BlendDetailsComponent,
    BlendViewControllerComponent,
    ColorPickerComponent,
    FooterComponent,
    HSBColorPickerComponent,
    HueSliderComponent,
    LikeButtonComponent,
    LoginComponent,
    LoginDialogComponent,
    NavBarComponent,
    ProfileButtonComponent,
    ProfilePictureComponent,
    RGBColorPickerComponent,
    SaturationBrightnessBoxComponent,
    SliderComponent,
    SharedBlendsComponent,
    SharedBlendListItemComponent,
    ShareButtonComponent,
    StopDetailsComponent,
  ],
  entryComponents: [
    AlertDialogComponent,
    LoginDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HammerModule,
    HttpClientModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
