import ColorUtil = require('utils/color-util');
import PropertyChangeEvent = require('events/property-change-event');
import XYSlider = require('controls/xy-slider');

class SaturationBrightnessBox extends XYSlider {
	
	//--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------
	
	private static PART_HUE: string = 'hue';
	private static PART_THUMB_BACKGROUND: string = 'thumbBackground';
	
	//--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------
	
	/**
	 * @constructor
	 */
	constructor() {
		super();

		this.templateString = `
			<div class="slider saturationBrightnessBox" style="position: relative; width: 100%; height: 100%;">
				<div data-part-id="hue" class="hue" style="position: absolute; width: 100%; height: 100%;"></div>
				<img src="assets/images/saturation.png" style="position: absolute; width: 100%; height: 100%; pointer-events: none; user-select: none;"></img>
				<img src="assets/images/brightness.png" style="position: absolute; width: 100%; height: 100%; pointer-events: none; user-select: none;"></img>
				<div data-part-id="track" class="track satBoxOverlay"></div>
				<div data-part-id="thumb" class="thumb" style="top: 0px;">
					<div class="inner">
						<div data-part-id="thumbBackground" class="color" style="position: absolute; left: 2px; right: 2px; top: 2px; bottom: 2px; border-radius: 50%; border-color: white; border-style: solid; border-width: thin;"></div>
						<div style="position: absolute; left: 0px; right: 0px; top: 0px; bottom: 0px; border-radius: 50%; border-color: #464646; border-style: solid; border-width: thin;"></div>
					</div>
				</div>
			</div>`;
		
		this.minimum = 0.0;
		this.maximum = 1.0;
		
		this.minimumY = 0.0;
		this.maximumY = 1.0;
		
		// forward changes to "value" and "valueY" as "saturation" and "brightness" respectively
		this.on(PropertyChangeEvent.PROPERTY_CHANGE, (originalPropertyChangeEvent: PropertyChangeEvent) => {
			var propertyName: string = originalPropertyChangeEvent.propertyName;
			if ((propertyName === 'brightness') || (propertyName === 'valueY')) {
				console.log('change: ', propertyName, originalPropertyChangeEvent.value);
			}
			switch (propertyName) {
				case 'value':
				case 'valueY':
					var propertyChangeEvent = new PropertyChangeEvent();
					if (propertyName === 'value') {
						propertyChangeEvent.value = originalPropertyChangeEvent.value;
						propertyChangeEvent.propertyName = 'saturation';
					}
					else if (propertyName === 'valueY') {
						propertyChangeEvent.propertyName = 'brightness';
						// invert since lower brightness along bottom
						// would prefer to invert minimum and maximum
						propertyChangeEvent.value = this.maximumY - originalPropertyChangeEvent.value;
					}
					this.trigger(PropertyChangeEvent.PROPERTY_CHANGE, propertyChangeEvent);
					break;
			}
			
			// trigger repaint of thumb
			switch (propertyName) {
				case 'value':
					this.invalidateProperty('saturation');
					break;
				case 'valueY':
					this.invalidateProperty('brightness');
					break;
			}
		});
	}
	
	//--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  hue
    //----------------------------------
	
	private _hue: number;
	
	/**
	 * Hue, from 0 to 360, representing angle along color wheel.
	 */
	set hue(value: number) {
		this._hue = value;
		this.invalidateProperty('hue');
	}
	get hue(): number {
		return this._hue;
	}
	
	//----------------------------------
    //  saturation
    //----------------------------------
	
	private _saturation: number;
	
	/**
	 * Saturation, from 0 to 1.
	 */
	set saturation(value: number) {
		this._saturation = value;
		this.value = value;
	}
	get saturation(): number {
		return this._saturation;
	}
	
	//----------------------------------
    //  brightness
    //----------------------------------
	
	private _brightness: number;
	
	/**
	 * Brightness, from 0 to 1.
	 */
	set brightness(value: number) {
		this._brightness = value;
		// invert since lower brightness is on bottom
		// would prefer to invert minimum and maximum
		this.silent = true;
		this.valueY = this.maximumY - value;
		this.silent = false;
	}
	get brightness(): number {
		return this._brightness;
	}
	
	//----------------------------------
    //  transition
    //----------------------------------
	
	private _transition: boolean = true;
	
	/**
	 * Whether or not external changes should result in transitions
	 * to the background color and cursor position.
	 * Can be disabled when a more immediate response is desired (e.g. dragging slider that controls hue).
	 * The transitions themselves are controlled via CSS.
	 */
	set transition(value: boolean) {
		if (this._transition !== value) {
			this._transition = value;
			this.invalidateProperty('transition');
		}
	}
	get transition(): boolean {
		return this._transition;
	}
	
	//--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------
	
	/**
     * PolymerJS
     */
    created(): void {
        super.created();

        // apply the constructor manually to the instance
        SaturationBrightnessBox.apply(this);

        this.applyAttributes();
    }
	
	/**
	 * @inheritDoc
	 */
	validateProperties(changed?): void {
		super.validateProperties(changed);
		
		let allChanged = !changed;
		
		if (allChanged || changed.transition) {
			if (this.transition) {
				this.root.classList.add('transition');
			}
			else {
				this.root.classList.remove('transition');
			}
		}
		
		if (allChanged || changed.hue) {
			let hueElement = this.getPart(SaturationBrightnessBox.PART_HUE);
			if (hueElement) {
				let rgba = ColorUtil.hsbToRGBA(this._hue, 1.0, 1.0);
				hueElement.style.backgroundColor = rgba.toCSS();
			}
		}
		
		if (allChanged || changed.hue || changed.saturation || changed.brightness) {
			let thumbBackground = this.getPart(SaturationBrightnessBox.PART_THUMB_BACKGROUND);
			if (thumbBackground) {
				let rgba = ColorUtil.hsbToRGBA(this._hue, this._saturation, this._brightness);
				thumbBackground.style.backgroundColor = rgba.toCSS();
			}
		}
	}
}
export = SaturationBrightnessBox;