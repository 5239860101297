<div [class.initialized]="initializedUser" [class.user]="signedIn">
    <!-- keep button around always to simplify triggering menu programmatically -->
    <button class="sign-in-button" mat-flat-button (click)="signIn();">Sign in</button>
    <profile-picture class="pic" *ngIf="initializedUser && signedIn" [user]="user" style="width: 36px; height: 36px; cursor: pointer;" [matMenuTriggerFor]="menu"></profile-picture>
    <mat-menu #menu="matMenu" (closed)="_menuClosed($event)" class="menu" backdropClass="backdrop" style="position: absolute; right: 200px;">
        <div *ngIf="signedIn" class="details">
            <profile-picture [user]="user" class="details-pic"></profile-picture>
            <mat-form-field class="details-name" (click)="_nameInputClickHandler($event)">
                <input #nameInput matInput type="text" [(ngModel)]="_nameModel" [disabled]="_editing ? false : true" (change)="_updateName($event);">
                <button *ngIf="!_editing && !_updatingName" mat-button matSuffix mat-icon-button aria-label="Edit display name" style="margin-top: 10px;" (click)="_editName($event)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button *ngIf="_editing && !_updatingName" mat-button matSuffix mat-icon-button aria-label="Clear display name" style="margin-top: 10px;" (click)="_pendingName = ''">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-spinner *ngIf="_updatingName" matSuffix diameter="22" style="top: 6px; right: 2px"></mat-spinner>
            </mat-form-field>
            <button mat-flat-button class="sign-out-button" (click)="signOut();">Sign out</button>
        </div>
    </mat-menu>
</div>
