import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';

import PropertyChangeEvent = require('events/property-change-event');
import HueSlider = require('controls/colorPickers/hue-slider');

@Component({
    selector: 'hue-slider',
    template: '<div #holder class="hueSlider" style="width: 100%; height: 100%;"></div>',
    encapsulation: ViewEncapsulation.None,
    // styles loaded via styles.scss to keep pathing correct in deployment
    //styleUrls: ['./hue-slider.component.scss'],
})
export class HueSliderComponent extends HueSlider {

    /**
     * @constructor
     */
    constructor() {
        super();

        // generic handler for property change events that triggers the corresponding EventEmitter
        this.on(PropertyChangeEvent.PROPERTY_CHANGE, (event: PropertyChangeEvent) => {
            let propertyName: string = event ? event.propertyName : null;
            let value = event ? event.value : this[propertyName];
            if (propertyName === 'value') {
                propertyName = 'hue';
            }
            let emitter = this[propertyName + 'Change'];
            if (emitter) {
                emitter.emit(value);
            }
        });
    }

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    @ViewChild("holder", { read: ElementRef }) holder: ElementRef;

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  hue
    //----------------------------------
	
	/**
	 * Hue, from 0 to 360, representing angle along color wheel.
	 */
    @Input()
	set hue(value: number) {
		this.value = value;
	}
	get hue(): number {
		return this.value;
    }
    @Output() hueChange = new EventEmitter<number>();
	
    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------

    ngAfterViewInit() {
        this.holder.nativeElement.append(this.el);
    }
}