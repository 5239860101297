import IEventDispatcher = require('events/i-event-dispatcher');
import PropertyChangeEvent = require('events/property-change-event');

class BindingUtil {

    /**
     * Makes a property of the specified class bindable.
     * Whenever an instance of the class has this property modified (e.g. model.property = newValue),
     * the instance will trigger a PropertyChangeEvent.
     * The <code>nested</code> argument will cause instances to listen for internal changes
     * on the specified property and trigger PropertyChangeEvents for those as well.
     * e.g. model.property.internal changes => model triggers PropertyChangeEvent for "property".
     */
    static bindable(clazz, propertyNameOrNames: string | string[], nested: boolean = false): void {
        if (propertyNameOrNames instanceof Array) {
            const propertyNames: string[] = (<string[]>propertyNameOrNames);
            const len: number = propertyNames ? propertyNames.length : 0;
            for (let i: number = 0; i < len; i++) {
                const propertyName: string = propertyNames[i];
                BindingUtil._bindableProperty(clazz, propertyName, nested);
            }
        }
        else {
            BindingUtil._bindableProperty(clazz, propertyNameOrNames, nested);
        }
    }
    private static _bindableProperty(clazz, propertyName: string, nested: boolean = false): void {
        if (Object.isSealed(clazz)) {
            throw new Error(clazz + ' is sealed and cannot be made bindable');
        }

        const propertyDescriptor = Object.getOwnPropertyDescriptor(clazz.prototype, propertyName);
        if (propertyDescriptor && !propertyDescriptor.configurable) {
            throw new Error(clazz + '.' + propertyName + 'is not configurable and cannot be made bindable');
        }

        const internalVariableName: string = '_' + propertyName;

        const originalGetter = (propertyDescriptor && propertyDescriptor.get) ? propertyDescriptor.get : null;
        const originalSetter = (propertyDescriptor && propertyDescriptor.set) ? propertyDescriptor.set : null;

        // handler for when an internal property changes
        // triggers as our own
        // Example: binding created on instance.model; model.property changes triggers PropertyChangeEvent on instance.model
        const internalPropertyChangeHandler = function(internalPropertyChangeEvent: PropertyChangeEvent): void {
            const instance: IEventDispatcher = this;

            const propertyChangeEvent = new PropertyChangeEvent();
            propertyChangeEvent.propertyName = propertyName;
            //propertyChangeEvent.oldValue = oldValue;
            propertyChangeEvent.value = instance[propertyName];
            instance.trigger(PropertyChangeEvent.PROPERTY_CHANGE, propertyChangeEvent);
        };
        let boundInternalPropertyChangeHandler;

        // create setter if one doesn't exist
        // could do originalSetter check inline to save code, but this setup will not incur unnecessary overhead
        if (originalSetter) {
            Object.defineProperty(clazz.prototype, propertyName, {
                set: function (value) {
                    const instance: IEventDispatcher = this;

                    //console.log("set : " + propertyName +' on ', instance);
                    const oldValue = instance[propertyName];

                    // stop listening for internal changes
                    if (nested && oldValue && oldValue.off) {
                        (<IEventDispatcher>oldValue).off(PropertyChangeEvent.PROPERTY_CHANGE, boundInternalPropertyChangeHandler);
                    }

                    // must call prior to dispatching event below so that new value is available in handlers
                    originalSetter.call(instance, value);

                    if (nested && value && value.on) {
                        // need 'this' context from setter
                        if (!boundInternalPropertyChangeHandler) {
                            boundInternalPropertyChangeHandler = internalPropertyChangeHandler.bind(this);
                        }
                        (<IEventDispatcher>value).on(PropertyChangeEvent.PROPERTY_CHANGE, boundInternalPropertyChangeHandler);
                    }

                    if (oldValue !== value) {
                        const propertyChangeEvent = new PropertyChangeEvent();
                        propertyChangeEvent.propertyName = propertyName;
                        propertyChangeEvent.oldValue = oldValue;
                        propertyChangeEvent.value = value;
                        instance.trigger(PropertyChangeEvent.PROPERTY_CHANGE, propertyChangeEvent);
                    }
                },
                configurable: true
            });
        }
        else {
            Object.defineProperty(clazz.prototype, propertyName, {
                set: function (value) {
                    const instance: IEventDispatcher = this;

                    const oldValue = instance[propertyName];

                    // stop listening for internal changes
                    if (nested && oldValue && oldValue.off) {
                        (<IEventDispatcher>oldValue).off(PropertyChangeEvent.PROPERTY_CHANGE, internalPropertyChangeHandler);
                    }

                    instance[internalVariableName] = value;

                    if (nested && value && value.on) {
                        // need 'this' context from setter
                        if (!boundInternalPropertyChangeHandler) {
                            boundInternalPropertyChangeHandler = internalPropertyChangeHandler.bind(this);
                        }
                        (<IEventDispatcher>value).on(PropertyChangeEvent.PROPERTY_CHANGE, boundInternalPropertyChangeHandler);
                    }

                    if (oldValue !== value) {
                        const propertyChangeEvent = new PropertyChangeEvent();
                        propertyChangeEvent.propertyName = propertyName;
                        propertyChangeEvent.oldValue = oldValue;
                        propertyChangeEvent.value = value;
                        instance.trigger(PropertyChangeEvent.PROPERTY_CHANGE, propertyChangeEvent);
                    }
                },
                configurable: true
            });

            if (originalGetter) {
                Object.defineProperty(clazz.prototype, propertyName, {
                    get: function () {
                        // ensure the original getter still gets called
                        // Note: the behavior may be different if the original getter calculated its return value
                        // the new getter will always return the same value that was set
                        originalGetter.call(this);

                        return this[internalVariableName];
                    },
                    configurable: true
                });
            }
            else {
                Object.defineProperty(clazz.prototype, propertyName, {
                    get: function () {
                        return this[internalVariableName];
                    },
                    configurable: true
                });
            }
        }
    }
}
export = BindingUtil;
