// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import "firebase/auth";

import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { LoginComponent } from "../login/login.component";

@Component({
  selector: 'profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileButtonComponent {

    @ViewChild('nameInput') nameInputRef: ElementRef;

    /**
     * Whether we have initialized the current user.
     * Used to prevent flashing the "Sign in" button in the case that a user is signed in,
     * but we haven't completed initializing.
     */
    public initializedUser: boolean = false;

    /**
     * The current  user that is signed in, or null if no user is signed in.
     */
    public user: firebase.User;

    /**
     * Whether or not we are explicitly signed in.
     * Anonymously signed in is not considered signed in. 
     */
    public signedIn: boolean;

    /**
     * @private - public for template
     */
    public _editing: boolean = false;

    /**
     * String corresponding to the name input value.
     */
    public _nameModel: string;

    /**
     * Whether we are currently processing a name change.
     */
    public _updatingName: boolean;

    /**
     * @constructor
     */
    constructor(
        private cd: ChangeDetectorRef,
    ) {
        firebase.auth().onAuthStateChanged(async (user: firebase.User) => {
            if (!user) {
                // sign user in anonymously to allow all users to "like" 
                firebase.auth().signInAnonymously();
                return;
            }
            this.user = user;
            this.signedIn = this.user && !this.user.isAnonymous;
            this._nameModel = user.displayName;
            this.initializedUser = true;
            this.cd.detectChanges();
        });
    }

    public signIn(): void {
        LoginComponent.show();
    }

    public async signOut(): Promise<void> {
        try {
            await firebase.auth().signOut();
        }
        catch (e) {
        }
    }

    /**
     * @private - public for template
     * Handler for when the name label is clicked on.
     * Enters into edit mode so that the user can update his/her display name.
     */
    public _editName = (event): void => {
        // prevent dialog from dismissing
        event.preventDefault();
        event.stopPropagation();

        this._editing = true;

        window.requestAnimationFrame(() => {
            if (this.nameInputRef && this.nameInputRef.nativeElement) {
                this.nameInputRef.nativeElement.focus();
            }
        });
    }

    /**
     * @private - public for template
     * Handler for when the name input is clicked.
     * Prevents dialog dismissal.
     */
    public _nameInputClickHandler = (event): void => {
        // prevent dialog from dismissing
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * @private - public for template
     * Updates the current users display name.
     */
    public async _updateName(event): Promise<void> {
        if (!this._updatingName) {
            this._updatingName = true;
            this.cd.detectChanges();
            const user = firebase.auth().currentUser;

            await user.updateProfile({
                displayName: this._nameModel
            });
            this._editing = false;
            this._updatingName = false;
            this.cd.detectChanges();
        }
    }

    /**
     * @private - public for template.
     */
    public _menuClosed(event): void {
        this._editing = false;
    }
}
