import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import View = require('ui/view');
import { HueSliderComponent } from 'controls/colorPickers/hue-slider.component';
import { SaturationBrightnessBoxComponent } from 'controls/colorPickers/saturation-brightness-box.component';

@Component({
  selector: 'hsb-color-picker',
  templateUrl: './hsb-color-picker.component.html',
  styleUrls: ['./hsb-color-picker.component.scss'],
})
export class HSBColorPickerComponent extends View {

    //--------------------------------------------------------------------------
    //
    //  Events
    //
    //--------------------------------------------------------------------------

    @Output()
    change: EventEmitter<number> = new EventEmitter<number>();

    /**
     * @constructor
     */
    constructor() {
      super();
    }

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    @ViewChild('hueSlider') hueSlider: HueSliderComponent;
    @ViewChild('satBrightnessBox') satBrightnessBox: SaturationBrightnessBoxComponent;
    
    private _dirty: boolean = false;

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------
    
    //----------------------------------
    //  h
    //----------------------------------

    private _h: number;

    /**
     * The hue value, from 0 to 360.
     */
    set h(value: number) {
      if (this._h !== value) {
        this._h = value;
        this._markForDispatch();
      }
    }
    get h(): number {
      return this._h;
    }

    //----------------------------------
    //  s
    //----------------------------------

    private _s: number;

    /**
     * The saturation value, from 0.0 to 1.0.
     */
    set s(value: number) {
      if (this._s !== value) {
        this._s = value;
        this._markForDispatch();
      }
    }
    get s(): number {
      return this._s;
    }

    //----------------------------------
    //  b
    //----------------------------------

    private _b: number;

    /**
     * The brightness value, from 0.0 to 1.0.
     */
    set b(value: number) {
      if (this._b !== value) {
        this._b = value;
        this._markForDispatch();
      }
    }
    get b(): number {
      return this._b;
    }

    //----------------------------------
    //  dispatch
    //----------------------------------

    /**
     * Whether or not to dispatch events due to property changes.
     */
    dispatch: boolean = true;

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    layout(): void {
      super.layout();
      
      this.layoutChild(this.hueSlider);
      this.layoutChild(this.satBrightnessBox._instance);
    }

    private _markForDispatch(): void {
      if (!this.dispatch) {
          return;
      }

      // notify external listeners to change to overall color
      // prevent multiple immediate changes from triggering multiple change events
      // saturation and brightness will both change simultaneously,
      // but there is no need to trigger two events
      if (!this._dirty) {
          this._dirty = true;
          
          window.requestAnimationFrame(() => {
              this._dirty = false;
              this.change.emit();
          });
        }
    }

    /* private (Angular-CLI #5621) */ _validateInput = (event, propertyName: string, min: number = 0, max: number = 100, factor: number = null) => {
      let value = event.target.value;
      let originalValue = value * factor;
      
      value = Math.round(value);

      // apply prior to enforcing min/max to minimize floating point issues
      // this means that the min/max should also be set in relation to the factor
      // e.g. an input that allows a user to enter values between 0 and 100 
      // though internally should result in values between 0.0 and 1.0
      // would have a factor of 100, min of 0, and max of 100.

      if (factor) {
        value = value * factor;
      }

      if (value < min) {
        value = min;  
      }
      else if (value > max) {
        value = max;
      }

      // force setting of input
      // otherwise the input element may not get updated since the underlying value may have already been clamped
      if (value !== originalValue) {
        let stringValue = factor ? value / factor : value;
        event.target.value = String(stringValue);
      }

      this[propertyName] = value;
    }
}