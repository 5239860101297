import BindingUtil = require('utils/binding-util');
import EventDispatcher = require('events/event-dispatcher');
import PropertyChangeEvent = require('events/property-change-event');

class RGBA extends EventDispatcher {

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    constructor()
    constructor(r: number, g: number, b: number, a?: number)
    constructor(rgba: RGBA)
    constructor(color: number, alpha?: number)
    constructor(...args) {
        super();
        (<any>this)._iddd = Math.round(Math.random() * 1000);

        var defaultAlpha: number = 1;
        var a: number;

        var numArgs = args ? args.length : 0;
        if (numArgs >= 3) {
            this._r = args[0];
            this._g = args[1];
            this._b = args[2];
            a = args[3]
            
        }
        else if (numArgs >= 1) {
            if (args[0] instanceof RGBA) {
                var rgba: RGBA = args[0];
                this._setColor(rgba.color, false);
                a = rgba.a;
            }
            else {
                var color: number = args[0];
                this._setColor(color, false);
                a = args[1];
            }
        }
        this._a = (typeof a !== 'undefined') ? a : defaultAlpha;
        this._colorDirty = true;
    }

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    private _colorDirty: boolean = false;

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  r
    //----------------------------------

    private _r: number = 0;

    private static _bindableR = BindingUtil.bindable(RGBA, 'r');
    
    /**
     * Red component, from 0 to 255.
     */
    set r(value: number) {
        this._r = value;
        this._colorDirty = true;
    }
    get r(): number {
        return this._r;
    }

    //----------------------------------
    //  g
    //----------------------------------

    private _g: number = 0;

    private static _bindableG = BindingUtil.bindable(RGBA, 'g');
    
    /**
     * Green component, from 0 to 255.
     */
    set g(value: number) {
        this._g = value;
        this._colorDirty = true;
    }
    get g(): number {
        return this._g;
    }

    //----------------------------------
    //  b
    //----------------------------------

    private _b: number = 0;

    private static _bindableB = BindingUtil.bindable(RGBA, 'b');
    
    /**
     * Blue component, from 0 to 255.
     */
    set b(value: number) {
        this._b = value;
        this._colorDirty = true;
    }
    get b(): number {
        return this._b;
    }

    //----------------------------------
    //  a
    //----------------------------------

    private _a: number = 1;

    private static _bindableA = BindingUtil.bindable(RGBA, 'a');
    
    /**
     * Alpha component, from 0 to 1.
     */
    set a(value: number) {
        this._a = value;
    }
    get a(): number {
        return this._a;
    }

    //----------------------------------
    //  color
    //----------------------------------

    private _color: number;

    private static _bindableColor = BindingUtil.bindable(RGBA, 'color');

    set color(value: number) {
        this._setColor(value, true);
    }
    private _setColor(value: number, trigger: boolean = true) {
        var r = value >> 16;
        var g = value >> 8 & 0xFF;
        var b = value & 0xFF;
        
        if (trigger) {
            this.r = r;
            this.g = g;
            this.b = b;
        }
        else {
            this._r = r;
            this._g = g;
            this._b = b;
        }
    }
    
    get color(): number {
        if (this._colorDirty) {
            this._colorDirty = false;
            
            var color: number = this.r << 16;
            color = color | (this.g << 8);
            color = color | this.b;
            this._color = color;
        }
        return this._color;
    }

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * Returns the CSS value corresponding to the current RGBA values.
     */
    toCSS(): string {
        // browser only supports integer
        // https://developer.mozilla.org/en-US/docs/Web/CSS/color_value
        var r = Math.round(this.r);
        var g = Math.round(this.g);
        var b = Math.round(this.b);
        var a = Math.round(this.a);
        
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
    }
}
export = RGBA;