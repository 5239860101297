<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.message}}</p>
</div>
<mat-dialog-actions align="end">
    <button *ngFor="let button of data.buttons; let i = index"
        [color]="button.color"
        mat-flat-button
        class="dialogButton"
        [attr.data-index]="i"
        (click)="onClick($event)">
        {{button.label}}
    </button>
</mat-dialog-actions>