/**
 * Defines the return type from FeatureManager.info()
 * Contains enough information to prompt the user to purchase the feature, if not available.
 */
class FeatureInfo {
    
    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------
    
    /**
     * @constructor
     */
    constructor(available: boolean = false) {
        this.available = available;
    }
    
    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------
    
    //----------------------------------
    //  id
    //----------------------------------
    
    /**
     * Unique ID of this feature.
     */
    id: string;
    
    //----------------------------------
    //  available
    //----------------------------------
    
    /**
     * Whether or not the feature is available.
     */
    available: boolean;
    
    //----------------------------------
    //  title
    //----------------------------------
    
    /**
     * The title for the user prompt, when the feature is not available.
     */
    title: string;
    
    //----------------------------------
    //  message
    //----------------------------------
    
    /**
     * The message for the user prompt, when the feature is not available.
     */
    message: string;
    
    //----------------------------------
    //  details
    //----------------------------------
    
    /**
     * Additional details to pass along when retrieving feature info.
     * These will vary from feature to feature. 
     * E.g. Maximum export size. 
     */
    details: any;
}
export = FeatureInfo;