import vertexShader from './blend-vertex-shader';
import fragmentShader from './blend-fragment-shader';

import ArrayUtil = require('utils/array-util');
import CollectionEvent = require('events/collection-event');
import Gradient = require('graphics/gradient');
import GradientUtil = require('utils/gradient-util');
import GradientEntry = require('graphics/gradient-entry');
import Blend = require('graphics/blend');
import BlendPath = require('graphics/blend-path');
import IList = require('collections/i-list');
import RGBA = require('graphics/rgba');
import Rectangle = require('geom/rectangle');
import SortUtil = require('utils/sort-util');
import View = require('ui/view');

import * as THREE from 'three';

class BlendRenderer extends View {
    private static _sourceMaterial = new THREE.ShaderMaterial({
        vertexShader: vertexShader,
        fragmentShader: fragmentShader
    });
    
    // TODO: move?
    private static rgbaToUniform(rgba: RGBA, includeAlpha: boolean = true): any {
        var r: number = rgba.r / 256;
        var g: number = rgba.g / 256;
        var b: number = rgba.b / 256;

        if (includeAlpha) {
            return {
                type: "v4",
                value: new THREE.Vector4(r, g, b, rgba.a)
            };
        }
        else {
            return {
                type: "v3",
                value: new THREE.Vector3(r, g, b)
            };
        }
    }

    private static numberToUniform(num: number, int: boolean = false): any {
        if (int) {
            return {
                type: "i",
                value: num
            }
        }
        else {
            return {
                type: "f",
                value: num
            }
        }
    }

    private static mergeOffsets(entries: IList<GradientEntry>, offsets: number[]): void {
        var numEntries: number = entries ? entries.length : 0;
        for (var i: number = 0; i < numEntries; i++) {
            var entry: GradientEntry = entries.getItemAt(i);
            var offset: number = entry.offset;
            if (offsets.indexOf(offset) === -1) {
                offsets.push(offset);
            }
        }
    }

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    private _scene;
    private _renderer;
    private _camera;
    private _entryCompareFunction;
    private _meshPool = [];

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor 
     */
    constructor() {
        super();
        
        var renderer = this._renderer = new THREE.WebGLRenderer({ preserveDrawingBuffer: true, alpha: true, premultipliedAlpha: false });

        // prevent right-click Save Image
        renderer.domElement.style.pointerEvents = 'none';

        (<any>this).uid = Math.round(Math.random() * 1000);

        // must add into subview rather than replace since we want to use this as Polymer element also       
        var subView = new View();
        subView.el = renderer.domElement;
        this.addChild(subView);

        this._entryCompareFunction = SortUtil.compareBy('offset');
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------
    
    //----------------------------------
    //  blend
    //----------------------------------
    
    private _blend: Blend;
    
    set blend(value: Blend) {
        this._blend = value;
        this.invalidateProperty('blend');
    }
    get blend(): Blend {
        return this._blend;
    }
    
    //----------------------------------
    //  rect
    //----------------------------------

    rect: Rectangle;

    //----------------------------------
    //  rotation
    //----------------------------------

    rotation: number;
        
    //----------------------------------
    //  canvas
    //----------------------------------
    
    /**
     * The canvas that is rendered to.
     * Exposed to allow others to use as the source of drawImage.
     */
    get canvas(): HTMLCanvasElement {
        return this._renderer.domElement;
    }
    
    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * @inheritDoc
     */
    initialize(): void {
        super.initialize();

        var scene = this._scene = new THREE.Scene();
        var camera = this._camera = new THREE.OrthographicCamera(0, 1, 1, 0, 1, 1000);
        camera.position.z = 1;
        
        this.render();
    }

    /**
     * @inheritDoc
     */
    layout(): void {
        super.layout();

        if ((this.pixelWidth > 0) && (this.pixelHeight > 0)) {
            this._renderer.setSize(this.pixelWidth, this.pixelHeight);
            this.render();
        }
    }

    /**
     * @inheritDoc
     */
    validateProperties(changed?: any): void {
        var allChanged: boolean = (changed === null);

        if (allChanged || changed.blend) {
            // avoid rendering if layout also invalidated to avoid duplicate render
            // renderer must be sized at least once prior to rendering
            if (!this._invalidLayout) {
                this.render();
            }
        }
    }

    render(): void {
        // console.log('render: '+(<any>this).uid +' at: '+this.pixelWidth+', '+this.pixelHeight);
        
        // haven't been initialized yet
        if (!this._scene) {
            return;
        }
        
        // var tStart = Date.now();

        // remove all existing children; there is probably a more efficient way to do this
        var children: any[] = this._scene ? this._scene.children : null;
        if (children) {
            while (children.length > 0) {
                var mesh: THREE.Mesh = children[0];
                mesh.geometry.dispose();
                // do not dispose of the material which results in expensive call to initMaterial (~10ms on Surface Pro 3)
                // there does not appear to be any memory leak despite not disposing
                // mesh.material.dispose();
                this._scene.remove(mesh);
            }
        }
        
        var meshCount = 0;

        var blend = this.blend;
        var paths: IList<BlendPath> = blend ? blend.paths : null;
        var numPaths: number = paths ? paths.length : 0;
        
        if (numPaths === 0) {
            // render empty scene
            if (this._renderer) {
                var emptyGeometry = new (<any>THREE).PlaneBufferGeometry(1, 1);
                var emptyMaterial = new THREE.MeshBasicMaterial( {color: 0x000000} );
                var emptyPlane = new THREE.Mesh( emptyGeometry, emptyMaterial );
                emptyPlane.position.x = 0.5;
                emptyPlane.position.y = 0.5;
                this._scene.add(emptyPlane);
                this._renderer.render(this._scene, this._camera);
            }
            return;
        }

        // sort by offset from smallest to biggest
        var sortedPaths: BlendPath[] = [];
        var sortedAlphaPaths: BlendPath[] = [];
        var sortedColorPaths: BlendPath[] = [];
        var offset0Found: boolean = false;
        var offset1Found: boolean = false;
        for (var i: number = 0; i < numPaths; i++) {
            var path: BlendPath = paths.getItemAt(i);
            var cleanEntry: BlendPath;

            ArrayUtil.sortedInsert(sortedPaths, path, this._entryCompareFunction);

            if (path.gradient.alphaEntries && (path.gradient.alphaEntries.length > 0)) {
                // don't insert line at same position as another of same type
                if (ArrayUtil.binarySearch(sortedAlphaPaths, path, true, this._entryCompareFunction) === -1) {
                    //ArrayUtil.sortedInsert(sortedAlphaPaths, entry, this._entryCompareFunction);
                    // SBLENDED-56 remove duplicate entries
                    cleanEntry = path.clone(true, false);
                    GradientUtil.dedupeGradient(cleanEntry.gradient);
                    ArrayUtil.sortedInsert(sortedAlphaPaths, cleanEntry, this._entryCompareFunction);
                }
            }
            if (path.gradient.colorEntries && (path.gradient.colorEntries.length > 0)) {
                // don't insert line at same position as another of same type
                if (ArrayUtil.binarySearch(sortedColorPaths, path, true, this._entryCompareFunction) === -1) {
                    //ArrayUtil.sortedInsert(sortedColorPaths, entry, this._entryCompareFunction);
                    // SBLENDED-56 remove duplicate entries
                    cleanEntry = path.clone(true, false);
                    GradientUtil.dedupeGradient(cleanEntry.gradient);
                    ArrayUtil.sortedInsert(sortedColorPaths, cleanEntry, this._entryCompareFunction);
                }
            }


            if (path.offset === 0) {
                offset0Found = true;
            }
            if (path.offset === 1) {
                offset1Found = true;
            }
        }

        // ensure that entries exist for offset 0 and 1 so that entire renderer is filled
        // use existing setup and just use the outermost gradients again
        // TODO: potential optimization for area outside of actual offset limits since these areas
        // could be rendered with simple, linear gradient
        if (!offset0Found) {
            var firstPath: BlendPath = sortedPaths[0];
            var zeroEntry: BlendPath = firstPath.clone(false, false);
            zeroEntry.offset = 0;
            sortedPaths.unshift(zeroEntry);
        }
        if (!offset1Found) {
            var lastEntry: BlendPath = sortedPaths[sortedPaths.length - 1];
            var oneEntry: BlendPath = lastEntry.clone(false, false);
            oneEntry.offset = 1;
            sortedPaths.push(oneEntry);
        }

        numPaths = sortedPaths.length;
        for (var i: number = 0; i < numPaths - 1; i++) {
            var startEntry: BlendPath = sortedPaths[i];
            var endEntry: BlendPath = sortedPaths[i + 1];

            var startLineOffset: number = startEntry.offset;
            var endLineOffset: number = endEntry.offset;
            var dLineOffset = endEntry.offset - startLineOffset;

            // color lines

            var previousColorGradientIdx: number = ArrayUtil.binarySearch(sortedColorPaths, startEntry, false, this._entryCompareFunction);
            // if not exact match for this offset, such as when the offset is for an alpha entry, the search will return the one afterwards
            if (!sortedColorPaths[previousColorGradientIdx] || (sortedColorPaths[previousColorGradientIdx].offset !== startEntry.offset)) {
                previousColorGradientIdx -= 1;
            }
            previousColorGradientIdx = Math.max(previousColorGradientIdx, 0);
            var previousColorGradientStackEntry: BlendPath = sortedColorPaths[previousColorGradientIdx];
            var previousColorGradient: Gradient = previousColorGradientStackEntry.gradient;

            var nextColorGradientIdx: number = ArrayUtil.binarySearch(sortedColorPaths, endEntry, false, this._entryCompareFunction);
            //// if not exact match for this offset, such as when the offset is for an alpha entry, the search will return the one afterwards
            //if (!sortedColorEntries[nextColorGradientIdx] || (sortedColorEntries[nextColorGradientIdx].offset !== endEntry.offset)) {
            //    nextColorGradientIdx -= 1;
            //}
            nextColorGradientIdx = Math.min(nextColorGradientIdx, sortedColorPaths.length - 1);
            var nextColorGradientStackEntry: BlendPath = sortedColorPaths[nextColorGradientIdx];
            var nextColorGradient: Gradient = nextColorGradientStackEntry ? nextColorGradientStackEntry.gradient : null;

            var colorGradientOffsetStart;
            var colorGradientOffsetRange;
            var colorGradientOffsetDelta: number;// = nextColorGradientStackEntry.offset - previousColorGradientStackEntry.offset;
            if (previousColorGradientStackEntry && nextColorGradientStackEntry) {
                colorGradientOffsetDelta = nextColorGradientStackEntry.offset - previousColorGradientStackEntry.offset;
            }
            else {
                colorGradientOffsetDelta = 0;
            }

            if (colorGradientOffsetDelta === 0) {
                colorGradientOffsetStart = BlendRenderer.numberToUniform(0);
                colorGradientOffsetRange = BlendRenderer.numberToUniform(0);
            }
            else {
                colorGradientOffsetStart = BlendRenderer.numberToUniform((startLineOffset - previousColorGradientStackEntry.offset) / colorGradientOffsetDelta);
                colorGradientOffsetRange = BlendRenderer.numberToUniform(dLineOffset / colorGradientOffsetDelta);
            }

            // alpha lines

            var previousAlphaGradientIdx: number = ArrayUtil.binarySearch(sortedAlphaPaths, startEntry, false, this._entryCompareFunction);
            // if not exact match for this offset, such as when the offset is for a color entry, the search will return the one afterwards
            if (!sortedAlphaPaths[previousAlphaGradientIdx] || (sortedAlphaPaths[previousAlphaGradientIdx].offset !== startEntry.offset)) {
                previousAlphaGradientIdx -= 1;
            }
            previousAlphaGradientIdx = Math.max(previousAlphaGradientIdx, 0);
            var previousAlphaGradientStackEntry: BlendPath = sortedAlphaPaths[previousAlphaGradientIdx];
            var previousAlphaGradient: Gradient = previousAlphaGradientStackEntry ? previousAlphaGradientStackEntry.gradient : null;

            var nextAlphaGradientIdx: number = ArrayUtil.binarySearch(sortedAlphaPaths, endEntry, false, this._entryCompareFunction);
            //// if not exact match for this offset, such as when the offset is for a color entry, the search will return the one afterwards
            //if (!sortedAlphaEntries[nextAlphaGradientIdx] || (sortedAlphaEntries[nextAlphaGradientIdx].offset !== endEntry.offset)) {
            //    nextAlphaGradientIdx -= 1;
            //}
            nextAlphaGradientIdx = Math.min(nextAlphaGradientIdx, sortedAlphaPaths.length - 1);
            var nextAlphaGradientStackEntry: BlendPath = sortedAlphaPaths[nextAlphaGradientIdx];
            var nextAlphaGradient: Gradient = nextAlphaGradientStackEntry ? nextAlphaGradientStackEntry.gradient : null;

            var alphaGradientOffsetDelta: number;
            var alphaGradientOffsetStart;
            var alphaGradientOffsetRange;
            if (previousAlphaGradientStackEntry && nextAlphaGradientStackEntry ) {
                alphaGradientOffsetDelta = nextAlphaGradientStackEntry.offset - previousAlphaGradientStackEntry.offset;
            }
            else {
                alphaGradientOffsetDelta = 0;
            }
            
            if (alphaGradientOffsetDelta === 0) {
                alphaGradientOffsetStart = BlendRenderer.numberToUniform(0.0);
                alphaGradientOffsetRange = BlendRenderer.numberToUniform(0.0);
            }
            else {
                alphaGradientOffsetStart = BlendRenderer.numberToUniform((startLineOffset - previousAlphaGradientStackEntry.offset) / alphaGradientOffsetDelta);
                alphaGradientOffsetRange = BlendRenderer.numberToUniform(dLineOffset / alphaGradientOffsetDelta);
            }

            var startGradient: Gradient = startEntry.gradient;
            var endGradient: Gradient = endEntry.gradient;

            // create a plane between entry on either gradient and interpolated value of the other
            // always include the bounds 0 and 1
            var combinedOffsets = [0, 1];
            //var flattenedStartGradientEntries = GradientUtil.flattenGradient(startGradient);
            //var flattenedEndGradientEntires = GradientUtil.flattenGradient(endGradient);
            //BlendRenderer.mergeOffsets(flattenedStartGradientEntries, combinedOffsets);
            //BlendRenderer.mergeOffsets(flattenedEndGradientEntires, combinedOffsets);
            //BlendRenderer.mergeOffsets(startGradient.alphaEntries, combinedOffsets);
            //BlendRenderer.mergeOffsets(startGradient.colorEntries, combinedOffsets);
            //BlendRenderer.mergeOffsets(endGradient.alphaEntries, combinedOffsets);
            //BlendRenderer.mergeOffsets(endGradient.colorEntries, combinedOffsets);

            if (previousColorGradient) {
                BlendRenderer.mergeOffsets(previousColorGradient.colorEntries, combinedOffsets);
            }
            if (nextColorGradient) {
                BlendRenderer.mergeOffsets(nextColorGradient.colorEntries, combinedOffsets);
            }
            if (previousAlphaGradient) {
                BlendRenderer.mergeOffsets(previousAlphaGradient.alphaEntries, combinedOffsets);
            }
            if (nextAlphaGradient) {
                BlendRenderer.mergeOffsets(nextAlphaGradient.alphaEntries, combinedOffsets);
            }
            combinedOffsets.sort(SortUtil.numericCompareFunction);

            var numOffsets: number = combinedOffsets.length;
            var numPlanes: number = numOffsets - 1;
            for (var j: number = 0; j < numPlanes; j++) {
                var startOffset: number = combinedOffsets[j];
                var endOffset: number = combinedOffsets[j + 1];
                var dOffset: number = endOffset - startOffset;

                // colors

                // LOOK HERE: have GradientUtil.neighbors be smart about overlapping stops.  
                // can't just assume neighbors are +/- 1 from where offset is since thos

                var previousColorGradientNeighbors = GradientUtil.colorNeighbors(previousColorGradient, startOffset);
                var previousColorGradientPreviousEntry: GradientEntry = previousColorGradientNeighbors.previous;
                var previousColorGradientNextEntry: GradientEntry = previousColorGradientNeighbors.next;
                var previousColorGradientPreviousColor = BlendRenderer.rgbaToUniform(new RGBA(previousColorGradientPreviousEntry.color), false);
                var previousColorGradientNextColor = BlendRenderer.rgbaToUniform(new RGBA(previousColorGradientNextEntry.color), false);
                var previousColorGradientOffsetDelta: number = previousColorGradientNextEntry.offset - previousColorGradientPreviousEntry.offset;
                var previousColorGradientOffsetStart = BlendRenderer.numberToUniform((startOffset - previousColorGradientPreviousEntry.offset) / previousColorGradientOffsetDelta);
                var previousColorGradientOffsetRange = BlendRenderer.numberToUniform(dOffset / previousColorGradientOffsetDelta);
                
                var nextColorGradientPreviousColor;
                var nextColorGradientNextColor;
                var nextColorGradientOffsetStart;
                var nextColorGradientOffsetRange;
                if (nextColorGradient && (nextColorGradient !== previousColorGradient)) {
                    var nextColorGradientNeighbors = GradientUtil.colorNeighbors(nextColorGradient, startOffset);
                    var nextColorGradientPreviousEntry: GradientEntry = nextColorGradientNeighbors.previous;
                    var nextColorGradientNextEntry: GradientEntry = nextColorGradientNeighbors.next;
                    nextColorGradientPreviousColor = BlendRenderer.rgbaToUniform(new RGBA(nextColorGradientPreviousEntry.color), false);
                    nextColorGradientNextColor = BlendRenderer.rgbaToUniform(new RGBA(nextColorGradientNextEntry.color), false);
                    var nextColorGradientOffsetDelta: number = nextColorGradientNextEntry.offset - nextColorGradientPreviousEntry.offset;
                    nextColorGradientOffsetStart = BlendRenderer.numberToUniform((startOffset - nextColorGradientPreviousEntry.offset) / nextColorGradientOffsetDelta);
                    nextColorGradientOffsetRange = BlendRenderer.numberToUniform(dOffset / nextColorGradientOffsetDelta);
                }
                else {
                    nextColorGradientPreviousColor = previousColorGradientPreviousColor;
                    nextColorGradientNextColor = previousColorGradientNextColor;
                    nextColorGradientOffsetStart = previousColorGradientOffsetStart;
                    nextColorGradientOffsetRange = previousColorGradientOffsetRange;
                }

                // alphas
                var previousAlphaGradientPreviousAlpha;
                var previousAlphaGradientNextAlpha;
                var previousAlphaGradientOffsetStart;
                var previousAlphaGradientOffsetRange;
                if (previousAlphaGradient) {
                    var previousAlphaGradientNeighbors = GradientUtil.alphaNeighbors(previousAlphaGradient, startOffset);
                    var previousAlphaGradientPreviousEntry: GradientEntry = previousAlphaGradientNeighbors.previous;
                    var previousAlphaGradientNextEntry: GradientEntry = previousAlphaGradientNeighbors.next;
                    previousAlphaGradientPreviousAlpha = BlendRenderer.numberToUniform(previousAlphaGradientPreviousEntry.alpha, false);
                    previousAlphaGradientNextAlpha = BlendRenderer.numberToUniform(previousAlphaGradientNextEntry.alpha, false);
                    var previousAlphaGradientOffsetDelta: number = previousAlphaGradientNextEntry.offset - previousAlphaGradientPreviousEntry.offset;
                    if (previousAlphaGradientOffsetDelta === 0) {
                        previousAlphaGradientOffsetStart = BlendRenderer.numberToUniform(0.0);
                        previousAlphaGradientOffsetRange = BlendRenderer.numberToUniform(0.0);
                    }
                    else {
                        previousAlphaGradientOffsetStart = BlendRenderer.numberToUniform((startOffset - previousAlphaGradientPreviousEntry.offset) / previousAlphaGradientOffsetDelta);
                        previousAlphaGradientOffsetRange = BlendRenderer.numberToUniform(dOffset / previousAlphaGradientOffsetDelta);
                    }
                }
                else {
                    // no alpha entries at all; default to 1.0
                    previousAlphaGradientPreviousAlpha = BlendRenderer.numberToUniform(1.0);
                    previousAlphaGradientNextAlpha = BlendRenderer.numberToUniform(1.0);
                    previousAlphaGradientOffsetStart = BlendRenderer.numberToUniform(0.0);
                    previousAlphaGradientOffsetRange = BlendRenderer.numberToUniform(1.0);
                }

                var nextAlphaGradientPreviousAlpha;
                var nextAlphaGradientNextAlpha;
                var nextAlphaGradientOffsetStart;
                var nextAlphaGradientOffsetRange;
                if (nextAlphaGradient && (nextAlphaGradient !== previousAlphaGradient)) {
                    var nextAlphaGradientNeighbors = GradientUtil.alphaNeighbors(nextAlphaGradient, startOffset);
                    var nextAlphaGradientPreviousEntry: GradientEntry = nextAlphaGradientNeighbors.previous;
                    var nextAlphaGradientNextEntry: GradientEntry = nextAlphaGradientNeighbors.next;
                    nextAlphaGradientPreviousAlpha = BlendRenderer.numberToUniform(nextAlphaGradientPreviousEntry.alpha, false);
                    nextAlphaGradientNextAlpha = BlendRenderer.numberToUniform(nextAlphaGradientNextEntry.alpha, false);
                    var nextAlphaGradientOffsetDelta: number = nextAlphaGradientNextEntry.offset - nextAlphaGradientPreviousEntry.offset;

                    if (nextAlphaGradientOffsetDelta === 0) {
                        nextAlphaGradientOffsetStart = BlendRenderer.numberToUniform(0.0);
                        nextAlphaGradientOffsetRange = BlendRenderer.numberToUniform(0.0);
                    }
                    else {
                        nextAlphaGradientOffsetStart = BlendRenderer.numberToUniform((startOffset - nextAlphaGradientPreviousEntry.offset) / nextAlphaGradientOffsetDelta);
                        nextAlphaGradientOffsetRange = BlendRenderer.numberToUniform(dOffset / nextAlphaGradientOffsetDelta);
                    }
                }
                else {
                    nextAlphaGradientPreviousAlpha = previousAlphaGradientPreviousAlpha;
                    nextAlphaGradientNextAlpha = previousAlphaGradientNextAlpha;
                    nextAlphaGradientOffsetStart = previousAlphaGradientOffsetStart;
                    nextAlphaGradientOffsetRange = previousAlphaGradientOffsetRange;
                }
                
                
                var geometry = new (<any>THREE).PlaneBufferGeometry(dOffset, dLineOffset);

                // clone material and change uniforms to leverage existing shaders
                var material = (<any>BlendRenderer._sourceMaterial).clone();
                material.uniforms = {
                    // color

                    previousColorGradientPreviousColor: previousColorGradientPreviousColor,
                    previousColorGradientNextColor: previousColorGradientNextColor,
                    previousColorGradientOffsetStart: previousColorGradientOffsetStart,
                    previousColorGradientOffsetRange: previousColorGradientOffsetRange,

                    nextColorGradientPreviousColor: nextColorGradientPreviousColor,
                    nextColorGradientNextColor: nextColorGradientNextColor,
                    nextColorGradientOffsetStart: nextColorGradientOffsetStart,
                    nextColorGradientOffsetRange: nextColorGradientOffsetRange,

                    colorGradientOffsetStart: colorGradientOffsetStart,
                    colorGradientOffsetRange: colorGradientOffsetRange,

                    // alpha
                    
                    previousAlphaGradientPreviousAlpha: previousAlphaGradientPreviousAlpha,
                    previousAlphaGradientNextAlpha: previousAlphaGradientNextAlpha,
                    previousAlphaGradientOffsetStart: previousAlphaGradientOffsetStart,
                    previousAlphaGradientOffsetRange: previousAlphaGradientOffsetRange,

                    nextAlphaGradientPreviousAlpha: nextAlphaGradientPreviousAlpha,
                    nextAlphaGradientNextAlpha: nextAlphaGradientNextAlpha,
                    nextAlphaGradientOffsetStart: nextAlphaGradientOffsetStart,
                    nextAlphaGradientOffsetRange: nextAlphaGradientOffsetRange,

                    alphaGradientOffsetStart: alphaGradientOffsetStart,
                    alphaGradientOffsetRange: alphaGradientOffsetRange
                };
                
                var plane = this._meshPool[meshCount];
                if (!plane) {
                    plane = new THREE.Mesh(geometry, material);
                    this._meshPool[meshCount] = plane;
                }
                else {
                    plane.geometry = geometry;
                    plane.material = material;
                }
                meshCount++;
                
                plane.position.x = startOffset + (dOffset / 2);
                plane.position.y = 1 - (startLineOffset + (dLineOffset / 2));

                this._scene.add(plane);

                //right += dOffset;
                //previousOffset = offset;
            }
        }

        this._renderer.render(this._scene, this._camera);

        // var tEnd = Date.now();
        // var tElapsed = tEnd - tStart;
        // console.log('render time: ' + tElapsed);
    }
}
export = BlendRenderer;