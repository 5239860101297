<div #container class="container unselectable">
    <div class="blend-container ripple" matRipple style="position: relative;" (mouseup)="this.edit.emit(this)">
        <div #blendHolder class="blend-holder"></div>
        <div class="label">
            {{_label}}

        </div>
        <div class="like-count" style="position: absolute;">
            <mat-icon aria-hidden="false" aria-label="Likes" class="like-icon">favorite</mat-icon>
            <label class="like-label">{{numLikes}}</label>
        </div>
    </div>
    <!-- border used to cover up browser rendering artifact from clipping canvas -->
    <div class="border"></div>
    <button *ngIf="editable" (click)="like.emit(this);" style="position: absolute; top: 0;">like</button>
    <button *ngIf="editable" (click)="delete.emit(this);" style="position: absolute; top: 0; right: 0;">DELETE</button>
    <input *ngIf="editable" type="number" value="{{featuredLevel}}" (change)="_featureLevelChange($event)" style="position: absolute; bottom: 20px;">
</div>