import BindingUtil = require('utils/binding-util');
import EventDispatcher = require('events/event-dispatcher');
import Blend = require('graphics/blend');
import ISerializable = require('core/i-serializable');
import PropertyChangeEvent = require('events/property-change-event');
import UIDUtil = require('utils/uid-util');

//-- Events
// PropertyChangeEvent.PROPERTY_CHANGE

/**
 * The <code>BlendInfo</code> class represents a user's Blend within the application.
 * Besides the <code>gradient</code> itself, it contains additional metadata
 * such as its name, whether or not it's a favorite, and potentially its external reference.
 */
class BlendInfo extends EventDispatcher implements ISerializable {

    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------

    /**
     * Deserializes the plain object into an instance.
     */
    static deserialize(o: any): BlendInfo {
        var instance: BlendInfo = new BlendInfo();
        //instance.guid = o.guid;
        if (o.blend) {
            instance.blend = Blend.deserialize(o.blend);
        }
        else if (o.gradient) {
            // ensure previously serialized continue working
            instance.blend = Blend.deserialize(o.gradient);
        }
        instance.name = o.name;
        instance.favorite = o.favorite;
        instance.created = o.created ? o.created : Date.now();      // ensure samples get a date associated with them
        instance.loaded = o.loaded ? o.loaded : Date.now();
        instance.opened = o.opened ? o.opened : Date.now();         // ensure samples get a date associated with them
        instance.version = o.version;

        return instance;
    }

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor(b?: Blend, name: string = '') { 
        super();

        this.blend = b;
        this.name = name;
        this.guid = UIDUtil.uid();
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  guid
    //----------------------------------

    /**
     * Unique ID of this GradientStack within the application.
     * Getting error using "id" property.
     * "Uncaught TypeError: Illegal invocation"
     */
    guid: string;

    //----------------------------------
    //  gradient
    //----------------------------------

    private static _bindableGradient = BindingUtil.bindable(BlendInfo, 'blend', true);
    
    private _blend: Blend;
    
    /**
     * The gradient defining colors / alphas.
     * May loosen type should we support linear, radial gradients in future.
     */
    set blend(value: Blend) {
        if (this._blend !== value) {
            if (this._blend) {
                this._blend.off(Blend.EVENT_CHANGE, this._blendChangeHandler);
            }
            
            this._blend = value;
            
            if (this._blend) {
                this._blend.on(Blend.EVENT_CHANGE, this._blendChangeHandler);
            }
            this._blendChangeHandler();
        }
    }
    get blend(): Blend {
        return this._blend;
    }

    //----------------------------------
    //  name
    //----------------------------------

    private static _bindableName = BindingUtil.bindable(BlendInfo, 'name');

    /**
     * The user-given name.
     */
    name: string = null;

    //----------------------------------
    //  favorite
    //----------------------------------

    private static _bindableFavorite = BindingUtil.bindable(BlendInfo, 'favorite');

    /**
     * Whether or not the user marked this as a favorite.
     * May want to manage favorites as a separate collection,
     * but doing so will introduce overhead of managing two
     * separate collections (all and favorites).
     */
    favorite: boolean = false;
    
    //----------------------------------
    //  loaded
    //----------------------------------

    /**
     * The timestamp at which this loaded locally. 
     */
    loaded: number = Date.now();
    
    //----------------------------------
    //  created
    //----------------------------------
    
    /**
     * The timestamp at which this was created.
     */
    created: number = Date.now();
    
    //----------------------------------
    //  opened
    //----------------------------------
    
    private static _bindableOpened = BindingUtil.bindable(BlendInfo, 'opened');
    
    /**
     * The timestamp at which this was last opened.
     */
    opened: number;
    
    //----------------------------------
    //  version
    //----------------------------------
    
    /**
     * Version.  For backwards/forwards compatibility.
     */
    version: number = 1.0;

    //----------------------------------
    //  data
    //----------------------------------

    /**
     * Optional data to associate with the Blend.
     */
    data: any;

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    //-- ISerializable

    /**
     * @inheritDoc
     */
    serialize(): any {
        const o: any = {};
        //o.guid = this.guid;
        if (this.blend) {
            o.blend = this.blend.serialize();
        }
        o.name = this.name;
        o.favorite = this.favorite;
        o.created = this.created;
        o.loaded = this.loaded;
        o.opened = this.opened;
        o.version = this.version;
        return o;
    }

    /**
     * Returns a clone of itself.
     */
    clone(): BlendInfo {
        const clone: BlendInfo = BlendInfo.deserialize(this.serialize());
        clone.guid = UIDUtil.uid();
        // TODO: get smarter about generated name; see Photoshop for example
        if (clone.name) {
            clone.name += ' copy';
        }
        return clone;
    }

    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------

    /**
     * Handler for when the internals of a Blend change.
     * Bubbles up.
     */
    private _blendChangeHandler = (): void => {
       const propertyChangeEvent = new PropertyChangeEvent();
       propertyChangeEvent.propertyName = 'blend';
       propertyChangeEvent.value = this.blend;
       this.trigger(PropertyChangeEvent.PROPERTY_CHANGE, propertyChangeEvent);
    }
}
export = BlendInfo;
