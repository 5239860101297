import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShareService } from '../share-service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements AfterViewInit {

    @ViewChild('shareButton') shareButton;

    public _exploreActive: boolean = false;             // public for template
    public _createActive: boolean = false;              // public for template
    public _phoneMode: boolean = false;                 // public for template

    constructor(
        /**
         * @private
         */
        public _router: Router,                         // public for template
        public _breakpointObserver: BreakpointObserver  // public for template
    ) {
        switch (this._router.url) {
            case '/create':
                this._createActive = true;
                break;
            case '/explore':
            default:
                this._exploreActive = true;
        }

        const layoutChanges = _breakpointObserver.observe([
            '(max-width: 599px)'
        ]);
        layoutChanges.subscribe(result => {
            this._phoneMode = result.matches;
        })
    }

    ngAfterViewInit() {
        this.shareButton.url = window.location.href;
        ShareService.shareButton = this.shareButton;
    }
}
