<div class="page">
    <app-nav-bar></app-nav-bar>
    <div class="body background">
        <div class="content" [class.loaded]="_loaded">
            <div *ngIf="_didJustCreate">
                <label class="title">CONGRATULATIONS!</label>
                <p>Your Blend has been published and is ready to share with the world.</p>
                <p>Go spread the love!</p>
                <mat-divider></mat-divider>
            </div>
            <div *ngIf="!_blendLoaded">
                <label class="title">BLEND NOT FOUND</label>
                <p>The author may have deleted this Blend or the link may be incorrect.</p>
            </div>
            <div class="blends-link" (click)="_moreBlendsHandler($event)">
                <mat-icon style="text-decoration: none;">arrow_back</mat-icon>
                <span class="label">{{_canGoBack ? 'Back' : 'More Blends'}}</span>
            </div>
            <div [style.visibility]="_blendLoaded ? 'inherit' : 'hidden'">
                <div style="display: flex; align-items: flex-end;">
                    <label class="name" style="flex: 1 1 auto;">{{_blendInfo?.name}}</label>
                    <button mat-mini-fab [matMenuTriggerFor]="downloadMenu" aria-label="Download" style="margin-left: 20px;" >
                            <mat-icon>file_download</mat-icon>
                    </button>
                    <mat-menu #downloadMenu="matMenu" xPosition="before">
                        <a #downloadBldAnchor>
                            <button mat-menu-item>
                                <span>.bld</span>
                                <mat-icon class="download-bld-help" (click)="_bldHelpHandler($event);" style="margin-left: 32px; margin-right: 0;">help_outline</mat-icon>
                            </button>
                        </a>
                        <a #downloadPngAnchor>
                            <button mat-menu-item>.png</button>
                        </a>
                    </mat-menu>
                </div>
                <div #blendHolder class="blend-holder"></div>
                <!-- <sblended-blend [blend]="_blend" style="width: 300px; height: 200px;"></sblended-blend> -->
                <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
                    <div style="display: flex; align-items: center;">
                        <label class="by-label">by: </label>
                        <label class="author-label">{{_authorName}}</label>
                    </div>
                    <div class="stretch"></div>
                    <like-button [numLikes]="_numLikes" [userLikes]="_usersLikeId ? true : false" [enabled]="_likeEnabled" (liked)="_likedHandler($event)" class="like-button"></like-button>
                </div>
                <button *ngIf="_canDelete" mat-flat-button color="warn" (click)="_deleteHandler($event)">
                    Delete
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
