import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'alert-dialog-dialog',
    templateUrl: 'alert-dialog.component.html',
})
export class AlertDialogComponent {

    @Output() buttonTap = new EventEmitter<number>();

    constructor(public dialogRef: MatDialogRef<AlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) 
        { }

    onClick(event): void {
        let button = event.currentTarget ? event.currentTarget : event.target;
        if (!button) {
            return;
        }
        let index = Number(button.getAttribute('data-index'));

        this.buttonTap.emit(index);

        // this.dialogRef.close();
    }
}