import { Component, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import * as firebaseui from 'firebaseui'

@Component({
  selector: 'login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * The login dialog which renders the Firebase AuthUI.
 */
export class LoginDialogComponent implements AfterViewInit {

    /**
     * Selector to use to find the primary render target
     * that will be used to complete authentication.
     */
    public authSelector: string = '#firebaseui-auth-container';

    private _firebaseUIConfig = {
        autoUpgradeAnonymousUsers: false,
        credentialHelper: firebaseui.auth.CredentialHelper.NONE,
        // signInFlow: 'popup',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID
        ],
        // tosUrl: 'https://demo.sblended.com',
        // privacyPolicyUrl: 
        callbacks: {
            signInSuccessWithAuthResult: (...args: any[]) => {
                return false;
            },
            signInFailure: (...args: any[]): Promise<void> => {
                console.error("Sign in failure ", args);
                return new Promise((resolve, reject) => {
                    resolve();
                });
            },
            uiShown: () => {
                // heightThe widget is rendered.
                // Hide the loader.
                this._loaded = true;
                this._cd.detectChanges();
            }
        }
    };

    public _loaded: boolean = false;

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     * 
     */
    constructor(
        private _cd: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * @inheritdoc
     */
    public ngAfterViewInit(): void {
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        ui.start(this.authSelector, this._firebaseUIConfig);
    }
}
