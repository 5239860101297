import ArrayList = require('collections/array-list');
import Blend = require('graphics/blend');
import BlendPath = require('graphics/blend-path');
import CollectionEvent = require('events/collection-event');
import Direction = require('geom/direction');
import Gradient = require('graphics/gradient');
import GradientEntry = require('graphics/gradient-entry');
import GradientEntryType = require('graphics/gradient-entry-type');
import GradientThumb = require('controls/gradient-thumb');
import IList = require('collections/i-list');
import ISliderThumb = require('controls/i-slider-thumb');
import PropertyChangeEvent = require('events/property-change-event');
import SelectableThumbSlider = require('controls/selectable-thumb-slider');
import Slider = require('controls/slider');
import View = require('ui/view');


class BlendPathOffsetSlider extends SelectableThumbSlider {

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
        super();
        
        this.thumbFactory = this.__thumbFactory;
        this.valuesProperty = 'offset';
        this.itemFactory = this.__itemFactory;
        
        // prevent lines from being removed due to "drag out" 
        this.removeDistance = Number.POSITIVE_INFINITY;
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  blend
    //----------------------------------

    private _blend: Blend;

    set blend(value: Blend) {
        if (this._blend) {
            this._blend.off(PropertyChangeEvent.PROPERTY_CHANGE, this._gradientStackChangeHandler);
        }
        
        this._blend = value;
        this.values = this._blend ? this._blend.paths : null;
        
        // listen for orientation changes
        if (this._blend) {
            this._blend.on(PropertyChangeEvent.PROPERTY_CHANGE, this._gradientStackChangeHandler);
        }
        this._gradientStackChangeHandler();
    }
    get blend(): Blend {
        return this._blend;
    }

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * Starts dragging the thumb at the specified index as if the user had touched it.
     * May want to move this to base class.
     */
    drag(index: number, offset: number = 0): void {
        // HACK: create fake event to leverage existing handler; this is brittle
        var event: any = {};
        event.currentTarget = {};
        event.preventDefault = function () { };
        //$(event.currentTarget).data('thumb-index', index);
        (<any>event.currentTarget).thumbIndex = index;
        var pointerOffset: any = {};
        
        // use same offset for x and y
        // slider only cares about primary axis
        // this works for both orientations
        pointerOffset.x = pointerOffset.y = offset;
        
        this._downHandler(<any>event, pointerOffset);
    }

    /**
     * Intentional double-underscore to prevent collision with Slider internal.
     */
    private __thumbFactory = (index: number): ISliderThumb => {
        var gradientThumb = new GradientThumb();

        if (this.blend) {
            var path: BlendPath = this.blend.paths.getItemAt(index);
            gradientThumb.gradient = path.gradient;
        }
        return gradientThumb;
    }

    private __itemFactory = (index: number, offset: number): BlendPath => {
        var gradient: Gradient = new Gradient();
        var colorEntries = gradient.colorEntries = new ArrayList<GradientEntry>();
        colorEntries.addItem(new GradientEntry(GradientEntryType.TYPE_COLOR, 0.0, 0x000000));
        colorEntries.addItem(new GradientEntry(GradientEntryType.TYPE_COLOR, 1.0, 0x0000FF));

        var alphaEntries = gradient.alphaEntries = new ArrayList<GradientEntry>();
        alphaEntries.addItem(new GradientEntry(GradientEntryType.TYPE_ALPHA, 1.0, 0));
        alphaEntries.addItem(new GradientEntry(GradientEntryType.TYPE_ALPHA, 1.0, 1));

        var path: BlendPath = new BlendPath(gradient, offset);
        return path;
    }
    
    private _gradientStackChangeHandler = (event?: PropertyChangeEvent): void => {
        if (!event || (event && event.propertyName === 'orientation')) {
            var verticalGradients: boolean = (this._blend && (this._blend.orientation === Direction.VERTICAL)) ? true : false;
        
            // // inverted bounds
            if (verticalGradients) {
                this.minimum = 1.0;
                this.maximum = 0.0;
            }
            else {
                this.minimum = 1.0;
                this.maximum = 0.0;
            }
            this.vertical = !verticalGradients;
        }
    }
}
export = BlendPathOffsetSlider;