<div class="container" style="display: flex; flex-direction: column; flex: 0 0 auto; width: 100%; height: 100%;">
    <div class="header" [@_headerState]="_collapsed ? 'collapsed' : 'expanded'" style="width: 100%; pointer-events: auto; position: relative;">
        <button mat-icon-button (click)="_collapseOrCloseHandler()">
            <mat-icon [@_iconState]="_collapsed ? 'collapsed' : 'expanded'">expand_more</mat-icon>
        </button>
        <div [@_colorDetailsState]="stopType" style="position: absolute; top: 0px; margin-left: 40px; ">
            <mat-form-field [@_dropDownState]="_collapsed ? 'collapsed' : 'expanded'" style="position: absolute; top: -10px; width: 60px;">
                <mat-select [(ngModel)]="colorPickerType" (click)="_openColorPickerTypeMenu();">
                    <mat-option value="hsb">HSB</mat-option>
                    <mat-option value="rgb">RGB</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <button class="color-picker-type-button" [style.pointerEvents]="!_collapsed && stopType ==='COLOR' ? 'auto' : 'none'" (click)="_openColorPickerTypeMenu()" (press)="_openColorPickerTypeMenu()"></button>
        <button mat-icon-button style="position: absolute; top: 0px; right: 15px;" (click)="_removeStopHandler()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>

    <div class="stretch background" [@_contentsState]="_collapsed ? 'collapsed' : 'expanded'" style="width: 100%; pointer-events: auto;">
        <div [@_alphaDetailsState]="stopType" style="position: absolute; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
            <sblended-slider #alphaSlider [(value)]="alpha" maximum="1" class="alphaSlider" style="width: 100%; height: 50px; padding-left: 25px; padding-right: 25px;"></sblended-slider>
        </div>
        <color-picker [@_colorDetailsState]="stopType" #colorPicker colorPickerType="{{colorPickerType}}" (change)="_colorChange()" style="position: absolute; width: 100%; height: 100%;"></color-picker>
    </div>

    <div class="footer" style="position: relative; height: 50px; width: 100%; z-index: 1; pointer-events: auto;">
        <div style="position: absolute; width: 100%; height: 2px; background-image: url('assets/images/transparent.png');"></div>
        <div class="colorTransition" style="position: absolute; width: 100%; height: 2px; background-color: black;" [style.opacity]="this._alpha"></div>
        <div [@_colorDetailsState]="stopType" class="colorTransition" style="position: absolute; width: 100%; height: 2px;" [style.background-color]="this._cssColor"></div>

        <div [@_alphaDetailsState]="stopType" style="position: absolute; height: 50px; padding: 0px 0px 0px 10px; display: flex; align-items: center;">
            <label class="secondary label" style="margin: auto;">OPACITY</label>

            <!-- spacer -->
            <div style="width: 10px; visibility: hidden;"></div>
            
            <mat-form-field style="width: 60px;">
                <input #alphaInput type="number" matInput [ngModel]="alpha * 100 | number:'1.0-0'" value="20" min="0" max="100" (change)="_validateInput($event, 'alpha', 0.0, 1.0, 1/100)" style="text-align: center;">
                <div class="secondary label" matSuffix>%</div>
            </mat-form-field>
            
            <!-- spacer -->
            <div style="width: 10px; visibility: hidden;"></div>
            
            <button mat-icon-button>
                <mat-icon svgIcon="sblended:drag-horiz" (panmove)="_onPanAlpha($event)"></mat-icon>
            </button>
        </div>

        <div [@_colorDetailsState]="stopType" style="position: absolute; height: 50px; padding: 0px 0px 0px 10px; margin: auto; display: flex; align-items: center;">
            <label class="secondary label" style="margin: auto;">COLOR</label>

            <!-- spacer -->
            <div style="width: 17px; visibility: hidden;"></div>

            <mat-form-field *ngIf="true" style="width: 85px;">
                <div class="secondary label" matPrefix>#</div>
                <input #hexInput type="text" pattern="[0-9a-fA-F]{3,6}" title="use hex" matInput [ngModel]="_hex" (change)="_validateHex($event)" style="text-align: center;">
            </mat-form-field>

            <!-- spacer -->
            <div style="width: 10px; visibility: hidden;"></div>

            <button *ngIf="_colorChipVisible" mat-raised-button class="color-chip colorTransition" (click)="_colorChipHandler()" style="width: 10px; height: 100%;" [style.background-color]="this._cssColor"></button>
        </div>

        <div class="position">
            <mat-form-field style="width: 70px;">
                <mat-icon matPrefix style="transform: translateY(8px);">swap_horiz</mat-icon>
                <input #offsetInput type="number" matInput [(ngModel)]="horizontal" min="0" max="100" (change)="_horizontalChange()" style="text-align: center;">
                <div class="secondary label" matSuffix>%</div>
            </mat-form-field>
            <div style="width: 20px; height: 100%;"></div>
            <mat-form-field style="width: 70px;">
                <mat-icon matPrefix style="transform: translateY(8px);">swap_vert</mat-icon>
                <input #pathOffsetInput type="number" matInput [(ngModel)]="vertical" min="0" max="100" (change)="_verticalChange()" style="text-align: center;">
                <div class="secondary label" matSuffix>%</div>
            </mat-form-field>
        </div>
    </div>

    <div #colorPickerTypeMenu class="menu">
        <button mat-menu-item (mouseup)="_onHSB()">
            <span>HSB</span>
        </button>
        <button mat-menu-item (mouseup)="_onRGB()">
            <span>RGB</span>
        </button>
    </div>
</div>