<div class="background" style="position: relative; width: 100%; height: 100%; overflow: hidden;">
  <mat-toolbar>
      <mat-form-field class="stretch" style="font-size: 16px; min-width: 100px; align-self: self-end" [floatLabel]="nameError ? 'always' : 'auto'">
        <mat-label class="name-placeholder" *ngIf="!nameError">Name</mat-label>
        <mat-label style="color: red;">{{nameError}}</mat-label>
        <input matInput [(ngModel)]="_name" (change)="_nameChange()" (input)="_nameInput()" [errorStateMatcher]="_errorStateMatcher">
      </mat-form-field>
      <button mat-icon-button (click)="_randomizeHandler()" aria-label="Randomize" title="Randomize" mat TooltipPosition="below"matTooltipShowDelay="750" style="transform: scaleX(-1)">
        <mat-icon #randomizeIcon>toys</mat-icon>
      </button>
      <button mat-icon-button (click)="_rotateHandler()" aria-label="Orientation" title="Orientation" matTooltipPosition="below" matTooltipShowDelay="750">
        <div style="display: flex; justify-content: center; align-items: center; transform: translateY(2px);">
          <mat-icon [@_leftState]="_orientation" style="position: absolute;">rotate_left</mat-icon>
          <mat-icon [@_rightState]="_orientation" style="position: absolute;">rotate_right</mat-icon>
        </div>
      </button>
      <!--<button mat-icon-button [matMenuTriggerFor]="menu">-->
      <button mat-icon-button (click)="_typeTapHandler()" (press)="_typePressHandler()" [ngSwitch]="controlsType" aria-label="Colors / Opacities" title="Colors/Opacities" matTooltipPosition="below" matTooltipShowDelay="750">
        <mat-icon svgIcon="sblended:image-color-lens" *ngSwitchCase="'color'"></mat-icon>
        <mat-icon svgIcon="sblended:image-blur-linear" *ngSwitchCase="'alpha'"></mat-icon>
        <mat-icon svgIcon="sblended:color-and-alpha" *ngSwitchCase="'both'"></mat-icon>
        <mat-icon style="position: absolute; right: 0px; bottom: -5px;">arrow_drop_down</mat-icon>
        <div style="position: absolute; left: 0px; top: 0px; width: 100%; height: 100%; pointer-events: none;"></div>
      </button>
      <!-- <mat-menu #controlsTypeMenu="matMenu" style="position: absolute; left: 100px; top: 100px;">
        <button mat-menu-item (mouseup)="_typeColorsHandler()">
          <mat-icon>palette</mat-icon>
          <span>Color</span>
        </button>
        <button mat-menu-item (mouseup)="_typeOpacitiesHandler()">
          <mat-icon>blur_linear</mat-icon>
          <span>Opacity</span>
        </button>
        <button mat-menu-item (mouseup)="_typeBothHandler()">
          <mat-icon svgIcon="sblended:color-and-alpha"></mat-icon>
          <span>Both</span>
        </button>
      </mat-menu> -->
      <button mat-icon-button (click)="_controlsHandler()" [@_controlsState]="_controlsState" aria-label="Show / Hide Controls" title="Show/Hide Controls" matTooltipPosition="below" matTooltipShowDelay="750">
        <mat-icon>tune</mat-icon>
      </button>
  </mat-toolbar>


  <div #editorHolder [@_editorState]="_detailsState" class="fit" style="top: 64px;"></div>
  <div #editorSizer class="fit" style="top: 64px; background-color: red; pointer-events: none; visibility: hidden;"></div>

  <stop-details #stopDetails collapsed="true" (propertyChange)="_detailsChangeHandler($event)" (collapseChange)="_collapseChangeHandler()" (remove)="_removeHandler()" [@_detailsState]="_detailsState" style="position: absolute; bottom: -220px; width: 100%; height: 220px; contain: strict; will-change: transform; pointer-events: none;"></stop-details>

  <!-- <button #pathTypeMenuPositioner #pathTypeTrigger="matMenuTrigger" mat-icon-button [matMenuTriggerFor]="pathTypeMenu" style="visibility: hidden; position: absolute;"></button> -->

  <!-- <mat-menu #pathTypeMenu> -->
  <div #pathTypeMenu class="menu pathTypeMenu" style="position: absolute;">
    <button mat-menu-item (mouseup)="_addColorHandler()">
      <mat-icon>palette</mat-icon>
      <span>Add Color</span>
    </button>
    <button mat-menu-item (mouseup)="_addOpacityHandler()">
      <mat-icon>blur_linear</mat-icon>
      <span>Add Opacity</span>
    </button>
  </div>
  <!-- </mat-menu> -->

  <!-- included to pull in styles -->
  <mat-menu>
  </mat-menu>

  <div #controlsTypeMenuManual class="menu controlsTypeMenu">
    <button mat-menu-item (mouseup)="_typeColorsHandler()">
      <mat-icon>palette</mat-icon>
      <span>Color</span>
    </button>
    <button mat-menu-item (mouseup)="_typeOpacitiesHandler()">
      <mat-icon>blur_linear</mat-icon>
      <span>Opacity</span>
    </button>
    <button mat-menu-item (mouseup)="_typeBothHandler()">
      <mat-icon svgIcon="sblended:color-and-alpha"></mat-icon>
      <span>Both</span>
    </button>
  </div>

</div>
