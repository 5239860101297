import _ from 'underscore';

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import { Location } from '@angular/common';
import { Component, AfterViewInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

import Alert = require("ui/alert");
import ArrayList = require("collections/array-list");
import { BlendData } from "../blend-data";
import BlendInfo = require("graphics/blend-info");
import BlendView = require('graphics/blend-view');
import { LikeData } from "../like-data";
import { ShareService } from "../share-service";
import StringUtil = require("utils/string-util");
@Component({
    selector: 'blend-details',
    templateUrl: './blend-details.component.html',
    styleUrls: ['./blend-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlendDetailsComponent implements AfterViewInit, OnDestroy {

    @ViewChild('blendHolder') blendHolder: ElementRef;
    @ViewChild('downloadBldAnchor') downloadBldAnchor;
    @ViewChild('downloadPngAnchor') downloadPngAnchor;

    private _blendId: string;

    private _blendView: BlendView;

    /**
     * @private - public for template
     */
    public _loaded: boolean = false;

    /**
     * Whether or not the Blend was successfully loaded.
     * False before Blend loaded and if it could not be loaded because Blend deleted or link invalid.
     */
    public _blendLoaded: boolean = false;

    /**
     * @private - public for template
     */
    public _blendInfo: BlendInfo;

    /**
     * @private - public for template
     */
    public _authorName: string;

    /**
     * @private - public for template
     */
    public _numLikes: number;

    /**
     * @private - public for template
     * If the user has liked the Blend, the ID of the like in the DB.
     * Null if the user has not liked the Blend.
     */
    public _usersLikeId: string = null;

    /**
     * Whether or not the like button is enabled.
     * Disabled while requests are pending.
     */
    public _likeEnabled: boolean = true;

    /**
     * Whether or not the user just created this Blend.
     * Determined from query params.
     */
    public _didJustCreate: boolean = false;

    /**
     * Whether or not the user can delete.
     * Users can only delete Blends that they created.
     */
    public _canDelete: boolean = false;

    /**
     * Whether or not we can go back to the list.
     * Will be true if we have navigated from the list
     * and false if the browser loaded this route fresh.
     */
    public _canGoBack: boolean = false;

    /**
     * Handler for when resize occurs.
     * Generated via _.debounce 
     */
    private _resizeHandler;


    /**
     * @constructor
     * @param route 
     */
    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _location: Location,
        private _snackBar: MatSnackBar,
        private _cd: ChangeDetectorRef,
    ) {
        this._didJustCreate = this._route.snapshot.queryParamMap.has('created');

        this._canGoBack = this._router.navigated;

        // remove query param should user share this 
        this._router.navigate([], {
            queryParams: {
                created: null,
            },
            queryParamsHandling: 'merge',
            replaceUrl: true
        });

        // HACK: determine this from user role in future
        // this only enables the Admin-specific UI
        // the server still restricts changes based on the authenticated user
        this._canDelete = (<any>window).isAdmin;
    }

    /**
     * @inheritdoc
     */
    ngOnInit() {
        this._route.paramMap.subscribe(async (paramMap) => {
            this._blendId = paramMap.get('blendId');
            let doc = await this._fetchBlend(this._blendId);
            if (doc && doc.exists) {
                let blendData: BlendData = doc.data() as BlendData;

                this._numLikes = blendData.numLikes;

                const authorId: string = blendData.authorId;
                if (authorId === firebase.auth().currentUser.uid) {
                    this._authorName = 'You';       // TODO: localize
                }
                else {
                    const getAuthorFunction = firebase.functions().httpsCallable('getUserDisplayName');
                    // don't use `await` so that we don't delay drawing the blend itself
                    // still initiate fetch immediately rather than after rendering 
                    getAuthorFunction(authorId).then((result) => {
                        this._authorName = result.data;
                        this._cd.markForCheck();
                    });
                }

                this._fetchUsersLike(this._blendId).then((likeDoc) => {
                    this._usersLikeId = likeDoc ? likeDoc.id : null;
                    this._cd.markForCheck();
                });

                blendData.blend = JSON.parse(blendData.blend);
                this._blendInfo = BlendInfo.deserialize(blendData);
                if (this.blendHolder) {
                    this._renderBlend();
                }

                let anchorBld: HTMLAnchorElement = this.downloadBldAnchor.nativeElement;

                const list: ArrayList<BlendInfo> = new ArrayList([this._blendInfo]);
                let serializedList = list.serialize();
                serializedList.version = 1;
                const s: string = JSON.stringify(serializedList);

                const dataString: string = "data:text/json;charset=utf-8," + encodeURIComponent(s);
                anchorBld.setAttribute('href', dataString);

                const fileName: string = StringUtil.safeName(blendData.name) + '.bld';
                anchorBld.setAttribute("download", fileName);
                
                let anchorPng: HTMLAnchorElement = this.downloadPngAnchor.nativeElement;

                let type = "image/png";
                let quality = 1.0;
                const dataUrl: string = this._blendView.canvas.toDataURL(type, quality);
                anchorPng.setAttribute('href', dataUrl);

                const pngFileName: string = StringUtil.safeName(blendData.name) + '.jpg';
                anchorPng.setAttribute("download", pngFileName);

                let message: string;
                const userId: string = firebase.auth().currentUser.uid;
                let userCreated: boolean = userId === blendData.authorId;
                if (userCreated) {
                    message = 'Check out my Blend - "' + blendData.name + '".';
                    this._canDelete = true;
                }
                else {
                    message = 'Check out this Blend - "' + blendData.name + '".';
                }
                ShareService.shareButton.message = message;

                this._blendLoaded = true;
                this._loaded = true;

                this._cd.markForCheck();
            }
            else {
                // doc.data() will be undefined in this case
                console.warn("Failed to find Blend: ", this._blendId);

                this._loaded = true;

                this._cd.markForCheck();
            }
        });

        // re-render on window resize
        // may want to change this to use BreakpointObserver to avoid unnecessary renders
        // but this works for now.
        // debounce for performance
        this._resizeHandler = _.debounce(this.__resizeHandler, 250);
        window.addEventListener('resize', this._resizeHandler);
    }

    /**
     * @inheritdoc
     */
    ngAfterViewInit(): void {
        if (this._blendInfo) {
            this._renderBlend();
        }
    }

    /**
     * @inheritdoc
     */
    ngOnDestroy(): void {
        window.removeEventListener('resize', this._resizeHandler);
    }

    private __resizeHandler = (): void => {
        if (this._blendView && this.blendHolder.nativeElement) {
            this._blendView.width = this.blendHolder.nativeElement.offsetWidth;
            this._blendView.height = this.blendHolder.nativeElement.offsetHeight;
        }
    }

    /**
     * Fetches the blend with the specified ID.
     * @param blendId 
     */
    private async _fetchBlend(blendId: string): Promise<firebase.firestore.DocumentSnapshot> {
        const docRef = firebase.firestore().collection("blends").doc(blendId);
        return await docRef.get();
    }

    /**
     * Determines if the current user likes the specified Blend.
     * @param blendId 
     */
    private async _fetchUsersLike(blendId: string): Promise<firebase.firestore.DocumentSnapshot> {
        const userId: string = firebase.auth().currentUser.uid;
        const likes = firebase.firestore().collection("likes");
        const userLikesForBlend = await likes.where('blendId', '==', blendId).where('userId', '==', userId).get();
        if (userLikesForBlend.empty) {
            return null;
        }
        else {
            const like = userLikesForBlend.docs[0];     // there should only ever be one
            return like;
        }
    }

    // TODO: replace with `sblended-blend` component
    private _renderBlend(): void {
        const blendView = this._blendView = new BlendView();

        // disable watch since we need to watch BlendInfo explicitly
        // to see if the blend was completely re-assigned
        blendView.watch = false;
        blendView.debounce = 300;
        if (this._blendInfo) {
            blendView.blend = this._blendInfo.blend;
        }

        // need to set explicit size here to avoid flicker
        let blendHolderElement = this.blendHolder.nativeElement;
        blendView.width = blendHolderElement.offsetWidth;
        blendView.height = blendHolderElement.offsetHeight;

        blendView.validate();
        this.blendHolder.nativeElement.appendChild(blendView.el);
    }

    private _goBackOrHome(): void {
        if (this._canGoBack) {
            this._location.back();
        }
        else {
            this._router.navigate(['/explore']);
        }
    }

    /**
     * Handler for when the Blend is "liked".
     * Records in the DB.
     */
    public _likedHandler = async (liked) => {
        let user = firebase.auth().currentUser;
        if (!user) {
            // we should always have a user
            // will be anonymous if the user has not signed in
            return;
        }

        this._likeEnabled = false;
        this._cd.markForCheck();
        try {
            const collection = firebase.firestore().collection('likes');
            if (liked) {
                let like: LikeData = {} as any;
                like.blendId = this._blendId;
                like.userId = user.uid;
                like.timestamp = firebase.firestore.Timestamp.now();
                let likeDoc = await collection.add(like);
                this._usersLikeId = likeDoc.id;
            }
            else {
                if (this._usersLikeId === null) {
                    console.error('Cannot unlike. No like id found.');
                }
                else {
                    await collection.doc(this._usersLikeId).delete();
                    this._usersLikeId = null;
                }
            }
        }
        catch (e) {
            const likeOrUnlike: string = liked ? 'like' : 'unlike';
            console.error('Failed to ' + likeOrUnlike + '.', e);
        }
        this._likeEnabled = true;
        this._cd.markForCheck();
    }

    public _deleteHandler = async (event) => {
        const title: string = 'Confirm Delete';
        const message: string = "Are you sure you want to delete this Blend?\nThis cannot be undone."
        Alert.show(message, title, [{label: 'Cancel'}, {label: 'Delete', color: 'warn'}], false, true)
            .then(
                (index: number) => {
                    // intentional loose inequality because strings coming in
                    const confirmed: boolean = index == 1 ? true : false;
                    if (confirmed) {
                        this._deleteConfirmHandler();
                    }
                }, 
                () => {
                }
            )
    }

    public _deleteConfirmHandler = async () => {
        let user = firebase.auth().currentUser;
        if (!user) {
            // we should always have a user
            // will be anonymous if the user has not signed in
            return;
        }

        try {
            const collection = firebase.firestore().collection('blends');
            await collection.doc(this._blendId).delete();

            this._snackBar.open('Blend deleted', null, {
                duration: 2000,
                panelClass: 'snackbar'
            });

            this._goBackOrHome();
        }
        catch (e) {
            console.warn(e);
        }
    }

    public _bldHelpHandler(event) {
        window.location.href = 'https://www.sblended.com/news/blend-packs';
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
    }

    public _moreBlendsHandler(event) {
        this._goBackOrHome();
    }
}
