<div class="rgbColorPicker" style="position: absolute; width: 100%; height: 100%;">
    <div style="position: absolute; height: 100%; display: flex; flex-direction: column; justify-content: space-around; width: 80px;">
        <div style="display: flex; justify-content: space-around; align-items: center; width: 100%; height: 30px;">
            <span>R</span>
            <mat-form-field style="width: 50px;">
                <input type="number" matInput [ngModel]="r | number:'1.0-0'" (change)="_validateInput($event, 'r', 0, 255);" style="text-align: center;">
            </mat-form-field>
        </div>

        <div style="display: flex; justify-content: space-around; align-items: center; width: 100%; height: 30px;">
            <span>G</span>
            <mat-form-field style="width: 50px">
                <input matInput [ngModel]="g | number:'1.0-0'" (change)="_validateInput($event, 'g', 0, 255);" style="text-align: center;">
            </mat-form-field>
        </div>
        
        <div style="display: flex; justify-content: space-around; align-items: center; width: 100%; height: 30px;">
            <span>B</span>
            <mat-form-field style="width: 50px;">
                <input matInput [ngModel]="b | number:'1.0-0'" (change)="_validateInput($event, 'b', 0, 255);" style="text-align: center;">
            </mat-form-field>
        </div>
    </div>
    
    <div style="position: absolute; left: 90px; right: 10px; height: 100%; display: flex; flex-direction: column; justify-content: space-around;">
        <sblended-slider #rSlider [(value)]="r" maximum="255" class="rSlider" style="height: 30px;"></sblended-slider>
        <sblended-slider #gSlider [(value)]="g" maximum="255" class="gSlider" style="height: 30px;"></sblended-slider>
        <sblended-slider #bSlider [(value)]="b" maximum="255" class="bSlider" style="height: 30px;"></sblended-slider>
    </div>
    <!-- <hue-slider #hueSlider [(hue)]="h" style="position: absolute; top: 0px; left: 0px; left: 90px; right: 8px; height: 30px;"></hue-slider> -->
    <!-- <saturation-brightness-box #satBrightnessBox [(hue)]="h" [(saturation)]="s" [(brightness)]="b" style="position: absolute; top: 34px; left: 90px; right: 8px; bottom: 0px; background-color: green;"></saturation-brightness-box> -->
</div>