import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';

import PropertyChangeEvent = require('events/property-change-event');
import SaturationBrightnessBox = require('controls/colorPickers/saturation-brightness-box');

@Component({
    selector: 'saturation-brightness-box',
    template: '<div #holder class="saturationBrightnessBox" style="width: 100%; height: 100%;"></div>',
    styleUrls: ['./saturation-brightness-box.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SaturationBrightnessBoxComponent {

    /**
     * @constructor
     */
    constructor() {
        this._instance = new SaturationBrightnessBox();

        // generic handler for property change events that triggers the corresponding EventEmitter
        this._instance.on(PropertyChangeEvent.PROPERTY_CHANGE, (event: PropertyChangeEvent) => {
            let propertyName: string = event ? event.propertyName : null;
            let value = event ? event.value : this[propertyName];
            let emitter = this[propertyName + 'Change'];
            if (emitter) {
                emitter.emit(value);
            }
        });
    }

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    @ViewChild("holder", { read: ElementRef }) holder: ElementRef;
    _instance: SaturationBrightnessBox;

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  hue
    //----------------------------------
	
	/**
	 * Hue, from 0 to 360, representing angle along color wheel.
	 */
    @Input()
	set hue(value: number) {
		this._instance.hue = value;
	}
	get hue(): number {
		return this._instance.hue;
    }
    @Output() hueChange = new EventEmitter<number>();
	
	//----------------------------------
    //  saturation
    //----------------------------------
	
	/**
	 * Saturation, from 0 to 1.
	 */
    @Input()
	set saturation(value: number) {
        this._instance.saturation = value;
	}
	get saturation(): number {
		return this._instance.saturation;
    }
    @Output() saturationChange = new EventEmitter<number>();
	
	//----------------------------------
    //  brightness
    //----------------------------------
	
	/**
	 * Brightness, from 0 to 1.
	 */
    @Input()
	set brightness(value: number) {
		this._instance.brightness = value;
	}
	get brightness(): number {
		return this._instance.brightness;
    }
    @Output() brightnessChange = new EventEmitter<number>();

    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------

    ngAfterViewInit() {
        this.holder.nativeElement.append(this._instance.el);
    }
}