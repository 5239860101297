import { Component, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'ui/alert-dialog.component';

@Component({
    selector: 'alert',
    template: '',
})
export class AlertComponent {
    constructor(public dialog: MatDialog) {}

    show(message: string, title?: string, buttons?: any[], autoDismiss: boolean = true, shade: boolean = true, rect?: any, z?: number): Promise<number> {
        return new Promise((resolve, reject) => {
            let dialogRef = this.dialog.open(AlertDialogComponent, {
                hasBackdrop: shade,
                disableClose: !autoDismiss,
                width: '250px',
                data: {
                    title: title,
                    message: message,
                    buttons: buttons
                }
            });

            let alertDialog = dialogRef.componentInstance;
            alertDialog.buttonTap.subscribe(result => {
                dialogRef.close();
                resolve(result);
            });

            // called when tapping on shade
            dialogRef.afterClosed().subscribe(result => {
                resolve(-1);
            });
        });
    }
}