import ArrayList = require('collections/array-list');
import ArrayUtil = require('utils/array-util');
import ColorUtil = require('utils/color-util');
import Direction = require('geom/direction');
import Gradient = require('graphics/gradient');
import GradientEntry = require('graphics/gradient-entry');
import GradientEntryType = require('graphics/gradient-entry-type');
import Blend = require('graphics/blend');
import BlendPath = require('graphics/blend-path');
import IList = require('collections/i-list');
import NeighborInfo = require('utils/neighbor-info');
import RGBA = require('graphics/rgba');
import SortUtil = require('utils/sort-util');

class GradientUtil {

    static COLOR_CHOICE_RANDOM: string = 'RANDOM';
    static COLOR_CHOICE_ORDERED: string = 'ORDERED';
    static COLOR_CHOICE_ELIMINATION: string = 'ELIMINATION';
    static OFFSET_CHOICE_RANDOM: string = 'RANDOM';
    static OFFSET_CHOICE_SPREAD: string = 'SPREAD';
    static OFFSET_CHOICE_SECTION: string = 'SECTION';

    static flattenGradient(gradient: Gradient): IList<GradientEntry> {
        var entries: ArrayList<GradientEntry> = new ArrayList<GradientEntry>();
        var combinedOffsets: number[] = [];

        GradientUtil._mergeOffsets(gradient.alphaEntries, combinedOffsets);
        GradientUtil._mergeOffsets(gradient.colorEntries, combinedOffsets);
        combinedOffsets.sort(SortUtil.numericCompareFunction);

        var entriesArray = [];
        var len: number = combinedOffsets.length;
        for (var i: number = 0; i < len; i++) {
            var offset: number = combinedOffsets[i];
            var entry = new GradientEntry();
            var rgba: RGBA = GradientUtil.interpolateGradient(gradient, offset);
            entry.color = rgba.color;
            entry.alpha = rgba.a;
            entry.offset = offset;
            entriesArray.push(entry);
        }
        entries.source = entriesArray;
        return entries;
    }

    /**
     * Removes "duplicate" stop entries from the Gradient.
     * "Duplicate" refers to stops that have the same offset.
     */
    static dedupeGradient(gradient: Gradient): void {
        var i: number;
        var len: number;
        var offset: number;
        
        var alphaEntries = gradient.alphaEntries;
        var alphaOffsetHash = {};
        len = alphaEntries ? alphaEntries.length : 0;
        for (i = len - 1; i >= 0; i--) {        // walk backwards to avoid affecting subsequent loop iteration
            offset = alphaEntries.getItemAt(i).offset;
            if (alphaOffsetHash[offset]) {
                alphaEntries.removeItemAt(i);
            }
            else {
                alphaOffsetHash[offset] = true;
            }
        }

        var colorEntries = gradient.colorEntries;
        var colorOffsetHash = {};
        len = colorEntries ? colorEntries.length : 0;
        for (i = len - 1; i >= 0; i--) {        // walk backwards to avoid affecting subsequent loop iteration
            offset = colorEntries.getItemAt(i).offset;
            if (colorOffsetHash[offset]) {
                colorEntries.removeItemAt(i);
            }
            else {
                colorOffsetHash[offset] = true;
            }
        }
    }

    static randomBlendFromPalette(palette: number[], typeIdx?: number) {
        let blend: Blend = new Blend();
        let paths: ArrayList<BlendPath> = new ArrayList<BlendPath>();

        // when line wraps, invert the ordering of second line.
        // this better maintains relationship between the last color in first line and the next color

        let numColors: number = palette ? palette.length : 0;
        let types: number[][][] = [];
        // assign weights such that some occur more frequently than others
        let weights: number[] = [];
        switch (numColors) {
            case 6:
                // 5 color permutations we want
                types.push([[0, 1, 2], [5, 4, 3]]);         // 0: [3, 3]
                types.push([[0, 1, 2, 3], [5, 4]]);         // 1: [4, 2]

                weights.push(0);
                weights.push(100);

            case 5:
                // 5 color permutations we want
                types.push([[0, 1], [4, 3, 2]]);         // 0: [2, 3]
                types.push([[0, 1, 2], [4, 3]]);         // 1: [3, 2]
                types.push([[0, 1], [3, 2], [4]]);       // 2: [2, 2, 1]
                types.push([[0], [1, 2], [4, 3]]);       // 3: [1, 2, 2]
                types.push([[0, 1], [2], [3, 4]]);       // 4: [2, 1, 2]
                types.push([[0], [1, 2, 3, 4]]);         // 5: [1, 4]
                types.push([[0, 1, 2, 3], [4]]);         // 6: [4, 1]

                weights.push(25);
                weights.push(25);
                weights.push(15);
                weights.push(10);
                weights.push(10);
                weights.push(10);
                weights.push(5);

                break;
            case 4: 
                // 4 color permutations we want
                types.push([[0, 1], [3, 2]]);         // 0: [2, 2]
                types.push([[0, 1, 2], [3]]);         // 1: [3, 1]
                types.push([[0], [1, 2, 3]]);         // 2: [1, 3]

                weights.push(75);
                weights.push(15);
                weights.push(10);

                break;
            case 3: 
                // 3 color permutations we want
                types.push([[0, 1], [2]]);         // 0: [2, 1]
                types.push([[0], [1, 2]]);         // 1: [1, 2]

                weights.push(50);
                weights.push(50);

                break;
            case 2:
            case 1:
            default:
                // not enough colors to make a proper blend;
                // get a random one instead
                return GradientUtil.randomBlend();
        }

        if (typeof typeIdx !== 'number') {
            let appliedWeights = [];
            let numWeights: number = weights.length;
            let sumWeights: number = 0;
            for (let i: number = 0; i < numWeights; i++) {
                let weight: number = weights[i];
                sumWeights += weight;
                appliedWeights[i] = sumWeights;
            }
            let valueInAppliedWeights: number = Math.random() * sumWeights;
            typeIdx = ArrayUtil.binarySearch(appliedWeights, valueInAppliedWeights, false);
            if (isNaN(typeIdx) || (typeIdx < 0) || (typeIdx > types.length)) {
                typeIdx = 0;
            }
        }

        typeIdx = typeIdx % types.length;

        let type: number[][] = types[typeIdx]; 

        let colorIdx: number = 0;
        let numLines: number = type.length;
        for (let i: number = 0; i < numLines; i++) {
            let colorPalette: number[] = [];
            let lineColors: number[] = type[i];
            let numLineColors: number = lineColors ? lineColors.length : 0;
            for (let j: number = 0; j < numLineColors; j++) {
                colorPalette.push(palette[lineColors[j]])
            }
            let g = GradientUtil.randomGradient(numLineColors, numLineColors, 0, 0, colorPalette, null, GradientUtil.COLOR_CHOICE_ORDERED, GradientUtil.OFFSET_CHOICE_SPREAD);
            let lineOffset: number = i / (numLines - 1);
            paths.addItem(new BlendPath(g, lineOffset));
        }

        blend.paths = paths;
        
        var v = Math.random();
        //blend.orientation = (v > 0.5) ? Direction.HORIZONTAL : Direction.VERTICAL;

        return blend;
    }

    /**
     * Generates and returns a "random" Blend.
     * Defaults to 4-corner color gradient with no alphas
     */
    static randomBlend(minLines: number = 2, maxLines: number = 2, minColorsPerLine: number = 1, maxColorsPerLine: number = 2, minAlphasPerLine: number = 0, maxAlphasPerLine: number = 0): Blend {
        var blend: Blend = new Blend();
        var paths: ArrayList<BlendPath> = new ArrayList<BlendPath>();
        
        // var randomOffset: number = Math.random() * (maxLines - minLines);
        // var numLines: number = Math.round(minLines + randomOffset);
        // for (var i: number = 0; i < numLines; i++) {
        //     var g = GradientUtil.randomGradient(minColorsPerLine, maxColorsPerLine, minAlphasPerLine, maxAlphasPerLine);
        //     var offset: number = Math.random();
        //     stackEntries.addItem(new BlendPath(g, offset));
        // }
        
        
        let randomOffset: number = Math.random() * (maxColorsPerLine - minColorsPerLine);
        // let numColorsLine1: number = Math.round(minColorsPerLine + randomOffset);
        // HACK: use two stops per line for 60% of cases
        let numColorsLine1: number = (Math.random() > 0.40) ? 2 : 1;

        // give white and black a 10% chance if single color in line; TODO: use cached palette
        let colorPalette: number[] = null;
        if (numColorsLine1 === 1) {
            colorPalette = [0xFFFFFF, 0x000000];
            for (let i: number = 0; i < 18; i++) {
                colorPalette.push(null);
            }
        }

        var g1 = GradientUtil.randomGradient(numColorsLine1, numColorsLine1, 0, 0, colorPalette);
        paths.addItem(new BlendPath(g1, 0.0));

        randomOffset = Math.random() * (maxColorsPerLine - minColorsPerLine);
        //let numColorsLine2: number = Math.round(minColorsPerLine + randomOffset);
        // HACK: use two stops per line for 60% of cases
        let numColorsLine2: number = (Math.random() > 0.4) ? 2 : 1;


        // ensure that blend is multidimensional
        if ((numColorsLine1 === 1) && (numColorsLine2 === 1)) {
            numColorsLine2 += 1;
        }

        // give white and black a 10% chance if single color in line; TODO: use cached palette
        colorPalette = null;
        if (numColorsLine2 === 1) {
            colorPalette = [0xFFFFFF, 0x000000];
            for (let i: number = 0; i < 18; i++) {
                colorPalette.push(null);
            }
        }

        //var g2 = GradientUtil.randomGradient(minColorsPerLine, maxColorsPerLine, 0, 0);
        var g2 = GradientUtil.randomGradient(numColorsLine2, numColorsLine2, 0, 0, colorPalette);
        paths.addItem(new BlendPath(g2, 1.0));

        blend.paths = paths;
        
        var v = Math.random();
        blend.orientation = (v > 0.5) ? Direction.HORIZONTAL : Direction.VERTICAL;

        return blend;
    }

    /**
     * Generates a random Gradient using the specified minimum/maximum number of colors and alphas.
     */
    static randomGradient(minColors: number = 2, maxColors: number = 2, minAlphas: number = 0, maxAlphas: number = 0, colorPalette: number[] = null, alphaPalette: number[] = null, colorChoice: string = GradientUtil.COLOR_CHOICE_RANDOM, offsetChoice: string = GradientUtil.OFFSET_CHOICE_RANDOM): Gradient {
        var gradient: Gradient = new Gradient();

        var randomOffset: number = Math.random() * (maxColors - minColors);
        var numColorStops: number = Math.round(minColors + randomOffset);

        randomOffset = Math.random() * (maxAlphas - minAlphas);
        var numAlphaStops: number = Math.round(minAlphas + randomOffset);

        var i: number;
        var offset: number;
        var paletteSize: number = colorPalette ? colorPalette.length : 0;
        var numAlphas: number = alphaPalette ? alphaPalette.length : 0;

        if (numColorStops > 0) {
            var colorEntries = new ArrayList<GradientEntry>();
            var portionPerStop = 1 / numColorStops;
            for (i = 0; i < numColorStops; i++) {
                var color: number;
                if (colorPalette) {
                    switch (colorChoice) {
                        case GradientUtil.COLOR_CHOICE_RANDOM:
                            randomOffset = Math.round(Math.random() * (paletteSize - 1));
                            color = colorPalette[randomOffset];
                            break;
                        case GradientUtil.COLOR_CHOICE_ORDERED:
                            color = colorPalette[i];
                            break;
                        case GradientUtil.COLOR_CHOICE_ELIMINATION:
                            // TODO: use random offset, but mark as used and only use once
                            break;
                    }
                    
                    // allow null to be used to indicate random; e.g. [red, blue, null] will have a 1/3 chance of random color
                    if ((color === undefined) || (color === null)) {
                        color = Math.random() * 0xFFFFFF;
                    }
                }
                else {
                    color = Math.random() * 0xFFFFFF;
                }
                switch (offsetChoice) {
                    case GradientUtil.OFFSET_CHOICE_RANDOM:
                        offset = Math.random() * 1.0;
                        break;
                    case GradientUtil.OFFSET_CHOICE_SECTION:
                        offset = (i * portionPerStop) + (Math.random() * portionPerStop);   // keep each stop within section  
                        break;
                    case GradientUtil.OFFSET_CHOICE_SPREAD:
                        offset = (numColorStops === 1) ? 0 : i / (numColorStops - 1);
                        break;
                }
                colorEntries.addItem(new GradientEntry(GradientEntryType.TYPE_COLOR, offset, color));
            }
            gradient.colorEntries = colorEntries;
        }

        if (numAlphaStops > 0) {
            var alphaEntries = new ArrayList<GradientEntry>();
            for (i = 0; i < numAlphaStops; i++) {
                var alpha: number;
                if (alphaPalette) {
                    randomOffset = Math.round(Math.random() * (numAlphas - 1));
                    alpha = alphaPalette[randomOffset];
                    // allow null to be used to indicate random
                    if ((alpha === undefined) || (alpha === null)) {
                        alpha = Math.random() * 1.0;
                    }
                }
                else {
                    Math.random() * 1.0;
                }
                offset = Math.random() * 1.0;
                alphaEntries.addItem(new GradientEntry(GradientEntryType.TYPE_ALPHA, offset, alpha));
            }
            gradient.alphaEntries = alphaEntries;
        }

        return gradient;
    }

    static interpolateGradient(gradient: Gradient, offset: number): RGBA {
        var compareFunction = SortUtil.compareBy('offset');

        //var interpolatedColor = GradientUtil.interpolateStop(gradient.colorEntries, offset, 'color', ColorUtil.interpolateColor);
        var interpolatedColor;
        if (gradient.colorEntries && (gradient.colorEntries.length > 0)) {
            interpolatedColor = GradientUtil.interpolateStop(gradient.colorEntries, offset, 'color', ColorUtil.interpolateColor);
        }
        else {
            interpolatedColor = 0x000000;
        }

        var interpolatedAlpha;
        if (gradient.alphaEntries && (gradient.alphaEntries.length > 0)) {
            interpolatedAlpha = GradientUtil.interpolateStop(gradient.alphaEntries, offset, 'alpha', GradientUtil.linearInterpolator);
        }
        else {
            interpolatedAlpha = 1.0;
        }
        //console.log("gradient at: " + offset + " color: " + interpolatedColor + " alpha: " + interpolatedAlpha);

        return new RGBA(interpolatedColor, interpolatedAlpha);
    }

    private static interpolateStop(entries: IList<GradientEntry>, offset: number, propertyName: string, interpolateFunction?: (previous: any, next: any, offset: number) => number): any {
        var compareFunction = SortUtil.compareBy('offset');

        var interpolated: any;
        var entriesArray = entries.toArray();
        entriesArray.sort(compareFunction);
        var idxOfEntry: number = ArrayUtil.indexOf(entriesArray, { offset: offset }, true, compareFunction);
        if (idxOfEntry === -1) {
            // no entry exists for this offset; interpolate between neighbors
            var neighboringEntries = ArrayUtil.neighbors(entriesArray, { offset: offset }, false, compareFunction);
            GradientUtil._normalizeNeighbors(neighboringEntries);     // ensure neighbors on both sides
            var previousEntry: GradientEntry = (<GradientEntry>neighboringEntries.previous);
            var nextEntry: GradientEntry = (<GradientEntry>neighboringEntries.next);

            var previousValue: any = previousEntry[propertyName];
            var nextValue: any = nextEntry[propertyName];
            if (previousValue === nextValue) {
                interpolated = previousValue;
            }
            else {
                var relativeOffset: number = (offset - previousEntry.offset) / (nextEntry.offset - previousEntry.offset);
                interpolated = interpolateFunction(previousValue, nextValue, relativeOffset);
            }
        }
        else {
            // specific entry exists for this offset; use it
            var entry = entriesArray[idxOfEntry];
            interpolated = entry[propertyName];
        }
        return interpolated;
    }

    /**
     * Ensures that there are neighbors on both sides,
     * using a clone of the one from the previous for the next and vice-versa.
     * The offset of the cloned neighbor will be 0 or 1 for the previous and next respectively.
     */
    private static _normalizeNeighbors(neighbors: { previous: GradientEntry; next: GradientEntry }): void {
        if (!neighbors.previous) {
            neighbors.previous = neighbors.next.clone();
            neighbors.previous.offset = 0;
        }
        else if (!neighbors.next) {
            neighbors.next = neighbors.previous.clone();
            neighbors.next.offset = 1;
        }
    }

    /**
     * Finds the neighboring alpha and color stops for the specified offset
     * as well as the normalized offset between the neighboring stops.
     * @deprecated Use colorNeighbors and alphaNeighbors
     */
    static neighbors(gradient: Gradient, offset: number, force: boolean = true) /*: { alphaNeighbors: any, colorNeighbors: any } */ {
        var alphaNeighbors = GradientUtil.alphaNeighbors(gradient, offset);
        var colorNeighbors = GradientUtil.colorNeighbors(gradient, offset);

        return {
            alphaNeighbors: alphaNeighbors,
            colorNeighbors: colorNeighbors
        };
    }

    static colorNeighbors(gradient: Gradient, offset: number): NeighborInfo {
        return GradientUtil.stopNeighbors(gradient.colorEntries, offset);
    }

    static alphaNeighbors(gradient: Gradient, offset: number): NeighborInfo {
        return GradientUtil.stopNeighbors(gradient.alphaEntries, offset);
    }

    /**
     * Finds the neighboring stops and returns the normalized offset between these two neighbors.
     * If no neighbor is present on one side, it will use the neighbor from the other side.
     */
    private static stopNeighbors(entries: IList<any>, offset: number): NeighborInfo {
        if (!entries) {
            return null;
        }

        var compareFunction = SortUtil.compareBy('offset');

        // no entry exists for this offset; interpolate between neighbors
        var neighboringEntries: NeighborInfo;
        var previousEntry: GradientEntry;
        var nextEntry: GradientEntry;
        var normalizedOffset: number;

        var interpolated: any;
        var entriesArray = entries.toArray();
        entriesArray.sort(compareFunction);
        var idxOfEntry: number = ArrayUtil.indexOf(entriesArray, { offset: offset }, true, compareFunction);
        if (idxOfEntry === -1) {
            neighboringEntries = ArrayUtil.neighbors(entriesArray, { offset: offset }, false, compareFunction);

            GradientUtil._normalizeNeighbors(neighboringEntries);     // ensure neighbors on both sides

            previousEntry = (<GradientEntry>neighboringEntries.previous);
            nextEntry = (<GradientEntry>neighboringEntries.next);

            //// determine "neighbor" offset prior to normalizing neighbors
            //// use bounds of 0 and 1 if no neighbors on specific side
            //var previousEntryOffset: number = previousEntry ? previousEntry.offset : 0;
            //var nextEntryOffset: number = nextEntry ? nextEntry.offset : 1;

            
            
            normalizedOffset = (offset - previousEntry.offset) / (nextEntry.offset - previousEntry.offset);
        }
        else {
            // specific entry exists for this offset; use it
            previousEntry = entriesArray[idxOfEntry];
            nextEntry = entriesArray[idxOfEntry + 1];
            if (!nextEntry) {
                nextEntry = previousEntry.clone();
                nextEntry.offset = 1;
            }

            normalizedOffset = 0;
        }

        var neighborInfo: NeighborInfo = new NeighborInfo();
        neighborInfo.previous = previousEntry;
        neighborInfo.next = nextEntry;
        neighborInfo.normalizedOffset = normalizedOffset;
        return neighborInfo;
    }
    
    static colorLineNeighbors(blend: Blend, lineOffset: number): NeighborInfo {
        var paths = blend.paths;
        var pathsArray = paths.toArray();
        var colorPathsArray = pathsArray.filter((value, index: number, array: any[]) => {
            var line = value;
            var gradient: Gradient = line.gradient;
            if (gradient && gradient.colorEntries && (gradient.colorEntries.length > 0)) {
                return true;
            }
            return true;
        });
        
        var colorPaths: IList<BlendPath> = new ArrayList(colorPathsArray);
        return GradientUtil.stopNeighbors(colorPaths, lineOffset);
    }
    
    static alphaLineNeightbors(blend: Blend, lineOffset: number): NeighborInfo {
        return null;
    }

    /**
     * Provides the W3C CSS value for rendering the specified Gradient.
     */
    static GradientToCSS(gradient: Gradient): {[styleName: string]: string} {
        var o: any = {};
        var styleName: string;

        var flattenedGradientEntries = GradientUtil.flattenGradient(gradient);
        //var entries: IList<GradientEntry> = gradient.entries;
        var entry: GradientEntry;
        var rgba: RGBA;
        var len: number = flattenedGradientEntries ? flattenedGradientEntries.length : 0;
        if (len > 1) {
            // background: linear-gradient(to right, rgba(30,87,153,1) 0%,rgba(41,137,216,1) 40%,rgba(125,185,232,1) 100%);
            var s: string = 'linear-gradient(to right, ';
            for (var i: number = 0; i < len; i++) {
                entry = flattenedGradientEntries.getItemAt(i);
                rgba = new RGBA(entry.color, entry.alpha);
                s += rgba.toCSS();
                s += ' ' + (entry.offset * 100) + '%';
                s += ','
            }
            s = s.slice(0, -1);     // remove trailing ','

            styleName = 'background';
            o[styleName] = s;
        }
        else {
            styleName = 'background-color';
            entry = flattenedGradientEntries.getItemAt(0);
            rgba = new RGBA(entry.color, entry.alpha);
            o[styleName] = rgba.toCSS();
        }

        return o;
    }

    /*
    static interpolateGradientRGBA(gradient: Gradient, offset: number): RGBA {
        var rgba: RGBA = new RGBA();

        var entries: IList<GradientEntry> = gradient.entries;
        var numEntries = entries ? entries.length : 0;
        if (numEntries === 1) {
            entry = entries.getItemAt(0);
            rgba.color = entry.color;
            rgba.a = entry.alpha;
        }
        else {
            var entryIndex: number = ArrayUtil.binarySearch(entries.toArray(), { offset: offset }, false, ColorUtil._entryOffsetCompareFunction);
            if ((entryIndex <= 0) || (entryIndex >= numEntries)) {
                // beyond outer entry
                var entry: GradientEntry;
                entryIndex = Math.max(entryIndex, 0);
                entryIndex = Math.min(entryIndex, numEntries - 1);

                entry = entries.getItemAt(entryIndex);
                rgba.color = entry.color;
                rgba.a = entry.alpha;
            }
            else {
                // interpolate
                var startEntry = entries.getItemAt(entryIndex - 1);
                var endEntry = entries.getItemAt(entryIndex);

                var interpolateOffset: number = (offset - startEntry.offset) / (endEntry.offset - startEntry.offset);

                rgba.color = ColorUtil.interpolateColor(startEntry.color, endEntry.color, interpolateOffset);
                rgba.a = startEntry.alpha + ((endEntry.alpha - startEntry.alpha) * interpolateOffset);
            }
        }
        return rgba;
    }
    */

    private static linearInterpolator(a: any, b: any, offset: number): any {
        return a + ((b - a) * offset);
    }

    private static _mergeOffsets(entries: IList<GradientEntry>, offsets: number[]): void {
        var numEntries: number = entries ? entries.length : 0;
        for (var i: number = 0; i < numEntries; i++) {
            var entry: GradientEntry = entries.getItemAt(i);
            var offset: number = entry.offset;
            if (offsets.indexOf(offset) === -1) {
                offsets.push(offset);
            }
        }
    }
}

class InterpolatedStop {
    startIndex: number;
    endIndex: number;
    offset: number;
}

export = GradientUtil;