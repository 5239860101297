<div class="hsbColorPicker" style="position: absolute; width: 100%; height: 100%;">
    <div style="position: absolute; height: 100%; display: flex; flex-direction: column; justify-content: space-around; width: 80px;">
        <div style="display: flex; justify-content: space-around; align-items: center; width: 100%; height: 30px;">
            <span>H</span>
            <mat-form-field style="width: 50px;">
                <input type="number" matInput [ngModel]="h | number:'1.0-0'" (change)="_validateInput($event, 'h', 0, 360);" style="text-align: center;">
            </mat-form-field>
        </div>

        <div style="display: flex; justify-content: space-around; align-items: center; width: 100%; height: 30px;">
            <span>S</span>
            <mat-form-field style="width: 50px">
                <input matInput [ngModel]="s*100 | number:'1.0-0'" (change)="_validateInput($event, 's', 0, 1.0, 1/100);" style="text-align: center;">
            </mat-form-field>
        </div>
        
        <div style="display: flex; justify-content: space-around; align-items: center; width: 100%; height: 30px;">
            <span>B</span>
            <mat-form-field style="width: 50px;">
                <input matInput [ngModel]="b* 100 | number:'1.0-0'" (change)="_validateInput($event, 'b', 0, 1.0, 1/100);" style="text-align: center;">
            </mat-form-field>
        </div>
    </div>
    
    <hue-slider #hueSlider [(hue)]="h" style="position: absolute; top: 0px; left: 90px; right: 8px; height: 30px;"></hue-slider>
    <saturation-brightness-box #satBrightnessBox [(hue)]="h" [(saturation)]="s" [(brightness)]="b" style="position: absolute; top: 34px; left: 90px; right: 8px; bottom: 0px; background-color: green;"></saturation-brightness-box>
</div>