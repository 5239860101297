import Gradient = require('graphics/gradient');
import GradientEntry = require('graphics/gradient-entry');
import GradientUtil = require('utils/gradient-util');
import IList = require('collections/i-list');
import PropertyChangeEvent = require('events/property-change-event');
import Rectangle = require('geom/rectangle');
import RGBA = require('graphics/rgba');
import View = require('ui/view');

/**
 * Renders a LinearGradient.
 * Draws the gradient using Canvas, but could also use a DOM element with CSS gradient applied.
 */
class LinearGradientRenderer extends View {

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    private _context: any;//CanvasRenderingContext;
    private _canvasElement: HTMLCanvasElement;

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
        super();

        this.initialize();
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  gradient
    //----------------------------------

    private _gradient: Gradient;

    set gradient(value: Gradient) {
        if (this._gradient) {
            this._gradient.off(PropertyChangeEvent.PROPERTY_CHANGE, this._gradientChangeHandler);
        }

        this._gradient = value;

        this._gradient.on(PropertyChangeEvent.PROPERTY_CHANGE, this._gradientChangeHandler);
        this._gradientChangeHandler();
    }
    get gradient(): Gradient {
        return this._gradient;
    }

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * @inheritDoc
     */
    initialize(): void {
        super.initialize();

        //this._canvasElement = <HTMLCanvasElement>this.element;
        this._canvasElement = <HTMLCanvasElement>$('<canvas></canvas>')[0];
        $(this.el).append(this._canvasElement);
        this._context = this._canvasElement.getContext('2d');
    }

    /**
     * @inheritDoc
     */
    layout(): void {
        super.layout();

        this._canvasElement.width = this.pixelWidth;
        this._canvasElement.height = this.pixelHeight;

        this.render();
    }

    render(): void {
        var element = this._canvasElement;
        var ctx: CanvasRenderingContext2D = this._context;
        var gradient = this.gradient;

        var w: number = this.pixelWidth;
        var h: number = this.pixelHeight;

        //var tStart = Date.now();
        ctx.clearRect(0, 0, w, h);
        //ctx.rect(0, 0, w, h);
        var linearGradient = ctx.createLinearGradient(0, 0, w, h);
        var gradientEntries = GradientUtil.flattenGradient(gradient);
        var numLinearGradientEntries: number = gradientEntries ? gradientEntries.length : 0;

        //numLinearGradientEntries = 0;
        for (var i: number = 0; i < numLinearGradientEntries; i++) {
            var gradientEntry: GradientEntry = gradientEntries.getItemAt(i);
            var offset: number = gradientEntry.offset;
            offset = Math.max(offset, 0);
            offset = Math.min(offset, 1.0);
            linearGradient.addColorStop(offset, new RGBA(gradientEntry.color, gradientEntry.alpha).toCSS());
        }
        //linearGradient.addColorStop(0, 'white');
        //linearGradient.addColorStop(1, 'black');

        ctx.fillStyle = linearGradient;
        ctx.fillRect(0, 0, w, h);
        //var tEnd = Date.now();
        //console.log(tEnd - tStart);
    }

    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------

    /**
     * Handler for when the gradient changes.
     * Re-renders to reflect.
     */
    private _gradientChangeHandler = (): void => {
        this.render();
    }
}

export = LinearGradientRenderer;