class GradientEntryType {
    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------

    static TYPE_COLOR: string = 'COLOR';
    static TYPE_ALPHA: string = 'ALPHA';
    static TYPE_COLOR_AND_ALPHA: string = 'COLOR_AND_ALPHA';    // currently unused

    // -- Line Entries
    static TYPE_COLOR_GRADIENT: string = 'COLOR_GRADIENT';
    static TYPE_ALPHA_GRADIENT: string = 'ALPHA_GRADIENT';
    static TYPE_GRADIENT: string = 'GRADIENT';
}
export = GradientEntryType;
