class StringUtil {
	static dashToCamelCase(s: string): string {
		var parts = s.split('-');
		var len: number = parts.length;
		// start at 1 to skip first part and leave lowercase
		var convertedParts: string[] = [];
		convertedParts.push(parts[0]);
		for (var i: number = 1; i < len; i++) {
			var part: string = parts[i];
			part = part.charAt(0).toUpperCase() + part.substr(1);
			convertedParts.push(part);
		}
		
		return convertedParts.join('');
    }
    
    static safeName(s: string): string {
        return s.replace(/[^a-z0-9]/gi, '_');
    }
}
export = StringUtil;