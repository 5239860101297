import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import BlendInfo = require('graphics/blend-info');
import BlendView = require('graphics/blend-view');
import GradientUtil = require('utils/gradient-util');
import View = require('ui/view');

@Component({
  selector: 'shared-blend-list-item',
  templateUrl: './shared-blend-list-item.component.html',
  styleUrls: ['./shared-blend-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedBlendListItemComponent extends View {

    @Output()
    edit: EventEmitter<SharedBlendListItemComponent> = new EventEmitter<SharedBlendListItemComponent>();

    @Output()
    like: EventEmitter<SharedBlendListItemComponent> = new EventEmitter<SharedBlendListItemComponent>();

    @Output()
    delete: EventEmitter<SharedBlendListItemComponent> = new EventEmitter<SharedBlendListItemComponent>();

    @Output()
    featured: EventEmitter<SharedBlendListItemComponent> = new EventEmitter<SharedBlendListItemComponent>();

    @ViewChild('blendHolder', { static: true }) blendHolder: ElementRef;

    private _blendView: BlendView;

    // public for template 
    public _label: string;

    constructor() { 
        super();

        // HACK: determine this from user role in future
        // this only enables the Admin-specific UI
        // the server still restricts changes based on the authenticated user
        this.editable = (<any>window).isAdmin;
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  blendInfo
    //----------------------------------

    private _blendInfo: BlendInfo;

    /**
     * Info of the Blend to render.
     */
    set blendInfo(value: BlendInfo) {
        this._blendInfo = value;
        if (value) {
            if (this._blendView) {
                this._blendView.blend = this._blendInfo.blend;
            }
            this._label = this._blendInfo ? this._blendInfo.name : null;
        }
    }
    get blendInfo(): BlendInfo {
        return this._blendInfo;
    }

    //----------------------------------
    //  index
    //----------------------------------

    /**
     * Index within the collection that this item corresponds to.
     */
    index: number;

    //----------------------------------
    //  numLikes
    //----------------------------------

    /**
     * The total number of likes for the Blend.
     */
    numLikes: number = 0;

    //----------------------------------
    //  userLikes
    //----------------------------------

    /**
     * Whether or not the current user likes the Blend.
     */
    userLikes: boolean = false;

    //----------------------------------
    //  featuredLevel
    //----------------------------------

    /**
     * The level at which this Blend is featured. 
     * Higher levels are displayed above lower levels.
     * Undefined indicates that it is not featured.
     */
    featuredLevel: number = undefined;

    //----------------------------------
    //  editable
    //----------------------------------

    /**
     * Whether or not to expose editing controls.
     * Available for Admin only
     */
    editable: boolean = false;

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * @inheritdoc
     */
    initialize(): void {
        super.initialize();

        if (!this.blendHolder) {
            return;
        }

        const blendHolderElement = this.blendHolder.nativeElement;
        const blendView = this._blendView = new BlendView();

        // disable watch since we need to watch BlendInfo explicitly
        // to see if the blend was completely re-assigned
        blendView.watch = false;
        blendView.debounce = 300;
        if (this._blendInfo) {
            blendView.blend = this._blendInfo.blend;
        }
        else {
            blendView.blend = GradientUtil.randomBlend();
        }
        
        // need to set explicit size here to avoid flicker
        blendView.width = blendHolderElement.offsetWidth;
        blendView.height = blendHolderElement.offsetHeight;
        blendView.validate();
        this.blendHolder.nativeElement.appendChild(blendView.el);
    }

    /**
     * @inheritdoc
     */
    ngOnDestroy(): void {
        if (this._blendView) {
            this._blendView.destroy();
        }
    }

    /**
     * @private - public for template
     */
    public _featureLevelChange = (event): void => {
        this.featuredLevel = Number(event.target.value);
        this.featured.emit(this);
    }
}
