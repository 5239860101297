import $ = require('jquery');

import Gradient = require('graphics/gradient');
import GradientEntry = require('graphics/gradient-entry');
import GradientUtil = require('utils/gradient-util');
import IList = require('collections/i-list');
import ISliderThumb = require('controls/i-slider-thumb');
import RGBA = require('graphics/rgba');
import SliderThumb = require('controls/slider-thumb');
import View = require('ui/view');

const templateString = `
    <div class="gradientThumb">
        <div data-part-id="background" class="background"></div>
        <div data-part-id="gradient" class="gradient"></div>
    </div>
`;

class GradientThumb extends SliderThumb {

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    private _$gradientElement: JQuery;

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
        super();

        // requires amd-dependency above
        // this.templatePath = 'text!templates/controls/gradient-thumb.jst';
        this.templateString = templateString;
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  gradient
    //----------------------------------

    private _gradient: Gradient;

    /**
     * The Gradient.
     */
    set gradient(value: Gradient) {
        this._gradient = value;
        this.invalidateProperty('gradient');
    }
    get gradient(): Gradient {
        return this._gradient;
    }

    //----------------------------------
    //  selected
    //----------------------------------

    private _selected: boolean = false;

    /**
     * Whether or not the chip is selected;
     */
    set selected(value: boolean) {
        this._selected = value;
    }
    get selected(): boolean {
        return this._selected;
    }

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     *
     */
    initialize(): void {
        super.initialize();

        this._$gradientElement = $(this.el).find('[data-part-id="gradient"]');
    }

    /**
     * @inheritDoc
     */
    validateProperties(changed?: any): void {
        super.validateProperties(changed);

        var allChanged: boolean = (changed === null);

        if (allChanged || changed.gradient) {
            //var styles = this._gradientToCSS(this.gradient);
            //var styleName: string;
            //for (styleName in styles) {
            //    var styleValue = styles[styleName];
            //    this._$gradientElement.css(styleName, styleValue);
            //}
        }
    }

    /**
     * Provides the W3C CSS value for rendering the specified Gradient.
     */
    private _gradientToCSS(gradient: Gradient): {[styleName: string]: string} {
        var o: any = {};
        var styleName: string;

        var flattenedGradientEntries = GradientUtil.flattenGradient(gradient);
        //var entries: IList<GradientEntry> = gradient.entries;
        var entry: GradientEntry;
        var rgba: RGBA;
        var len: number = flattenedGradientEntries ? flattenedGradientEntries.length : 0;
        if (len > 1) {
            // background: linear-gradient(to right, rgba(30,87,153,1) 0%,rgba(41,137,216,1) 40%,rgba(125,185,232,1) 100%);
            var s: string = 'linear-gradient(to right, ';
            for (var i: number = 0; i < len; i++) {
                entry = flattenedGradientEntries.getItemAt(i);
                rgba = new RGBA(entry.color, entry.alpha);
                s += rgba.toCSS();
                s += ' ' + (entry.offset * 100) + '%';
                s += ','
            }
            s = s.slice(0, -1);     // remove trailing ','

            styleName = 'background';
            o[styleName] = s;
        }
        else {
            styleName = 'background-color';
            entry = flattenedGradientEntries.getItemAt(0);
            rgba = new RGBA(entry.color, entry.alpha);
            o[styleName] = rgba.toCSS();
        }

        return o;
    }
}
export = GradientThumb;