// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import "firebase/firestore";
import _ from 'underscore';
import { Component, ViewChild, ComponentFactoryResolver, ViewContainerRef, ChangeDetectionStrategy, ComponentRef, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, RoutesRecognized } from '@angular/router';
import { CollectionView, Animation, GridLayout, Range, Ranges, Size, CollectionViewAnimationReason } from 'collection-view';
import { MatButtonToggleChange, MatButtonToggleGroup } from "@angular/material/button-toggle";

import BlendInfo = require('graphics/blend-info');
import { LikeData } from "../like-data";
import { SharedBlendListItemComponent } from '../shared-blend-list-item/shared-blend-list-item.component';

@Component({
  selector: 'shared-blends',
  templateUrl: './shared-blends.component.html',
  styleUrls: ['./shared-blends.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedBlendsComponent implements AfterViewInit, OnDestroy {
    @ViewChild('collectionViewRef') collectionViewRef;
    @ViewChild('collectionViewRef', { read: ViewContainerRef, static: true }) collectionViewContainerRef: ViewContainerRef;
    @ViewChild('scrollerRef') scrollerRef;
    @ViewChild(MatButtonToggleGroup, { static: true }) categoryToggle: MatButtonToggleGroup;

    private _collectionView: CollectionView;
    /**
     * References to item components. Needed in order to cleanup.
     */
    private _componentRefs: ComponentRef<any>[] = [];
    public _blends: BlendInfo[] = [];               // public for template
    private _blendsCache: { [category: string]: BlendInfo[] } = {};
    private _collection: firebase.firestore.CollectionReference;
    private _moreAvailable: boolean = true;
    private _moreAvailableCache: { [category: string]: boolean } = {};
    public _category: Category = 'trending';        // public for template
    public _searchTerm: string;                     // public for template
    private _cursor: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
    private _cursorCache: { [category: string]: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> } = {};
    private _batchSize: number = 20;
    private _checkPending: boolean = false;
    private _initialized: boolean = false;
    public _loadPending: boolean = false;           // public for template
    public _loaded: boolean = false;                // public for template
    public _margin: number = 0;                     // public for template
    private _staggerIndices: number[];
    private _animationDuration: number = 300;
    private _animationDelayMultiplier: number = 100;
    private _likesCollection: firebase.firestore.CollectionReference;
    private _lastScrollPosition: number;

    /**
     * Handler for when resize occurs.
     * Generated via _.debounce 
     */
    private _resizeHandler;

    /**
     * @constructor
     */
    constructor(
        private cd: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private router: Router,
        private route: ActivatedRoute
    ) {
        const db = firebase.firestore();
        this._collection = db.collection('blends');
        this._likesCollection = db.collection('likes');
    }

    /**
     * @inheritDoc
     */
    ngAfterViewInit(): void {
        this.initialize();
    }

    /**
     * @inheritdoc
     */
    ngOnDestroy(): void {
        if (this.scrollerRef.nativeElement) {
            (this.scrollerRef.nativeElement as HTMLElement).removeEventListener('scroll', this._scrollHandler);
        }
        window.removeEventListener('resize', this._resizeHandler);
    }

    private async initialize(): Promise<void> {
        // detect changes from browser navigation
        this.route.url.subscribe((url) => {
            const snapshot = this.route.snapshot;
            const searchTerm: string = snapshot.queryParamMap.get('q');
            const category: Category = snapshot.paramMap.get('category') as Category;

            if (category === 'search') {
                if (this._initialized) {
                    if (this._searchTerm !== searchTerm) {
                        this._searchTerm = searchTerm;
                        this._search();
                    }
                }
                else {
                    this._searchTerm = searchTerm;
                    this._category = category;
                }
            }
            else {
                if (this._initialized) {
                    if (category !== this._category) {
                        // categoryChangeHandler will set `_category`
                        this._categoryChangeHandler(category as any);
                    }
                }
                else {
                    this._category = category;
                }
            }
        });

        await this._loadMoreBlends();
        
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(SharedBlendListItemComponent);
        let delegate = {
            getCount: () => {
                return this._blends.length;
            },
            configureElement: (element, index) => {
                let instance: SharedBlendListItemComponent;
                if (!element.componentRef) {
                    let componentRef = this.collectionViewContainerRef.createComponent(componentFactory);
                    element.componentRef = componentRef;
                    element.appendChild(componentRef.location.nativeElement);
                    
                    instance = componentRef.instance;
                    instance.edit.subscribe(this._viewHandler);
                    instance.like.subscribe(this._likeHandler);
                    instance.delete.subscribe(this._deleteHandler);
                    instance.featured.subscribe(this._featuredHandler);

                    this._componentRefs.push(componentRef);
                }
                else {
                    instance = element.componentRef.instance;
                }

                let blendInfo: BlendInfo = this._blends[index];
                let blendDoc = blendInfo.data as firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;

                instance.index = index;
                instance.blendInfo = blendInfo;
                instance.numLikes = blendDoc.data().numLikes;
                instance.featuredLevel = blendDoc.data().featuredLevel;
                    
                this.cd.detectChanges();
            },
            getAnimation: (index: number, info, property, reason) => {
                let delay: number = 0;
                if (reason === CollectionViewAnimationReason.ELEMENT_ADDITION) {
                    // item is in view to start
                    if (this._staggerIndices && this._staggerIndices.indexOf(index) !== -1) {
                        delay = (info.row + info.column) * this._animationDelayMultiplier;
                    }
                }
                return new Animation(this._animationDuration, delay);
            }
        };

        let itemSize = new Size(300, 300);
        let layout = new GridLayout({ itemSize: itemSize });

        this._collectionView = new CollectionView(this.collectionViewRef.nativeElement, layout, delegate);
        
        this.scrollerRef.nativeElement.addEventListener('scroll', this._scrollHandler, { passive: true });

        // debounce for performance
        // need to wait at least 1000ms for layout to stabilize
        // tested with smaller delays and layout did not provide correct positions 
        this._resizeHandler = _.debounce(this._updateSideMargins.bind(this), 1000);
        window.addEventListener('resize', this._resizeHandler);

        this._updateSideMargins();

        await this._animateBlendsIn();

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                const routePrefix: string = '/explore';
                if (!event.url.startsWith(routePrefix)) {
                    // navigating away; probably a better way to detect
                    // save scroll position to be restored when navigation back
                    this._lastScrollPosition = this.scrollerRef.nativeElement.scrollTop;
                }
                else if (!this.router.url.startsWith(routePrefix)) {
                    // navigating back; probably a better way to detect
                    // restore scroll position
                    window.requestAnimationFrame(() => {
                        this.scrollerRef.nativeElement.scrollTop = this._lastScrollPosition;
                    });
                }
            }
          });

        // wait until after entry animation complete before rendering other content
        this._loaded = true;
        this._initialized = true;
        this.cd.detectChanges();
    }

    /**
     * Updates the side margin to match up with teh positions of the rendered list items.
     */
    private _updateSideMargins(): void {
        if (!this._collectionView) {
            return;
        }
        this._margin = this._collectionView.layout.getElementPosition(0).x;
        this.cd.detectChanges();
    }

    /**
     * Determines the current indices that are in view.
     */
    private _getIndicesInView(): number[] {
        const numBlends: number = this._blends.length;

        // determine total duration to animate all items
        // CollectionView throws errors if data changes while animating
        let containerSize = this._collectionView.containerSize;
        let xRange: Range = new Range(0, containerSize.width);
        let yRange: Range = new Range(0, containerSize.height);

        // remember which indices are in view which will be referenced for item animations
        // avoids having to redetermine for each item
        return this._collectionView.layout.getIndices(new Ranges(xRange, yRange), numBlends, containerSize);
    }

    /**
     * Determines the total duration to animate in the current Blends (assuming empty previously)
     */
    private _getAnimationDuration(): number {
        let indicesInView = this._getIndicesInView();

        let info = this._collectionView.layout.getElementInfo(indicesInView[indicesInView.length - 1]);
        let maxDelay: number = (info.row + info.column) * this._animationDelayMultiplier;
        let totalDuration: number = maxDelay + this._animationDuration;
        return totalDuration;
    }


    /**
     * Tells the CollectionView to animate in all the current items.
     */
    private _animateBlendsIn(): Promise<void> {
        return new Promise((resolve, reject) => {
            this._staggerIndices = this._getIndicesInView();

            const numBlends: number = this._blends.length;
            const indices: number[] = [];
            for (let i: number = 0; i < numBlends; i++) {
                indices.push(i);
            }
            this._collectionView.changeIndices([], indices, new Map());
            
            let animationDuration: number = this._getAnimationDuration();
            setTimeout(() => {
                // avoid future staggering of animation
                this._staggerIndices = null;
                resolve();
            }, animationDuration);
        });
    }

    /**
     * Resets the Blends so that we will fetch more.
     */
    private async _showBlends(category: Category): Promise<void> {
        // hide existing Blends
        // this MUST be done prior to loading
        // to ensure entry animation for new Blends is correct
        await this._clearBlends();

        this._category = category;

        // restore any Blends that were previously loaded so we avoid re-fetching
        this._blends = this._blendsCache[category];
        if (!this._blends) {
            this._blends = [];
        }
        this._cursor = this._cursorCache[category];

        this._moreAvailable = this._moreAvailableCache[category];
        if (typeof this._moreAvailable !== 'boolean') {
            this._moreAvailable = true;
        }

        // move this to initialize method and make sure we unsubscribe when destroyed
        // clear caches on change
        this._collection.onSnapshot((querySnapshot) => {
            console.log('LOOK HERE: changed******************');
        });
        // this._likesCollection.onSnapshot(())

        if (this._blends.length <= 0) {
            await this._loadMoreBlends();
        }
        else {
            await this._animateBlendsIn();
        }
    }

    /**
     * Clears all Blends from view in animated fashion.
     */
    private _clearBlends(): Promise<void> {
        return new Promise((resolve, reject) => {
            let originalNumBlends: number = this._blends ? this._blends.length : 0;
            this._blends = [];

            if (this._collectionView) {
                // trigger entry animation on removed items
                // may be better to delay until after we fetched other blends
                // but this makes things a bit simpler
                const removedIndices: number[] = [];
                for (let i: number = 0; i < originalNumBlends; i++) {
                    removedIndices.push(i);
                }
                if (removedIndices.length > 0) {
                    // note: this is resetting the scroll position to the top whenever the category is changed
                    this._collectionView.changeIndices(removedIndices, [], new Map());

                    // timeout needed to allow change to be applied
                    // error thrown otherwise
                    setTimeout(() => {
                        resolve();
                    }, this._animationDuration);
                }
                else {
                    resolve();
                }
            }
            else {
                resolve();
            }
        });
    }

    /**
     * Loads more blends into view and updates the cursor.
     */
    private async _loadMoreBlends(): Promise<void> {
        if (!this._moreAvailable) {
            return;
        }

        this._loadPending = true;

        let results = await this._fetchBlends(this._category, this._searchTerm, this._batchSize, this._cursor);
        let blends: BlendInfo[] = results.blends;
        this._blends = this._blends ? this._blends.concat(blends) : blends;

        let docs = results.docs;
        if (docs.length < this._batchSize) {
            // all blends loaded
            // stop additional requests
            this._moreAvailable = false;
        }
        else {
            // update cursor
            this._cursor = docs[docs.length - 1];
        }

        if (this._collectionView) {
            // trigger entry animation on new items
            const newIndices: number[] = [];
            const numBlends: number = this._blends.length;
            const numNewBlends: number = blends ? blends.length : 0;
            for (let i: number = 0; i < numNewBlends; i++) {
                let idx: number = numBlends - (numNewBlends - i);
                newIndices.push(idx);
            }
            this._collectionView.changeIndices([], newIndices, new Map());
        }

        // do not consider loading completed until animation has finished
        // CollecionView throws errors when changing data while animation running  
        await new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, this._animationDuration)
        });

        this._loadPending = false;
    }

    /**
     * Fetches Blends from the server.
     * @param start 
     * @param limit 
     */
    private async _fetchBlends(category: Category, searchTerm?: string, limit: number = 50, cursor?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>): 
        Promise<{ blends: BlendInfo[], docs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]}> 
    {
        const query: firebase.firestore.Query<firebase.firestore.DocumentData> = this._constructQuery(category, searchTerm, limit, cursor);



        let blendResults = await query.get();

        let blends: BlendInfo[] = [];
        let docs = blendResults.docs;
        let numDocs: number = docs ? docs.length : 0;
        for (let i: number = 0; i < numDocs; i++) {
            let doc = docs[i];
            let blendData = doc.data();
            try {
                blendData.blend = JSON.parse(blendData.blend);
                let blendInfo: BlendInfo = BlendInfo.deserialize(blendData);
                blendInfo.guid = doc.id;
                blendInfo.data = doc;
                blends.push(blendInfo);
            }
            catch (e) {
                console.error('Failed to parse Blend');
            }
        }

        return {
            blends: blends,
            docs: docs
        };
    }

    private _constructQuery(category: Category, searchTerm?: string, limit: number = 50, cursor?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
        let query: firebase.firestore.Query<firebase.firestore.DocumentData>;

        query = this._collection;

        if (searchTerm) {
            query = query.where('nameFragments', 'array-contains', searchTerm.toLowerCase());
        }
        if ((category === 'featured') && !searchTerm) {
            query = query.where('featuredLevel', ">", 0);
        }

        let orderBy: string = 'name';
        let order: firebase.firestore.OrderByDirection = 'asc';
        if (searchTerm) {
            orderBy = 'numLikes';
            order = 'desc';
        }
        else if (category === 'featured') {
            orderBy = 'featuredLevel';
            order = 'desc';
        }
        else if (category === 'recent') {
            orderBy = 'created';
            order = 'desc';
        }
        else if (category === 'trending') {
            orderBy = 'numLikes';
            order = 'desc';
        }
        query = query.orderBy(orderBy, order);

        if (cursor) {
            query = query.startAfter(cursor);
        }
        query = query.limit(limit);
        
        return query;
    }

    /**
     * Handler for when user requests search results.
     * Navigates via router which refreshes view to reflect.
     */
    public _searchHandler = (event: any | string): void => {
        let searchTerm: string;
        if (event instanceof String) {
            searchTerm = String(event);
        }
        else {
            const input = event?.target as HTMLInputElement;
            searchTerm = input ? input.value : '';
        }
        this.router.navigateByUrl('/explore/search?q=' + searchTerm);
    }

    /**
     * @private - public for template
     * Searches for Blends using the current `_searchTerm`.
     */
    public async _search(): Promise<void> {
        // invalidate search cache whenever we search for something else
        this._clearCaches(['search']);

        this._loadPending = true;
        this.cd.detectChanges();
        await this._showBlends('search');
        this._loadPending = false;   
        this.cd.detectChanges();
    }

    /**
     * Checks whether we should load more based on 
     * how close we are to the bottom of the list.
     */
    private _checkLoadMore = (): void => {
        this._checkPending = false;

        let scrollerEl = this.scrollerRef.nativeElement as HTMLElement;
        let maxScroll: number = scrollerEl.scrollHeight - scrollerEl.offsetHeight;
        let distanceFromBottom: number = maxScroll - scrollerEl.scrollTop;
        if (distanceFromBottom < 300) {
            this._loadMoreBlends();
        }
    }

    /**
     * Clears the caches for the specific category,
     * resulting in them being re-fetched and re-rendered.
     * @param categories
     */
    private _clearCaches(categories?: Category[]): void {
        if (categories) {
            for (let i: number = 0; i < categories.length; i++) {
                let category: Category = categories[i];
                this._blendsCache[category] = [];
                delete this._cursorCache[category];
                delete this._moreAvailableCache[category];
            }
        }
        else {
            this._blendsCache = {};
            this._cursorCache = {};
            this._moreAvailableCache = {};
        }
    }

    /**
     * Handler for when the user chooses to view a Blend.
     * Navigates to the appropriate view.
     */
    private _viewHandler = (target: SharedBlendListItemComponent) => {
        let blendInfo = target.blendInfo;

        this.router.navigate(['/details', blendInfo.guid]);
    }

    /**
     * Handler for when the user likes a Blend.
     * Increments the `numLikes` and adds a new `like` to the `likes` collection.
     */
    private _likeHandler = async (target: SharedBlendListItemComponent) => {
        let blendInfo = target.blendInfo;

        let user = firebase.auth().currentUser;
        if (!user) {
            // we should always have a user
            // will be anonymous if the user has not signed in
            return;
        }

        let doc = blendInfo.data as firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;

        let numLikes: number = doc.data().numLikes;
        if (isNaN(numLikes)) {
            numLikes = 0;
        }

        // support un-liking later
        let liked: boolean = true;
        if (liked) {
            await doc.ref.update({ numLikes: numLikes + 1 });

            let like: LikeData = {} as LikeData;
            like.blendId = blendInfo.guid;
            like.userId = user.uid;
            like.timestamp = firebase.firestore.Timestamp.now();

            await this._likesCollection.add(like);
        }
        else {
            doc.ref.update({ numLikes: numLikes - 1 });

            // TODO: remove like from DB
            // get doc, then delete
            // let doc = this._likesCollection.doc(id);
            // doc.delete();
        }
    }

    /**
     * Handler for when the Admin deletes a Blend.
     * Requests deletion from DB and updates view to reflect.
     * This clears all caches in order to re-fetch.
     */
    private _deleteHandler = async (target: SharedBlendListItemComponent) => {
        let blendInfo = target.blendInfo;
        let guid: string = blendInfo.guid;

        // find index where Blend is
        // attempted to assign index in `configureElement` for use here
        // but it appears that is not called again to updatethe index when the collection is modified
        // let idx: number = target.index;
        let idx: number;
        let blends = this._blends;
        let numBlends: number = blends ? blends.length : 0; 
        for (let i: number = 0; i < numBlends; i++) {
            let blend = this._blends[i];
            if (blend.guid === guid) {
                idx = i;
                break;
            }
        }

        await this._collection.doc(guid).delete();

        this._blends.splice(idx, 1);

        // deleting invalidates caches
        this._clearCaches();

        this._collectionView.changeIndices([idx], [], new Map());
    }

    /**
     * Handler for when the Admin features a Blend.
     * Updates the entry in the DB and updates view to reflect.
     * This clears the featured cache so that the items are redrawn in updated order.
     */
    private _featuredHandler = async (target: SharedBlendListItemComponent) => {
        let blendInfo = target.blendInfo;
        let doc = blendInfo.data as firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;

        let featuredLevel: number = target.featuredLevel;
        if (featuredLevel >= 0) {
            await doc.ref.update({ featuredLevel: target.featuredLevel });
        }
        else {
            await doc.ref.update({ featuredLevel: firebase.firestore.FieldValue.delete() });
        }

        // clear featured cache so that items are re-ordered and removed as necessary
        this._clearCaches(['featured']);

        // re-render if necessary
        if (this._category === 'featured') {
            await this._clearCaches();
            this._showBlends(this._category);
        }
    }
    
    /**
     * Handler for when the user scrolls.
     * Queues up a check to see whether we need to load more.
     */
    private _scrollHandler = (event: Event): void => {
        if (!this._checkPending && !this._loadPending) {
            window.requestAnimationFrame(this._checkLoadMore);
            this._checkPending = true;
        }
    }

    /**
     * Handler for when user toggles the category.
     * Navigates via router which refreshes the view to reflect.
     * @param event 
     */
    public _categoryToggleHandler(event: MatButtonToggleChange): void {
        let category: Category = event.value;

        this.router.navigateByUrl('/explore/' + category);
    }

    /**
     * Handler for when the category changes.
     * Updates caches and the view to reflect.
     */
    private _categoryChangeHandler = async (category: Category): Promise<void> => {
        this._searchTerm = undefined;

        if (this._category !== 'search') {
            this._blendsCache[this._category] = this._blends;
            this._cursorCache[this._category] = this._cursor;
            this._moreAvailableCache[this._category] = this._moreAvailable;
        }

        this.categoryToggle.disabled = true;
        this._loadPending = true;
        this.cd.detectChanges();
        await this._showBlends(category);
        this.categoryToggle.disabled = false;
        this._loadPending = false;
        this.cd.detectChanges();
    }
}

type Category = 'featured' | 'trending' | 'recent' | 'search';
