/// <reference path="../typings/adobe-cep.d.ts"/>
/// <reference path="../typings/creative-cloud-extension.d.ts" />

import { Component, Input } from '@angular/core';

import {
  query,
  trigger,
  stagger,
  state,
  style,
  animate,
  animateChild,
  transition
} from '@angular/animations';

@Component({
  selector: 'share-button',
  templateUrl: './share-button.component.html',
  animations: [
    trigger('menuState', [
        state('open', style({
            visibility: 'visible'
        })),
        state('closed', style({
            visibility: 'hidden'
        })),
        transition('closed => open', [
            style({ visibility: 'visible'}),
            query('button', style({ opacity: 0, transform: 'scale(0.5)' })),
            query('button', stagger('40ms', [
                animate('150ms ease-out', style({ opacity: 1, transform: 'scale(1.0)' }))
            ]))
        ]),
        transition('open => closed', [
            query('button', style({ opacity: '*', transform: '*' })),
            query('button', stagger('-30ms', [
                animate('100ms ease-out', style({ opacity: 0, transform: 'scale(0.5)' }))
            ]))
        ])
    ]),
    trigger('iconState', [
        state('open', style({
            opacity: 0.0,
            transform: 'rotateZ(180deg)'
        })),
        state('closed', style({
            opacity: 1.0,
            transform: 'rotateZ(0deg)'
        })),
        transition('* <=> *', animate('200ms ease-out'))
    ]),
    trigger('closeIconState', [
        state('open', style({
            opacity: 1.0,
            transform: 'rotateZ(180deg)'
        })),
        state('closed', style({
            opacity: 0.0,
            transform: 'rotateZ(0deg)'
        })),
        transition('* <=> *', animate('200ms ease-out'))
    ])
  ],
  styleUrls: ['./share-button.component.scss'],
})
export class ShareButtonComponent {

    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------

    static EVENT_FACEBOOK: string = 'facebook';
    static EVENT_TWITTER: string = 'twitter';
    static EVENT_G_PLUS: string = 'gPlus';
    static EVENT_MAIL: string = 'mail';

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
    }

    _menuState: string = 'closed';

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  url
    //----------------------------------

    /**
     * URL to share.
     */
    @Input()
    url: string;

    //----------------------------------
    //  shareTitle
    //----------------------------------

    /**
     * Title to include when sharing.
     * "title" is used for HTMLElement tooltips. 
     */
    @Input()
    shareTitle: string;

    //----------------------------------
    //  message
    //----------------------------------

    /**
     * Message to include when sharing.
     */
    @Input()
    message: string;

    //----------------------------------
    //  twitter
    //----------------------------------

    /**
     * Twitter handle.
     */
    @Input()
    twitter: string;

    //----------------------------------
    //  tags
    //----------------------------------

    /**
     * Comma-delimited tags to include.
     */
    @Input()
    tags: string;

    //----------------------------------
    //  open
    //----------------------------------

    _opened: boolean = false;

    /**
     * Whether or not the sharing menu is open.
     */
    set opened(value: boolean) {
        if (this._opened !== value) {
            this._opened = value;
            this._menuState = this._opened ? 'open' : 'closed';
        }
    }
    get opened(): boolean {
        return this._opened;
    }

    //----------------------------------
    //  callback
    //----------------------------------

    callback: Function = (...args): void => {}

    //----------------------------------
    //  label
    //----------------------------------

    @Input()
    /**
     * Label to display on the button.
     * If not set, will only show the icon.
     */
    label: string;

    @Input()
    buttonStyle: 'mat-raised-button' | 'mat-flat-button' | 'mat-stroked-button' | 'mat-icon-button' | 'mat-fab' | 'mat-mini-fab' = 'mat-icon-button';

    _facebookHandler = (): void => {
        let intentUrl: string = 'https://www.facebook.com/sharer.php';
        intentUrl += '?u=' + this.url;
        intentUrl += '&t=' + (<any>window).encodeURI(this.message);
        this._openLink(intentUrl);
        this.callback(ShareButtonComponent.EVENT_FACEBOOK);
        this.opened = false;
    }

    _twitterHandler = (): void => {
        // https://dev.twitter.com/web/tweet-button/web-intent
        var intentUrl: string = 'https://twitter.com/intent/tweet';
        // TODO: use QueryStringUtil
        intentUrl += '?url=' + this.url;
        intentUrl += '&text=' + (<any>window).encodeURI(this.message + '\n');
        intentUrl += '&hashtags=' + this.tags;
        intentUrl += '&via=' + this.twitter;
        this._openLink(intentUrl);
        this.callback(ShareButtonComponent.EVENT_TWITTER);
        this.opened = false;
    }

    _gPlusHandler = (): void => {
        // https://developers.google.com/+/web/share
        var intentUrl: string = 'https://plus.google.com/share';
        // TODO: use QueryStringUtil
        intentUrl += '?url=' + this.url
        this._openLink(intentUrl);
        this.callback(ShareButtonComponent.EVENT_G_PLUS);
        this.opened = false;
    }

    _mailHandler = (): void => {
        var intentUrl: string = 'mailto:';
        // TODO: use QueryStringUtil
        intentUrl += '?subject=' + (<any>window).encodeURI(this.shareTitle);
        var body = this.message + '\n' + this.url;
        body = (<any>window).encodeURI(body);
        intentUrl +=' &body=' + body;
        this._openLink(intentUrl, true);
        this.callback(ShareButtonComponent.EVENT_MAIL);
        this.opened = false;
    }

    _openLink(url: string, self: boolean = false): void {
        // need to open links through host when running as plugin
        if (window.creativeCloudExtension && (<any>window).cep) {
            window.cep.util.openURLInDefaultBrowser(url);
        }
        else {
            if (self) {
                window.open(url, '_self');
            }
            else {
                window.open(url, '_blank');
            }
        }
    }

    _toggle = () => {
        this.opened = !this.opened;
    }
}