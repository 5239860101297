import _ from 'underscore';

import { Component, ViewChild } from '@angular/core';

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import { Router } from '@angular/router';

import BlendInfo = require('graphics/blend-info');
import { BlendData } from '../blend-data';
import { BlendViewControllerComponent } from 'blend-view-controller.component';
import { LoginComponent } from '../login/login.component';

@Component({
    selector: 'app-blend-create',
    templateUrl: './blend-create.component.html',
    styleUrls: ['./blend-create.component.scss']
})
export class BlendCreateComponent {

    @ViewChild('blendViewController', { static: true }) blendViewController: BlendViewControllerComponent;

    constructor(
        private router: Router
    ) {
        // re-render on window resize
        // may want to change this to use BreakpointObserver to avoid unnecessary renders
        // but this works for now.
        // debounce for performance
        this._resizeHandler = _.debounce(this.__resizeHandler, 250);
        window.addEventListener('resize', this._resizeHandler);
    }

    public blend;

    /**
     * Whether or not we are attempting to publish to the DB.
     */
    public _publishing: boolean = false;

    /**
     * Handler for when resize occurs.
     * Generated via _.debounce 
     */
    private _resizeHandler;

    private __resizeHandler = (): void => {
        if (this.blendViewController) {
            this.blendViewController.invalidateHorizontalLayout();
            this.blendViewController.invalidateVerticalLayout();
            this.blendViewController.layout();
        } 
    }

    /**
     * @private;
     */
    public _submit = async (): Promise<void> => {
        this._publishing = true;

        const db = firebase.firestore();

        let blendInfo: BlendInfo = this.blendViewController.blendInfo;

        try {
            // user signed in
            const currentUser = firebase.auth().currentUser;
            if (currentUser && !currentUser.isAnonymous) {
                let blend: BlendData = {} as any;
                blend.name = blendInfo.name;

                if (!blend.name) {
                    this.blendViewController.nameError = 'Give this beauty a name.';
                    this._publishing = false;
                    return;
                }

                const isProfaneFunction = firebase.functions().httpsCallable('isProfane');
                const isProfaneResult = await isProfaneFunction(blend.name);
                const isProfane: boolean = isProfaneResult.data;
                if (isProfane) {
                    this.blendViewController.nameError = 'Keep it clean, buddy!';
                    this._publishing = false;
                    return;
                }

                blend.blend = JSON.stringify(blendInfo.blend.serialize());
                blend.authorId = currentUser.uid;
                blend.created = firebase.firestore.Timestamp.now();
                blend.numLikes = 0;

                // hard-coded for now so that documents will contain this field
                // for future use where we want explicit approval
                // at that point, it would be better to move this into a DB trigger
                // though that will incur an additional write
                blend.approved = true;

                let docReference = await db.collection("blends").add(blend);
                
                this.router.navigate(['/details', docReference.id], { queryParams: { created: true} });
            }
            else {
                await LoginComponent.show('Please sign in to publish.');
            }
        }
        catch (e) {
            console.error(e);
        }

        this._publishing = false;
    }
}
