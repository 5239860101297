class Direction {

    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------

    static HORIZONTAL: string = 'HORIZONTAL';

    static VERTICAL: string = 'VERTICAL';
}
export = Direction;
