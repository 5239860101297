import $ = require('jquery');
import Hammer = require('hammerjs');

import ArrayList = require('collections/array-list');
import CollectionEvent = require('events/collection-event');
import IList = require('collections/i-list');
import ISliderThumb = require('controls/i-slider-thumb');
import Point = require('geom/point');
import PointerUtil = require('utils/pointer-util');
import Slider = require('controls/slider');
import View = require('ui/view');

/**
 * The <code>SelectableThumbSlider</code> extends <code>Slider</code> to 
 * provide thumb selection management.
 * TODO: rename
 */
class SelectableThumbSlider extends Slider {

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    // item of the thumb being removed via drag out
    private _removedItem: any = undefined;

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
        super();

        this._selectedIndices.on(CollectionEvent.COLLECTION_CHANGE, this._selectedIndicesChangeHandler);
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  selectedIndices
    //----------------------------------

    private _selectedIndices: IList<number> = new ArrayList<number>();

    /**
     * Hash of whether or not the thumbs are selected, by index.
     * Typed as ArrayList for access to "source".
     */
    set selectedIndices(value: IList<number>) {
        if (this._selectedIndices === value) {
            return;
        }

        if (this._selectedIndices) {
            this._selectedIndices.off(CollectionEvent.COLLECTION_CHANGE, this._selectedIndicesChangeHandler);
        }

        this._selectedIndices = value;
        this._selectedIndices.on(CollectionEvent.COLLECTION_CHANGE, this._selectedIndicesChangeHandler);
        this._selectedIndicesChangeHandler(null);
        
        if (this._selectedIndices) {
            // console.log("set selectedIndices: ", (<any>this._selectedIndices).source);
        }
    }
    get selectedIndices(): IList<number> {
        return this._selectedIndices;
    }
    
    //----------------------------------
    //  selectedValues
    //----------------------------------
    
    /**
     * Helper property for mapping between selected values by reference and their indices.
     * Should only be used when values are references and not simple types (e.g. numbers)
     * and the list of values contains no duplicate references.
     */
    set selectedValues(value: IList<any>) {
        var indices: number[] = [];
        
        var values = this.values;
        var numValues: number = values ? values.length : 0;
        
        var selectedValues = value;
        var numSelected: number = selectedValues ? selectedValues.length : 0;
        for (var i: number = 0; i < numSelected; i++) {
            var selectedValue = selectedValues.getItemAt(i);
            // determine the index of this stop within the list of values
            for (var j: number = 0; j < numValues; j++) {
                var v = values.getItemAt(j);
                if (v === selectedValue) {
                    indices.push(j);
                    break;
                }
            }
        }
        this.selectedIndices = new ArrayList(indices);
    }
    get selectedValues(): IList<any> {
        var selectedValues: any[] = [];
        var values = this.values;
        var numValues: number = values ? values.length : 0;
            
        var selectedIndices: IList<number> = this.selectedIndices;
        var numSelected: number = selectedIndices ? selectedIndices.length : 0;
        for (var i: number = 0; i < numSelected; i++) {
            var selectedIndex: number = selectedIndices.getItemAt(i);
            var v = values.getItemAt(selectedIndex);
            selectedValues.push(v);
        }
        return new ArrayList(selectedValues);
    }
    
    //----------------------------------
    //  itemFactory
    //----------------------------------

    /**
     * Factory used to create new items.
     */ 
    itemFactory: (index: number, offset: number) => any;

    //----------------------------------
    //  minLength
    //----------------------------------

    /**
     * The minimum length to impose on <code>values</code>.
     * The Slider will not allow further thumbs to be removed by dragging them off.
     */
    minLength: number = 1;

    //----------------------------------
    //  maxLength
    //----------------------------------

    /**
     * The maximum length to impose on <code>values</code>.
     * The Slider will not allow further thumbs to be added by pressing the track.
     */
    maxLength: number = 10;

    //----------------------------------
    //  addOnDown
    //----------------------------------

    /**
     * Whether or not to add an item on the "down" event rather than a full tap.
     * When <code>true</code> the new thumb will immediately start dragging.
     * To disable addition of new items completely you may set the <code>maxLength</code>.
     */
    addOnDown: boolean = true;

    //----------------------------------
    //  removeDistance
    //----------------------------------

    /**
     * The minimum distance that the pointer needs to be away from the gutter
     * for a value to be removed.
     */
    removeDistance: number = Number.POSITIVE_INFINITY;//80;
    
    //----------------------------------
    //  validator
    //----------------------------------
    
    /**
     * Optional validator that can be used to validate the addition / removal of values.
     */
    validator: (proposedLength: number) => boolean;

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * @inheritDoc
     */
    initialize(): void {
        super.initialize();

        var $track = $(this.getPart(Slider.PART_TRACK));

        // setup both down and tap handlers once and check addOnDown whenever they fire
        $track.on('mousedown touchstart', this.__trackDownHandler);
        // use hammer here instead of jQuery tap
        // hammer is more accurate in determining taps (e.g. long press/release is not a tap)
        // var hammertime = new Hammer($track[0]);
        // hammertime.on('tap', this._trackTapHandler);

        // $track.on('tap', this._trackTapHandler);
        $track[0].addEventListener('click', this._trackTapHandler);

        this.on(Slider.EVENT_THUMB_DOWN, this._thumbDownHandler);
    }

    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------

    /**
     * Extended to add necessary events to thumb elements for selection tracking.
     */
    /* override protected */
    /*_createThumb(index: number): View {
        var thumb = super._createThumb(index);
        $(thumb.element).on('mousedown touchstart', this._thumbDownHandler);
        return thumb;
    }
    */

    
    private _thumbDownHandler = (event, index: number): void => {
        // remember that we are no longer removing an item so that it won't be added back on move
        // could do this on thumbUp as well
        this._removedItem = undefined;
        
        // single select
        this._selectedIndices.empty();
        this._selectedIndices.addItem(index);
    }
    
    

    /**
     * Handler for when the selected indices change.
     * Applies selections to the thumbs.
     */
    private _selectedIndicesChangeHandler = (event?: CollectionEvent): void => {
        var thumbs: ISliderThumb[] = this._thumbs;

        // console.log("selections change for slider: " + this.name);

        var kind: string = event ? event.kind : null;
        switch (kind) {
            default:
                var numThumbs: number = thumbs ? thumbs.length : 0;
                for (var i: number = 0; i < numThumbs; i++) {
                    var thumb = thumbs[i];
                    var idx: number = this._selectedIndices.findIndex((idx: number) => {
                        if (i === idx) {
                            return true;
                        }
                        return false;
                    });
                    var selected: boolean = idx === -1 ? false : true;
                    (<any>thumb).selected = selected;
                }
        }
    }

    /* protected */ _moveHandler(event: BaseJQueryEventObject): void {
        var activeThumbIndex: number = this._activeThumbIndex;
        
        //var offset: Point = new Point(this._offsetLeft, this._offsetTop);
        var p: Point = PointerUtil.normalizeJQueryEvent(event, false);// true, offset);

        var d: number;
        var removing: boolean = false;
        // TODO: use outer bound (left or right, top or bottom); thin enough now that it's close enough
        if (this.vertical) {
            d = p.x - this._offsetLeft;
        }
        else {
            d = p.y - this._offsetTop;
        }
        //console.log("d: " + d, "removed: " + this._removedItem);
        if (Math.abs(d) > this.removeDistance) {
            removing = true;
        }
        else {
            removing = false;
        }
        if (removing && (typeof this._removedItem === 'undefined')) {
            if (!this.minLength || (this.values.length > this.minLength)) {
                // remove it
                // console.log("removeItemAt: " + this._activeThumbIndex);
                this._removedItem = this.values.removeItemAt(this._activeThumbIndex);
                
                // update selectedIndices to reflect removal
                var selectedIndices: IList<number> = this._selectedIndices;
                var numSelected: number = selectedIndices ? selectedIndices.length : 0;
                for (var i: number = numSelected - 1; i >= 0; i--) {
                    var selectedIndex: number = selectedIndices[i];
                    if (selectedIndex === this._activeThumbIndex) {
                        // selected item removed
                        selectedIndices.removeItemAt(i);
                    }
                    else if (selectedIndex > this._activeThumbIndex) {
                        // selected item index decremented 
                        selectedIndices.setItemAt(i, selectedIndex - 1);
                    }
                }
            }
            else {
                // drag it like normal
                super._moveHandler(event);
            }
        }
        else if (!removing && (typeof this._removedItem !== 'undefined')) {
            // add it back
            //console.log("vfp: " + this._valueForPosition(p.x, p.y));
            var value = this._valueForPosition(p.x, p.y);

            // add to end
            // not maintaining order
            //var index: number = this.values.length;// - 1;
            var item: any = this._removedItem;
            //item.offset = ratio;
            item.offset = value;
            this.values.addItemAt(this._activeThumbIndex, this._removedItem);
            
            // select re-added item
            selectedIndices.addItem(this._activeThumbIndex);

            // remember that it's been added back
            this._removedItem = undefined;

            // force addition of thumb in case another move event fires prior to validation occurring
            this.validate();
        }
        else if (!removing) {
            // drag it like normal
            super._moveHandler(event);
        }
    }

    private __trackDownHandler = (event: BaseJQueryEventObject, fromHammer: boolean = false): void => {
        // cache offset on down and use for subsequent drags to avoid browser reflows
        // offset should not be changing at this time
        var elementOffset = $(this.el).offset();
        this._offsetLeft = elementOffset.left;
        this._offsetTop = elementOffset.top;
        
        this._downOffsetLeft = this._downOffsetTop = 0;
        
        var isDownEvent: boolean = (event.type === 'mousedown') || (event.type === 'touchstart');
        if (!this.addOnDown && isDownEvent) {
            // we already handled
            // listener is setup regardless to we don't have to toggle on/off
            return;
        }

        if (!this.itemFactory) {
            return;
        }
        
        if (this.validator) {
            var proposedLength: number = this.values.length + 1;
            var valid: boolean = this.validator(proposedLength);
            if (!valid) {
                return;
            }
        }
        if (this.maxLength && this.values && (this.values.length >= this.maxLength)) {
            return;
        }

        var p: Point = fromHammer ? (<any>event).center : PointerUtil.normalizeJQueryEvent(event, false);
        if (fromHammer) {
            var zoom = Number($(document.body).css('zoom'));
            if ((zoom !== 1) && !isNaN(zoom)) {
                p.x /= zoom;
                p.y /= zoom;
            }
        }
        
        var offset: number = this._valueForPosition(p.x, p.y, false);
        
        // add to end
        // not maintaining order
        var index: number = this.values.length;
        var item: any = this.itemFactory(index, offset);
        this.values.addItemAt(index, item);
        
        // select added item
        this._selectedIndices.addItem(index);

        // prevent outer handlers from triggering
        event.preventDefault();
        //event.stopPropagation();
        //event.stopImmediatePropagation();

        // force validation of values so that thumb is created for downHander below
        this.validate();

        if (this.addOnDown && isDownEvent) {
            // pretend that thumb was pressed immediately
            // thumb must be created as part of addItemAt
            var thumb = this._thumbs[index];
            var fakeDownEvent: any = {};
            fakeDownEvent.currentTarget = thumb.el;
            fakeDownEvent.preventDefault = function () { };
            fakeDownEvent.originalEvent = {
                stopPropagation: function () { },
                stopImmediatePropagation: function () { }
            };

            this._downHandler(fakeDownEvent);
        }
        else {
            // pretend that thumb was tapped immediately
            // thumb must be created as part of addItemAt
            var thumb = this._thumbs[index];
            var fakeTapEvent: any = {};
            fakeTapEvent.srcEvent = {};
            fakeTapEvent.target = thumb.el;
            fakeTapEvent.currentTarget = thumb.el;
            fakeTapEvent.preventDefault = function () { };
            fakeTapEvent.originalEvent = {
                stopPropagation: function () { },
                stopImmediatePropagation: function () { }
            };
            this._tapHandler(fakeTapEvent);
        }
    }

    private _trackTapHandler = (event): void => {
        this.__trackDownHandler(event, false);
    }
}
export = SelectableThumbSlider;