class UIDUtil {

    /**
     * Generates a "unique" ID.
     * Not actually unique, but random enough that the chances of a collision are negligible.
     */
    static uid(): string {
        var delimiter: string = '-';
        var S4 = UIDUtil._S4;
        return (S4() + S4() + delimiter + S4() + delimiter + S4() + delimiter + S4() + delimiter + S4() + S4() + S4());
    }

    private static _S4(): string {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
}
export = UIDUtil;