import $ = require('jquery');

import BindingUtil = require('utils/binding-util');
import ISliderThumb = require('controls/i-slider-thumb');
import PropertyChangeEvent = require('events/property-change-event');
import RGBA = require('graphics/rgba');
import SliderThumb = require('controls/slider-thumb');
import View = require('ui/view');

// inner element allows hit area to extend outside of visible area
const templateString = `
    <div class="colorThumb thumb part-thumb">
        <div class="inner">
            <div data-part-id="color" class="color"></div>
        </div>
    </div>
`;

class ColorThumb extends SliderThumb implements ISliderThumb {

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    private _$colorElement: JQuery;

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
        super();

        this.templateString = templateString;
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  rgba
    //----------------------------------

    private _rgba: RGBA;

    /**
     * The RGBA color.
     */
    set rgba(value: RGBA) {
        //console.log('color thumb listen to: ', value);
        if (this._rgba) {
            // assumes watch wasn't disabled after setting rgba
            if (this.watch) {
                this._rgba.off(PropertyChangeEvent.PROPERTY_CHANGE, this._rgbaChangeHandler);
            }
        }

        this._rgba = value;
        
        if (this.watch) {
            this._rgba.on(PropertyChangeEvent.PROPERTY_CHANGE, this._rgbaChangeHandler);
        }
        
        this.invalidateProperty('rgba');
    }
    get rgba(): RGBA {
        return this._rgba;
    }

    //----------------------------------
    //  selected
    //----------------------------------

    private _selected: boolean = false;

    /**
     * Whether or not the chip is selected;
     */
    set selected(value: boolean) {
        if (this._selected !== value) {
            this._selected = value;
            this.invalidateProperty('selected');
        }
    }
    get selected(): boolean {
        return this._selected;
    }

    //-- ISliderThumb

    //----------------------------------
    //  index
    //----------------------------------
    
    private static _bindableIndex = BindingUtil.bindable(ColorThumb, 'index');

    /**
     * @inheritDoc
     */
    index: number;
    
    //----------------------------------
    //  value
    //----------------------------------
    
    private static _bindableValue = BindingUtil.bindable(ColorThumb, 'value');
    
    value: any;
    
    //----------------------------------
    //  watch
    //----------------------------------
    
    /**
     * Whether or not to watch <code>rgba</code> for changes and automatically update.
     */
    watch: boolean = true;
    
    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     *
     */
    initialize(): void {
        super.initialize();

        this._$colorElement = $(this.el).find('[data-part-id="color"]');
    }

    /**
     * @inheritDoc
     */
    validateProperties(changed?: any): void {
        super.validateProperties(changed);

        var allChanged: boolean = (changed === null);

        if (allChanged || changed.rgba) {
            if (this._$colorElement && this._rgba) {
                this._$colorElement.css('background-color', this._rgba.toCSS());
            }
        }
        if (allChanged || changed.selected) {
            $(this.el).toggleClass('selected', this._selected);
        }
    }

    /**
     * @inheritDoc
     */
    destroy(): void {
        if (this._rgba) {
            this._rgba.off(PropertyChangeEvent.PROPERTY_CHANGE, this._rgbaChangeHandler);
        }

        super.destroy();
    }

    private _rgbaChangeHandler = (): void => {
        this.invalidateProperty('rgba');
    }

    
}
export = ColorThumb;