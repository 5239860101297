import ColorThumb = require('controls/color-thumb');
import ColorUtil = require('utils/color-util');
import PropertyChangeEvent = require('events/property-change-event');
import Slider = require('controls/slider');

class HueSlider extends Slider {
	
    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------
    
    private _thumb: ColorThumb;
    
	//--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() { 
        super();
		
		this.minimum = 0;
		this.maximum = 360;
        
        this.thumbFactory = () => {
            this._thumb = new ColorThumb();
            this._thumb.watch = false;
            return this._thumb;
        }
        
        this.on(PropertyChangeEvent.PROPERTY_CHANGE, this._propertyChangeEventHandler);
    }
    
    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------
    
    /**
     * PolymerJS
     */
    created(): void {
        super.created();

        // apply the constructor manually to the instance
        HueSlider.apply(this);

        this.applyAttributes();
    }
    
    /**
     * @inheritDoc
     */
    validateProperties(changed?) {
        super.validateProperties(changed);
        
        var allChanged = !changed;
        
        if (allChanged || changed.value || changed.hue) {
            if (this._thumb) {
                var rgba = ColorUtil.hsbToRGBA(this.value, 1, 1);
                this._thumb.rgba = ColorUtil.hsbToRGBA(this.value, 1, 1);
                
                // we already went through invalidation sequence
                this._thumb.validate();
            }
        }
    }
    
    /**
     * @inheritDoc
     */
    destroy(): void {
        super.destroy();
        
        this.off(PropertyChangeEvent.PROPERTY_CHANGE, this._propertyChangeEventHandler);
    }
    
    private _propertyChangeEventHandler = (propertyChangeEvent: PropertyChangeEvent): void => {
        if (propertyChangeEvent.propertyName === 'value') {
            // using fake property for invalidation
            // don't want to use value to avoid work in base class
            this.invalidateProperty('hue');
        }
    }
}
export = HueSlider;