import ArrayUtil = require('utils/array-util');
import CollectionEvent = require('events/collection-event');
import EventDispatcher = require('events/event-dispatcher');
import IEventDispatcher = require('events/i-event-dispatcher');
import IList = require('collections/i-list');
import ISerializable = require('core/i-serializable');
import PropertyChangeEvent = require('events/property-change-event');

class ArrayList<T> extends EventDispatcher implements IList<T> {

    //--------------------------------------------------------------------------
    //
    //  Class methods
    //
    //--------------------------------------------------------------------------

    /**
     * Deserializes the plain object into an instance,
     * optionally using the specified Class to deserialize the individual items.
     */
    static deserialize(o: any, clazz?: any): ArrayList<any> {
        const instance = new ArrayList();
        if (o && typeof o.bubbleChanges !== 'undefined') {
            instance.bubbleChanges = o.bubbleChanges;
        }
        const source: any[] = [];
        const serializedSource = o ? o.source : null;
        const len: number = serializedSource ? serializedSource.length : 0;
        for (let i: number = 0; i < len; i++) {
            const serializedItem = serializedSource[i];
            if (clazz) {
                const deserializedItem = clazz.deserialize(serializedItem);
                source.push(deserializedItem);
            }
            else {
                source.push(serializedItem);
            }
        }
        instance.source = source;

        return instance;
    }

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor(source: T[] = []) {
        super();

        this.source = source;
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //-- IList

    //----------------------------------
    //  id
    //----------------------------------

    id: string;

    //----------------------------------
    //  length
    //----------------------------------

    get length(): number {
        return this._source.length;
    }

    //----------------------------------
    //  source
    //----------------------------------

    private _source: T[];

    /**
     * The Array to back the ArrayList.
     */
    set source(value: T[]) {
        let i: number;
        let len: number;

        if (this._bubbleChanges) {
            const oldSource: T[] = this._source;
            len = oldSource ? oldSource.length : 0;
            for (i = 0; i < len; i++) {
                this._removeEventListenersFromItem(oldSource[i], i);
            }
        }

        this._source = value;

        if (this._bubbleChanges) {
            len = this._source ? this._source.length : 0;
            for (i = 0; i < len; i++) {
                this._addEventListenersToItem(this._source[i], i);
            }
        }

        const collectionEvent: CollectionEvent = new CollectionEvent();
        collectionEvent.kind = CollectionEvent.KIND_RESET;
        this.trigger(CollectionEvent.COLLECTION_CHANGE, collectionEvent);
    }
    get source(): T[] {
        return this._source;
    }

    //----------------------------------
    //  bubbleChanges
    //----------------------------------

    // do NOT change the default due to serialization
    private _bubbleChanges: boolean = true;

    /**
     * Whether or not to listen for and bubble up PropertyChangeEvents
     * from the items within the ArrayList.
     */
    set bubbleChanges(value: boolean) {
        if (this._bubbleChanges !== value) {
            this._bubbleChanges = value;

            const len: number = this._source ? this._source.length : 0;
            for (let i: number = 0; i < len; i++) {
                if (this._bubbleChanges) {
                    this._addEventListenersToItem(this._source[i], i);
                }
                else {
                    this._removeEventListenersFromItem(this._source[i], i);
                }
            }
        }
    }
    get bubbleChanges(): boolean {
        return this._bubbleChanges;
    }

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    //-- IList

    /**
     * @inheritDoc
     */
    getItemAt(index: number): T {
        return this._source[index];
    }

    /**
     * @inheritDoc
     */
    setItemAt(index: number, newItem: T): void {
        const oldItem: T = this._source[index];
        if (oldItem === newItem) {
            return;
        }

        if (oldItem && this._bubbleChanges) {
            this._removeEventListenersFromItem(oldItem, index);
        }

        this._source[index] = newItem;

        if (this._bubbleChanges) {
            this._addEventListenersToItem(newItem, index);
        }

        const collectionEvent: CollectionEvent = new CollectionEvent();
        collectionEvent.kind = CollectionEvent.KIND_UPDATE;
        collectionEvent.locations = [index];
        this.trigger(CollectionEvent.COLLECTION_CHANGE, collectionEvent);
    }

    /**
     * @inheritDoc
     */
    addItem(item: T): void {
        this.addItemAt(this.length, item);
    }

    /**
     * @inheritDoc
     */
    addItemAt(index: number, item: T): void {
        this._source.splice(index, 0, item);

        if (this._bubbleChanges) {
            this._addEventListenersToItem(item, index);
        }

        const collectionEvent: CollectionEvent = new CollectionEvent();
        collectionEvent.kind = CollectionEvent.KIND_ADD;
        collectionEvent.locations = [index];
        collectionEvent.items = [item];
        this.trigger(CollectionEvent.COLLECTION_CHANGE, collectionEvent);
    }

    removeItem(item?: T): T {
        const index = item ? this._source.indexOf(item) : this.length - 1;
        if (index === -1) {
            if (item) {
                console.log('removeItem failed. Item not found.');
            }
            else {
                console.log('removeItem failed. No items left.');
            }
            return null;
        }
        return this.removeItemAt(index);
    }

    /**
     * @inheritDoc
     */
    removeItemAt(index: number): T {
        const item = <any>(this._source.splice(index, 1))[0];

        if (this._bubbleChanges) {
            this._removeEventListenersFromItem(item, index);
        }

        const collectionEvent: CollectionEvent = new CollectionEvent();
        collectionEvent.kind = CollectionEvent.KIND_REMOVE;
        collectionEvent.locations = [index];
        collectionEvent.items = [item];
        this.trigger(CollectionEvent.COLLECTION_CHANGE, collectionEvent);

        return item;
    }

    /**
     * @inheritDoc
     */
    toArray(): T[] {
        return ArrayUtil.clone(this._source);
    }

    /**
     * @inheritDoc
     */
    find(qualifier: (item: T) => boolean): T {
        let foundItem: T;
        const source = this._source;
        const len: number = source ? source.length : 0;
        for (let i: number = 0; i < len; i++) {
            const item: T = source[i];
            if (qualifier(item) === true) {
                foundItem = item;
                break;
            }
        }
        return foundItem;
    }

    /**
     * @inheritDoc
     */
    findIndex(qualifier: (item: T) => boolean): number {
        const source = this._source;
        const len: number = source ? source.length : 0;
        for (let i: number = 0; i < len; i++) {
            const item: T = source[i];
            if (qualifier(item) === true) {
                return i;
            }
        }
        return -1;
    }

    /**
     * @inheritDoc
     */
    empty(): void {
        this.source = [];
    }

    //-- ISerializable

    /**
     * @inheritDoc
     */
    serialize(): any {
        const o: any = {};
        const serializedSource: any[] = [];

        const source = this._source;
        const len: number = source ? source.length : 0;
        for (let i: number = 0; i < len; i++) {
            const item: any = source[i];
            if (item && (typeof item.serialize === 'function')) {
                const serializedItem = (<ISerializable>item).serialize();
                serializedSource.push(serializedItem);
            }
            else {
                serializedSource.push(item);
            }
        }

        o.source = serializedSource;
        // only need to save if different than default
        // note: this means that the default must not change
        if (this._bubbleChanges === false) {
            o.bubbleChanges = this._bubbleChanges;
        }
        return o;
    }

    private _addEventListenersToItem(item: T, index: number): void {
        const eventSourceItem: IEventDispatcher = <any>item;
        if (eventSourceItem && eventSourceItem.on) {
            eventSourceItem.on(PropertyChangeEvent.PROPERTY_CHANGE, this._propertyChangeHandler);
        }
    }
    private _removeEventListenersFromItem(item: T, index: number): void {
        const eventSourceItem: IEventDispatcher = <any>item;
        if (eventSourceItem && eventSourceItem.off) {
            eventSourceItem.off(PropertyChangeEvent.PROPERTY_CHANGE, this._propertyChangeHandler);
        }
    }

    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------

    private _propertyChangeHandler = (event: PropertyChangeEvent) => {
        const collectionEvent = new CollectionEvent();
        collectionEvent.kind = CollectionEvent.KIND_UPDATE;
        this.trigger(CollectionEvent.COLLECTION_CHANGE, collectionEvent);
    }
}
export = ArrayList;
