import ISliderThumb = require('controls/i-slider-thumb');
import View = require('ui/view');

/**
 * <code>SliderThumb</code> provides a base thumb that adds active state handling for touch.
 */ 
class SliderThumb extends View implements ISliderThumb {
    
    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------
    
    private static CLASS_ACTIVE: string = 'active';
    
    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------
    
    /**
     * @inheritDoc
     */
    initialize(): void {
        super.initialize();
        
        var el = this.el;
        el.addEventListener('mousedown', this._downHandler);
        el.addEventListener('touchstart', this._downHandler);
    }
    
    /**
     * @inheritDoc
     */
    destroy(): void {
        super.destroy();
        
        var el = this.el;
        el.removeEventListener('mousedown', this._downHandler);
        document.removeEventListener('mouseup', this._upHandler);
        document.removeEventListener('touchend', this._upHandler);
        document.removeEventListener('touchcancel', this._upHandler);
    }
    
    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------
    
    private _downHandler = () => {
        this.el.classList.add(SliderThumb.CLASS_ACTIVE);
        document.addEventListener('mouseup', this._upHandler);
        document.addEventListener('touchend', this._upHandler);
        document.addEventListener('touchcancel', this._upHandler);
    }
    
    private _upHandler = () => {
        this.el.classList.remove(SliderThumb.CLASS_ACTIVE);
        document.removeEventListener('mouseup', this._upHandler);
        document.removeEventListener('touchend', this._upHandler);
        document.removeEventListener('touchcancel', this._upHandler);
    }
}
export = SliderThumb;