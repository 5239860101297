import BindingUtil = require('utils/binding-util');
import EventDispatcher = require('events/event-dispatcher');
import GradientEntryType = require('graphics/gradient-entry-type');
import ISerializable = require('core/i-serializable');
import PropertyChangeEvent = require('events/property-change-event');

class GradientEntry extends EventDispatcher implements ISerializable {

    //--------------------------------------------------------------------------
    //
    //  Class methods
    //
    //--------------------------------------------------------------------------

    /**
     * Deserializes the plain object into an instance.
     */
    static deserialize(o: any): GradientEntry {
        const instance: GradientEntry = new GradientEntry();
        if (typeof o.bubbleChanges !== 'undefined') {
            instance.bubbleChanges = o.bubbleChanges;
        }
        instance._color = o.color;
        instance._alpha = o.alpha;
        instance._offset = o.offset;
        instance.type = o.type;
        return instance;
    }

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor(type: string = GradientEntryType.TYPE_COLOR, offset: number = 0.0, colorOrAlpha?: number) {
        super();

        this.type = type;

        switch (type) {
            case GradientEntryType.TYPE_ALPHA:
                this._alpha = colorOrAlpha;
                break;
            case GradientEntryType.TYPE_COLOR:
                this._color = colorOrAlpha;
                break;
            default:
        }

        this._offset = offset;
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  color
    //----------------------------------

    private _color: number;

    set color(value: number) {
        if (this._color !== value) {
            const oldValue = this._color;
            this._color = value;
            if (this.bubbleChanges) {
                this._triggerPropertyChange('color', value, oldValue);
            }
        }
    }
    get color(): number {
        return this._color;
    }

    //----------------------------------
    //  alpha
    //----------------------------------

    private _alpha: number;

    set alpha(value: number) {
        if (this._alpha !== value) {
            const oldValue = this._alpha;
            this._alpha = value;
            if (this.bubbleChanges) {
                this._triggerPropertyChange('alpha', value, oldValue);
            }
        }
    }
    get alpha(): number {
        return this._alpha;
    }

    //----------------------------------
    //  offset
    //----------------------------------

    private _offset: number;

    set offset(value: number) {
        if (this._offset !== value) {
            const oldValue = this._offset;
            this._offset = value;
            if (this.bubbleChanges) {
                this._triggerPropertyChange('offset', value, oldValue);
            }
        }
    }
    get offset(): number {
        return this._offset;
    }

    //----------------------------------
    //  type
    //----------------------------------

    /**
     * The type of the entry.
     * Use the TYPE_ constants.
     */
    type: string;

    //----------------------------------
    //  bubbleChanges
    //----------------------------------

    // must NOT change this default due to serialization skipping this
    bubbleChanges: boolean = true;

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    clone(bubbleChanges?: boolean): GradientEntry {
        if (typeof bubbleChanges === 'undefined') {
            bubbleChanges = this.bubbleChanges;
        }

        const clone: GradientEntry = new GradientEntry();
        clone.bubbleChanges = bubbleChanges;
        clone._color = this.color;
        clone._alpha = this.alpha;
        clone._offset = this.offset;
        clone.type = this.type;
        return clone;
    }

    //-- ISerializable

    /**
     * @inheritDoc
     */
    serialize(): any {
        const o: any = {};
        // o.bubbleChanges = this.bubbleChanges;
        // only serialize if different than default; do not change the default
        if (this.bubbleChanges !== true) {
            o.bubbleChanges = this.bubbleChanges;
        }
        o.color = this.color;
        o.alpha = this.alpha;
        o.offset = this.offset;
        o.type = this.type;
        return o;
    }

    /**
     * Helper to trigger PropertyChangeEvents.
     */
    private _triggerPropertyChange(propertyName: string, value: any, oldValue?: any) {
        const propertyChangeEvent = new PropertyChangeEvent();
        propertyChangeEvent.propertyName = propertyName;
        propertyChangeEvent.oldValue = oldValue;
        propertyChangeEvent.value = value;
        this.trigger(PropertyChangeEvent.PROPERTY_CHANGE, propertyChangeEvent);
    }
}
export = GradientEntry;
