import Rectangle = require('geom/rectangle');

class Stamper {

    /**
     * @param clearRect  Optional rectangle to clear if stamp spills outside of stampRect.
     * Note: Only a clearRect which completely contains the stampRect is supported for now.
     */
    static stamp(outputContext: CanvasRenderingContext2D, text: string, fontSize: number, fontFamily: string = 'sans-serif', fontRGBA: string = 'rgba(255, 255, 255, 0.1)', stampRect?: Rectangle, clearRect?: Rectangle) {
        outputContext.font = fontSize + 'px ' + fontFamily;
        outputContext.fillStyle = fontRGBA;

        let metrics = outputContext.measureText(text);
        let textWidth = metrics.width;
        let height = fontSize;

        var minX: number = stampRect.left;
        var minY: number = stampRect.top + height;
        var maxX: number = minX + stampRect.width;
        var maxY: number = minY + stampRect.height;

        // TODO: take as arguments
        var horizontalGap: number = textWidth;
        var verticalGap: number = 20;

        var i: number = 0;
        var y: number = minY;
        while (y < maxY) {
            var x: number = minX;

            // indent every other line to create a checker pattern
            if (i % 2 === 1) {
                x += horizontalGap;
            }

            //while (x < viewRect.width) {
            while (x < maxX) {
                outputContext.fillText(text, x, y);
                x += textWidth + horizontalGap;
            }
            y += height;
            y += verticalGap;
            i++;
        }

        // clean up any text that overflowed to right or below
        if (clearRect) {
            // right
            var rightDW: number = clearRect.right - stampRect.right;
            outputContext.clearRect(clearRect.right, 0, rightDW, clearRect.height);

            // below
            var bottomDH: number = clearRect.bottom - stampRect.bottom;
            outputContext.clearRect(0, clearRect.bottom, clearRect.width, bottomDH);
        }
    }

    static stampDiagonalLines(outputContext: CanvasRenderingContext2D, stampRect?: Rectangle, spacing: number = 10, lineWidth: number = 1, strokeStyle: string = 'black') {
        let minX: number = stampRect.left;
        let minY: number = stampRect.top;
        let maxX: number = minX + stampRect.width;
        let maxY: number = minY + stampRect.height;
        let h = stampRect.height;

        let startX = minX;
        let startY = minY;
        let endX;
        let endY;

        outputContext.lineWidth = lineWidth;
        outputContext.strokeStyle = strokeStyle;
        outputContext.beginPath();

        // draw lines from the top edge until they hit the bottom or right side
        while (startX < maxX) {
            endX = startX + h;
            if (endX > maxX) {
                endX = maxX;
            }
            endY = startY + (endX - startX);

            outputContext.moveTo(startX, startY);
            outputContext.lineTo(endX, endY);

            startX += spacing;
        }

        // draw lines from the left edge until they hit the bottom or right side
        startX = minX;
        startY = minY + spacing;    // skip line from top-left corner since it was already drawn above.
        while (startY < maxY) {
            endY = startY + h;
            if (endY > maxY) {
                endY = maxY;
            }
            endX = startX + (endY - startY);
            
            outputContext.moveTo(startX, startY);
            outputContext.lineTo(endX, endY);

            startY += spacing;
        }

        outputContext.stroke();
    }
}
export = Stamper;