
import AlphaThumb = require('controls/alpha-thumb');
import ArrayList = require('collections/array-list');
import CollectionEvent = require('events/collection-event');
import Gradient = require('graphics/gradient');
import GradientUtil = require('utils/gradient-util');
import GradientEntry = require('graphics/gradient-entry');
import GradientEntryType = require('graphics/gradient-entry-type');
import IList = require('collections/i-list');
import ISliderThumb = require('controls/i-slider-thumb');
import PropertyChangeEvent = require('events/property-change-event');
import RGBA = require('graphics/rgba');
import SelectableThumbSlider = require('controls/selectable-thumb-slider');
import Slider = require('controls/slider');
import View = require('ui/view');

class AlphaGradientEditor extends SelectableThumbSlider {

    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------

    static STATE_NORMAL: string = 'normal';
    static STATE_ACTIVE: string = 'active';

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
        super();

        this.minimum = 0.0;
        this.maximum = 1.0;
        this.itemFactory = this.__itemFactory;
        this.thumbFactory = this.__thumbFactory;
        this.className = 'alpha-gradient-editor';
        this.valuesProperty = 'offset';
        this.trackDragging = false;     // track used to add items
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  gradient
    //----------------------------------

    private _gradient: Gradient;

    /**
     * The <code>Gradient</code> to manipulate.
     */
    set gradient(value: Gradient) {
        this._gradient = value;
        //this.values = GradientUtil.flattenGradient(this._gradient);
        this.values = this._gradient.alphaEntries;

        // need to update thumb colors, but after the values have been validated so that all thumbs are available
        this.invalidateProperty('gradient');
    }
    get gradient(): Gradient {
        return this._gradient;
    }
    
    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * @inheritDoc
     */
    validateProperties(changed?: any): void {
        super.validateProperties(changed);

        var allChanged: boolean = (changed === null);

        if (allChanged || changed.gradient) {
            // update thumb alphas
            // the number of thumbs should be inline with the entries after super.validateProperties()
            //var entries = GradientUtil.flattenGradient(this.gradient);
            var entries = this.gradient ? this.gradient.alphaEntries : null;
            var numEntries = entries ? entries.length : 0;
            var thumbs: any[] = this._thumbs;
            var numThumbs: number = thumbs ? thumbs.length : 0;
            var len: number = Math.min(numEntries, numThumbs);
            for (var i: number = 0; i < numEntries; i++) {
                var thumb: AlphaThumb = thumbs[i];
                var entry: GradientEntry = entries.getItemAt(i);
                thumb.alpha = entry.alpha;
            }
        }
    }

    private __itemFactory = (index: number, offset: number): GradientEntry => {
        var entry: GradientEntry = new GradientEntry(GradientEntryType.TYPE_ALPHA);
        entry.offset = offset;

        var rgba = GradientUtil.interpolateGradient(this.gradient, offset);
        entry.alpha = rgba.a;

        return entry;
    }

    /**
     * Intentional double-underscore to prevent collision with Slider internal.
     */
    private __thumbFactory = (index: number): ISliderThumb => {
        var thumb = new AlphaThumb();

        // update whenever the entry changes
        // entry is obtained when thumbIndex is set below
        var entry: GradientEntry;
        var entryChangeHandler = () => {
            thumb.alpha = entry.alpha;
        };

        // a change to the thumb index means it is being used for a different stop (reorder, remove, etc.)
        var thumbIndexChangeHandler = (event: PropertyChangeEvent): void => {
            if (event.propertyName === 'index') {       // ISliderThumb.index
                if (entry) {
                    entry.off(PropertyChangeEvent.PROPERTY_CHANGE, entryChangeHandler);
                }
                var index: number = event.value;
                entry = this.values.getItemAt(index);
                if (entry) {
                    entry.on(PropertyChangeEvent.PROPERTY_CHANGE, entryChangeHandler);
                    entryChangeHandler();
                }
            }
        };
        thumb.on(PropertyChangeEvent.PROPERTY_CHANGE, thumbIndexChangeHandler);
        
        // hit existing handler via fake event
        var propertyChangeEvent = new PropertyChangeEvent();
        propertyChangeEvent.value = index;
        propertyChangeEvent.propertyName = 'index';     // ISliderThumb.index
        thumbIndexChangeHandler(propertyChangeEvent);

        thumb.on(View.EVENT_DESTROY,() => {
            if (entry) {
                entry.off(PropertyChangeEvent.PROPERTY_CHANGE, entryChangeHandler);
            }
            thumb.off(PropertyChangeEvent.PROPERTY_CHANGE, thumbIndexChangeHandler);
        });

        return thumb;
    }
}
export = AlphaGradientEditor;