import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';

import PropertyChangeEvent = require('events/property-change-event');
import Slider = require('controls/slider');
import View = require('ui/view');

@Component({
  selector: 'sblended-slider',
  template: '<div #holder style="width: 100%; height: 100%;"></div>',
  encapsulation: ViewEncapsulation.None
})

/**
 * The Slider class is a control for manipulating one or values by dragging 
 * "thumb" elements along a linear track, either horizontally or vertically.
 */
export class SliderComponent extends View {

  /**
   * @constructor
   */
  constructor() {
    super();

    this._instance = new Slider();

    // generic handler for property change events that triggers the corresponding EventEmitter
    this._instance.on(PropertyChangeEvent.PROPERTY_CHANGE, (event: PropertyChangeEvent) => {
        let propertyName: string = event ? event.propertyName : null;
        let value = event ? event.value : this[propertyName];
        let emitter = this[propertyName + 'Change'];
        if (emitter) {
            emitter.emit(value);
        }
    });
  }

  //--------------------------------------------------------------------------
  //
  //  Variables
  //
  //--------------------------------------------------------------------------
  
  @ViewChild("holder", { read: ElementRef }) holder: ElementRef;

  private _instance: Slider;

  //--------------------------------------------------------------------------
  //
  //  Properties
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  //  minimum
  //----------------------------------

  @Input()
  set minimum(value: number) {
    this._instance.minimum = Number(value);
  }
  get minimum(): number {
    return this._instance.minimum;
  }

  //----------------------------------
  //  maximum
  //----------------------------------

  @Input()
  set maximum(value: number) {
    this._instance.maximum = Number(value);
  }
  get maximum(): number {
    return this._instance.maximum;
  }

  //----------------------------------
  //  value
  //----------------------------------

  @Input()
  set value(value: number) {
    this._instance.value = Number(value);
  }
  get value(): number {
    return this._instance.value;
  }
  @Output() valueChange = new EventEmitter<number>();
  
  
  //--------------------------------------------------------------------------
  //
  //  Methods
  //
  //--------------------------------------------------------------------------

  ngAfterViewInit() {
    this.holder.nativeElement.append(this._instance.el);
  }

  layout() {
    super.layout();

    this.layoutChild(this._instance);
  }
}
