import $ = require('jquery');
import Hammer = require('hammerjs');

import ArrayList = require('collections/array-list');
import ArrayUtil = require('utils/array-util');
import CollectionEvent = require('events/collection-event');
import Event = require('events/event');
import IList = require('collections/i-list');
import ISliderThumb = require('controls/i-slider-thumb');
import IView = require('ui/i-view');
import Point = require('geom/point');
import PointerUtil = require('utils/pointer-util');
import PropertyChangeEvent = require('events/property-change-event');
import SliderThumb = require('controls/slider-thumb');
import View = require('ui/view');

const templateString = `
    <div class="slider">
        <div data-part-id="track" class="track">
            <div class="inner">
            </div>
        </div>
        <div data-part-id="thumb" class="thumb">
            <div class="inner">
            </div>
        </div>
    </div>
`;

//--------------------------------------------------------------------------
// 
//  Events
//
//--------------------------------------------------------------------------

// change

/**
 * The Slider class is a control for manipulating one or values by dragging 
 * "thumb" elements along a linear track, either horizontally or vertically.
 */
class Slider extends View {

    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------

    //-- Events

    static EVENT_THUMB_DOWN: string = 'THUMB_DOWN';
    static EVENT_THUMB_UP: string = 'THUMB_UP';
    static EVENT_THUMB_TAP: string = 'THUMB_TAP';
    static EVENT_THUMB_DOUBLE_TAP: string = 'THUMB_DOUBLE_TAP';
    static EVENT_TRACK_DOWN: string = 'TRACK_DOWN';
    static EVENT_CHANGE: string = 'change';

    //-- Parts

    static PART_THUMB: string = 'thumb';
    static PART_TRACK: string = 'track';
    
    //-- States
    
    static STATE_ACTIVE: string = 'active';
    
    //------------f--------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------
    
    protected _offsetLeft: number;
    protected _offsetTop: number;
    private _range: number;
    protected _thumbs: ISliderThumb[] = [];
    protected _activeThumbIndex: number;
    private _cachedValues: number[];
    protected _changing: boolean = false;    // know whether values are changing internally
    protected _downOffsetLeft: number;
    protected _downOffsetTop: number;
    
    private _pendingMoveEvent;
    private _throttledMoveHandler;
    private _moveInvalidated: boolean = false;

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor () {
        super();

        this.templateString = templateString;

        this._throttledMoveHandler = (event) => {
            // always update to last
            this._pendingMoveEvent = event;
            if (!this._moveInvalidated) {
                this._moveInvalidated = true;
                window.requestAnimationFrame(this._moveAnimationFrameHandler);
            }
        };
        
        this._range = this._maximum - this._minimum;
        this.values = new ArrayList<number>([0]);
    }
    
    private _moveAnimationFrameHandler = () => {
        this._moveInvalidated = false;
        this._moveHandler(this._pendingMoveEvent);
    }
    
    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  minimum
    //----------------------------------

    private _min: number;       // the real min
    private _minimum: number = 0;

    /**
     * The "minimum" value.
     * This is actually the leftmost / bottommost value,
     * allowing Sliders to have inverse bounds without additional properties.
     */
    set minimum(value: number) {
        this._minimum = value;
        this.invalidateProperty('minimum');
    }
    get minimum(): number {
        return this._minimum;
    }

    //----------------------------------
    //  maximum
    //----------------------------------

    private _max: number;       // the real max
    private _maximum: number = 100;

    /**
     * The "maximum"" value.
     * This is actually the rightmost / topmost value,
     * allowing Sliders to have inverse bounds without additional properties.
     */
    set maximum(value: number) {
        this._maximum = value;
        this.invalidateProperty('maximum');
    }
    get maximum(): number {
        return this._maximum;
    }

    //----------------------------------
    //  value
    //----------------------------------

    protected _value: number = 0;

    /**
     * The value of the Slider.
     * This property is superceded by <code>values</code>, 
     * which allows multiple values to be manipulated.
     *
     * @see Slider#values
     */
    set value(value: number) {
        if (this._value === value) {
            return;
        }

        var oldValue = this._value;
        this._value = value;
        this._setValueAt(0, value);
        this.invalidateProperty('value');

        if (this.silent !== true) {
            this._triggerValueChange(value, oldValue);
        }
    }
    get value(): number {
        return this._value;
    }

    //----------------------------------
    //  values
    //----------------------------------

    private _values: IList<any>;

    /**
     * Array of values that the Slider should manipulate.
     * This property supercedes the <code>value</code> property.
     *
     * @see controls/Slider#value
     */
    set values(value: IList<any>) {
        if (this._values) {
            this._values.off(CollectionEvent.COLLECTION_CHANGE, this._valuesChangeHandler);
        }

        this._values = value;
        this.invalidateProperty('values');

        if (this.silent !== true) {
            var propertyChangeEvent: PropertyChangeEvent = new PropertyChangeEvent();
            propertyChangeEvent.propertyName = 'values';
            propertyChangeEvent.value = value;
            this.trigger(PropertyChangeEvent.PROPERTY_CHANGE, propertyChangeEvent);
        }

        if (this._values) {
            this._values.on(CollectionEvent.COLLECTION_CHANGE, this._valuesChangeHandler);
        }
        this._valuesChangeHandler();
    }
    get values(): IList<any> {
        return this._values;
    }

    //----------------------------------
    //  valuesProperty
    //----------------------------------

    private _valuesProperty: string;

    /**
     * Name of the property to modify within the items in the <code>values</code>.
     * If defined, changes the property within the item rather than setting the item itself.
     */
    set valuesProperty(value: string) {
        this._valuesProperty = value;
    }
    get valuesProperty(): string {
        return this._valuesProperty;
    }

    //----------------------------------
    //  vertical
    //----------------------------------

    protected _vertical: boolean = false;

    /**
     * Whether or not to work along the vertical axis.
     * Defaults to false.
     */
    set vertical(value: boolean) {
        if (this._vertical !== value) {
            this._vertical = value;
            this.invalidateProperty('vertical');
        }
    }
    get vertical(): boolean {
        return this._vertical;
    }

    //----------------------------------
    //  liveDragging
    //----------------------------------

    private _liveDragging: boolean = true;

    /**
     * Whether or not changes should be surfaced immediately as the thumb is dragged.
     * or deferred until the thumb is released.
     * Defaults to true.
     */
    set liveDragging(value: boolean) {
        this._liveDragging = value;
    }
    get liveDragging(): boolean {
        return this._liveDragging;
    }
    
    //----------------------------------
    //  trackDragging
    //----------------------------------

    private _trackDragging: boolean = true;

    /**
     * Whether or not the track can be dragged to adjust the value,
     * or whether only the thumb may be used.
     * 
     * Defaults to true.
     */
    set trackDragging(value: boolean) {
        this._trackDragging = value;
    }
    get trackDragging(): boolean {
        return this._trackDragging;
    }


    //----------------------------------
    //  step
    //----------------------------------

    private _step: number;

    /**
     * The amount to round to, such as rounding to the nearest 1 or nearest 10.
     * Defaults to undefined such that the value is not rounded.
     */
    set step(value: number) {
        this._step = value;
    }
    get step(): number {
        return this._step;
    }

    //----------------------------------
    //  thumbFactory
    //----------------------------------

    private _thumbFactory: (index: number) => ISliderThumb;

    /**
     * Function used to generate the thumb Views.
     */
    set thumbFactory(value: (index: number) => ISliderThumb) {
        this._thumbFactory = value;
        this.invalidateProperty('thumbFactory');
    }
    get thumbFactory(): (index: number) => ISliderThumb {
        return this._thumbFactory;
    }

    //----------------------------------
    //  silent
    //----------------------------------

    /**
     * Whether or not the "value" setter should be silent,
     * that is NOT trigger a PropertyChangeEvent.
     * Used to prevent AngularJS error "$digest already in progress".
     */
    silent: boolean;
    
    //----------------------------------
    //  thumbInset
    //----------------------------------
    
    /**
     * Amount to inset the extreme positions of the thumbs.
     * Defaults to zero such that thumbs are positioned at the edges.
     * Set to half the width of the thumbs to keep thumbs from overflowing.
     */
    thumbInset: number = 0;
    
    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    attached(): void {
        // console.log("slider was attached !");
    }

    /**
     * PolymerJS
     */
    created(): void {
        super.created();

        // apply the constructor manually to the instance
        Slider.apply(this);

        this.applyAttributes();
    }
    
    /**
     * Polymer data bindings.
     */
    bind(name, observable, oneTime) {
        if (observable.setValue) {
            this.on(PropertyChangeEvent.PROPERTY_CHANGE, (propertyChangeEvent) => {
                observable.setValue(this.value);
            });
        }

        observable.open((newValue, oldValue) => {
            this.value = newValue;
        });
    }

    /**
     * @inheritDoc
     */
    initialize(): void {
        super.initialize();

        var thumbElement = this.getPart(Slider.PART_THUMB);

        // template-based thumb only for single value case
        if (!this.thumbFactory && thumbElement) {
            // promote Element to View
            var thumb: ISliderThumb;
            if (thumbElement instanceof View) {
                thumb = thumbElement;
            }
            else {
                thumb = new SliderThumb();
            }

            thumb.el = thumbElement;
            this._thumbs[0] = thumb;
            
            // hack for Hammer not being able to be setup unless element is on the DOM
            var _attemptSetupThumb = (thumb: ISliderThumb, index: number) => {
                var doc = thumbElement.ownerDocument;
                if (doc.defaultView || doc.parentWindow) {
                    this._setupThumb(thumb, index);
                }
                else {
                    setTimeout(_attemptSetupThumb, 10, thumb, index);
                }
            }
            _attemptSetupThumb(thumb, 0);
        }

        var trackElement = this.getPart(Slider.PART_TRACK);
        if (trackElement) {
            $(trackElement).on('mousedown touchstart', this._trackDownHandler);
        }
    }
    
    /**
     * @inheritDoc
     */
    layout(changed?: any) {
        super.layout();
        
        // reposition all thumbs
        // this would not be necessary if thumbs were positioned using left/top percentage
        // however, positioning thumbs using x,y (translate) should be faster while dragging
        // given relative expected frequency of drag vs. resize, optimize for drag
        var numValues: number = this._values ? this._values.length : 1;
        var numThumbs = this._thumbs ? this._thumbs.length : 0;
        var len: number = Math.min(numValues, numThumbs);
        for (var i: number = 0; i < numThumbs; i++) {
            this._positionThumbForValue(this._thumbs[i], this._getValueAt(i));
        }
    }

    /**
     * @inheritDoc
     */
    validateProperties(changed?: any): void {
        super.validateProperties(changed);

        var allChanged: boolean = (changed === null);

        if (allChanged || changed.minimum || changed.maximum) {
            this._range = this._maximum - this._minimum;
            this._min = Math.min(this._minimum, this._maximum);
            this._max = Math.max(this._minimum, this._maximum);
        }

        var i: number;
        var thumbs = this._thumbs;
        var numThumbs: number = thumbs ? thumbs.length : 0;
        var thumb: ISliderThumb;
        var $thumb: JQuery;

        if (allChanged || changed.thumbFactory) {
            if (this.thumbFactory) {
                for (i = numThumbs - 1; i >= 0; i--) {
                    thumb = thumbs[i];
                    this._destroyThumb(thumb, i);
                }
                thumbs = this._thumbs = [];
                numThumbs = 0;
            }
        }

        var values = this._values;
        var numValues: number = values ? values.length : 1;         // assume 1 if values not set for simple slider
        if (allChanged || changed.values) {
            // destroy old thumbs
            for (i = numThumbs - 1; i >= numValues; i--) {
                thumb = thumbs[i];
                this._destroyThumb(thumb, i);

                thumbs.splice(i, 1);
            }

            // create new thumbs
            for (i = numThumbs; i < numValues; i++) {
                var thumb = this._createThumb(i);
                this._thumbs.push(thumb);
            }
            numThumbs = numValues;

            // refresh all thumb indices to account for additions / removals
            // TODO: expose option to avoid overhead
            for (i = 0; i < numThumbs; i++) {
                var thumb = this._thumbs[i];
                thumb.index = i; 
                // pass item directly rather than unwrapping to offset value
                thumb.value = values ? values.getItemAt(i) : null;
                var $thumb = $(thumb.el);
                // $thumb.data('thumb-index', i);               // associate index with element for lookup in pointer event handlers
                (<any>thumb).thumbIndex = i;
                (<any>thumb.el).thumbIndex = i;
            }
        }
        
        if (allChanged || changed.vertical) {
            $(this.el).toggleClass('vertical', this._vertical);
            // reset offset of thumbs when toggling orientation
            for (i = 0; i < numThumbs; i++) {
                var thumb = this._thumbs[i];
                if (this.vertical) {
                    thumb.x = 0;
                }
                else {
                    thumb.y = 0;
                }
                this._positionThumbForValue(this._thumbs[i], this._getValueAt(i));
            }
        }
        
        if (allChanged || changed.minimum || changed.maximum || changed.value || changed.values || changed.vertical) {
            for (i = 0; i < numThumbs; i++) {
                this._positionThumbForValue(this._thumbs[i], this._getValueAt(i));
            }
        }
    }

    /**
     * Determines the value for a particular position along the Slider.
     */
    protected _valueForPosition(xPosition: number, yPosition: number, round: boolean= true): number {
        var ratio: number;
        if (this.vertical) {
            var relativeY: number = yPosition - this.thumbInset - this._offsetTop + this._downOffsetTop;
            var h: number = this.pixelHeight - (this.thumbInset * 2);
            ratio = relativeY / h;
            
            // minimum is at bottom
            ratio = 1 - ratio;
        }
        else {
            var relativeX: number = xPosition - this.thumbInset - this._offsetLeft + this._downOffsetLeft;
            var w: number = this.pixelWidth - (this.thumbInset * 2);
            ratio = relativeX / w;
        }

        var value: number = this._minimum + (ratio * this._range);

        // keep within bounds
        value = Math.max(value, this._min);
        value = Math.min(value, this._max);

        if (round && this._step) {
            value = this._roundValue(value);
        }

        return value;
    }

    /**
     * Positions the specified thumb according to the specified value.
     */
    protected _positionThumbForValue(thumb: IView, value: number): void {
        // keep within bounds
        if (value > this._max) {
            value = this._max;
        }
        if (value < this._min) {
            value = this._min;
        }
        
        var ratio = (value - this._minimum) / this._range;
        
        var offset: number;
        if (this.vertical) {
            // $(thumb.el).css('top', percent + '%');
            var h: number = this.pixelHeight - (this.thumbInset * 2);
            offset = this.thumbInset + (h - (h * ratio));   // start from bottom
            // keep on whole pixels
            offset = Math.round(offset);
            // start from bottom
            thumb.y = offset;
        }
        else {
            // $(thumb.el).css('left', percent + '%');
            offset = this.pixelWidth * ratio;
            var w: number = this.pixelWidth - (this.thumbInset * 2);
            offset = this.thumbInset + (w * ratio);
            // keep on whole pixels
            offset = Math.round(offset);
            thumb.x = offset;
        }
    }

    /**
     * Rounds the value to nearest increment within bounds or pegs at the bounds.
     * Note: This may provide values that are not rounded if the bounds themselves are not round (e.g. maximum of 8 rounded to 10)
     * TODO: keep rounded 
     */
    protected _roundValue(value: number) {
        value = Math.round(value / this._step) * this._step;

        // keep within bounds
        if (value > this._max) {
            value = this._max;
        }
        if (value < this._min) {
            value = this._min;
        }
        return value;
    }

    /**
     * Helper method for setting the value at a particular location.
     * Behavior differs depending on whether <code>valuesProperty</code> is set.
     */
    protected _setValueAt(index: number, value: number): void {
        // console.log('set value at: '+index+', value: '+value);
        this._changing = true;
        if (this._valuesProperty) {
            var item = this._values.getItemAt(index);
            item[this._valuesProperty] = value;
        }
        else {
            this._values.setItemAt(index, value);
        }
        
        if (index === 0) {
            this._value = value;
            this._triggerValueChange(value, null);
        }
        
        this._changing = false;
    }

    /**
     * Helper method for getting the value at a particular location.
     * Behavior differs depending on whether <code>valuesProperty</code> is set.
     */
    private _getValueAt(index: number): number {
        var item = this._values ? this._values.getItemAt(index) : null;
        if (this._valuesProperty) {
            return item ? item[this._valuesProperty] : null;
        }
        else {
            return item;
        }
    }

    protected _createThumb(index: number): ISliderThumb {
        // console.log("createThumb for: " + index);
        var thumb: ISliderThumb = this._thumbFactory(index);
        this._setupThumb(thumb, index);
        return thumb;
    }
    
    protected _setupThumb(thumb: ISliderThumb, index: number): void {
        thumb.index = index;

        // TODO: abstract DOM
        var $thumb = $(thumb.el);
        $thumb.on('mousedown touchstart', this._downHandler);
        var hammertime = new Hammer(thumb.el);
        hammertime.on('tap', this._tapHandler);
        // Note: Hammer adds `touch-action` styles
        thumb.el.style.touchAction = 'none';

        // use jQuery rather than Hammer due to this issue:
        // https://github.com/hammerjs/hammer.js/issues/917
        $thumb.on('dblclick', this._doubleTapHandler);

        //$thumb.data('thumb-index', index);              // associate index with element for lookup in pointer event handlers
        (<any>thumb.el).thumbIndex = index;
        thumb.el.style.position = 'absolute';            // required for left to apply

        this.addChild(thumb);
    }

    protected _destroyThumb(thumb: ISliderThumb, index: number): void {
        // console.log("destroyThumb: " + thumb);
        this.removeChild(thumb);
        thumb.destroy();
    }

    protected _triggerValueChange(newValue, oldValue): void {
        var propertyChangeEvent = new PropertyChangeEvent();
        propertyChangeEvent.propertyName = 'value';
        propertyChangeEvent.value = newValue;
        propertyChangeEvent.oldValue = oldValue;
        this.trigger(PropertyChangeEvent.PROPERTY_CHANGE, propertyChangeEvent);
    }

    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------
    
    /**
     * Handler for when a thumb is pressed.
     * Sets up the pointer move handlers.
     */
    protected _downHandler = (event: BaseJQueryEventObject, pointerOffset?: Point): void => {
        //var thumb: ISliderThumb = event.target;
        // var thumbIndex: number = (<any>$(event.currentTarget).data('thumb-index'));
        var thumbIndex: number = (<any>event.currentTarget).thumbIndex;
        this._activeThumbIndex = isNaN(thumbIndex) ? 0 : thumbIndex;

        // cache the values when the operation starts so that "pushed" values can be restored
        var cachedValues: number[] = [];
        var len: number = this._values ? this._values.length : 0;
        for (var i: number = 0; i < len; i++) {
            var value: number = this._getValueAt(i);
            cachedValues.push(value);
        }
        this._cachedValues = cachedValues;

        // cache offset on down and use for subsequent drags to avoid browser reflows
        // offset should not be changing at this time
        var offset = $(this.el).offset();
        this._offsetLeft = offset.left;
        this._offsetTop = offset.top;
        
        // remember distance from press to center of thumb
        // all subsequent value determination from pointer position will take this into account
        // such that thumb center will not jump to pointer position
        if (pointerOffset) {
            this._downOffsetLeft = pointerOffset.x;
            this._downOffsetTop = pointerOffset.y;
        }
        else {
            var thumb: HTMLElement = (<HTMLElement>event.currentTarget);
            // need to pass offset for Touch scenario
            var thumb$Offset = $(thumb).offset();
            var thumbOffset: Point = new Point(thumb$Offset.left, thumb$Offset.top);
            var p: Point = PointerUtil.normalizeJQueryEvent(event, true, thumbOffset);
            
            // could limit to one direction for internal purposes, but calculate both offsets for subclasses
            var thumbHeight: number = thumb.offsetHeight;
            this._downOffsetTop = (thumbHeight / 2) - p.y
            var thumbWidth: number = thumb.offsetWidth;
            this._downOffsetLeft = (thumbWidth / 2) - p.x;
        }
        
        $(document).off('mousemove touchmove', this._throttledMoveHandler);//.on('mousemove touchmove', this._moveHandler);
        $(document).on('mousemove touchmove', this._throttledMoveHandler);
        $(document).on('mouseup touchend', this._upHandler);

        this.root.classList.add('active');
        
        this.trigger(Slider.EVENT_THUMB_DOWN, thumbIndex);
    }

    protected _tapHandler = (event): void => {
        var target;
        // element property explicitly added in Hammerjs due to local modification of eeit
        if ((<any>event).element) {
            target = (<any>event).element;
        }

        // not sure why Hammer doesn't handle this internally
        else if (event.srcEvent && (event.srcEvent.type === 'touchend')) {
            target = event.srcEvent.currentTarget;
        }
        else {
            target = event.target;
        }
        // var thumbIndex: number = (<any>$(target).data('thumb-index'));
        var thumbIndex: number = (<any>target).thumbIndex;
        this.trigger(Slider.EVENT_THUMB_TAP, thumbIndex);
    }

    protected _doubleTapHandler = (event: BaseJQueryEventObject): void => {
        var target = event.currentTarget;
        // var thumbIndex: number = (<any>$(target).data('thumb-index'));
        var thumbIndex: number = (<any>target).thumbIndex;
        this.trigger(Slider.EVENT_THUMB_DOUBLE_TAP, thumbIndex);
    }

    /**
     * Handler for when a thumb is moved.
     * Modifies the corresponding value accordingly.
     * Pushes / restores the values of the thumbs around it.
     * Implemented as two methods to allow for override of _moveHandler.
     */
    protected _moveHandler(event: BaseJQueryEventObject): void {
        var activeThumbIndex: number = this._activeThumbIndex;
        var thumb: IView = this._thumbs[activeThumbIndex];

        var offset: Point = new Point(this._offsetLeft, this._offsetTop);
        var p: Point = PointerUtil.normalizeJQueryEvent(event, false);//true, offset);
        var value: number = this._valueForPosition(p.x, p.y, false);
        this._positionThumbForValue(thumb, value);

        // value wasn't rounded above so that thumb would drag smoothly
        var roundValue: number = value;
        if (this._step) {
            roundValue = Math.round(value / this._step) * this._step;
        }

        // TODO: display round value somewhere

        // TODO: add property for this feature which results in thumbs pushing each other each other so that their order remains constant
        if (false) {
            // push values to left
            var i: number;
            var thumbValue: number;         // use separate variables to maintain values for single "value" PropertyChangeEvent below
            var thumbRoundValue: number;
            var cachedValue: number;
            for (i = 0; i < activeThumbIndex; i++) {
                cachedValue = this._cachedValues[i];

                thumb = this._thumbs[i];
                thumbValue = Math.min(value, cachedValue);
                this._positionThumbForValue(thumb, thumbValue);

                if (this._step) {
                    thumbValue = this._roundValue(thumbValue);
                }
                // TODO: should manipulate cached copy in case liveDragging is off
                this._setValueAt(i, Math.min(thumbValue, cachedValue));
            }

            // push values to right
            var numThumbs: number = this._values ? this._values.length : 0;
            for (i = activeThumbIndex + 1; i < numThumbs; i++) {
                cachedValue = this._cachedValues[i];

                thumb = this._thumbs[i];
                thumbValue = Math.max(value, cachedValue);
                this._positionThumbForValue(thumb, thumbValue);

                if (this._step) {
                    thumbValue = this._roundValue(thumbValue);
                }
                // TODO: should manipulate cached copy in case liveDragging is off
                this._setValueAt(i, Math.max(thumbValue, cachedValue));
            }
        }

        if (this._liveDragging) {
            this._setValueAt(this._activeThumbIndex, roundValue);
        }
    }
    
    /**
     * Handler for when a thumb is released.
     * Snapst the thumb to its rounded value and stops dragging.
     */
    protected _upHandler = (event: BaseJQueryEventObject): void => {
        // events are dispatched immediately when liveDragging
        if (!this._liveDragging) {
            var value: number = this._valueForPosition(event.pageX, event.pageY);
            if (this._step) {
                value = this._roundValue(value);
            }

            var propertyChangeEvent: PropertyChangeEvent;
            if (this._activeThumbIndex === 0) {
                var oldValue = this._value;
                this._value = value;

                this._triggerValueChange(value, oldValue);
            }

            this._setValueAt(this._activeThumbIndex, value);
        }
        
        this.root.classList.remove('active');
        
        $(document).off('mousemove touchmove', this._throttledMoveHandler);
        $(document).off(<any>'mouseup touchend', <any>this._upHandler);

        this.trigger(Slider.EVENT_THUMB_UP, this._activeThumbIndex);
    }

    private _trackDownHandler = (event) => {
        var e: any = new Event();
        e.originalEvent = event;
        this.trigger(Slider.EVENT_TRACK_DOWN, e);
        
        if (this._trackDragging) {
            // HACK: create fake event to leverage existing handler; this is brittle
            var fakeEvent: any = {};
            fakeEvent.currentTarget = {};
            fakeEvent.preventDefault = function () { };
            //$(fakeEvent.currentTarget).data('thumb-index', 0);
            fakeEvent.currentTarget.thumbIndex = 0;
            var pointerOffset: any = {};
            pointerOffset.x = 0;
            pointerOffset.y = 0;
            this._downHandler(<any>fakeEvent, pointerOffset);
            
            this._moveHandler(event);
        }
    }

    /**
     * Handler for when the values change.
     * Updates the positions of the thumbs.
     */
    private _valuesChangeHandler = (event?: CollectionEvent) => {
        // ignore changes that we are making to the Collection
        // only concerned about external changes
        if (this._changing) {
            return;
        }
        
        // SBLENDED-155; on value removal, also remove the thumb that was associated with it
        // this will keep thumbs from jumping around when transition applied to thumbs 
        if (event && (event.kind === CollectionEvent.KIND_REMOVE)) {
            // TODO: ensure sort; only removing one at a time now anyway
            var sortedLocations: number[] = event.locations;
            var len: number = sortedLocations ? sortedLocations.length : 0;
            for (var i: number = len - 1; i >= 0; i--) {
                var idx: number = sortedLocations[i];
                var removedThumbs = this._thumbs.splice(idx, 1);
                var removedThumb = removedThumbs[0];
                this._destroyThumb(removedThumb, idx);
            }
        }

        // leverage existing handler
        this.invalidateProperty('values');
    }
}
export = Slider;
