import Event = require('events/event');
import GradientEntry = require('graphics/gradient-entry');

class GradientEditorEvent extends Event {
    
    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------

    static THUMB_DOWN: string = 'THUMB_DOWN';
    static THUMB_TAP: string = 'THUMB_TAP';
    static THUMB_DOUBLE_TAP: string = 'THUMB_DOUBLE_TAP';

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
        super();
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  gradientEntry
    //----------------------------------

    gradientEntry: GradientEntry;

    //----------------------------------
    //  index
    //----------------------------------

    /**
     * Index of the thumb that was pressed.
     */
    //index: number;
}
export = GradientEditorEvent;