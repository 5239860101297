 /// <reference path="../typings/cordova-plugin-inapppurchase.d.ts" />

var constructorToken = {};

import Environment = require('core/environment');

/**
 * The <code>LicenseManager</code> is responsible for persisting purchases
 * and using those purchases to determine a license type for the application.
 */
class LicenseManager {
    
    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------
    
    static LICENSE_TIER_1: string = 'TIER_1';
    static LICENSE_TIER_2: string = 'TIER_2';
    static LICENSE_TIER_3: string = 'TIER_3';
    
    private static STORAGE_KEY: string = 'purchases';
    
    // perpetual
    // these values must match the product IDs of the InApp purchases of iTunes Connect
    static PRODUCT_TIER_2: string = 'Tier2Perpetual';
    static PRODUCT_TIER_3: string = 'Tier3Perpetual';
    static PRODUCT_TIER_2_TO_3_UPGRADE: string = 'Tier2To3UpgradePerpetual';
    
    static PRODUCT_TIER_2_YEAR: string = 'PRODUCT_TIER_2_YEAR';
    static PRODUCT_TIER_3_YEAR: string = 'PRODUCT_TIER_3_YEAR';
    static PRODUCT_TIER_2_TO_3_UPGRADE_YEAR: string = 'PRODUCT_TIER_2_TO_3_UPGRADE_YEAR';
    
    private static TIER_2_PRODUCTS: string[] = [LicenseManager.PRODUCT_TIER_2, LicenseManager.PRODUCT_TIER_2_YEAR];
    private static TIER_3_PRODUCTS: string[] = [LicenseManager.PRODUCT_TIER_3, LicenseManager.PRODUCT_TIER_2_TO_3_UPGRADE, LicenseManager.PRODUCT_TIER_3_YEAR, LicenseManager.PRODUCT_TIER_2_TO_3_UPGRADE_YEAR];
    
    //--------------------------------------------------------------------------
    //
    //  Class methods
    //
    //--------------------------------------------------------------------------

    private static _instance: LicenseManager;

    /**
     * Returns the singleton instance.
     */
    static getInstance(): LicenseManager {
        if (!LicenseManager._instance) {
            LicenseManager._instance = new LicenseManager(constructorToken);
        }
        
        return LicenseManager._instance;
    }
    
    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------
    
    private _purchases: IProductPurchase[] = [];
    
    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor(token) {
        if (token !== constructorToken) {
            throw new Error("This class cannot be instantiated.  Use the static getInstance method.");
        }
        
        var jsonPurchases: string = window.localStorage.getItem(LicenseManager.STORAGE_KEY);
        if (jsonPurchases) {
            this._purchases = JSON.parse(jsonPurchases);
        }
        
        this._license = this._determineLicense(this._purchases) ;
    }
    
    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------
    
    //----------------------------------
    //  license
    //----------------------------------
    
    private _license: string;
    
    /**
     * The license type, as determined from the list of purchased products. 
     */
    get license(): string {
        return this._license;
    }
    
    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------
    
    /**
     * Adds a product to the application.
     */
    addPurchase(purchase: IProductPurchase): void {
        // check whether this purchase is already registered
        var alreadyRegistered: boolean = false;
        var purchases  = this._purchases;
        var len: number = purchases ? purchases.length : 0;
        for (var i: number = 0; i < len; i++) {
            var p = purchases[i];
            if (p.transactionId === purchase.transactionId) {
                alreadyRegistered = true;
            }
        }
        if (alreadyRegistered) {
            return;
        }
        
        this._purchases.push(purchase);
        
        this._license = this._determineLicense(this._purchases);
        
        this._persist();
    }
    
    /**
     * Restores purchases.
     */
    restorePurchases(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (window.inAppPurchase) {
                window.inAppPurchase.restorePurchases().then(
                    (purchases: IInAppPurchasePurchase[]) => {
                        var len: number = purchases ? purchases.length : 0;
                        for (var i: number = 0; i < len; i++) {
                            var purchase = purchases[i];
                            LicenseManager.getInstance().addPurchase(purchase);
                        }
                        
                        resolve(purchases);
                    },
                    (err) => {
                        reject(err);
                    }
                )
            }
        });
    }
    
    /**
     * The name of the license, such as "Professional".
     * TODO: localize
     */
    licenseName(license: string = this.license): string {
        var name: string;
        switch (license) {
            case LicenseManager.LICENSE_TIER_1:
                name = 'Basic Version';
                break;
            case LicenseManager.LICENSE_TIER_2:
                name = 'Indie Version';
                break;
            case LicenseManager.LICENSE_TIER_3:
                name = 'Professional Version';
                break;
            default:
                console.warn('Unknown license type: ' + this._license);
        }
        return name;
    }
    
    private _persist(): void {
        var jsonPurchases: string = JSON.stringify(this._purchases);
        window.localStorage.setItem(LicenseManager.STORAGE_KEY, jsonPurchases);
    }
    
    /**
     * Determines the license of the user based on the list of purchased products.
     * E.g. Tier 2 purchase + Tier 3 upgrade = Tier 3 
     */
    private _determineLicense(purchases: IProductPurchase[]): string {
        var licenseTier: number = 1;
        var len: number = purchases ? purchases.length : 0;
        for (var i: number = 0; i < len; i++) {
            var purchase = purchases[i];
            if (!purchase.expirationDate || (Date.now() < purchase.expirationDate)) {
                var purchaseTier: number = this._productTier(purchase.productId);
                licenseTier = Math.max(licenseTier, purchaseTier);
                
                var bestTier: number = 3;
                if (licenseTier >= bestTier) {
                    break;
                }
            }
        }
        
        // map from numeric tier to explicit license type
        var license: string;
        switch (licenseTier) {
            case 1:
                license = LicenseManager.LICENSE_TIER_1;
                break;
            case 2: 
                license = LicenseManager.LICENSE_TIER_2;
                break;
            case 3:
                license = LicenseManager.LICENSE_TIER_3;
                break;
        } 
        return license;
    }
    
    /**
     * Determines the product tier of the specified product ID.
     */
    private _productTier(productID: string): number {
        if (LicenseManager.TIER_3_PRODUCTS.indexOf(productID) !== -1) {
            return 3; 
        }
        
        if (LicenseManager.TIER_2_PRODUCTS.indexOf(productID) !== -1) {
            return 2;
        }  
        
        return 1;
    }
}
interface IProductPurchase {
    productId: string;
    transactionId: string;
    date: number;
    expirationDate?: number;
}

export = LicenseManager;