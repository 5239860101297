class Point {

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    constructor(x: number = 0, y: number = 0) {
        this.x = x;
        this.y = y;
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  x
    //----------------------------------

    x: number;

    //----------------------------------
    //  y
    //----------------------------------

    y: number;
}
export = Point;