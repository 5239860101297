import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import * as firebaseui from 'firebaseui'

import { LoginDialogComponent } from './login-dialog.component';

@Component({
  selector: 'login',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Helper component used to provide access to login workflows.
 * Delegates to the LoginDialogComponent to render the Firebase AuthUI.
 */
export class LoginComponent {

    //--------------------------------------------------------------------------
    //
    //  Class methods
    //
    //--------------------------------------------------------------------------

    private static _instance: LoginComponent; 

    /**
     * Shows the login UI overlaid on top of the entire application.
     */
    public static show(title: string = 'Sign in'): void {
        return LoginComponent._instance.show(false, title);
    }

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    private _signedIn: boolean = false;
    private _dialogRef: MatDialogRef<LoginDialogComponent, any>;

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor(
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
    ) {
        LoginComponent._instance = this;

        // automatically hide once login complete
        firebase.auth().onAuthStateChanged((user: firebase.User) => {
            if (user) {
                this._signedIn = true;
                this.hide();
            } else {
                // show toast if we were previously signed in
                if (this._signedIn) {
                    let message: string = 'Successfully signed out';   // TODO: localize
                    this._snackBar.open(message, null, {
                        duration: 2000,
                        panelClass: 'snackbar'
                    });
                }
                this._signedIn = false;
                this.hide();
            }
        });

        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        if (ui.isPendingRedirect()) {
            this.show(true);
        }
    }

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * Shows the login UI overlaid on top of the entire application.
     */
    public show(completingAuth: boolean = false, title: string = 'Sign in'): void {
        let w: string = '480px';
        // let h: string = completingAuth ? '500px' : '300px';
        let h: string = '500px';

        this._dialogRef = this.dialog.open(LoginDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
            width: w,
            height: h,
            backdropClass: 'login-dialog-backdrop', 
            data: {
                title: title
            },
        });
    }

    /**
     * Hides the UI.
     */
    public hide(): void {
        if (this._dialogRef) {
            this._dialogRef.close();
            this._dialogRef = undefined;
        }
    }
}
