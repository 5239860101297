import NeighborInfo = require('utils/neighbor-info');
import SortUtil = require('utils/sort-util');

class ArrayUtil {

    //--------------------------------------------------------------------------
    //
    //  Class methods
    //
    //--------------------------------------------------------------------------

    /**
     * Inserts the value into the sorted array at the appropriate index to maintain the sort,
     * returning the index of the insertion.
     */
    static sortedInsert(sortedArray: any[], value: any, compareFunction?: (a: any, b: any) => number): number {
        if (!sortedArray) {
            throw new Error('Expecting sorted Array');
        }
        const insertIdx: number = ArrayUtil.binarySearch(sortedArray, value, false, compareFunction);
        if (insertIdx <= sortedArray.length) {
            sortedArray.splice(insertIdx, 0, value);
        }
        else {
            sortedArray.push(value);
        }
        return insertIdx;
    }

    /**
     * TODO: use real binary search
     * TODO: support compareFunction
     * TODO: support strict
     */
    static binarySearch(sortedArray: any[], value: any, strict: boolean = true, compareFunction?: (a: any, b: any) => number): number {


        if (!sortedArray) {
            return -1;
        }
        const len: number = sortedArray.length;
        if (!compareFunction) {
            compareFunction = SortUtil.numericCompareFunction;
        }
        return ArrayUtil._binarySearch(sortedArray, value, strict, compareFunction, 0, len - 1);
    }

    private static _binarySearch(
        sortedArray: any,
        value: any,
        strict: boolean,
        compareFunction: (a: any, b: any) => number,
        startIdx: number,
        endIdx: number): number {
        if (endIdx < startIdx) {
            if (strict) {
                return -1;
            }
            return startIdx;
        }

        const midIdx: number = startIdx + Math.floor((endIdx - startIdx) / 2);

        const midValue: any = sortedArray[midIdx];
        const compareResult: 0 | 1 | -1 = compareFunction(value, midValue) as 0 | 1 | -1;
        switch (compareResult) {
            case -1:
                return ArrayUtil._binarySearch(sortedArray, value, strict, compareFunction, startIdx, midIdx - 1);
            case 0:
                return midIdx;
            case 1:
                return ArrayUtil._binarySearch(sortedArray, value, strict, compareFunction, midIdx + 1, endIdx);
        }
    }

    static clone(arr: any[]): any[] {
        if (!arr) {
            return null;
        }
        return arr.concat();
    }

    //static interpolate(arr: any[], item: any

    /**
     *
     */
    static neighbors(a: any[], item: any, sorted: boolean = false, compareFunction?: (a: any, b: any) => number): NeighborInfo {
        const neighborInfo: NeighborInfo = new NeighborInfo();
        if (!compareFunction) {
            compareFunction = SortUtil.numericCompareFunction;
        }

        let sortedArray;
        if (sorted) {
            sortedArray = a;
        }
        else {
            sortedArray = ArrayUtil.clone(a);
            sortedArray.sort(compareFunction);
        }

        const idx: number = ArrayUtil.binarySearch(sortedArray, item, false, compareFunction);

        let leftNeighborIdx;
        let rightNeighborIdx;

        const itemAtIndex = a[idx];

        // matching item exists in Array
        if (itemAtIndex && compareFunction(itemAtIndex, item) === 0) {
            leftNeighborIdx = idx - 1;
            rightNeighborIdx = idx;
        }
        else {
            // matching item not found in Array
            leftNeighborIdx = idx - 1;
            rightNeighborIdx = idx;
        }

        // check for items that have same "value" as the match
        // return first non-match in both directions
        // e.g. ArrayUtil.neighbors([0, 1, 2, 2, 2, 3], 2, true); ==> 1, 3
        // could parameterize this behavior to do first / last / outer

        let leftNeighbor;   // = a[leftNeighborIdx];
        let rightNeighbor;  // = a[rightNeighborIdx];

        let found: boolean = false;
        while (!found && (leftNeighborIdx >= 0)) {
            leftNeighbor = a[leftNeighborIdx];
            if (compareFunction(leftNeighbor, item) === 0) {
                leftNeighborIdx--;
            }
            else {
                found = true;
            }
        }

        found = false;
        const len: number = a ? a.length : 0;
        while (!found && (rightNeighborIdx < len)) {
            rightNeighbor = a[rightNeighborIdx];
            if (compareFunction(rightNeighbor, item) === 0) {
                rightNeighborIdx++;
            }
            else {
                found = true;
            }
        }

        //if (itemAtIndex && compareFunction(itemAtIndex, item) === 0) {
        //    // found exact match
        //    // act as our own left neighbor
        //    // TODO: expose option as argument
        //    leftNeighborIdx = idx - 1;
        //    rightNeighborIdx = idx + 1;
        //}
        ///*else if (itemAtIndex) {
        //    leftNeighborIdx = idx;
        //    rightNeighborIdx = idx + 1;
        //}*/
        //else {
        //    // item is past the last item in the Array
        //    leftNeighborIdx = idx - 1;
        //    rightNeighborIdx = idx;
        //}

        /*
        var compareValue = compareFunction(itemAtIndex, item);
        switch (compareValue) {
            case -1:
                // item should be prior to the idx
                leftNeighborIdx = idx - 1;
                rightNeighborIdx = idx;
                break;
            case 0:
                // found exact match
                // act as our own left neighbor
                // TODO: expose option as argument
                leftNeighborIdx = idx - 1;
                rightNeighborIdx = idx + 1;
                break;
            case 1:
                // item should be after the idx
                leftNeighborIdx = idx;
                rightNeighborIdx = idx;
                break;
        }
*/

        leftNeighbor = a[leftNeighborIdx];
        rightNeighbor = a[rightNeighborIdx];

        neighborInfo.previous = leftNeighbor;
        neighborInfo.next = rightNeighbor;
        return neighborInfo;
    }

    static indexOf(arr: any[], item: any, sorted: boolean= false, compareFunction?: (a: any, b: any) => number): number {
        let idx: number = -1;
        //if (sorted) {
        if (false) {
        }
        else {
            const len: number = arr ? arr.length : 0;
            for (let i: number = 0; i < len; i++) {
                if (compareFunction(item, arr[i]) === 0) {
                    idx = i;
                    break;
                }
            }
        }
        return idx;
    }

    static lastIndexOf(arr: any[], item: any, sorted: boolean= false, compareFunction?: (a: any, b: any) => number): number {
        let idx: number = -1;
        //if (sorted) {
        if (false) {
        }
        else {
            const len: number = arr ? arr.length : 0;
            for (let i: number = 0; i < len; i++) {
                if (compareFunction(item, arr[i]) === 0) {
                    idx = i;
                }
            }
        }
        return idx;
    }
}
export = ArrayUtil;
