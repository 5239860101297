import Event = require('events/event');

class PropertyChangeEvent extends Event {
    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------

    static PROPERTY_CHANGE: string = 'propertyChange';

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  propertyName
    //----------------------------------

    /**
     * Name of the property that changed.
     */
    propertyName: string;

    //----------------------------------
    //  oldValue
    //----------------------------------

    /**
     * The old value of the property before it changed.
     */
    oldValue: any;

    //----------------------------------
    //  value
    //----------------------------------

    /**
     * The new value of the property.
     */
    value: any;

    //----------------------------------
    //  location
    //----------------------------------

    /**
     * The specific location where the change occurred
     * in the case a change was made to a specific element
     * within an Array or Object.
     */
    location: any;
}
export = PropertyChangeEvent;
