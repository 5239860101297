/// <reference path="../declarations.d.ts" />

var constructorToken = {};

import Alert = require('ui/alert');
import Environment = require('core/environment');
import EventDispatcher = require('events/event-dispatcher');
import FeatureInfo = require('sblended/feature-info');
import LicenseManager = require('sblended/license-manager');

/**
 * The <code>FeatureManager</code> is responsible for managing the availability
 * of paid features throughout the application.
 * 
 * Features are checked via the <code>info</code> method using the registered callback.
 * This setup allows the registration of features to be done in a centralized place,
 * while the checking of features can be done at lower levels without specific
 * knowlege of the products and licenses or how to go about purchasing them.
 */
class FeatureManager extends EventDispatcher {
    
    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------
    
    //-- Events
    
    static EVENT_PURCHASE: string = 'PURCHASE';
    
    static FEATURE_EXPORT_SIZE: string = 'EXPORT_SIZE';
    static FEATURE_NUMBER_OF_LINES: string = 'NUMBER_OF_LINES';
    static FEATURE_NUMBER_OF_STOPS_PER_LINE: string = 'NUMBER_OF_STOPS_PER_LINE';
    static FEATURE_ALPHA_STOPS: string = 'ALPHA_STOPS';
    static FEATURE_COLOR_THEMES: string = 'COLOR_THEMES';
    static FEATURE_HEX_INPUT: string = 'HEX_INPUT';
    static FEATURE_TEXT_INPUTS: string = 'TEXT_INPUTS';
    static FEATURE_RGB_COLOR_PICKER: string = 'RGB_COLOR_PICKER';
    static FEATURE_SEND_TO_PHOTOSHOP: string = 'SEND_TO_PHOTOSHOP';
    static FEATURE_SEND_TO_ILLUSTRATOR: string = 'SEND_TO_ILLUSTRATOR';
    static FEATURE_PERSISTENT: string = 'PERSISTENT';

    static PRODUCT_URL: string = 'https://get.sblended.com';
        
    //--------------------------------------------------------------------------
    //
    //  Class methods
    //
    //--------------------------------------------------------------------------

    private static _instance: FeatureManager;

    /**
     * Returns the singleton instance.
     */
    static getInstance(): FeatureManager {
        if (!FeatureManager._instance) {
            FeatureManager._instance = new FeatureManager(constructorToken);
        }
        
        return FeatureManager._instance;
    }
    
    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------
    
    private _featureCallbacks: { [s: string]: (featureName: string, ...args) => FeatureInfo } = {};
    private _prompting: boolean = false;
    
    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor(token) {
        super();
        
        if (token !== constructorToken) {
            // TODO: localize
            throw new Error("This class cannot be instantiated.  Use the static getInstance method.");
        }
        
        // register features to specific products
        // the indie license has a maximum export size of 1024x768. would you like to upgrade your license?
        this._register(FeatureManager.FEATURE_EXPORT_SIZE, (featureName: string, ...args): FeatureInfo => {
            var maxW: number;
            var maxH: number;

            if (Environment.context === Environment.CONTEXT_WEB) {
                maxW = 1080;
                maxH = 1080;
            }
            else {
                switch (LicenseManager.getInstance().license) {
                    case LicenseManager.LICENSE_TIER_1:
                        maxW = 640;
                        maxH = 640;
                        break;
                    case LicenseManager.LICENSE_TIER_2:
                        maxW = 1080;
                        maxH = 1080;
                        break;
                    case LicenseManager.LICENSE_TIER_3:
                        maxW = Number.POSITIVE_INFINITY;
                        maxH = Number.POSITIVE_INFINITY;
                        break;
                }
            }

            var w: number = args[0];
            var h: number = args[1];
            if ((w > maxW) || (h > maxH)) {
                var info = new FeatureInfo(false);
                if (Environment.context === Environment.CONTEXT_WEB) {
                    info.message = 'The web version has a maximum export size of ' + maxW + 'x' + maxH + '. \nCurious to know more about our Photoshop add-on?';
                }
                else {
                    // TODO: localize
                    info.message = 'The ' + LicenseManager.getInstance().licenseName() + ' has a maximum export size of ' + maxW + 'x' + maxH + '. \nWould you like to upgrade your license now?';
                }
                info.details = {};
                info.details.width = maxW;
                info.details.height = maxH;
                return info;
            }
            
            return new FeatureInfo(true);
        });
        
        this._register(FeatureManager.FEATURE_NUMBER_OF_LINES, (featureName: string, ...args): FeatureInfo => {
            let maxNumberOfLines: number = 3;
            if ((Environment.context === Environment.CONTEXT_WEB) || (Environment.context === Environment.CONTEXT_BLENDS)) {
                maxNumberOfLines = 5;
            }
            else {
                switch (LicenseManager.getInstance().license) {
                    case LicenseManager.LICENSE_TIER_1:
                    default:
                        maxNumberOfLines = 3;
                        break;
                    case LicenseManager.LICENSE_TIER_2:
                        maxNumberOfLines = 6;
                        break;
                    case LicenseManager.LICENSE_TIER_3:
                        maxNumberOfLines = Number.POSITIVE_INFINITY;
                        break;
                }
            }
            
            var numberOfLines: number = args[0];
            if (numberOfLines > maxNumberOfLines) {
                var info = new FeatureInfo(false);

                // TODO: localize
                if (Environment.context === Environment.CONTEXT_BLENDS) {
                    info.message = 'Community Blends have a maximum of ' + maxNumberOfLines + ' lines, but our Photoshop add-on supports unlimited. \Want to know more?';
                }
                else {
                    info.message = 'The ' + LicenseManager.getInstance().licenseName() + ' has a maximum of ' + maxNumberOfLines + ' lines.';
                    if (LicenseManager.getInstance().license === LicenseManager.LICENSE_TIER_2) {
                        info.message += '\nWould you like to upgrade to a ' + LicenseManager.getInstance().licenseName(LicenseManager.LICENSE_TIER_3) + ' for unlimited lines?';
                    }
                    else {
                        info.message += '\nWould you like to upgrade your license now?';
                    }
                }
                return info;
            }
            
            return new FeatureInfo(true);
        });
        
        this._register(FeatureManager.FEATURE_NUMBER_OF_STOPS_PER_LINE, (featureName: string, ...args): FeatureInfo => {
            var maxStopsPerLine: number;
            switch (LicenseManager.getInstance().license) {
                case LicenseManager.LICENSE_TIER_1:
                default:
                    maxStopsPerLine = 3;
                    break;
                case LicenseManager.LICENSE_TIER_2:
                    maxStopsPerLine = 6;
                    break;
                case LicenseManager.LICENSE_TIER_3:
                    maxStopsPerLine = Number.POSITIVE_INFINITY;
                    break;
            }
            
            var stopsInLine: number = args[0];
            if (stopsInLine > maxStopsPerLine) {
                var info = new FeatureInfo(false);
                // TODO: localize
                info.message = 'The ' + LicenseManager.getInstance().licenseName() + ' has a maximum of ' + maxStopsPerLine + ' stops per line.';
                if (LicenseManager.getInstance().license === LicenseManager.LICENSE_TIER_2) {
                    info.message += '\nWould you like to upgrade to a ' + LicenseManager.getInstance().licenseName(LicenseManager.LICENSE_TIER_3) + ' for unlimited stops per line?';
                }
                else {
                    info.message += '\nWould you like to upgrade your license now?';
                }
                return info;
            }
            
            return new FeatureInfo(true);
        });

        this._register(FeatureManager.FEATURE_ALPHA_STOPS, (featureName: string, ...args): FeatureInfo => {
             if (LicenseManager.getInstance().license === LicenseManager.LICENSE_TIER_1) {
                var info = new FeatureInfo(false); 
                // TODO: localize
                info.message = 'Opacity stops are available with a paid license. \nWould you like to upgrade your license now?';
                return info;
            }
            
            return new FeatureInfo(true);
        });
        
        this._register(FeatureManager.FEATURE_COLOR_THEMES, (featureName: string, ...args): FeatureInfo => {
            if (LicenseManager.getInstance().license === LicenseManager.LICENSE_TIER_1) {
                var info = new FeatureInfo(false); 
                // TODO: localize
                info.message = 'Personal and community-built color themes are available with a paid license. \nWould you like to upgrade your license now?';
                return info;
            }
            
            return new FeatureInfo(true);
        });
        
        this._register(FeatureManager.FEATURE_HEX_INPUT, (featureName: string, ...args): FeatureInfo => {
            if (LicenseManager.getInstance().license === LicenseManager.LICENSE_TIER_1) {
                var info = new FeatureInfo(false);
                // TODO: localize
                // info.message = 'Hex input is available with a paid license. \nWould you like to upgrade your license now?';
                info.message = 'Text inputs are available with a paid license. \nWould you like to upgrade your license now?';
                return info;
            }
            
            return new FeatureInfo(true);
        });
        
        this._register(FeatureManager.FEATURE_TEXT_INPUTS, (featureName: string, ...args): FeatureInfo => {
            if (LicenseManager.getInstance().license === LicenseManager.LICENSE_TIER_1) {
                var info = new FeatureInfo(false);
                // TODO: localize
                info.message = 'Text inputs are available with a paid license. \nWould you like to upgrade your license now?';
                return info;
            }
            
            return new FeatureInfo(true);
        });
        
        this._register(FeatureManager.FEATURE_RGB_COLOR_PICKER, (featureName: string, ...args): FeatureInfo => {
            if (LicenseManager.getInstance().license === LicenseManager.LICENSE_TIER_3) {
                return new FeatureInfo(true);
            }
            
            var info = new FeatureInfo(false);
            // TODO: localize
            info.message = 'The RGB color picker is available with a Professional license. \nWould you like to upgrade to one now?';
            return info;
        });
        
        
        // send to desktop
        this._register(FeatureManager.FEATURE_SEND_TO_PHOTOSHOP, (featureName: string, ...args): FeatureInfo => {
            if (LicenseManager.getInstance().license === LicenseManager.LICENSE_TIER_3) {
                return new FeatureInfo(true);
            }
            
            var info = new FeatureInfo(false);
            // TODO: localize
            info.message = 'The Send to Photoshop feature is available with a Professional license. \nWould you like to upgrade to one now?';
            return info;
        });
        
        this._register(FeatureManager.FEATURE_SEND_TO_ILLUSTRATOR, (featureName: string, ...args): FeatureInfo => {
            if (LicenseManager.getInstance().license === LicenseManager.LICENSE_TIER_3) {
                return new FeatureInfo(true);
            }
            
            var info = new FeatureInfo(false);
            // TODO: localize
            info.message = 'The Send to Illustrator feature is available with a Professional license. \nWould you like to upgrade to one now?'
            return info;
        });

        this._register(FeatureManager.FEATURE_PERSISTENT, (featureName: string, ...args): FeatureInfo => {
            if (Environment.context === Environment.CONTEXT_WEB) {
                var info = new FeatureInfo(false);
                // TODO: localize
                return info;
            }
            return new FeatureInfo(true);
        });
    }
    
    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------
    
    /**
     * Registers the feature with the specified name to use the
     * supplied callback in order to check for validity.
     * 
     * The callback should expect the featureName as the first argument, with optional additional arguments.
     * The callback should return an Array of product IDs which are sufficient to support this feature.
     */
    private _register(featureName: string, callback: (featureName: string, ...args) => FeatureInfo): boolean {
        var existingCallback = this._featureCallbacks[featureName];
        if (existingCallback) {
            console.warn('Feature ' + featureName + ' has already been registered');
            return false;
        }
        
        this._featureCallbacks[featureName] = callback;
    }
    
    /**
     * Checks whether any of the products that allow the specified feature
     * are available to the user.
     */
    info(featureName: string, ...args): FeatureInfo {
        // allow all features in extension context
        if (Environment.context === Environment.CONTEXT_ADDON) {
            return new FeatureInfo(true);
        }

        var featureCallback = this._featureCallbacks[featureName];
        if (!featureCallback) {
            throw new Error('Unknown feature: '+ featureName);
        }
        
        var combinedArgs = [].concat(featureName, args);
        return featureCallback.apply(this, combinedArgs);
    }
    
    /**
     * Prompts the user to purchase the feature using the info provided by the registered featureCallback.
     * If accepted, the Store is presented for the user to complete the purchase.
     */
    prompt(info: FeatureInfo, shade: boolean = true): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this._prompting) {
                resolve(false);
                return;
            }
            this._prompting = true;
            
            // TODO: track this
            
            Alert.show(info.message, info.title, [{ label: 'No thanks' }, { label: 'Yes, Please!' }], false, shade)
                .then(
                    (index: number) => {
                        this._prompting = false;
                        
                        var accepted: boolean = index === 1 ? true : false;
                        if (accepted) {
                            this.trigger(FeatureManager.EVENT_PURCHASE, info.id );
                        }
                        resolve(accepted);
                    }, 
                    () => {
                        this._prompting = false;
                        resolve(false);
                    }
                );
        });
    }
}

export = FeatureManager;