import { Component, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { firebaseConfig } from "./firebaseConfig";
import Environment = require('core/environment');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'blends';

    @ViewChild('alert', { static: true }) alert;

    constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        iconRegistry.addSvgIconSetInNamespace('sblended', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons.svg'));

        firebase.initializeApp({
            apiKey: firebaseConfig.apiKey,
            authDomain: firebaseConfig.authDomain,
            projectId: firebaseConfig.projectId
        });

        Environment.context = Environment.CONTEXT_BLENDS;
    }

    ngOnInit(): void {
        // HACK: workaround for dependency injection issues with static methods in Angular
        // this will be used by ui/Alert.ts
        window['Alert'] = this.alert;
    }
}
