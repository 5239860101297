import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BlendCreateComponent } from './blend-create/blend-create.component';
import { BlendDetailsComponent } from './blend-details/blend-details.component';
import { SharedBlendsComponent } from './shared-blends/shared-blends.component';

const routes: Routes = [
  { path: '', component: SharedBlendsComponent },
  { path: 'explore',   redirectTo: '/explore/featured', pathMatch: 'full' },
  { path: 'explore/:category', component: SharedBlendsComponent },
  { path: 'details/:blendId', component: BlendDetailsComponent },
  { path: 'create', component: BlendCreateComponent },
  { path: '**', component: SharedBlendsComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
