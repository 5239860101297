export const firebaseConfig = {
    apiKey: "AIzaSyDMgsbc_ZSja01HwIFgh_BuAtqkzziLFuw",
    // authDomain: "sblended-94fac.firebaseapp.com",
    authDomain: "blends.sblended.com",
    databaseURL: "https://sblended-94fac.firebaseio.com",
    projectId: "sblended-94fac",
    storageBucket: "sblended-94fac.appspot.com",
    messagingSenderId: "344516005317",
    appId: "1:344516005317:web:f58a4057ea160dda474fac"
};
      