// import BaseElement = require('core/base-element');

class Invalidating {//extends BaseElement {

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    private _invalidProperties: any = null;
    private _invalid: boolean;

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
    }

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * Marks a property as invalid for the validateProperties call on the next frame.
     */
    invalidateProperty(prop: string) {
        if (prop) {
            if (this._invalidProperties !== true) {             // all properties have already been invalidated
                if (!this._invalidProperties) {
                    this._invalidProperties = {};
                }
                this._invalidProperties[prop] = true;
            }
        }
        else {
            this._invalidProperties = true;
        }

        this.invalidate();
    }

    /* protected */ invalidate(): void {
        if (!this._invalid) {
            this._invalid = true;
            window.requestAnimationFrame(this._animationFrameHandler);
        }
    }

    /**
     * Force propagation of changes since the last execution.
     * This method will automatically be queued for execution on the next
     * animationFrame whenever invalidate has been called.
     */
    validate(): void {
        if (this._invalid) {
            if (this._invalidProperties) {
                const changed = (this._invalidProperties === true) ? null : this._invalidProperties;
                this.validateProperties(changed);
                this._invalidProperties = null;
            }
            this._invalid = false;
        }
    }
    /**
     * Force propagation of property changes since the last execution.
     * This method will automatically be queued for execution on the next
     * animationFrame whenever a property is invalidated.
     */
    validateProperties(changed?: any): void {
        // abstract
    }

    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------

    private _animationFrameHandler = (): void => {
        this.validate();
    }
}
export = Invalidating;
