import ArrayUtil = require('utils/array-util');
import ColorAndAlpha = require('graphics/color-and-alpha');
import Gradient = require('graphics/gradient');
import GradientEntry = require('graphics/gradient-entry');
import IEaser = require('animation/easers/i-easer');
import IList = require('collections/i-list');
import RGBA = require('graphics/rgba');
import SortUtil = require('utils/sort-util');

class ColorUtil {
    
    //--------------------------------------------------------------------------
    //
    //  Class methods
    //
    //--------------------------------------------------------------------------

    /**
     * Converts from RGB components to a numeric color.
     */
    static rgbToColor(r: number, g: number, b: number): number {
        var color: number = r << 16;
        color = color | (g << 8);
        color = color | b;
        return color;
    }

    /**
     * Converts from a numeric color to RGB components.
     */
    static colorToRGB(color: number): RGBA {
        var r: number = color >> 16;
        var g: number = color >> 8 & 0xFF;
        var b: number = color & 0xFF;

        return new RGBA(r, g, b);
    }

    static colorToHSB(color: number): { h: number, s: number, b: number } {
        let rgb = ColorUtil.colorToRGB(color);
        return ColorUtil.rgbToHSB(rgb.r, rgb.g, rgb.b);
    }

    static colorToHex(color: number, alpha?: number, upperCase: boolean = true): string {
        var hex: string = '';
        var rgba = new RGBA(color, alpha);
        
        var r = rgba.r.toString(16);
        if (r.length === 1) {
            r = "0" + r;
        } 
        
        var g = rgba.g.toString(16);
        if (g.length === 1) {
            g = "0" + g;
        }
        
        var b = rgba.b.toString(16);
        if (b.length === 1) {
            b = "0" + b;
        }
        
        hex = r + g + b;
        if (typeof alpha !== 'undefined') {
            // TODO:
        }
        if (upperCase) {
            hex = hex.toUpperCase();
        }

        return hex;
    }

    static hexToRGBA(hexString: string): RGBA {
        var rgba: RGBA = new RGBA();

        if (false) {
            // TODO: support rgba hex string
        }
        else {
            let simpleHexString = hexString.startsWith('#') ? hexString.substr(1) : hexString;

            // convert from 3 digit hex to six digit by replicating each char #ABC -> #AABBCC
            if (simpleHexString.length === 3) {
                let firstChar: string = simpleHexString.charAt(0);
                let secondChar: string = simpleHexString.charAt(1);
                let thirdChar: string = simpleHexString.charAt(2);
                simpleHexString = firstChar + firstChar + secondChar + secondChar + thirdChar + thirdChar;
            }

            // Number supports strings of form 0xBLAH
            var color = new Number('0x' + simpleHexString);
            rgba.color = (<any>color.toExponential());
        }
        return rgba;
    }

    static interpolateRGBA(rgba1: RGBA, rgba2: RGBA, offset: number, easer?: IEaser): RGBA {
        // interpolate individual r,g, b components
        var rgba: RGBA = new RGBA();

        var easedOffset: number = easer ? easer.ease(offset) : offset;
        rgba.r = rgba1.r + ((rgba2.r - rgba1.r) * easedOffset);
        rgba.g = rgba1.g + ((rgba2.g - rgba1.g) * easedOffset);
        rgba.b = rgba1.b + ((rgba2.b - rgba1.b) * easedOffset);
        rgba.a = rgba1.a + ((rgba2.a - rgba1.a) * easedOffset);
        return rgba;
    }

    static interpolateColor(color1: number, color2: number, offset: number, easer?: IEaser): number {
        // TODO: investigate HSB interpolation
        var rgba1: RGBA = new RGBA(color1);
        var rgba2: RGBA = new RGBA(color2);

        var rgba: RGBA = ColorUtil.interpolateRGBA(rgba1, rgba2, offset, easer);

        return rgba.color;
    }
    
    // https://en.wikipedia.org/wiki/HSL_and_HSV
    static hsbToRGBA(hue: number, saturation: number = 1, brightness: number = 1): RGBA {
        var r: number;
        var g: number;
        var b: number;
        if (saturation === 0) {
            // will be shade of grey
            r = g = b = 255 * brightness;
        }
        else {
            // c = chroma
            var c: number = brightness * saturation;
            var h = hue / 60;
            
            var x = c * (1 - Math.abs(h % 2 - 1));
            
            switch (Math.floor(h % 6)) {
               case 0:
                    r = c;
                    g = x;
                    b = 0;
                    break;
                case 1:
                    r = x;
                    g = c;
                    b = 0;
                    break;
                case 2:
                    r = 0;
                    g = c;
                    b = x;
                    break;
                case 3:
                    r = 0;
                    g = x;
                    b = c;
                    break;
                case 4:
                    r = x;
                    g = 0;
                    b = c;
                    break;
                case 5:
                    r = c;
                    g = 0;
                    b = x;
                    break;
           }
           
           var m = brightness - c;
            
           r = (r + m) * 255;
           g = (g + m) * 255;
           b = (b + m) * 255;
        }
        
        var rgba: RGBA = new RGBA();
        rgba.r = r;
        rgba.g = g;
        rgba.b = b;
        return rgba;
    }
    
    static rgbToHSB(r: number, g: number, b: number): { h: number, s: number, b: number} {
        var min: number = Math.min(r, g, b);
        var max: number = Math.max(r, g, b);
        var c: number = max - min;
        var h: number;
        if (c === 0) {
            h = 0;
        }
        else if (max === r) {
            h = ((g - b) / c) % 6; 
        }
        else if (max === g) {
            h = ((b - r) / c) + 2;
        }
        else if (max === b) {
            h = ((r - g) / c) + 4;
        }
        var hue: number = h * 60;
        hue = hue % 360;
        if (hue < 0) {
            hue += 360;
        }
        
        var brightness: number = Math.max(r, g, b) / 255;
        var saturation: number = (c === 0) ? 0 : c / brightness / 255;
        
        return {
            h: hue,
            s: saturation,
            b: brightness
        }
    }
}
export = ColorUtil;