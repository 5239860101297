import BindingUtil = require('utils/binding-util');
import Direction = require('geom/direction');
import EventDispatcher = require('events/event-dispatcher');
import Gradient = require('graphics/gradient');
import GradientEntry = require('graphics/gradient-entry');
import GradientEntryType = require('graphics/gradient-entry-type');
import ISerializable = require('core/i-serializable');
import PropertyChangeEvent = require('events/property-change-event');

/**
 * Represents a path within a Blend.
 * Currently defined as simple offset.
 */
class BlendPath extends EventDispatcher implements ISerializable {

    //--------------------------------------------------------------------------
    //
    //  Class methods
    //
    //--------------------------------------------------------------------------

    /**
     * Deserializes the plain object into an instance.
     */
    static deserialize(o: any): BlendPath {
        const instance = new BlendPath();

        if (o.gradient) {
            instance.gradient = Gradient.deserialize(o.gradient);
        }
        instance.offset = o.offset;
        //instance.type = o.type;

        if (typeof o.bubbleChanges !== 'undefined') {
            instance.bubbleChanges = o.bubbleChanges;
        }

        if (typeof o.orientation !== 'undefined') {
            instance.orientation = o.orientation;
        }

        return instance;
    }

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor(gradient?: Gradient, offset: number= 0) {
        super();

        this.gradient = gradient;
        this.offset = offset;
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  gradient
    //----------------------------------

    private static _bindableGradient = BindingUtil.bindable(BlendPath, 'gradient');

    private _gradient: Gradient;

    set gradient(value: Gradient) {
        if (this._gradient) {
            this._gradient.off(PropertyChangeEvent.PROPERTY_CHANGE, this._gradientPropertyChange);
        }
        this._gradient = value;

        // TODO: move this behavior to BindingUtil
        // if something in our gradient changes, then bubble this up as a property change to gradient
        if (this._gradient && this.bubbleChanges) {
            this._gradient.on(PropertyChangeEvent.PROPERTY_CHANGE, this._gradientPropertyChange);
        }
    }
    get gradient(): Gradient {
        return this._gradient;
    }

    //----------------------------------
    //  offset
    //----------------------------------

    private static _bindableOffset = BindingUtil.bindable(BlendPath, 'offset');

    offset: number;

    //----------------------------------
    //  type
    //----------------------------------

    get type(): string {
        const t: string = this._gradient ? this._gradient.type : null;
        return t;
    }

    //----------------------------------
    //  bubbleChanges
    //----------------------------------

    bubbleChanges: boolean = true;

    //----------------------------------
    //  orientation
    //----------------------------------

    /**
     * Orientation of this entry, either Direction.HORIZONTAL or Direction.VERTICAL.
     */
    orientation: string = Direction.HORIZONTAL;

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * Returns a clone.
     * @param deep      Whether or not to make a deep or shallow copy and share the Gradient by reference.
     */
    clone(deep: boolean = true, bubbleChanges?: boolean): BlendPath {
        if (typeof bubbleChanges === 'undefined') {
            bubbleChanges = this.bubbleChanges;
        }

        const entry: BlendPath = new BlendPath();
        entry.bubbleChanges = bubbleChanges;
        if (deep) {
            entry.gradient = this.gradient ? this.gradient.clone(deep, bubbleChanges) : null;
        }
        else {
            entry.gradient = this.gradient;
        }
        entry.offset = this.offset;
        entry.orientation = this.orientation;
        return entry;
    }

    //-- ISerializable

    /**
     * @inheritDoc
     */
    serialize(): any {
        const o: any = {};

        if (this.gradient) {
            o.gradient = this.gradient.serialize();
        }
        o.offset = this.offset;
        o.type = this.type;
        o.orientation = this.orientation;

        return o;
    }

    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------

    private _gradientPropertyChange = (event: PropertyChangeEvent) => {
        const propertyChangeEvent: PropertyChangeEvent = new PropertyChangeEvent();
        propertyChangeEvent.propertyName = 'gradient';
        this.trigger(PropertyChangeEvent.PROPERTY_CHANGE, propertyChangeEvent);
    }
}
export = BlendPath;
