class Event {

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor(target?: string) {
        this.target = target;
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  target
    //----------------------------------

    /**
     * The object triggering the Event.
     */
    target: any;

    //----------------------------------
    //  type
    //----------------------------------

    type: string;
}
export = Event;
