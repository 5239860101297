/// <reference path="../declarations.d.ts" />

/// <amd-dependency path="jquery" />

import $ = require('jquery');

import Point = require('geom/point');

class PointerUtil {
    /**
     * Normalizes the jQuery Touch and Mouse events to return a simple Point.
     * If requesting the offset, pass a <code>targetOffset</code> for Touch events
     * as well as for cases where the event is bound to the document vs. the View itself.
     */
    static normalizeJQueryEvent(event: BaseJQueryEventObject, offset: boolean = true, targetOffset: Point = null): Point {
        var p: Point = new Point();

        var pointerEvent: PointerEvent;
        var originalEvent = event.originalEvent ? event.originalEvent : event;
        var touches = (<any>originalEvent).touches;
        if (touches) {
            pointerEvent = touches[0];
        }
        else {
            pointerEvent = (<PointerEvent>originalEvent);
        }

        var pointerPageX: number;
        var pointerPageY: number;
        if (offset || !targetOffset || (typeof pointerEvent.offsetX !== 'undefined')) {
            // pageX/pageY not present in latest Chrome
            if (typeof pointerEvent.pageX !== 'undefined') {
                pointerPageX = pointerEvent.pageX;
                pointerPageY = pointerEvent.pageY;
            }
            else {
                pointerPageX = pointerEvent.x;
                pointerPageY = pointerEvent.y;
            }
        }

        // adjust for zoom
        var zoom = Number($(document.body).css('zoom'));
        if ((zoom !== 1) && !isNaN(zoom)) {
            pointerPageX /= zoom;
            pointerPageY /= zoom;
        }

        if (offset) {
            if (targetOffset) {
                p.x = pointerPageX - targetOffset.x;
                p.y = pointerPageY - targetOffset.y;
            }
            else {
                if (typeof pointerEvent.offsetX !== 'undefined') {
                    p.x = pointerEvent.offsetX;
                    p.y = pointerEvent.offsetY;
                }
                else {
                    p.x = pointerPageX;
                    p.y = pointerPageY;
                }
            }
        }
        else {
            p.x = pointerPageX;
            p.y = pointerPageY;
        }

        return p;
    }

    static normalizePointerEvent(event): Point {
        var p: Point = new Point();
        var pageX: number;
        var pageY: number;
        if (event instanceof TouchEvent) {
            pageX = (event).touches[0].pageX;
            pageY = (event).touches[0].pageY;
        }
        else {
            pageX = event.pageX;
            pageY = event.pageY;
        }
        p.x = pageX;
        p.y =  pageY;
        return p;
    }

    /**
     * Adjusts a point to account for zoom.
     * Needed for Hammer events.
     * PointerUtil.normalizeJQueryEvent handles this for jQuery events.
     */
    static adjustForZoom(p: Point): void {
        var zoom = Number($(document.body).css('zoom'));
        if ((zoom !== 1) && !isNaN(zoom)) {
            p.x /= zoom;
            p.y /= zoom;
        }
    }
}
export = PointerUtil;