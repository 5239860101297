import $ = require('jquery');
import _ = require('underscore');
// import Hammer: HammerStatic = require('hammerjs');
import Velocity = require('libs/velocity/velocity');

import Alert = require('ui/alert');
import ArrayList = require('collections/array-list');
import BindingUtil = require('utils/binding-util');
import Blend = require('graphics/blend');
import BlendPath = require('graphics/blend-path');
import BlendPathOffsetSlider = require('controls/blend-path-offset-slider');
import BlendView = require('graphics/blend-view');
import BlendRenderer = require('graphics/blend-renderer');
import CollectionEvent = require('events/collection-event');
import ColorUtil = require('utils/color-util');
import Direction = require('geom/direction');
import Environment = require('core/environment');
import FeatureManager = require('sblended/feature-manager');
import Gradient = require('graphics/gradient');
import GradientEditor = require('controls/advanced-gradient-editor');
import GradientEditorEvent = require('events/gradient-editor-event');
import GradientEntry = require('graphics/gradient-entry');
import GradientEntryType = require('graphics/gradient-entry-type');
import GradientSelection = require('graphics/gradient-selection');
import GradientUtil = require('utils/gradient-util');
import IList = require('collections/i-list');
import LineEditor = require('controls/line-editor');
import Point = require('geom/point');
import PointerUtil = require('utils/pointer-util');
import PropertyChangeEvent = require('events/property-change-event');
import Rectangle = require('geom/rectangle');
import RGBA = require('graphics/rgba');
import Slider = require('controls/slider');
import Stamper = require('graphics/stamper');
import View = require('ui/view');

class BlendEditor extends View {
    
    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------
    
    static EVENT_PATH_CHOICE: string = 'pathChoice';

    private static ACTIVE_CLASS: string = 'active';
    
    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------
    
    private _attached: boolean = false;
    private _viewA: BlendView;
    private _viewB: BlendView;
    private _rendererA: BlendRenderer;
    private _rendererB: BlendRenderer;
    private _viewAInvalidHorizontal: boolean;
    private _viewAInvalidVertical: boolean;
    private _viewBInvalidHorizontal: boolean;
    private _viewBInvalidVertical: boolean;
    private _viewAInUse: boolean = false;
    private _holder: View;
    private _gradientEditors: LineEditor[] = [];
    private _offsetSlider: BlendPathOffsetSlider;
    private _activeGradientIndex: number = null;
    private _activeThumbIndex: number = null;
    private _pendingLineOffset: number;
    private _pendingStopOffset: number;
    private _orientation: string;
    private _debouncedFadeIn;
    private _watermark: View;
    private _watermarkContext: CanvasRenderingContext2D;

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */ 
    constructor() {
        super();
        
        this._selections.bubbleChanges = false;
        this._selections.on(CollectionEvent.COLLECTION_CHANGE, this._selectionsChangeHandler);

        // wait for rotation to complete
        this._debouncedFadeIn = _.debounce(() => {
            Velocity(this._holder.el, "stop");
            Velocity(this._holder.el,  
                { 
                    opacity: [1]
                },
                {
                    duration: '200ms',
                    easing: 'ease-out',
                }
            );
            this._holder.visible = true;
        }, 500);
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  blend
    //----------------------------------

    private _blend: Blend;

    /**
     * The Blend to manipulate.
     */ 
    set blend(value: Blend) {
        if (this._blend) {
            this._blend.paths.off(CollectionEvent.COLLECTION_CHANGE, this._blendEntriesChangeHandler);
            this._blend.off(PropertyChangeEvent.PROPERTY_CHANGE, this._blendPropertyChangeHandler);
        }
        
        this._blend = value;
        
        var w: number = this._offsetSliderVisible ? this.pixelWidth - this._offsetSlider.pixelWidth : this.pixelWidth;
        var h: number = this.pixelHeight;
        
        if (this._viewAInUse) {
            if (this._viewB) {
                this._viewB.blend = value;
                
                // renderer may have skipped layout while hidden
                this._viewB.width = w;
                this._viewB.height = h;
                
                this._viewB.el.style.visibility = 'inherit';
                Velocity(this._viewB.el, "stop");
                Velocity(this._viewB.el, { opacity: 1 });
                Velocity(this._viewA.el, "stop");
                Velocity(this._viewA.el, { opacity: 0 })
                    .then((elements) => {
                        if (!this._viewAInUse) {
                            this._viewA.el.style.visibility = 'hidden';
                            this._viewA.blend = null;
                        }
                    });   
            }
        }
        else {
            if (this._viewA) {
                this._viewA.blend = value;
                
                // renderer may have skipped layout while hidden
                this._viewA.width = w;
                this._viewA.height = h;
                
                this._viewA.el.style.visibility = 'inherit';
                Velocity(this._viewA.el, "stop");
                Velocity(this._viewA.el, { opacity: 1 });
                Velocity(this._viewB.el, "stop");
                Velocity(this._viewB.el, { opacity: 0 })
                    .then((elements) => { 
                        if (this._viewAInUse) {
                            this._viewB.el.style.visibility = 'hidden';
                            this._viewB.blend = null;
                        }
                    });
            }
        }
        this._viewAInUse = !this._viewAInUse;
        
        if (this._offsetSlider) {
            this._offsetSlider.blend = <any>value;
        }

        if (this._blend && this._blend.paths) {
            this._blend.paths.on(CollectionEvent.COLLECTION_CHANGE, this._blendEntriesChangeHandler);
        }
        this._blendEntriesChangeHandler(null);
        
        if (this._blend) {
            this._blend.on(PropertyChangeEvent.PROPERTY_CHANGE, this._blendPropertyChangeHandler);
        }
        this._blendPropertyChangeHandler(null);

        // clear selections
        this._selections.source = [];
    }
    get blend(): Blend {
        return this._blend;
    }

    //----------------------------------
    //  selections
    //----------------------------------
    
    private _selections: ArrayList<GradientEntry> = new ArrayList<GradientEntry>();
    
    /**
     * List of selected stops.
     */
    get selections(): IList<GradientEntry> {
        return this._selections;
    }


    //----------------------------------
    //  stopsDragLines
    //----------------------------------

    private _stopsDragLines: boolean = true;

    /**
     * Whether or not dragging a stop allows the line 
     * which contains it to be dragged.
     */
    set stopsDragLines(value: boolean) {
        if (this._stopsDragLines !== value) {
            this._stopsDragLines = value;
        }
    }
    get stopsDragLines(): boolean {
        return this._stopsDragLines;
    }

    //----------------------------------
    //  alphaControlsVisible
    //----------------------------------

    private _alphaControlsVisible: boolean = true;

    /**
     * Whether or not to display the alpha controls.
     * Could do this through <code>state</code>, but this provides a simpler hookup for toggle scenario.
     */
    set alphaControlsVisible(value: boolean) {
        if (this._alphaControlsVisible !== value) {
            this._alphaControlsVisible = value;
            this.invalidateProperty('alphaControlsVisible');
        }
    }
    get alphaControlsVisible(): boolean {
        return this._alphaControlsVisible;
    }

    //----------------------------------
    //  colorControlsVisible
    //----------------------------------

    private _colorControlsVisible: boolean = true;

    /**
     * Whether or not to display the color controls.
     * Could do this through <code>state</code>, but this provides a simpler hookup for toggle scenario.
     */
    set colorControlsVisible(value: boolean) {
        if (this._colorControlsVisible !== value) {
            this._colorControlsVisible = value;
            this.invalidateProperty('colorControlsVisible');
        }
    }
    get colorControlsVisible(): boolean {
        return this._colorControlsVisible;
    }
    
    //----------------------------------
    //  offsetSliderVisible
    //----------------------------------
    
    private _offsetSliderVisible: boolean = false;
    
    /**
     * Whether or not the offset slider should be displayed.
     */
    set offsetSliderVisible(value: boolean) {
        if (this._offsetSliderVisible !== value) {
            this._offsetSliderVisible = value
            if (this._offsetSlider) {
                this._offsetSlider.visible = value;     // may want to enable this for desktop mode
            }
        }
    }
    get offsetSliderVisible(): boolean {
        return this._offsetSliderVisible;
    }
    
    //----------------------------------
    //  lineInset
    //----------------------------------
    
    /**
     * Amount to inset the lines.
     * This should match half the size of the lines.
     */
    lineOffset: number = 20;

    //----------------------------------
    //  canvas
    //----------------------------------

    /**
     * The underlying HTMLCanvasElement.
     * Useful for copying the already rendered content.
     */
    get canvas(): HTMLCanvasElement {
        if (this._viewAInUse) {
            return this._viewA.canvas;
        }
        else {
            return this._viewB.canvas;
        }
    }
    
    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    initialize(): void {
        super.initialize();

        this._rendererA = new BlendRenderer();
        this._rendererA.partID = 'rendererA';
        // this._rendererA.width = '100%';
        // this._rendererA.height = '100%';
        
        this._rendererB = new BlendRenderer();
        this._rendererB.partID = 'rendererB';
        // this._rendererB.width = '100%';
        // this._rendererB.height = '100%';
        // this.addChild(this._rendererB);
        
        this._viewA = new BlendView();
        this._viewA.watch = true;
        this._viewA.partID = 'viewA';
        this._viewA.renderer = this._rendererA;
        this._viewA.ownRenderer = true;
        this._viewA.rendererResizeRule = BlendView.RESIZE_RULE_AUTO;
        this._viewAInvalidHorizontal = this._viewAInvalidVertical = true;
        this._viewA.y = 0;
        this.addChild(this._viewA);
        
        this._viewB = new BlendView();
        this._viewB.watch = true;
        this._viewB.partID = 'viewB';
        this._viewB.renderer = this._rendererB;
        this._viewB.ownRenderer = true;
        this._viewB.rendererResizeRule = BlendView.RESIZE_RULE_AUTO;
        this._viewBInvalidHorizontal = this._viewBInvalidVertical = true;
        this._viewB.y = 0;
        this._viewB.el.style.visibility = 'hidden';
        this.addChild(this._viewB);
        
        // re-add so that watermark appears above renderers and below controls
        if (Environment.demoMode || (Environment.context === Environment.CONTEXT_WEB)) {
            this._watermark = new View('canvas');
            // position absolute so appears above blend renderers
            this._watermark.el.style.position = 'absolute';
            // disable pointer events to prevent them from blocking layers below
            this._watermark.el.style.pointerEvents = 'none';
            this._watermark.partID = 'wmark';
            this.addChild(this._watermark);
        }
        
        this._holder = new View();
        this._holder.name = 'controlsHolder';
        this.addChild(this._holder);
        
        this._offsetSlider = new BlendPathOffsetSlider();
        this._offsetSlider.thumbInset = this.lineOffset;
        this._offsetSlider.on(GradientEditorEvent.THUMB_DOWN, this._offsetSliderDownHandler);
        this._offsetSlider.on(GradientEditorEvent.THUMB_TAP, this._offsetSliderTapHandler);

        this.addChild(this._offsetSlider);

        $(this._offsetSlider.el).css('position', 'absolute');
        this._offsetSlider.visible = this._offsetSliderVisible;
        // this._offsetSlider.height = '100%';
        // this._offsetSlider.right = 0;
        
        this.invalidateLayout();
        
        // Note: tap listener added in "attached" to avoid issue with Hammer
        // Hammer doesn't like getting setup on a node that is not attached to the DOM
    }

    /**
     * Called explicitly by BlendViewController.
     */
    attached(): void {
        if (this._attached) {
            return;
        }
        this._attached = true;

        // SBLENDED-229 - debounce to prevent line added and the new slider also getting a track tap event
        var debouncedTapHandler = _.debounce(this._tapHandler, 10);
        
        // Note: tap listener added in "attached" to avoid issue with Hammer
        // Hammer doesn't like getting setup on a node that is not attached to the DOM
        // var hammertime = new Hammer(this._viewA.el, { recognizers: [ [Hammer.Tap] ] });
        // hammertime.on('tap', debouncedTapHandler);
        // reported issue of the hammer tap handler not firing on Mac
        this._viewA.el.addEventListener('click', debouncedTapHandler);

        // hammertime = new Hammer(this._viewB.el, { recognizers: [ [Hammer.Tap] ] });
        // hammertime.on('tap', debouncedTapHandler);
        // reported issue of the hammer tap handler not firing on Mac
        this._viewB.el.addEventListener('click', debouncedTapHandler);
    }

    /**
     * @inheritDoc
     * 
     * Note: Explicitly sizing items for performance and off-DOM support rather than using percentage sizes 
     */
    layout(): void {
        super.layout();
        
        var w: number;
        var h: number;
        
        var vertical: boolean = (this._blend && (this._blend.orientation === Direction.VERTICAL)) ? true : false;
        
        if (this._invalidHorizontalLayout) {
            w = (this._offsetSliderVisible && !vertical) ? this.pixelWidth - this._offsetSlider.pixelWidth : this.pixelWidth;
        }
        if (this._invalidVerticalLayout) {
            h = (this._offsetSliderVisible && vertical) ? this.pixelHeight - this._offsetSlider.pixelHeight : this.pixelHeight;
        }
        
        // conditionally layout rendererA/B based on current visibility
        // will need to layout invisible one when it comes back into view later
        if (this._viewAInUse) {
            if (this._viewA) {
                if (this._invalidHorizontalLayout) {
                    this._viewA.width = w;
                }
                if (this._invalidVerticalLayout) {
                    this._viewA.height = h;
                }
            }
            
            this._viewAInvalidHorizontal = false;
            this._viewAInvalidVertical = false;
            this._viewBInvalidHorizontal = this._viewBInvalidHorizontal || this._invalidHorizontalLayout;
            this._viewBInvalidVertical = this._viewBInvalidVertical || this._invalidVerticalLayout;
        }
        else {
            this._viewAInvalidHorizontal = this._viewAInvalidHorizontal || this._invalidHorizontalLayout;
            this._viewAInvalidVertical = this._viewAInvalidVertical || this._invalidVerticalLayout;
            this._viewBInvalidHorizontal = false;
            this._viewBInvalidVertical = false;
            
            if (this._viewB) {
                if (this._invalidHorizontalLayout) {
                    this._viewB.width = w;
                }
                if (this._invalidVerticalLayout) {
                    this._viewB.height = h;
                }
            }
        }
        
        
        var numGradientEditors: number = this._gradientEditors ? this._gradientEditors.length : 0;
        for (var i: number = 0; i < numGradientEditors; i++) {
            var gradientEditor: View = this._gradientEditors[i];
            
            if (vertical) {
                if (this._invalidVerticalLayout) {
                    gradientEditor.height = h;
                }
                if (this._invalidHorizontalLayout) {
                    var path: BlendPath = this._blend.paths.getItemAt(i);
                    this._positionLineForOffset(gradientEditor, path.offset);
                    // gradientEditor.invalidateHorizontalLayout();
                }
            }
            else {
                if (this._invalidVerticalLayout) {
                    var path: BlendPath = this._blend.paths.getItemAt(i);
                    this._positionLineForOffset(gradientEditor, path.offset);
                    // gradientEditor.invalidateVerticalLayout();
                }
                if (this._invalidHorizontalLayout) {
                    gradientEditor.width = w;
                }
            }
        }

        if (this._offsetSlider) {
            // always need to lay this out even if it is not visible
            // since it is used for line dragging regardless
            
            if (vertical) {
                if (this._invalidHorizontalLayout) {
                    this._offsetSlider.width = w;
                }
            }
            else {
                if (this._invalidVerticalLayout) {
                    this._offsetSlider.height = h;
                }
            }
        }

        
        let watermarkCanvas: HTMLCanvasElement = this._watermark ? this._watermark.el as HTMLCanvasElement : null;
        if (watermarkCanvas) {
            if (Environment.demoMode || (Environment.context === Environment.CONTEXT_WEB)) {
                if (this._invalidHorizontalLayout) {
                    var w: number = (this.pixelWidth > 0) ? this.pixelWidth : 100;
                    $(watermarkCanvas).attr('width', w);
                }
                if (this._invalidVerticalLayout) {
                    var h: number = (this.pixelHeight > 0) ? this.pixelHeight : 100;
                    $(watermarkCanvas).attr('height', h);
                }

                if (!this._watermarkContext) {
                    this._watermarkContext = watermarkCanvas.getContext('2d');
                }

                var canvasW: number = Number($(watermarkCanvas).attr('width'));
                var canvasH: number = Number($(watermarkCanvas).attr('height'));

                let stampRect: Rectangle = new Rectangle();
                stampRect.width = canvasW;
                stampRect.height = canvasH;
                this._watermarkContext.clearRect(0,0, canvasW, canvasH);
                Stamper.stampDiagonalLines(this._watermarkContext, stampRect, 15, 1, 'rgba(255, 255, 255, 0.15)');
            }
        }
    }

    validate(): void {
        super.validate();

        if (this._viewA && this._viewAInUse) {
            this._viewA.validate();
        }
        if (this._viewB && !this._viewAInUse) {
            this._viewB.validate();
        }
    }

    /**
     * @inheritDoc
     */
    validateProperties(changed?: any): void {
        super.validateProperties(changed);

        var allChanged: boolean = (changed === null);

        if (allChanged || changed.colorControlsVisible || changed.alphaControlsVisible) {
            // change opacity of controls
            // transition setup in initialize vs. a separate CSS file
            // could do this via transit
            var alphaControlOpacity: number = this._alphaControlsVisible ? 1 : 0;
            var colorControlOpacity: number = this._colorControlsVisible ? 1 : 0;
            var alphaControlEvents: string = this._alphaControlsVisible ? 'auto' : 'none';
            var colorControlEvents: string = this._colorControlsVisible ? 'auto' : 'none';
            
            if ((this._alphaControlsVisible === false) && (this._colorControlsVisible === false)) {
                $(this._holder.el).css('pointer-events', 'none');
            }
            else {
                $(this._holder.el).css('pointer-events', 'auto');
            }
            
            // change opacity of controls based on their type
            // rely on CSS transition (configured when LineEditor created) to provide fade effect
            // could apply opacity to container for better performance
            // but this would require some reshuffling that may be unnecessary
            var numGradientEditors: number = this._gradientEditors ? this._gradientEditors.length : 0;
            for (var i: number = 0; i < numGradientEditors; i++) {
                var gradientEditor = this._gradientEditors[i];
                var opacity: number;
                var pointerEvents: string;
                var type: string = (<LineEditor>gradientEditor).type;
                switch (type) {
                    case GradientEntryType.TYPE_COLOR:
                    case GradientEntryType.TYPE_COLOR_GRADIENT:
                        opacity = colorControlOpacity;
                        pointerEvents = colorControlEvents;
                        break;
                    case GradientEntryType.TYPE_ALPHA:
                    case GradientEntryType.TYPE_ALPHA_GRADIENT:
                        opacity = alphaControlOpacity;
                        pointerEvents = alphaControlEvents
                        break;
                }
                $(gradientEditor.el).css('opacity', opacity);
                $(gradientEditor.el).css('pointer-events', pointerEvents);
            }
        }

        var selectionType: string;
        if (allChanged || changed.selections) {
            var paths = this._blend ? this._blend.paths : null;
            var numPaths: number = paths ? paths.length : 0;
            for (var i: number = 0; i < numPaths; i++) {
               var gradientEditor: LineEditor = this._gradientEditors[i];
               
               // assign entire list to line; it will ignore entries outside of its scope
               gradientEditor.selectedEntries = this._selections;
            }
        }
    }

    private _addLine(type: string, offset: number = 0.0, stopOffset: number = 0.0): void {
        var numberOfLines: number = this._blend.paths.length + 1;
        var info = FeatureManager.getInstance().info(FeatureManager.FEATURE_NUMBER_OF_LINES, numberOfLines);
        if (!info.available) {
            if (Environment.context === Environment.CONTEXT_BLENDS) {
                Alert.show(info.message, info.title, [{ label: 'No thanks' }, { label: 'Yes, Please!' }], false, true)
                    .then((index: number) => {
                        var accepted: boolean = index === 1 ? true : false;
                        if (accepted) {
                            window.location.href = 'https://www.sblended.com';
                        }
                    });
            }
            else {
                FeatureManager.getInstance().prompt(info);
            }
            return;
        } 
        
        var path: BlendPath = new BlendPath();
        var selected: GradientEntry;
        var gradient = path.gradient = new Gradient();
        if (type === GradientType.ALPHA) {
            // entry.type = GradientEntryType.TYPE_ALPHA_GRADIENT;
            var alphaEntries = gradient.alphaEntries = new ArrayList<GradientEntry>();
            selected = new GradientEntry(GradientEntryType.TYPE_ALPHA, stopOffset, 1.0);
            alphaEntries.addItem(selected);
        }
        else {
            var colorEntries = gradient.colorEntries = new ArrayList<GradientEntry>();
            var color;
            if (this._blend.paths.length > 0) {
                // interpolate color of new stop
                var colorLineNeighbors = GradientUtil.colorLineNeighbors(<any>this._blend, offset);
                var previousColorGradient = colorLineNeighbors.previous.gradient;
                var nextColorGradient = colorLineNeighbors.next.gradient
                var previousColorForOffset: RGBA = GradientUtil.interpolateGradient(previousColorGradient, stopOffset);
                var nextColorForOffset: RGBA = GradientUtil.interpolateGradient(nextColorGradient, stopOffset);
                var c = ColorUtil.interpolateRGBA(previousColorForOffset, nextColorForOffset, offset);
                color = c.color;
            }
            else {
                // adding new color
                color = 0xFF0000;
            }
            selected = new GradientEntry(GradientEntryType.TYPE_COLOR, stopOffset, color);
            colorEntries.addItem(selected);
        }

        path.offset = offset;

        this.blend.paths.addItem(path);
        
        // select stop from new line
        this._selections.source = [selected];
        
        // ensure that the new line is hiddenvar alphaControlOpacity: number = (this._alphaControlsVisible === false) ? 0 : 1;
        this.invalidateProperty('colorControlsVisible');
        this.invalidateProperty('alphaControlsVisible');
    }
    
    private _positionLineForOffset(editor: View, offset: number) {
        var vertical: boolean = (this._blend && (this._blend.orientation === Direction.VERTICAL)) ? true : false;
        
        if (vertical) {
            var w: number = this.pixelWidth - (2 * this.lineOffset);
            // 0 on right for mapping across rotation
            var x = w - (w * offset);
            editor.x = x;        
        }
        else {
            var h: number = this.pixelHeight - (2 * this.lineOffset);
            var y = h * offset;
            editor.y = y;
        }
    }
    
    /**
     * Helper method that needs to be called when starting to drag a line,.
     * Adds the ACTIVE_CLASS to the line so that the normal transform transition can be disabled.
     */
    private _startLineDrag(index: number): void {
        var gradientEditor: LineEditor = this._gradientEditors[index];
        gradientEditor.el.classList.add(BlendEditor.ACTIVE_CLASS);
        document.addEventListener('mouseup', this._gradientEditorTrackUpHandler);
        document.addEventListener('touchend', this._gradientEditorTrackUpHandler);
        document.addEventListener('touchcancel', this._gradientEditorTrackUpHandler);
    }
    
    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------
    
    /**
     * PolymerJS
     */
    created(): void {
        super.created();

        // apply the Slider constructor manually to the instance
        BlendEditor.apply(this);

        this.applyAttributes();
    }
    
    private _blendEntriesChangeHandler = (collectionEvent: CollectionEvent) => {
        var kind: string = collectionEvent ? collectionEvent.kind : null;
        
        if (kind === CollectionEvent.KIND_REMOVE) {
            // TODO: ensure sort; only removing one at a time now anyway
            var sortedLocations: number[] = collectionEvent.locations;
            var len: number = sortedLocations ? sortedLocations.length : 0;
            for (var i: number = len - 1; i >= 0; i--) {
                var idx: number = sortedLocations[i];
                var removedEditors = this._gradientEditors.splice(idx, 1);
                var removedEditor: LineEditor = removedEditors[0];
                //this.removeChild(removedEditor);
                this._holder.removeChild(removedEditor);
                removedEditor.destroy();
            }
        }
        
        var vertical: boolean = (this._blend && (this._blend.orientation === Direction.VERTICAL)) ? true : false;
        
        switch (kind) {
            default:
                var paths: IList<BlendPath> = this._blend ? this._blend.paths : null;
                var numEntries: number = paths ? paths.length : 0;
                for (var i: number = 0; i < numEntries; i++) {
                    var path: BlendPath = paths.getItemAt(i);

                    var gradientEditor: LineEditor = this._gradientEditors[i];
                    if (!gradientEditor) {
                        gradientEditor = new LineEditor();
                        // apply dimension that doesn't change with layout
                        // based on orientation
                        if (vertical) {
                            gradientEditor.vertical = true;
                            gradientEditor.width = 40;
                        }
                        else {
                            gradientEditor.height = 40;
                        }
                        gradientEditor.on(GradientEditorEvent.THUMB_DOWN, this._gradientEditorThumbDownHandler);
                        gradientEditor.on(GradientEditorEvent.THUMB_TAP, this._gradientEditorThumbTapHandler);
                        gradientEditor.on(GradientEditorEvent.THUMB_DOUBLE_TAP, this._gradientEditorThumbDoubleTapHandler);

                        // any down on line triggers drag
                        if (!this._stopsDragLines) {
                            gradientEditor.on(Slider.EVENT_TRACK_DOWN, this._gradientEditorTrackDownHandler);
                        }
                        
                        $(gradientEditor.el).on('mousedown touchstart', this._gradientEditorDownHandler);

                        //gradientEditor.left = 0;
                        //gradientEditor.right = this._offsetSliderVisible ? this._offsetSlider.pixelWidth : 0;
                        //this.addChild(gradientEditor);
                        this._holder.addChild(gradientEditor);
                        this._gradientEditors.push(gradientEditor);
                        
                        // ensure that new editor(s) are sized and positioned
                        this.invalidateLayout();
                    }

                    gradientEditor.gradient = path.gradient;

                    // always reapply ID in case their was deletion / insertion which would otherwise affect the id:index mapping
                    gradientEditor.index = i;
                    gradientEditor.name = i.toString();
                    
                    // associate index with node for easier lookup in event handler
                    //$(gradientEditor.el).data('index', i);
                    (<any>gradientEditor.el).index = i;

                    // associate index with the selections for lookup in the change handler
                    //gradientEditor.selections.id = gradientEditor.id;
                    
                    // position editor along primary axis
                    this._positionLineForOffset(gradientEditor, path.offset);
                }

                // remove extra editors
                var numEditors: number = this._gradientEditors ? this._gradientEditors.length : 0;
                for (i = numEditors-1; i >= numEntries; i--) {
                    var gradientEditor: LineEditor = this._gradientEditors[i];
                    //this.removeChild(gradientEditor);
                    this._holder.removeChild(gradientEditor);
                    gradientEditor.destroy();
                }
                this._gradientEditors.length = numEntries;

                break;
        }
    }
    
    /**
     * Updates editor orientation if necessary.
     */
    private _blendPropertyChangeHandler = (event?: PropertyChangeEvent): void => {
        if (!event || (event && event.propertyName === 'orientation')) {
            // drop out if orientation doesn't change
            var o: string = this._blend ? this._blend.orientation : null;
            if (this._orientation === o) {
                return;
            } 
            this._orientation = o; 
            var vertical: boolean = (o === Direction.VERTICAL) ? true : false;
            
            var blendPaths: IList<BlendPath> = this._blend ? this._blend.paths : null;
            var numEntries: number = blendPaths ? blendPaths.length : 0;
            for (var i: number = 0; i < numEntries; i++) {
                var blendPath: BlendPath = blendPaths.getItemAt(i);

                var gradientEditor: LineEditor = this._gradientEditors[i];
                gradientEditor.vertical = vertical;
                if (vertical) {
                    gradientEditor.width = 40;
                    // reset to account for switching back/forth
                    gradientEditor.y = 0;
                }
                else {
                    gradientEditor.height = 40;
                    // reset to account for switching back/forth
                    gradientEditor.x = 0;
                }
            }
            
            this._holder.visible = false;
            $(this._holder.el).css('opacity', 0);
            
            // invalidate layout here so that editors can be resized / repositioned 
            this.invalidateLayout();
            
            // wait for rotation to complete
            this._debouncedFadeIn();
        }
    }

    /**
     * Handler for when a thumb is pressed.
     * Bubbles as our own.
     */
    private _gradientEditorThumbDownHandler = (event: GradientEditorEvent): void => {
        // set selection to whatever was pressed
        // deselects previous selection in process
        // leverage existing ArrayList to avoid adding additional listeners to individual entries            
        this._selections.source = [event.gradientEntry];
        
        this.trigger(GradientEditorEvent.THUMB_DOWN, event);
    }
    private _gradientEditorThumbTapHandler = (event: GradientEditorEvent): void => {
        // set selection to whatever was pressed
        // deselects previous selection in process
        // leverage existing ArrayList to avoid adding additional listeners to individual entries            
        this._selections.source = [event.gradientEntry];
        
        this.trigger(GradientEditorEvent.THUMB_TAP, event);
    }
    private _gradientEditorThumbDoubleTapHandler = (event: GradientEditorEvent): void => {
        // set selection to whatever was pressed
        // deselects previous selection in process
        // leverage existing ArrayList to avoid adding additional listeners to individual entries            
        this._selections.source = [event.gradientEntry];
        
        this.trigger(GradientEditorEvent.THUMB_DOUBLE_TAP, event);
    }
    private _gradientEditorDownHandler = (event: BaseJQueryEventObject): void => {
        // var index: number = (<any>$(event.currentTarget).data('index'));
        var index: number = (<any>event.currentTarget).index;
        
        // deselect the previous
        if ((typeof this._activeGradientIndex !== 'undefined') && (index !== this._activeGradientIndex)) {
            var previousActiveGradientEditor = this._gradientEditors[this._activeGradientIndex];
            if (previousActiveGradientEditor) {
                previousActiveGradientEditor.state = GradientEditor.STATE_NORMAL;
            }
        }
        this._activeGradientIndex = index;
        var activeGradientEditor = this._gradientEditors[this._activeGradientIndex];
        activeGradientEditor.state = GradientEditor.STATE_ACTIVE;

        // start dragging the line
        if (this._stopsDragLines) {
            var fakeEvent: any = {};
            fakeEvent.target = activeGradientEditor;
            fakeEvent.originalEvent = event;
            this._gradientEditorTrackDownHandler(fakeEvent, true);
        }
    }
    
    /**
     * Handler for when the track of a line is pressed.
     * Allows repositioning of the line.
     * Leverages existing offset slider.
     */
    private _gradientEditorTrackDownHandler = (event, forced: boolean = false): void => {
        // this handler is added regardles <code>stopsDragLines</code>
        // prevent it from firing twice (from downHandler and trackDownHandler)
        if (this._stopsDragLines && !forced) {
            return;
        }
        var gradientEditor: LineEditor = event.target;
        var index: number = gradientEditor.index;
        
        this._startLineDrag(index);
        
        //var targetOffset = $(gradientEditor.el).offset();
        var targetOffset = $(event.originalEvent.target).offset();
        var targetOffsetPoint = { x: targetOffset.left, y: targetOffset.top }; 
        var p = PointerUtil.normalizeJQueryEvent(event.originalEvent, true, targetOffsetPoint);

        var offset: number
        var vertical: boolean = (this._blend && (this._blend.orientation === Direction.VERTICAL)) ? true : false;
        if (vertical) {
            offset = p.x ? (gradientEditor.pixelWidth/2 - p.x) : 0;
        }
        else {
            offset = p.y ? (gradientEditor.pixelHeight/2 - p.y) : 0;
        }
        this._offsetSlider.drag(index, offset);
    }
    
    private _gradientEditorTrackUpHandler = (event: BaseJQueryEventObject): void => {
        var gradientEditor = this._gradientEditors[this._activeGradientIndex];
        if (gradientEditor) {
            gradientEditor.el.classList.remove(BlendEditor.ACTIVE_CLASS);
            document.removeEventListener('mouseup', this._gradientEditorTrackUpHandler);
            document.removeEventListener('touchend', this._gradientEditorTrackUpHandler);
            document.removeEventListener('touchcancel', this._gradientEditorTrackUpHandler);
        }
    }
    
    private _offsetSliderDownHandler = (event, index: number): void => {
        var gradientEditorEvent = new GradientEditorEvent();
        var entry = this._blend.paths.getItemAt(index);
        gradientEditorEvent.gradientEntry = <any>entry;
        
        this._startLineDrag(index);
        
        // TODO: allow line selection
        // currently firing as a result of pressing any thumb since that fires offsetSlider.drag
        //this.trigger(GradientEditorEvent.THUMB_DOWN, gradientEditorEvent);
    }
    private _offsetSliderTapHandler = (event, index: number): void => {
        var gradientEditorEvent = new GradientEditorEvent();
        var entry = this._blend.paths.getItemAt(index);
        gradientEditorEvent.gradientEntry = <any>entry;

        // TODO: allow line selection
        // currently firing as a result of pressing any thumb since that fires offsetSlider.drag
        //this.trigger(GradientEditorEvent.THUMB_DOWN, gradientEditorEvent);
    }

    private _tapHandler = (event): void => {
        // prevent adding new stops when controls hidden
        // tapHandler not added to holder to avoid getting triggered on slider tap
        // would need to expose capturePhase / preventDefault
        if (!this._alphaControlsVisible && !this._colorControlsVisible) {
            return;
        }

        //var position: Point = event.center;
        let position = PointerUtil.normalizePointerEvent(event);
        PointerUtil.adjustForZoom(position);
        var renderer = event.target;
        var jQueryOffset = $(renderer).offset();
        var relativePosition: Point = new Point(position.x - jQueryOffset.left, position.y - jQueryOffset.top);
        
        // TODO: switch based on gradient orientation
        var view = this._viewAInUse ? this._viewA : this._viewB;
        
        var vertical: boolean = (this._blend && (this._blend.orientation === Direction.VERTICAL)) ? true : false;
        if (vertical) {
            this._pendingLineOffset = (relativePosition.x / view.pixelWidth);
            // invert to account for rotation
            // offset of 0 is on right side
            this._pendingLineOffset = 1 - this._pendingLineOffset;
            this._pendingStopOffset = (relativePosition.y / view.pixelHeight);
        }
        else {
            this._pendingLineOffset = (relativePosition.y / view.pixelHeight);
            this._pendingStopOffset = (relativePosition.x / view.pixelWidth);
        }
        
        if (this.alphaControlsVisible && this.colorControlsVisible) {
            this.trigger(BlendEditor.EVENT_PATH_CHOICE, position, relativePosition);
        }
        else if (this.alphaControlsVisible) {
            this._addLine(GradientType.ALPHA, this._pendingLineOffset, this._pendingStopOffset);
        }
        else if (this.colorControlsVisible) {
            this._addLine(GradientType.COLOR, this._pendingLineOffset, this._pendingStopOffset);
        }
     }

    // expose method for BlendViewController to forward choice
    choiceCallback = (opacity?: boolean): void => {
        if (opacity) {
            this._alphaLineHandler();
        }
        else {
            this._colorLineHandler();
        }
    }
    
    private _colorLineHandler = (): void => {
        this._addLine(GradientType.COLOR, this._pendingLineOffset, this._pendingStopOffset);
        var optionDialog = this.getPart('colorOrAlphaOption');
        if (optionDialog) {
            optionDialog.close();
        }
    }
    private _alphaLineHandler = (): void => {
        this._addLine(GradientType.ALPHA, this._pendingLineOffset, this._pendingStopOffset);
        var optionDialog = this.getPart('colorOrAlphaOption');
        if (optionDialog) {
            optionDialog.close();
        }
    }

    /**
     * Handler for when the list of selectedStops changes.
     * Forwards to the individual path editors to reflect. 
     */
    private _selectionsChangeHandler = (event: CollectionEvent): void => {
        // ignore changes to internals of stops
        // these will get picked up by blend change
        if (event && event.kind === 'update') {
            return;
        }
        this.invalidateProperty('selections');
    }

    // /**
    //  * Handler for when the selection within a particular GradientEditor changes.
    //  * Updates our own selections to match.
    //  */
    // private _gradientEditorSelectionChangeHandler = (event: CollectionEvent): void => {
    //     // TODO: this gets called multiple times on a change (deselect and select)
    //     // this is OK as long as the heavy lifting up the chain uses invalidation
        
    //     var selectedIndices: IList<number> = event.target;
        
    //     var id: string = selectedIndices.id;
    //     var index: number = Number(id);

    //     // new selection occurred on particular Gradient
    //     // deselect all others
    //     if (selectedIndices.length > 0) {
    //         //var len: number = this._selections ? this._selections.length : 0;
    //         var len: number = this.gradientStack.entries.length;
    //         for (var i: number = 0; i < len; i++) {
    //             if (i !== index) {
    //                 this._selections.setItemAt(i, null);
    //             }
    //             //var selections = (i === index) ? gradientSelections : null;
    //             //this._selections.setItemAt(i, selections);
    //         }
    //     }
    // }
}

class GradientType {
    static COLOR: string = 'COLOR';
    static ALPHA: string = 'ALPHA';
}

export = BlendEditor;
