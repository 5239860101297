import AdvancedGradientEditor = require('controls/advanced-gradient-editor');
import AlphaGradientEditor = require('controls/alpha-gradient-editor');
import Gradient = require('graphics/gradient');
import GradientEditor = require('controls/gradient-editor');
import GradientEditorEvent = require('events/gradient-editor-event');
import GradientEntry = require('graphics/gradient-entry');
import GradientEntryType = require('graphics/gradient-entry-type');
import IList = require('collections/i-list');
import PropertyChangeEvent = require('events/property-change-event');
import Slider = require('controls/slider');
import View = require('ui/view');

//-- Events
//GradientEditorEvent.THUMB_TAP
//Slider.EVENT_TRACK_DOWN

/**
 * The <code>LineEditor</code> is responsible for editing an entire line within the stack.
 * It delegates to appropriate Gradient controls depending upon its type,
 * either "COLOR", "ALPHA", or "BOTH".
 */
class LineEditor extends View {
    
    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    /**
     * The type of the Line (see TYPE_ constants).
     */
    private _type: string;
    private _multiple: boolean = false;
    private _editor: View;

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
        super();
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  gradient
    //----------------------------------

    private _gradient: Gradient;

    set gradient(value: Gradient) {
        if (this._gradient !== value) {
            this._gradient = value;
            this._gradientChangeHandler();
        }
    }
    get gradient(): Gradient {
        return this._gradient;
    }

    //----------------------------------
    //  pixelHeight
    //----------------------------------

    get pixelHeight(): number {
        if (typeof this.height === 'number') {
            return this.height;
        }
        if (this._editor) {
            var p = this._editor.pixelHeight;
            // console.log("editor pixelHeight: "+p);
            return p;
            //return this._editor.pixelHeight;
        }
        return 0;
    }

    //----------------------------------
    //  index
    //----------------------------------

    /**
     * Index associated with LineEditor for subsequent lookups.
     */
    index: number;

    //----------------------------------
    //  type
    //----------------------------------

    /**
     * The type of entry this editor is currently configured for.
     */
    get type(): string {
        return this._type;
    }
    
    //----------------------------------
    //  selectedEntries
    //----------------------------------
    
    private _selectedEntries: IList<GradientEntry>;
    
    /**
     * List of selected entries.
     * Only those entries that are contained within the Gradient will have an effect.
     * In other words, there is no need to constrain this to just the list of entries
     * within <code>gradient</code>.
     */
    set selectedEntries(value: IList<GradientEntry>) {
        this._selectedEntries = value;
        
        
        this.invalidateProperty('selectedEntries');
    }
    get selectedEntries(): IList<GradientEntry> {
        return this._selectedEntries;
    }
    
    //----------------------------------
    //  vertical
    //----------------------------------
    
    private _vertical: boolean = false;
    
    set vertical(value: boolean) {
        this._vertical = value;
        this.invalidateProperty('vertical');
    }
    get vertical(): boolean {
        return this._vertical;
    }
    
    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * @inheritDoc
     */
    initialize(): void {
        super.initialize();

        // forward "state" changes to internal editor
        // allows them to be styled without needing LineEditor context
        this.on(PropertyChangeEvent.PROPERTY_CHANGE, (propertyChangeEvent: PropertyChangeEvent) => {
            if (propertyChangeEvent && (propertyChangeEvent.propertyName === 'state')) {
                if (this._editor) {
                    this._editor.state = this.state;
                }
            }
        });
        
        this.el.classList.add('line-editor');
    }

    /**
     * @inheritDoc
     */
    layout(): void {
        super.layout();

        if (this._editor) {
            // allow editors to remain their inherent size unless explicit size requested
            if (this.hasHorizontalSize) {
                this._editor.width = this.pixelWidth;
            }
            if (this.hasVerticalSize) {
                this._editor.height = this.pixelHeight;
            }
        }
    }
    
    /**
     * @inheritDoc
     */    
    validateProperties(changed?: any): void {
        super.validateProperties();
        
        var allChanged: boolean = !changed;
        
        if (allChanged || changed.selectedEntries) {
            if (this._editor) {
                // TODO: support advanced gradient here
                (<AlphaGradientEditor>this._editor).selectedValues = this.selectedEntries;
            }
        }
        if (allChanged || changed.vertical) {
            if (this._editor) {
                (<GradientEditor>this._editor).vertical = this.vertical;
                
                // HACK: invert bounds for better switch of orientation (rotation)
                var s: Slider = (<Slider>this._editor);
                if (this.vertical) {
                    s.minimum = 1.0;
                    s.maximum = 0.0;
                }
                else {
                    s.minimum = 0.0;
                    s.maximum = 1.0;
                }
            }
        }
    }
    
    /**
     * Helper to check whether the editor type has really changed.
     * Currently leveraging the same class for multiple types.
     */
    private _editorChanged(oldType: string, newType: string): boolean {
        switch (newType) {
            case GradientEntryType.TYPE_ALPHA:
            case GradientEntryType.TYPE_ALPHA_GRADIENT:
                if ((oldType === GradientEntryType.TYPE_ALPHA) || (oldType === GradientEntryType.TYPE_ALPHA_GRADIENT)) {
                    return false;
                }
                break;
            
            case GradientEntryType.TYPE_COLOR:
            case GradientEntryType.TYPE_COLOR_GRADIENT:
                if ((oldType === GradientEntryType.TYPE_COLOR) || (oldType === GradientEntryType.TYPE_COLOR_GRADIENT)) {
                    return false;
                }
                break;
             case GradientEntryType.TYPE_GRADIENT:
                if (oldType === GradientEntryType.TYPE_GRADIENT) {
                    return false;
                }
        }
        
        return true;
    }
    
    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------
    
    private _gradientChangeHandler = (): void => {
        var gradient: Gradient = this.gradient;

        var type: string = gradient.type;
        if (this._type !== type) {
            var editorChanged: boolean = this._editorChanged(this._type, type);
            this._type = type
            
            if (editorChanged) {
                var editor;
                switch (type) {
                    case GradientEntryType.TYPE_ALPHA:
                        // TODO: differentiate
                    case GradientEntryType.TYPE_ALPHA_GRADIENT:
                        editor = new AlphaGradientEditor();
                        (<GradientEditor>editor).addOnDown = false;
                        (<AlphaGradientEditor>editor).gradient = this.gradient;
                        (<AlphaGradientEditor>editor).selectedValues = this.selectedEntries;
                        (<AlphaGradientEditor>editor).on(Slider.EVENT_THUMB_TAP, this._thumbTapHandler);
                        (<AlphaGradientEditor>editor).on(Slider.EVENT_THUMB_DOUBLE_TAP, this._thumbDoubleTapHandler);
                        (<AlphaGradientEditor>editor).on(Slider.EVENT_THUMB_DOWN, this._thumbDownHandler);
                        (<AlphaGradientEditor>editor).on(Slider.EVENT_TRACK_DOWN, this._trackDownHandler);
                         (<AlphaGradientEditor>editor).thumbInset = 20;
                        break;
                    case GradientEntryType.TYPE_COLOR:
                        // TODO: differentiate
                    case GradientEntryType.TYPE_COLOR_GRADIENT:
                        editor = new GradientEditor();
                        (<GradientEditor>editor).addOnDown = false;
                        (<GradientEditor>editor).gradient = this.gradient;
                        (<GradientEditor>editor).selectedValues = this.selectedEntries;
                        (<GradientEditor>editor).on(Slider.EVENT_THUMB_TAP, this._thumbTapHandler);
                        (<GradientEditor>editor).on(Slider.EVENT_THUMB_DOUBLE_TAP, this._thumbDoubleTapHandler);
                        (<GradientEditor>editor).on(Slider.EVENT_THUMB_DOWN, this._thumbDownHandler);
                        (<GradientEditor>editor).on(Slider.EVENT_TRACK_DOWN, this._trackDownHandler);
                        (<GradientEditor>editor).thumbInset = 20;
                        break;
                    case GradientEntryType.TYPE_GRADIENT:
                        editor = new AdvancedGradientEditor();
                        (<AdvancedGradientEditor>editor).gradient = this.gradient;
                        break;
                    default:
                        console.warn('Unknown gradient type: ' + type);
                        break;
                }
    
                if (this._editor) {
                    this._editor.destroy();
                    this.removeChild(this._editor);
                }
    
                this._editor = editor; 
                this.addChild(editor);
                this.invalidateLayout();
            }
        }

        (<GradientEditor>this._editor).gradient = this.gradient;
    }

    /**
     * Bubbles up stop tap as our own.
     */
    private _thumbTapHandler = (event, index: number): void => {
        var promotedEvent = new GradientEditorEvent();

        promotedEvent.gradientEntry = event.target.values.getItemAt(index);
        this.trigger(GradientEditorEvent.THUMB_TAP, promotedEvent);
    }

    /**
     * Bubbles up stop tap as our own.
     */
    private _thumbDoubleTapHandler = (event, index: number): void => {
        var promotedEvent = new GradientEditorEvent();

        promotedEvent.gradientEntry = event.target.values.getItemAt(index);
        this.trigger(GradientEditorEvent.THUMB_DOUBLE_TAP, promotedEvent);
    }

    /**
     * Bubbles up stop down as our own.
     */
    private _thumbDownHandler = (event, index: number): void => {
        var promotedEvent = new GradientEditorEvent();

        promotedEvent.gradientEntry = event.target.values.getItemAt(index);
        this.trigger(GradientEditorEvent.THUMB_DOWN, promotedEvent);
    }

    /**
     * Bubbles up track down as our own.
     */
    private _trackDownHandler = (event): void => {
        this.trigger(Slider.EVENT_TRACK_DOWN, event);
    }
}

export = LineEditor;