<div class="container">
    <app-nav-bar></app-nav-bar>
    <div class="page">
        <div #scrollerRef style="position: absolute; top: 0px; bottom: 0px; width: 100%; overflow-x: hidden; overflow-y: scroll;">
            <div class="content" [class.loaded]="_loaded" [style.margin-left.px]="_margin" [style.margin-right.px]="_margin">
                <div class="about">
                    Welcome. Have a look around and get inspired by our user-created "Blends". 
                    <br>Or hit "Create" and whip up something new to share with the world.
                </div>
                <div style="margin-top: 20px;">
                    What are Blends?
                    <br>Think supercharged gradients.
                </div>
                <div class="about-blends">
                    <div class="about-blends-text">
                        All Blends are compatible with our <a href="https://www.adobeexchange.com/creativecloud.details.16897.html" style="color: white;">Adobe Add-on</a> and free to download.
                    </div>
                    <a class="exchange-badge" href="https://www.adobeexchange.com/creativecloud.details.16897.html">
                        <img src="/assets/images/available_on_exchange_logo_Large_Black.png">
                    </a>
                </div>
                <mat-divider></mat-divider>
            </div>
            <div class="category-and-search content" [class.loaded]="_loaded" [style.margin-left.px]="_margin" [style.margin-right.px]="_margin">
                <mat-button-toggle-group #group="matButtonToggleGroup" style="font-size: 14px;" [value]="_category" (change)="_categoryToggleHandler($event);">
                    <mat-button-toggle value="featured" aria-label="Text align left">Featured</mat-button-toggle>
                    <mat-button-toggle value="trending" aria-label="Text align center">Trending</mat-button-toggle>
                    <mat-button-toggle value="recent" aria-label="Text align right">Recent</mat-button-toggle>
                </mat-button-toggle-group>
                <mat-form-field class="search-field" style="flex: 1 1 auto;">
                    <mat-label>Search Blends</mat-label>
                    <input matInput type="search" [ngModel]="_searchTerm" (change)="_searchHandler($event)">
                    <button mat-button *ngIf="_searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="_searchHandler('')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div *ngIf="!_loadPending && (_blends.length <= 0)" class="content" [class.loaded]="_loaded" [style.margin-left.px]="_margin" [style.margin-right.px]="_margin">No Blends found!</div>
            <div #collectionViewRef class="unselectable" style="width: 100%; height: 100%; touch-action: pan-x pan-y;"></div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
