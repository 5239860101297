import $ = require('jquery');
import BindingUtil = require('utils/binding-util');
import Direction = require('geom/direction');
import EventDispatcher = require('events/event-dispatcher');
import IView = require('ui/i-view');
import PropertyChangeEvent = require('events/property-change-event');
import StringUtil = require('utils/string-util');
// import TemplateProvider = require('templates/template-provider');

class View extends EventDispatcher implements IView {

    //--------------------------------------------------------------------------
    //
    //  Events
    //
    //--------------------------------------------------------------------------

    static EVENT_DESTROY: string = 'DESTROY';

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------
    
    protected _initialized: boolean = false;
    protected _invalidLayout: boolean = true;
    protected _invalidHorizontalLayout: boolean = true;
    protected _invalidVerticalLayout: boolean = true;
    private _invalidPixelWidth: boolean = true;
    private _invalidPixelHeight: boolean = true;
    private _invalidPosition: boolean = true;
    private _invalidHorizontalPosition: boolean = true;
    private _invalidVerticalPosition: boolean = true;
    private _invalidLayoutProperties: any = null;
    //private _invalidProperties: any = null;
    //private _invalid: boolean;
    private _positionedAbsolute: boolean = false;

    /**
     * Whether or not this View was created from Polymer,
     * in which case it will extend HTMLElement rather than wrapping one.
     */
    private _isPolymer: boolean;

    /**
     * Polymer exposes skin parts here.
     * Use <code>getPart</code> to obtain parts.
     */
    private $: any;
    private _views: { [viewID: string]: View } = {};
    private _transformString: string = '';

    /**
     * Polymer/WebComponents expose attributes here.
     */
    private attributes: any[];
    
    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor(tagName: string = 'div') { 
        //console.log("View constructor *********************");
        super();

        this._tagName = tagName;
        
        // invalidates everything
        this.invalidateProperty(null);
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  name
    //----------------------------------

    /**
     * Name to help identify the View at runtime.
     * The <code>id</code> property is reserved.
     */
    name: string;

    //----------------------------------
    //  partID
    //----------------------------------

    /**
     * An ID to apply to the View.
     * Applied to the DOM node as a data attibute.
     */
    set partID(value: string) {
        $(this.el).attr('data-part-id', value);
    }
    get partID(): string {
        return $(this.el).attr('data-part-id');
    }

    //----------------------------------
    //  el
    //----------------------------------

    private _el: HTMLElement;
    private _$el: JQuery;

    /**
     * 
     */
    set el(value: HTMLElement) {
        if (this._$el) {
            this._$el.replaceWith(value);
        }
        else {
            this._$el = $(value);
        }

        this._el = value;
        this.initialize();
        this.invalidateLayout();
    }
    get el(): HTMLElement {
        if (!this._el) {
            if (this.templateString) {
                this.el = $(this.templateString)[0];
            }
            else if (this.templatePath) {
                console.error("Template paths not supported in new branch yet");
                // var templateString: string = require(this.templatePath);   // Note: the templatePath must be an explicit dependency
                // if (templateString) {
                //     this.el = $(templateString)[0];
                // }
                // else {
                //     throw new Error('Failed to load template at path: ' + this.templatePath);
                // }
            }
            else {
                //this.el = $('<' + this.tagName + '></' + this.tagName + '>')[0];
                this.el = $('<' + (<any>this)._tagName + '></' + (<any>this)._tagName + '>')[0];        // tagName may be conflicting with Polymer
            }
        }

        return this._el;
    }
    
    //----------------------------------
    //  root
    //----------------------------------
    
    private _root: HTMLElement;
    
    /**
     * Gets the root of the View.
     */
    get root(): HTMLElement {
        var root: HTMLElement;
        if (this._isPolymer) {
            if (this._root) {
                return this._root;
            }
            
            if (!(<any>this).shadowRoot) {
                (<any>this.el).createShadowRoot();
            }
            
            var r = this.getPart('root');
            if (r) {
                root = r;
            }
            else {
                if (!(<any>this).shadowRoot.children[0]) {
                    var rootNode = document.createElement('div');
                    (<any>this).shadowRoot.appendChild(rootNode);
                }
                // root = this.templatePath ? (<any>this).shadowRoot.children[0] : (<any>this).shadowRoot;
                
                root = (<any>this).shadowRoot.querySelector('#root');
                if (!root) {
                    var found: boolean = false;
                    var children = (<any>this).shadowRoot.children;
                    var numChildren: number = children ? children.length : 0;
                    for (var i: number = 0; i < numChildren; i++) {
                        root = children[i];
                        var nodeName = root.nodeName.toLowerCase();
                        var nodeType = root.nodeType;
                        if ((nodeType !== Node.COMMENT_NODE) && (nodeName !== 'style')) {
                            break;
                        }
                    }
                }
            }
            this._root = root;
        }
        else {
            root = this.el;
        }
        return root;
    }
    
    //----------------------------------
    //  tagName
    //----------------------------------

    // tagName may be conflicting with Polymer
    private _tagName: string = 'div';
    tagName: string;

    //----------------------------------
    //  className
    //----------------------------------

    private _lastClassName: string;
    private _className: string;

    /**
     * 
     */
    set className(value: string) {
        if (this._className !== value) {
            this._lastClassName = this._className;
            this._className = value;
            this.invalidateProperty('className');
        }
    }
    get className(): string {
        return this._className;
    }

    //----------------------------------
    //  state
    //----------------------------------

    private static _bindableState = BindingUtil.bindable(View, 'state');

    private _lastState: string;
    private _state: string;

    /**
     * Optional state to apply to the View.
     * This will be applied to the <code>element</code> as a class.
     */
    set state(value: string) {
        if (this._state !== value) {
            this._lastState = this._state;
            this._state = value;
            this.invalidateProperty('state');
        }
    }
    get state(): string {
        return this._state;
    }

    //----------------------------------
    //  x
    //----------------------------------

    private _x: number;
    private _hasX: boolean;

    set x(value: number) {
        if (this._x !== value) {
            this._x = value;
            this._hasX = ((typeof this._x !== 'undefined') && (this._x !== null)) ? true : false;
            this.invalidateHorizontalPosition();
        }
    }
    get x(): number {
        return this._x;
    }

    //----------------------------------
    //  y
    //----------------------------------

    private _y: number;
    private _hasY: boolean;

    set y(value: number) {
        if (this._y !== value) {
            this._y = value;
            this._hasY = ((typeof this._y !== 'undefined') && (this._y !== null)) ? true : false;
            this.invalidateVerticalPosition();
        }
    }
    get y(): number {
        return this._y;
    }

    //----------------------------------
    //  width
    //----------------------------------

    private _width: any;
    private _hasWidth: boolean = false;

    set width(value: any) {
        if (this._width === value) {
            return;
        }
        
        this._width = value;
        this._hasWidth = ((typeof this._width !== 'undefined') && (this._width !== null)) ? true : false;
        this.invalidateHorizontalLayout();
    }
    get width(): any {
        return this._width;
    }

    //----------------------------------
    //  pixelWidth
    //----------------------------------

    private _pixelWidth: number;

    get pixelWidth(): number {
        if (typeof this._width === 'number') {
            return this._width;
        }
        if (this._invalidPixelWidth) {
            this._pixelWidth = this._el ? this.el.offsetWidth : 0;
            this._invalidPixelWidth = false;
        }
        return this._pixelWidth;
    }

    //----------------------------------
    //  height
    //----------------------------------

    private _height: any;
    private _hasHeight: boolean = false;

    set height(value: any) {
        if (this._height === value) {
            return;
        }
        
        this._height = value;
        this._hasHeight = ((typeof this._height !== 'undefined') && (this._height !== null)) ? true : false;
        this.invalidateVerticalLayout();
    }
    get height(): any {
        return this._height;
    }

    //----------------------------------
    //  pixelHeight
    //----------------------------------

    private _pixelHeight: number;

    get pixelHeight(): number {
        if (typeof this._height === 'number') {
            return this._height;
        }
        if (this._invalidPixelHeight) {
            this._pixelHeight = this._el ? this._el.offsetHeight : 0;
            this._invalidPixelHeight = false;
        }
        return this._pixelHeight;
    }

    //----------------------------------
    //  left
    //----------------------------------

    private _left: any;
    private _hasLeft: boolean = false;

    set left(value: any) {
        if (this._left !== value) {
            this._left = value;

            this._hasLeft = ((typeof this._left !== 'undefined') && (this._left !== null)) ? true : false;
            if (this._hasLeft && this._hasRight) {
                this.invalidateHorizontalLayout();
            }
            this.invalidateHorizontalPosition();
        }
    }
    get left(): any {
        return this._left;
    }

    //----------------------------------
    //  right
    //----------------------------------

    private _right: any;
    private _hasRight: boolean = false;

    set right(value: any) {
        if (this._right !== value) {
            this._right = value;

            this._hasRight = ((typeof this._right !== 'undefined') && (this._right !== null)) ? true : false;
            this.invalidateHorizontalLayout();      // must invalidate so that position absolute applied
            if (!this._hasLeft) {
                this.invalidateHorizontalPosition();
            }
        }
    }
    get right(): any {
        return this._right;
    }

    //----------------------------------
    //  top
    //----------------------------------

    private _top: any;
    private _hasTop: boolean = false;

    set top(value: any) {
        if (this._top !== value) {
            this._top = value;

            this._hasTop = ((typeof this._top !== 'undefined') && (this._top !== null)) ? true : false;
            if (this._hasTop && this._hasBottom) {
                this.invalidateVerticalLayout();
            }
            this.invalidateVerticalPosition();
        }
    }
    get top(): any {
        return this._right;
    }

    //----------------------------------
    //  bottom
    //----------------------------------

    private _bottom: any;
    private _hasBottom: any = false;

    set bottom(value: any) {
        if (this._bottom !== value) {
            this._bottom = value;

            this._hasBottom = ((typeof this._bottom !== 'undefined') && (this._bottom !== null)) ? true : false;
            this.invalidateVerticalLayout();        // must invalidate so that position absolute applied
            if (!this._hasTop) {
                this.invalidateVerticalPosition();
            }
        }
    }
    get bottom(): any {
        return this._bottom;
    }

    //----------------------------------
    //  measuredWidth
    //----------------------------------

    protected _measuredWidth: number;

    /**
     * The "measured" height, which refers to the height that component
     * would naturally become based on its current properties.
     */
    public get measuredWidth(): number {
        if (typeof this._measuredWidth === 'undefined') {
            // propagate pending changes first
            this.validate();
            this._measureWidth();
        }
        return this._measuredWidth;
    }

    //----------------------------------
    //  measuredHeight
    //----------------------------------

    protected _measuredHeight: number;

    /**
     * The "measured" height, which refers to the height that component
     * would naturally become based on its current properties.
     * Should probably move this to `View`.
     */
    public get measuredHeight(): number {
        if (typeof this._measuredHeight === 'undefined') {
            // propagate pending changes first
            this.validate();
            this._measureHeight();
        }
        return this._measuredHeight;
    }


    //----------------------------------
    //  rotation
    //----------------------------------

    private _rotation: number;
    
    /**
     * Rotation, in degrees.
     */
    set rotation(value: number) {
        if (this._rotation !== value) {
            this._rotation = value;
            this.invalidatePosition();
        }
    }
    get rotation(): number {
        return this._rotation;
    }

    //----------------------------------
    //  scaleX
    //----------------------------------

    private _scaleX: number;

    /**
     * The scale along the x axis, with 1.0 being the normal scale.
     */
    set scaleX(value: number) {
        this._scaleX = value;
        this.invalidatePosition();
    }
    get scaleX(): number {
        return this._scaleX;
    }

    //----------------------------------
    //  scaleY
    //----------------------------------

    private _scaleY: number;

    /**
     * The scale along the y axis, with 1.0 being the normal scale.
     */
    set scaleY(value: number) {
        this._scaleY = value;
        this.invalidatePosition();
    }
    get scaleY(): number {
        return this._scaleY;
    }

    //----------------------------------
    //  visible
    //----------------------------------

    private _visible: boolean;

    /**
     * Whether or not the View is visible or hidden.
     */
    set visible(value: boolean) {
        this._visible = value;
        this.invalidateProperty('visible');
    }
    get visible(): boolean {
        return this._visible;
    }

    //----------------------------------
    //  hasHorizontalSize
    //----------------------------------

    /**
     * Whether or not an explicit horizontal size has been set,
     * either through an explicit "width" property or the combination of "left" and "right".
     */
    get hasHorizontalSize(): boolean {
        if (typeof this.width !== 'undefined') {
            return true;
        }
        else {
            if ((typeof this.left !== 'undefined') && (typeof this.right !== 'undefined')) {
                return true;
            }
        }
        return false;
    }

    //----------------------------------
    //  hasVerticalSize
    //----------------------------------

    /**
     * Whether or not an explicit vertical size has been set,
     * either through an explicit "height" property or the combination of "top" and "bottom".
     */
    get hasVerticalSize(): boolean {
        if (typeof this.height !== 'undefined') {
            return true;
        }
        else {
            if ((typeof this.top !== 'undefined') && (typeof this.bottom !== 'undefined')) {
                return true;
            }
        }
        return false;
    }

    //----------------------------------
    //  templatePath
    //----------------------------------

    /**
     * RequireJS path to the template, such as "text!templates/controls/slider.jst".
     * @deprecated
     */
    templatePath: string;

    templateString: string;
    
    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * Called after <code>element</code> has been set to allow event listeners to be setup.
     */
    initialize(): void {
        this._initialized = true;
    }
    
    /**
     * Marks the layout as invalid in the horizontal direction.
     */
    invalidateHorizontalLayout(prop?: string): void {
        this.invalidateLayout(Direction.HORIZONTAL, prop);
    }
    
    /**
     * Marks the layout as invalid in the vertical direction.
     */
    invalidateVerticalLayout(prop?: string): void {
        this.invalidateLayout(Direction.VERTICAL, prop);
    }
    
    /**
     * Marks the layout as invalid so that <code>layout</code> is called on the next frame.
     * invalidateHorizontalLayout and invalidateVerticalLayout should be used 
     * if it is known that the change only affects one direction. (e.g. setting width should not affect vertical size)
     */
    invalidateLayout(direction?: string, prop?: string): void {
        this._invalidLayout = true;
        
        // if (!direction || (direction === Direction.HORIZONTAL)) {
        if (direction !== Direction.VERTICAL) {
            this._invalidHorizontalLayout = true;
            this._invalidPixelWidth = true;
        }
        // if (!direction || (direction === Direction.VERTICAL)) {
        if (direction !== Direction.HORIZONTAL) {
            this._invalidVerticalLayout = true;
            this._invalidPixelHeight = true;
        }
        
        if (prop) {
            if (this._invalidLayoutProperties !== true) {             // all properties have already been invalidated
                if (!this._invalidLayoutProperties) {
                    this._invalidLayoutProperties = {};
                }
                this._invalidLayoutProperties[prop] = true;
            }
        }
        else {
            this._invalidLayoutProperties = true;
        }
        
        this.invalidate();
    }
    
    /**
     * Helper method to perform minimal layout of a child
     * based on which layout directions have been invalidated on ourselves.
     */
    layoutChild(child: View) {
        if (!child) {
            return;
        }
        if (this._invalidHorizontalLayout) {
            child.invalidateHorizontalLayout();
        }
        if (this._invalidVerticalLayout) {
            child.invalidateVerticalLayout();
        }
        // child.layout();
    }
    /**
     * Helper method to perform layout of skin parts.
     * This is a no-op when the part does not exist.
     * @deprecated
     */
    layoutPart(partID: string) {
        var child = this.getPart(partID);
        if (child) {
            this.layoutChild(child);
        }
    }

    /**
     * Called when the size of the element has changed so that internal items can be resized/repositioned.
     */
    layout(changed?: any): void {
        var allChanged: boolean = !changed;
        
        var $element = $(this.el);
        
        var needsAbsolute: boolean = false;
        if (this._hasRight || this._hasBottom) {
            needsAbsolute = true;
        }
        if (this._hasX || this._hasY) {
            needsAbsolute = true;
        }
        if ((typeof this.width === 'string') || (typeof this.height === 'string')) {
            needsAbsolute = true;
        } 
        // Note: should check styles as well style="width: 100%;" As workaround ensure layout properties are applied as properties
        
        if (needsAbsolute && !this._positionedAbsolute) {
            $element.css('position', 'absolute');
        }

        if (this._invalidHorizontalLayout) {
            if (this._hasLeft && this._hasRight) {
                $element.css('left', this._left);
                $element.css('right', this._right);
                this._invalidPixelWidth = true;
            }
            if (this._hasWidth && !(this._hasLeft && this._hasRight)) {
                $element.css('width', this._width);
            }
        }

        if (this._invalidVerticalLayout) {
            if (this._hasTop && this._hasBottom) {
                $element.css('top', this._top);
                $element.css('bottom', this._bottom);
                this._invalidPixelHeight = true;
            }
            if (this._hasHeight && !(this._hasTop && this._hasBottom)) {
                $element.css('height', this._height);
            }
        }
        
        // always apply explicit size to shadow for better performance through layout boundary
        // light node can still use percentage sizing
        if (this._isPolymer) {
            var root = this.root;
            if (this.invalidateHorizontalLayout) {
                root.style.width = this.pixelWidth + 'px';
            }
            if (this.invalidateVerticalLayout) {
                root.style.height = this.pixelHeight + 'px';
            }
        }
    }

    /**
     * Marks a property as invalid for the validateProperties call on the next frame.
     */
    /*
    invalidateProperty(prop: string) {
        if (prop) {
            if (this._invalidProperties !== true) {             // all properties have already been invalidated
                if (!this._invalidProperties) {
                    this._invalidProperties = {};
                }
                this._invalidProperties[prop] = true;
            }
        }
        else {
            this._invalidProperties = true;
        }
        
        this.invalidate();
    }
    */
    /**
     * Called after one or more properties have been invalidated so that these changes take effect.
     */
    validateProperties(changed?: any): void {
        var allChanged: boolean = !changed;

        var $element = $(this.el);
        if (allChanged || changed.className) {
            $element.removeClass(this._lastClassName);
            $element.addClass(this._className);
        }
        if (allChanged || changed.state) {
            
            $element.removeClass('state-' + this._lastState);
            if ((typeof this._lastState !== 'undefined') && (this._lastState !== null)) {
                $element.addClass('last-state-' + this._lastState);
            }
            $element.addClass('state-' + this._state);
        }

        if (allChanged || changed.visible) {
            if (typeof this.visible === 'undefined') {
                $element.css('visibility', '');
            }
            else {
                var visibility: string = this.visible ? 'visible' : 'hidden';
                $element.css('visibility', visibility);
            }
        }
    }

    /**
     * @private
     */
    /*
    private invalidate(): void {
        if (!this._invalid) {
            this._invalid = true;
            window.requestAnimationFrame(this._animationFrameHandler);
        }
    }*/
    
    invalidateHorizontalPosition(): void {
        this.invalidatePosition(Direction.HORIZONTAL);
    }
    
    invalidateVerticalPosition(): void {
        this.invalidatePosition(Direction.VERTICAL);
    }
    
    /**
     * Invalidates the position of the View so that it is repositioned on the next frame.
     */
    invalidatePosition(direction?: string): void {
        this._invalidPosition = true;
        
        if (!direction || (direction === Direction.HORIZONTAL)) {
            this._invalidHorizontalPosition = true;
        }
        if (!direction || (direction === Direction.VERTICAL)) {
            this._invalidVerticalPosition = true;
        }
        
        this.invalidate();
    }

    validatePosition(): void {
        // convert x, y into translate for performance

        var $element = $(this.el);
        
        var x: number = this._x;
        if (this._invalidHorizontalPosition) {
            if (this._hasLeft || this._hasRight) {
                // hasLeft and hasRight handled in layout()
                if (this._hasLeft) {
                    $element.css('left', this._left);
                }
                else if (this._hasRight) {
                    $element.css('right', this._right);
                }
    
                // left and right supercede x
                x = 0;
            }
            // else if (this._hasX) {
            //     x = this._x;
            //     $element.css('left', 0);
            // }
        }

        var y: number = this._y;
        if (this._invalidVerticalPosition) {
            if (this._hasTop || this._hasBottom) {
                // hasTop and hasBottom handled in layout()
                if (this._hasTop) {
                    $element.css('top', this._top);
                }
                else if (this._hasBottom) {
                    $element.css('bottom', this._bottom);
                }
    
                // top and bottom supercede y
                y = 0;
            }
            // else if (this._hasY) {
            //     y = this._y;
            //     $element.css('top', 0);
            // }
        }

        var transformString: string = '';
        if (this._invalidHorizontalPosition || this._invalidVerticalPosition) {
            if (x || y) {
                if (isNaN(x)) {
                    x = 0;
                }
                if (isNaN(y)) {
                    y = 0;
                }
                transformString = 'translate(' + x + 'px, ' + y + 'px)';
            }
        }
        if (typeof this._rotation !== 'undefined') {
            transformString += 'rotate(' + this._rotation + 'deg)';
        }
        if (typeof this._scaleX !== 'undefined') {
            transformString += 'scaleX(' + this._scaleX + ')';
        }
        if (typeof this._scaleY !== 'undefined') {
            transformString += 'scaleY(' + this._scaleY + ')';
        }

        if (this._transformString !== transformString) {
            this._transformString = transformString;
            $element.css('transform', transformString);
        }
    }

    /**
     * Invalidates both the `measuredWidth` and `measuredHeight` properties
     * so that they will be calculated again when requested.
     */
    invalidateMeasuredSize(): void {
        this.invalidateMeasuredWidth();
        this.invalidateMeasuredHeight();
    }

    /**
     * Invalidates the `measuredWidth` property so that
     * it will be calculated again when requested.
     */
    invalidateMeasuredWidth(): void {
        this._measuredWidth = undefined;
    }

    /**
     * Invalidates the `measuredHeight` property so that
     * it will be calculated again when requested.
     */
    invalidateMeasuredHeight(): void {
        this._measuredHeight = undefined;
    }

    /**
     * A component should override this method to determine the component's
     * "natural" height based on current properties (e.g. font size)
     */
    protected _measureWidth(): void {
        this._measuredWidth = 0;
    }

    /**
     * A component should override this method to determine the component's
     * "natural" height based on current properties (e.g. font size)
     */
    protected _measureHeight(): void {
        this._measuredHeight = 0;
    }


    /**
     * Validates any pending changes.
     * This may have a high performance cost and should not need to be called manually in most scenarios.
     */
    validate(): void {
        super.validate();

        //if (this._invalid) {
        //    if (this._invalidProperties) {
         //       this.validateProperties(this._invalidProperties);
         //       this._invalidProperties = null;
         //   }

            if (this._invalidPosition) {
                this.validatePosition();
                this._invalidPosition = this._invalidHorizontalPosition = this._invalidVerticalPosition = false;
            }

            if (this._invalidLayout) {
                this.layout();
                this._invalidLayout = this._invalidHorizontalLayout = this._invalidVerticalLayout = false;
            }
            //
            //this._invalid = false;
        //}
    }

    /**
     * @inheritDoc
     */
    destroy(): void {
        this.trigger(View.EVENT_DESTROY);

        // must wait until after triggering for super.destroy since it clean up event listeners
        super.destroy();

        var views = this._views;
        var viewID: string;
        for (viewID in views) {
            var view: View = views[viewID];
            view.destroy();
        }
    }

    addChild(child: IView): void {
        $(this.root).append(child.el);
    }
    removeChild(child: IView): void {
        $(child.el).remove();
    }

    /**
     * Removes all children from the View.
     */
    empty(): void {
        $(this.el).empty();
    }
    
    /**
     * Function for obtaining a skin part.
     * Abstracts the details of the underlying technology (e.g. Polymer).
     */
    /* protected */ getPart(partID: string, promoteToView: boolean = false): any {
        var part;
        if (this.$) {
            part = this.$[partID];
            
            // part added dynamically
            // avoid infinite loop with root lookup
            if (!part && (partID !== 'root')) {
                part = this.root.querySelector('#' + partID);
            }
        }
        else {
            part = $(this.el).find('[data-part-id="' + partID + '"]')[0];
        }

        if (promoteToView) {
            var view: View = this._views[partID];
            if (!view) {
                view = this._views[partID] = new View();
                view.el = part;
            }
            return view;
        }

        return part;
    }
    
    /**
     * Function for obtaining a skin parts by class.
     * Abstracts the details of the underlying technology (e.g. Polymer).
     */
    /* protected */ getParts(classID: string): any {
        var parts;
        
        var shadowRoot: HTMLElement = (<any>this).shadowRoot;
        if (shadowRoot) {
            parts = shadowRoot.querySelectorAll('[data-part-class="' + classID + '"]');
        }
        else {
            parts = $(this.el).find('[data-part-class="' + classID + '"]');
        }

        return parts;
    }

    /**
     * Applies element attributes as properties.
     * Ideally this would happen automatically with Polymer,
     * but only published attributes are applied and published attributes override existing getter/setter
     * https://github.com/Polymer/polymer/blob/master/src/instance/attributes.js
     * Hopefully they'll simplify this in the future. 
     */
    /* protected */ applyAttributes(): void {
        var scope: any = {};
        scope.bindPattern = /{{.+}}/;

        var attributes = this.attributes;
        var numAttributes: number = attributes ? attributes.length : 0;
        for (var i: number = 0; i < numAttributes; i++) {
            var attribute = attributes[i];
            var value = attribute.value;
            var name = attribute.name;
            
            // attributes with camelCasing will automatically be converted to lowercase
            // use dash-case in attributes and this function will convert back to our expected camelCase names
            name = StringUtil.dashToCamelCase(name);
            
            // same as attributeToProperty
            // filter out 'mustached' values, these are to be
            // replaced with bound-data and are not yet values
            // themselves
            if (value && value.search(scope.bindPattern) >= 0) {
                continue;
            }

            // different from attributeToProperty
            // attributeToProperty relies on initial values of items for type coercion
            try {
                this[name] = this.deserializeAttribute(value);
            }
            catch (error) {
                console.error('Failed to apply value of: ', value, ' to attribute: ' + name, this)
            }
        }
        
        
    }

    /**
     * Deserializes an attribute value (e.g. "5") to an appropriate value.
     * This is a pretty weak implementation without runtime type introspection.
     * Values are greedily converted to booleans and numbers so use with caution when expecting strings.
     */
    /* protected */ deserializeAttribute(value: string): any {
        if (value === 'true') {
            return true;
        }
        else if (value === 'false') {
            return false;
        }

        var num = Number(value);
        if (!isNaN(num)) {
            return num;
        }

        return value;
    }

    //trigger(eventType: string, ...args): void {
    //    var applyArgs = [eventType].concat(args);
    //    super.trigger.apply(this, applyArgs);

    //    // TODO: type as PolymerElement
    //    if ((<any>this)._elementPrepared) {
    //        var e = new CustomEvent(eventType);
    //        e.cancelable = true;
    //        e.bubbles = false;
    //        (<any>this).dispatchEvent(e);
    //    }
    //}

    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------

    /**
     * Called from Polymer JS.
     */
    created(): void {
        this._isPolymer = true;
        this._el = <any>this;
    }
    
    /**
     * PolymerJS
     */
    attributeChanged(name: string, oldValue: any, newValue: any): void {
        // existence of an attribute with empty value suggests a value of true (e.g. "hidden" attribute set)
        if (newValue === '') {
            newValue = true;
        }
        var value = this.deserializeAttribute(newValue);
        this[name] = value;
    }
    
    // TODO: support at this level; running into an issue with initialization; presumably timing-related
    // look at XYSlider which deals with camelCase loss from DOM attributes
    // /**
    //  * Polymer data bindings.
    //  */
    // bind(name, observable, oneTime) {
    //     if (observable.setValue) {
    //         this.on(PropertyChangeEvent.PROPERTY_CHANGE, (propertyChangeEvent: PropertyChangeEvent) => {
    //             observable.setValue(propertyChangeEvent.value);
    //         });
    //     }

    //     observable.open((newValue, oldValue) => {
    //         this[name] = newValue;
    //     });
    // }
}
export = View;