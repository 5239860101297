import $ = require('jquery');

import BindingUtil = require('utils/binding-util');
import ColorUtil = require('utils/color-util');
import ISliderThumb = require('controls/i-slider-thumb');
import PropertyChangeEvent = require('events/property-change-event');
import RGBA = require('graphics/rgba');
import SliderThumb = require('controls/slider-thumb');
import View = require('ui/view');

const templateString = `
    <div class="alphaThumb thumb part-thumb">
        <div class="inner">
            <!--<div class="background"></div>-->
            <div data-part-id="alpha" class="alpha"></div>
        </div>
    </div>
`;

class AlphaThumb extends SliderThumb implements ISliderThumb {

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    private _$alphaElement: JQuery;

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
        super();

        // requires amd-dependency above
        //this.templatePath = 'text!templates/controls/alpha-thumb.jst';
        this.templateString = templateString;
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  alpha
    //----------------------------------

    private _alpha: number;

    /**
     * The Alpha value, from 0 (transparent) to 1 (opaque).
     */
    set alpha(value: number) {
        //if (this._alpha) {
        //    this._rgba.off(PropertyChangeEvent.PROPERTY_CHANGE, this._rgbaChangeHandler);
        //}

        this._alpha = value;
        //this._rgba.on(PropertyChangeEvent.PROPERTY_CHANGE, this._rgbaChangeHandler);

        this.invalidateProperty('alpha');
    }
    get alpha(): number {
        return this._alpha;
    }

    //----------------------------------
    //  selected
    //----------------------------------

    private _selected: boolean = false;

    /**
     * Whether or not the chip is selected;
     */
    set selected(value: boolean) {
        if (this._selected !== value) {
            this._selected = value;
            this.invalidateProperty('selected');
        }
    }
    get selected(): boolean {
        return this._selected;
    }

    //-- ISliderThumb

    //----------------------------------
    //  index
    //----------------------------------
    
    private static _bindableIndex = BindingUtil.bindable(AlphaThumb, 'index');

    /**
     * @inheritDoc
     */
    index: number;

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     *
     */
    initialize(): void {
        super.initialize();

        this._$alphaElement = $(this.el).find('[data-part-id="alpha"]');
    }

    /**
     * @inheritDoc
     */
    validateProperties(changed?: any): void {
        super.validateProperties(changed);

        var allChanged: boolean = (changed === null);

        if (allChanged || changed.alpha) {
            // this._$alphaElement.css('opacity', this._alpha);

            // use color from white to black rather than opacity for performance            
            var color: number = ColorUtil.interpolateColor(0xFFFFFF, 0x000000, this.alpha);
            var hex = "#" + ColorUtil.colorToHex(color);
            this._$alphaElement.css('background-color', hex);
        }
        if (allChanged || changed.selected) {
            $(this.el).toggleClass('selected', this._selected);
        }
    }

    /**
     * @inheritDoc
     */
    destroy(): void {
        super.destroy();
    }
}
export = AlphaThumb;