import { Component, ViewChild, Input, AfterViewInit } from '@angular/core';

import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import _ = require('underscore');
import $ = require('jquery');

import {AnimationBuilder} from "@angular/animations";
import {
  query,
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

import ArrayList = require('collections/array-list');
import Blend = require('graphics/blend');
import BlendEditor = require('controls/blend-editor');
import BlendInfo = require('graphics/blend-info');
import BlendPath = require('graphics/blend-path');
import CollectionEvent = require('events/collection-event');
import Direction = require('geom/direction');
import Gradient = require('graphics/gradient');
import GradientEditorEvent = require('events/gradient-editor-event');
import GradientEntry = require('graphics/gradient-entry');
import GradientEntryType = require('graphics/gradient-entry-type');
import GradientUtil = require('utils/gradient-util');
import IList = require('collections/i-list');
import Preferences = require('sblended/preferences');
import PropertyChangeEvent = require('events/property-change-event');
// import StopDetails = require('controls/stop-details');
import View = require('ui/view');

enum DetailsState {
  open = 'open',
  closed = 'closed',
  collapsed = 'collapsed'
}

@Component({
  selector: 'blend-view-controller',
  templateUrl: './blend-view-controller.component.html',
  styleUrls: ['./blend-view-controller.component.scss'],
  animations: [
    trigger('_controlsState', [
      state('visible', style({
        opacity: 1.0
      })),
      state('hidden', style({
        opacity: 0.3
      })),
      transition('* <=> *', animate('200ms ease-out'))
    ]),
    trigger('_leftState', [
      state('horizontal', style({
        opacity: 0.0,
        transform: 'rotate(-180deg)'
      })),
      state('vertical', style({
        opacity: 1.0,
        transform: 'rotate(0deg)'
      })),
      transition('* <=> *', animate('200ms ease-out'))
    ]),
    trigger('_rightState', [
      state('horizontal', style({
        opacity: 1.0,
        transform: 'rotate(0deg)'
      })),
      state('vertical', style({
        opacity: 0.0,
        transform: 'rotate(180deg)'
      })),
      transition('* <=> *', animate('200ms ease-out'))
    ]),
    trigger('_editorState', [
      state('open', style({
        bottom: '220px'
      })),
      state('collapsed', style({
        bottom: '90px'
      })),
      state('closed', style({
        bottom: '0px'
      })),
      transition('* => open', animate('300ms 100ms ease-out')),
      transition('* => closed', animate('200ms ease-out'))
    ]),
    trigger('_detailsState', [
      state('open', style({
        transform: 'translateY(-220px)'
      })),
      state('closed', style({
        transform: 'translateY(0px)'
      })),
      state('collapsed', style({
        transform: 'translateY(-220px)'
      })),
      // only animate between closing states
      // animating between open/collapsed interferes with internal animation 
      // of StopDetails for reasons unknown
      transition('* => closed', animate('300ms 100ms ease-out')),
      transition('closed => *', animate('300ms ease-out')),
    ])
  ]
})
export class BlendViewControllerComponent extends View implements AfterViewInit {

  //--------------------------------------------------------------------------
  //
  //  Class constants
  //
  //--------------------------------------------------------------------------

  private static CONTROLS_TYPE_COLOR: string = 'color';
  private static CONTROLS_TYPE_ALPHA: string = 'alpha';
  private static CONTROLS_TYPE_BOTH: string = 'both';
  private static CONTROLS_TYPE_NONE: string = 'none';

  //--------------------------------------------------------------------------
  //
  //  Constructor
  //
  //--------------------------------------------------------------------------

  constructor(private _builder: AnimationBuilder, private _snackBar: MatSnackBar) {
    super();

    let blendInfo: BlendInfo = new BlendInfo();
    blendInfo.name = '';
    blendInfo.blend = GradientUtil.randomBlend();
    this.blendInfo = blendInfo;

    // throttle to cut down on repaints
    // disable leading edge throttle to avoid dismissing details on stop removal
    // another stop will typically be auto-selected immediately afterwards
    this._updateDetails = _.throttle(this._updateDetails, 20, {leading: false});
  }

  initialize(): void {
    super.initialize();

    let editorHolderElement = this.editorHolder.nativeElement;
    let editor = this._editor = new BlendEditor();
    editor.el;  // HACK: force initialize
    editor.width = '100%';
    editor.height = '100%';
    editor.blend = this._blendInfo.blend;
    editorHolderElement.appendChild(editor.el); 

    editor.on(BlendEditor.EVENT_PATH_CHOICE, this._pathChoiceHandler);
    
    editor.on(GradientEditorEvent.THUMB_DOWN,(event: GradientEditorEvent) => {
      // open details on stop tap
      if (editor.selections) {
        var gradientEntry = editor.selections.getItemAt(0);
        var type: string = gradientEntry.type;
        this._enableStopDetails(type);
      }
    });

    editor.on(GradientEditorEvent.THUMB_DOUBLE_TAP, (event: GradientEditorEvent) => {
      if (event && event.gradientEntry && (event.gradientEntry.type === GradientEntryType.TYPE_ALPHA)) {
          return;
      }

      if (window.creativeCloudExtension) {
          var color = (event && event.gradientEntry) ? event.gradientEntry.color : null;
          window.creativeCloudExtension.showColorPicker(color)
              .then(
                  (color) => {
                    let selections: IList<GradientEntry> = this._editor ? this._editor.selections : null;
                    var numSelected: number = selections ? selections.length : 0;
                    for (var i: number = 0; i < numSelected; i++) {
                      let entry = selections.getItemAt(i);
                      entry.color = color;
                    }
                  }, 
                  () => {
                      // ignore cancel
                  }
              );
      }
  });

    // listen for removal of stops
    editor.selections.on(CollectionEvent.COLLECTION_CHANGE, this._selectionsChangeHandler);

    if (this._attached) {
      editor.attached();
    }

    this.detailsState = this.detailsState;
  }

  //--------------------------------------------------------------------------
  //
  //  Variables
  //
  //--------------------------------------------------------------------------

  @ViewChild('randomizeIcon') randomizeIcon;
  @ViewChild('menu') menu;
  @ViewChild(MatMenuTrigger) controlsTypeTrigger: MatMenuTrigger;
  @ViewChild('controlsTypeMenuManual') controlsTypeMenuManual;
  @ViewChild('pathTypeTrigger') pathTypeTrigger: MatMenuTrigger;
  @ViewChild('pathTypeMenu') pathTypeMenu;
  @ViewChild('pathTypeMenuPositioner') pathTypeMenuPositioner;
  @ViewChild('editorHolder') editorHolder;
  @ViewChild('editorSizer') editorSizer;
  @ViewChild('stopDetails') stopDetails;

  _controlsState: string = 'visible';

  _detailsState: string ='closed';

  _orientation: string = 'horizontal';

  _name: string = '';

  private _attached: boolean = false;
  private _editor: BlendEditor;
  private _selectedPaths: BlendPath[]; 

  //--------------------------------------------------------------------------
  //
  //  Properties
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  //  blendInfo
  //----------------------------------

  private _blendInfo: BlendInfo;

  /**
   * The Blend info on which to interact.
   */
  set blendInfo(value: BlendInfo) {
    // if (this._blendInfo !== value) {
      if (this._blendInfo) {
        this._blendInfo.off(PropertyChangeEvent.PROPERTY_CHANGE, this._blendChangeHandler);
      }
      this._blendInfo = value;
      if (this._editor) {
        this._editor.blend = value.blend;
        this._editor.validate();
      }
      if (this._blendInfo) {
        this._blendInfo.on(PropertyChangeEvent.PROPERTY_CHANGE, this._blendChangeHandler);
      }
      this._blendChangeHandler();
    // }
  }
  get blendInfo(): BlendInfo {
    return this._blendInfo;
  }

  //----------------------------------
  //  canvas
  //----------------------------------

  /**
   * The underlying HTMLCanvasElement.
   * Useful for copying the already rendered content.
   */
  get canvas(): HTMLCanvasElement {
    return this._editor ? this._editor.canvas : null;
}

  //----------------------------------
  //  controlsType
  //----------------------------------

  _controlsType: string =BlendViewControllerComponent.CONTROLS_TYPE_COLOR;

  /**
   * Type of controls to display (opacities/colors)
   */
  @Input()
  set controlsType(value: string) {
    if (this._controlsType !== value) {
      this._controlsType = value;
      this.invalidateProperty('controlsType');
      
      let typeString: string;
      switch (this.controlsType) {
          case BlendViewControllerComponent.CONTROLS_TYPE_ALPHA:
              typeString = 'Opacities';            // TODO: localize
              break;
          case BlendViewControllerComponent.CONTROLS_TYPE_BOTH:
              typeString = 'Colors & Opacities';   // TODO: localize
              break;
          case BlendViewControllerComponent.CONTROLS_TYPE_COLOR:
          default:
              typeString = 'Colors';               // TODO: localize
              break;
      }

      // show controls if they aren't already visible
      this._controlsState = 'visible';
      this.controlsVisible = true;

      let message: string = 'Mode changed to: ' + typeString;   // TODO: localize
      this._snackBar.open(message, null, {
        duration: 1000,
        panelClass: 'snackbar'
      });
    }
  }
  get controlsType(): string {
    return this._controlsType;
  }

  //----------------------------------
  //  controlsVisible
  //----------------------------------

  private _controlsVisible: boolean = true;

  /**
   * Whether or not to display the alpha and color controls.
   */
  set controlsVisible(value: boolean) {
      if (this._controlsVisible !== value) {
          this._controlsVisible = value;
          this.invalidateProperty('controlsVisible');
      }
  }
  get controlsVisible(): boolean {
      return this._controlsVisible;
  }

  //----------------------------------
  //  nameError
  //----------------------------------

  private _nameError: string;
  public _errorStateMatcher: ErrorStateMatcher; // public for template

  public set nameError(value: string) {
    this._nameError = value;

    if (value) {
        this._errorStateMatcher = { isErrorState: () => true };
    }
    else {
        this._errorStateMatcher = { isErrorState: () => false };
    }
  }
  public get nameError(): string {
    return this._nameError;
  }

  set detailsState(value: DetailsState) {
    if (!this._initialized) {
      return;
    }

    let previousDetailsState = this._detailsState;
    this._detailsState = value;

    switch (this._detailsState) {
      case DetailsState.closed:
        this.editorSizer.nativeElement.style.bottom = '0px';
        break;
      case DetailsState.open:
        this.editorSizer.nativeElement.style.bottom = '220px';
        this.stopDetails.collapsed = false;
        break;
      case DetailsState.collapsed:
        this.editorSizer.nativeElement.style.bottom = '90px';
        this.stopDetails.collapsed = true;
        break;
    }

    let editorGrowing: boolean;
    if ((this._detailsState === DetailsState.closed) || ((this._detailsState === DetailsState.collapsed) && (previousDetailsState === DetailsState.open))) {
      editorGrowing = true;
    }
    if (editorGrowing) {
      this._editor.height = this.editorSizer.nativeElement.offsetHeight;
    }
    else {
      // delay to allow stop details to be slid up completely before changing the editor height
      // since overflow is explicitly disabled for a significant performance improvement
      // the transitioning height of the internal renderer is clipped immediately to smaller height
      // attempted transitioning height of outer elements which have overflow hidden also 
      // to avoid jump but this resulted in strange behavior
      setTimeout(() => {
        this._editor.height = this.editorSizer.nativeElement.offsetHeight;
      }, 300);
    }
  }

  /**
   * Enables (and presents) the details for the selected stop(s).
   * @param type      @see GradientEntryType
   */
  private _enableStopDetails(type: string): void {
    this.detailsState = Preferences.getInstance().detailsCollapsed ? DetailsState.collapsed : DetailsState.open;
  }

  /**
   * @inheritDoc
   */
  validateProperties(changed?: any): void {
    super.validateProperties(changed);

    const allChanged: boolean = (changed === null);

    if (allChanged || changed.controlsType || changed.controlsVisible) {
      if (this._editor) {
        if (this.controlsVisible) {
          this._editor.alphaControlsVisible = (this.controlsType === BlendViewControllerComponent.CONTROLS_TYPE_BOTH) || (this.controlsType === BlendViewControllerComponent.CONTROLS_TYPE_ALPHA);
          this._editor.colorControlsVisible = (this.controlsType === BlendViewControllerComponent.CONTROLS_TYPE_BOTH) || (this.controlsType === BlendViewControllerComponent.CONTROLS_TYPE_COLOR);
        }
        else {
          this._editor.alphaControlsVisible = this._editor.colorControlsVisible = false;
        }
      }
    }
  }

  /**
   * @inheritDoc
   */
  layout(): void {
    super.layout();

    if (this._editor) {
      if (this._invalidVerticalLayout) {
        this._editor.height = this.editorSizer.nativeElement.offsetHeight;
      }
      if (this._invalidHorizontalLayout) {
        this._editor.invalidateHorizontalLayout();
        this._editor.layout();
      }
    }

    if (this.stopDetails) {
      this.layoutChild(this.stopDetails);
    }
  }

  ngAfterViewInit(): void {
    this._attached = true;
    if (this._editor) {
      this._editor.attached();
    }
  }

  /**
   * Handler for when the list of selectedStops changes.
   * Disables/hides the Stop Details if no stops are selected.
   * Determines and caches the list of selected lines based on these stops.
   */
  private _updateDetails = (): void => {
    let selections: IList<GradientEntry> = this._editor ? this._editor.selections : null;
    var numSelected: number = selections ? selections.length : 0;
    if (numSelected === 0) {
      this.detailsState = DetailsState.closed;
    }
    
    // determine which path(s) are also selected based on whether they contain said stops
    var selectedPaths: BlendPath[] = [];
    var blend = this._blendInfo ? this._blendInfo.blend : null;
    var paths = blend ? blend.paths : null;
    var numPaths = paths ? paths.length : 0;
    for (var i: number = 0; i < numSelected; i++) {
        var stop: GradientEntry = selections.getItemAt(i);
        
        // find the path to which the stop belongs
        for (var j: number = 0; j < numPaths; j++) {
            var path = paths.getItemAt(j);
            var gradient = path.gradient;
            
            var stopInPath: boolean = false;
            var k: number;
            var entry;
            
            var colorEntries = gradient ? gradient.colorEntries: null;
            var numColorEntries: number = colorEntries ? colorEntries.length : 0;
            for (k = 0; k < numColorEntries; k++) {
                entry = colorEntries.getItemAt(k);
                if (entry === stop) {
                  stopInPath = true;
                  break;
                }
            }
            
            if (!stopInPath) {
                var alphaEntries = gradient ? gradient.alphaEntries: null;
                var numAlphaEntries: number = alphaEntries ? alphaEntries.length : 0;
                for (k = 0; k < numAlphaEntries; k++) {
                    entry = alphaEntries.getItemAt(k);
                    if (entry === stop) {
                      stopInPath = true;
                      break;
                    }
                }
            }
            
            if (stopInPath && (selectedPaths.indexOf(path) === -1)) {
              selectedPaths.push(path);
            }
        }
    }
    this._selectedPaths = selectedPaths;

    // stop could not be found
    // this occurs when stop is removed from path
    // and selections have not yet been updated
    if (selectedPaths.length < 1) {
      numSelected = 0;
    }
    
    // update stop details to reflect current selection
    var selectedColor;
    var selectedAlpha: number;
    var selectedOffset: number;
    var selectedType: string;
    var selectedPathOffset: number;
    if (numSelected === 1) {
        var selectedStop: GradientEntry = selections.getItemAt(0);
        selectedColor = (selectedStop.type === GradientEntryType.TYPE_COLOR) ? selectedStop.color : null;
        selectedAlpha = (selectedStop.type === GradientEntryType.TYPE_ALPHA) ? selectedStop.alpha : null;
        selectedOffset = selectedStop.offset;
        selectedType = selectedStop ? selectedStop.type : null;
        
        // can assume one path here
        var selectedPath: BlendPath = selectedPaths[0];
        selectedPathOffset = selectedPath.offset;
    }
    else if (numSelected <= 0) {
      // another selection should be made shortly
      // or the details will be hidden completely
      return;
    }
    else if (numSelected > 1) {
        // TODO: support multi-select
        console.warn("Multi-select not supported yet");
    }
    
    if (this._detailsState !== DetailsState.closed) {
      let stopDetails = this.stopDetails;
        
      // prevent feedback loop from bindings to Stop Details
      // this._updatingStopDetails = true;
      stopDetails.stopType = selectedType;
      if (!selectedStop || (selectedStop.type === GradientEntryType.TYPE_ALPHA)) {
          stopDetails.alpha = selectedAlpha;  
      }
      if (!selectedStop || (selectedStop.type === GradientEntryType.TYPE_COLOR)) {
          stopDetails.color = selectedColor;
      }

      let rotated: boolean = (this._blendInfo && (this._blendInfo.blend.orientation === Direction.VERTICAL)) ? true : false;

      if (rotated) {
        stopDetails.horizontal = Math.round((1.0 - selectedPathOffset) * 100);
        stopDetails.vertical = Math.round(selectedOffset * 100); 
      }
      else {
        stopDetails.horizontal = Math.round(selectedOffset * 100);
        stopDetails.vertical = Math.round(selectedPathOffset * 100);
      }
      // this._updatingStopDetails = false;
    }
  }

  //--------------------------------------------------------------------------
  //
  //  Event handlers
  //
  //--------------------------------------------------------------------------

  _randomizeHandler = () => {
    const factory = this._builder.build([
       style({ transform: 'rotateZ(0deg)' }),
       animate('500ms ease-out', style({
         transform: 'rotateZ(-500deg)'
        }))
    ]);
    
    const player = factory.create(this.randomizeIcon._elementRef.nativeElement);    
    player.play();

    
    let originalOrientation = this.blendInfo.blend.orientation;

    // update editor first so that it drops selections
    let blend = GradientUtil.randomBlend();
    blend.orientation = originalOrientation;
    this._editor.blend = blend;
    this.blendInfo.blend = blend;
  }

  _rotateHandler = () => {
    // TODO: use constants
    this._orientation = this._orientation === 'horizontal' ? 'vertical' : 'horizontal';

    this.blendInfo.blend.orientation = (this.blendInfo.blend.orientation === Direction.VERTICAL) ? Direction.HORIZONTAL : Direction.VERTICAL;
    
    // refresh stop details to account for rotation which affects horizontal/vertical positions
    // this._selectionsChangeHandler();
  }

  _nameChange = () => {
    this.blendInfo.name = this._name;
  }

  _nameInput = () => {
      this.nameError = undefined;
  }

  _typeTapHandler = () => {
    this.controlsType = (this.controlsType === BlendViewControllerComponent.CONTROLS_TYPE_COLOR) ? BlendViewControllerComponent.CONTROLS_TYPE_ALPHA : BlendViewControllerComponent.CONTROLS_TYPE_COLOR;
  }

  _typePressHandler = () => {
    // this.controlsTypeTrigger.openMenu();
    this._openControlsTypeMenu();
  }

  _openControlsTypeMenu = (): void => {
    let menuElement = this.controlsTypeMenuManual.nativeElement;
    menuElement.classList.add('open');

    // setTimeout to avoid triggering immediately
    window.setTimeout(() => {
      // prevent listening for click when already hidden
      // repro by tapping button twice (second tap both dismisses and sets up timeout)
      if (menuElement.classList.contains('open')) {
        document.removeEventListener('click', this._closeControlsTypeMenu);
        document.addEventListener('click', this._closeControlsTypeMenu);
      }
    }, 100);
  }

  _closeControlsTypeMenu = (): void => {
    let menuElement = this.controlsTypeMenuManual.nativeElement;
    menuElement.classList.remove('open');
    document.removeEventListener('click', this._closeControlsTypeMenu);
  }

  _typeColorsHandler = () => {
    this.controlsType = BlendViewControllerComponent.CONTROLS_TYPE_COLOR;
    // this.controlsTypeTrigger.closeMenu();
    this._closeControlsTypeMenu();
  }

  _typeOpacitiesHandler = () => {
    this.controlsType = BlendViewControllerComponent.CONTROLS_TYPE_ALPHA;
    // this.controlsTypeTrigger.closeMenu();
    this._closeControlsTypeMenu();
  }

  _typeBothHandler = () => {
    this.controlsType = BlendViewControllerComponent.CONTROLS_TYPE_BOTH;
    // this.controlsTypeTrigger.closeMenu();
    this._closeControlsTypeMenu();
  }

  _controlsHandler = () => {
    this._controlsState = (this._controlsState === 'visible') ? 'hidden' : 'visible';
    this.controlsVisible = !this.controlsVisible;
  }

  _pathChoiceHandler = (event, position, relativePosition) => {
    // HACK: relativePosition is relative to the editor 
    // and the positioner is relative to the BlendViewController
    // offset y to account
    // offset x so that menu somewhat centered under the menu
    // menu measured to be ~160px
    relativePosition.x -= 80;
    relativePosition.y += 20;

    // let positioner: HTMLElement = this.pathTypeMenuPositioner._elementRef.nativeElement;
    // positioner.style.left = (relativePosition.x + 80) + 'px';
    // positioner.style.top = (relativePosition.y - 10) + 'px';

    // this.pathTypeTrigger.openMenu();
    
    this._showPathTypeMenu(relativePosition);
  }

  _showPathTypeMenu = (position: {x: number, y: number}): void => {
    let menuElement = this.pathTypeMenu.nativeElement;
    let rect = menuElement.getBoundingClientRect();

    // center around position
    // menu measured to be ~160px
    menuElement.style.left = position.x + 'px';
    menuElement.style.top = position.y + 'px';
    menuElement.classList.add('open');

    // setTimeout to avoid triggering immediately
    window.setTimeout(() => {
      // prevent listening for click when already hidden
      // repro by tapping button twice (second tap both dismisses and sets up timeout)
      if (menuElement.classList.contains('open')) {
        document.removeEventListener('click', this._hidePathTypeMenu);
        document.addEventListener('click', this._hidePathTypeMenu);
      }
    }, 100);
  }

  _hidePathTypeMenu = (): void => {
    let menuElement = this.pathTypeMenu.nativeElement;
    menuElement.classList.remove('open');
    document.removeEventListener('click', this._hidePathTypeMenu);

    // temporarily disable event handler to prevent it from getting called as part of
    // "dismiss" from menu
    // no shade or anything trapping due to workaround for mat-menu and CSS zoom
    this._editor.off(BlendEditor.EVENT_PATH_CHOICE, this._pathChoiceHandler);
    setTimeout(() => {
      this._editor.on(BlendEditor.EVENT_PATH_CHOICE, this._pathChoiceHandler);
    }, 100);
  }

  /**
   * Handler for when the StopDetails are collapsed/expanded.
   * Updates our own state to match.
   */
  _collapseChangeHandler = () => {
    Preferences.getInstance().detailsCollapsed = this.stopDetails.collapsed;
    this.detailsState = this.stopDetails.collapsed ? DetailsState.collapsed : DetailsState.open;
  } 

  private _blendChangeHandler = () => {
    this._updateDetails();
    this._name = this._blendInfo ? this._blendInfo.name : '';
  }

  /**
   * Handler for when the stop details change.
   * Maps from horizontal/vertical properties to offset/pathOffset as needed.
   */
  _detailsChangeHandler = (event: PropertyChangeEvent) => {
    // event is firing as result of us updating the details
    // vs. from user interaction within the controls
    // if (this._updatingStopDetails) {
    //   return;
    // }

    let propertyName: string = event.propertyName;
    let value = event.value;

    let rotated: boolean = (this._blendInfo && (this._blendInfo.blend.orientation === Direction.VERTICAL)) ? true : false;

    if ((propertyName === 'horizontal') || (propertyName === 'vertical')) { 
      value /= 100;

      if (propertyName === 'horizontal') {
        propertyName = rotated ? 'pathOffset' : 'offset';
      }
      else {
        propertyName = rotated ? 'offset' : 'pathOffset';
      }
    }

    switch (propertyName) {
        case 'alpha':
        case 'color':
        case 'offset':
            // var selectedStops: IList<GradientEntry> = this.sel;
            let selections = this._editor ? this._editor.selections : null;
            let numSelections: number = selections ? selections.length : 0;
            for (let i: number = 0; i < numSelections; i++) {
              let stop: GradientEntry = selections.getItemAt(i);
              if (!stop) {
                continue;
              }
              stop[propertyName] = value;
            }
            break;
        case 'pathOffset':
            let selectedPaths = this._selectedPaths;
            let numSelectedPaths: number = selectedPaths ? selectedPaths.length : 0;
            for (let i: number = 0; i < numSelectedPaths; i++) {
              let path: BlendPath = selectedPaths[i];
              path.offset = value;
            }
            break;
    }

  }

  /**
   * Handler for when the list of selectedStops changes.
   * Disables/hides the Stop Details if no stops are selected.
   * Determines and caches the list of selected lines based on these stops.
   */
  private _selectionsChangeHandler = (event: CollectionEvent): void => {
    // ignore changes to internals of stops
    // these will get picked up by blend change
    if (event && event.kind === 'update') {
      return;
    }
    this._updateDetails();
  }

  /**
   * Handler for when "remove" is triggered through the Stop Details.
   * Removes the selected stops from the stack.
   */
  /* private (Angular-CLI #5621) */ _removeHandler = (): void => {
    // cache original stops before clearing below
    var selections: IList<GradientEntry> = this._editor.selections;

    // stop listening to the removed stops
    // and clear UI
    
    var paths = this._blendInfo.blend.paths;
    var numPaths: number = paths ? paths.length : 0;
    
    var lastSelectedStop: GradientEntry;
    var lastSelectedGradientIndex: number;
    var lastSelectedStopIndex: number;
    var lastSelectedGradientOffset: number;
    
    var numSelected: number = selections ? selections.length : 0;
    // walk backwards since we will be removing along the way
    for (var i: number = numSelected - 1; i >= 0; i--) {
        var stop: any = selections.getItemAt(i);     // TODO: use union type of GradientEntry and GradientStackEntry
        // remove from the list of selections first since removing from blend will trigger update
        // a stop that has been removed can not be selected
        // lastSelectedStop = selections.removeItemAt(i);

        var type: string = stop.type;

        var stopFound: boolean = false;
        for (var j: number = 0; j < numPaths; j++) {
            var path: BlendPath = paths.getItemAt(j);
            var gradient: Gradient = path.gradient;

            // TODO: remove alpha and color stop entries from public API and loop through flattened list
            var k: number;
            if (stop.type === GradientEntryType.TYPE_COLOR) {
                var colorEntries = gradient.colorEntries;
                var numColorEntries: number = colorEntries ? colorEntries.length : 0;
                for (k = 0; k < numColorEntries; k++) {
                    if (stop === colorEntries.getItemAt(k)) {
                        colorEntries.removeItemAt(k);
                        // lastSelectedStop = stop;
                        lastSelectedGradientIndex = j;
                        lastSelectedStopIndex = k;
                        lastSelectedGradientOffset = path.offset;
                        stopFound = true;
                        break;
                    }
                }
            }
            else if (stop.type === GradientEntryType.TYPE_ALPHA) {
                var alphaEntries = gradient.alphaEntries;
                var numAlphaEntries: number = alphaEntries ? alphaEntries.length : 0;
                for (k = 0; k < numAlphaEntries; k++) {
                    if (stop === alphaEntries.getItemAt(k)) {
                        alphaEntries.removeItemAt(k);
                        // lastSelectedStop = stop;
                        lastSelectedGradientIndex = j;
                        lastSelectedStopIndex = k;
                        lastSelectedGradientOffset = path.offset;
                        stopFound = true;
                        break;
                    }
                }
            }
            else {
                throw new Error("Deleting unknown stop type");
            }

            // same stop cannot exist in two places
            if (stopFound) {
                // remove the entire path if all stops for that path have been removed
                var colorEntries = gradient.colorEntries;
                var numColorEntries: number = colorEntries ? colorEntries.length : 0;
                var alphaEntries = gradient.alphaEntries;
                var numAlphaEntries: number = alphaEntries ? alphaEntries.length : 0;

                if ((numColorEntries === 0) && (numAlphaEntries === 0)) {
                    paths.removeItemAt(j);
                }
                
                break;
            }
        }
    }
    
    // select first stop of visible type
    // TODO: see legacy implementation for beginnings of getting "closest" stop when time permits
    let newStop;
    numPaths = paths ? paths.length : 0;
    for (var i: number = 0; i < numPaths; i++) {
        let path = paths.getItemAt(i);
        var gradient = path.gradient;
        
        // TODO: base on visible type
        // if (true) {
            var colorEntries = gradient.colorEntries;
            if (colorEntries.length > 0) {
                newStop = colorEntries.getItemAt(0);
                break;
            }
        // else {
        //     var alphaEntries = gradient.alphaEntries;
        //     if (alphaEntries.length > 0) {
        //         newStop = alphaEntries.getItemAt(0);
        //         break;
        //     }
        // }
    }

    (<ArrayList<any>>this._editor.selections).source = newStop ? [newStop] : [];
  }

  /* private (Angular-CLI #5621) */ _addColorHandler = () => {
    this._editor.choiceCallback(false);
  }

  /* private (Angular-CLI #5621) */ _addOpacityHandler = () => {
    this._editor.choiceCallback(true);
  }
}