
import ArrayList = require('collections/array-list');
import CollectionEvent = require('events/collection-event');
import ColorThumb = require('controls/color-thumb');
// import FeatureManager = require('sblended/feature-manager');
import Gradient = require('graphics/gradient');
import GradientUtil = require('utils/gradient-util');
import GradientEntry = require('graphics/gradient-entry');
import GradientEntryType = require('graphics/gradient-entry-type');
import IList = require('collections/i-list');
import ISliderThumb = require('controls/i-slider-thumb');
import PropertyChangeEvent = require('events/property-change-event');
import RGBA = require('graphics/rgba');
import SelectableThumbSlider = require('controls/selectable-thumb-slider');
import Slider = require('controls/slider');
import View = require('ui/view');

class GradientEditor extends SelectableThumbSlider {

    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------

    static STATE_NORMAL: string = 'normal';
    static STATE_ACTIVE: string = 'active';

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
        super();

        this.minimum = 0.0;
        this.maximum = 1.0;
        this.itemFactory = this.__itemFactory;
        this.thumbFactory = this.__thumbFactory;
        this.className = 'gradient-editor';
        this.valuesProperty = 'offset';
        this.trackDragging = false;     // track used to add items
        
        this.validator = (proposedLength: number): boolean => {
            // var info = FeatureManager.getInstance().info(FeatureManager.FEATURE_NUMBER_OF_STOPS_PER_LINE, proposedLength);
            
            // if (!info.available) {
            //     FeatureManager.getInstance().prompt(info);
            //     return false;
            // }
            
            return true;
        }
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  gradient
    //----------------------------------

    private _gradient: Gradient;

    /**
     * The <code>Gradient</code> to manipulate.
     */
    set gradient(value: Gradient) {
        this._gradient = value;
        this.values = this._gradient.colorEntries;
    }
    get gradient(): Gradient {
        return this._gradient;
    }
    
    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    private __itemFactory = (index: number, offset: number): GradientEntry => {
        var entry: GradientEntry = new GradientEntry(GradientEntryType.TYPE_COLOR);
        entry.offset = offset;

        var rgba = GradientUtil.interpolateGradient(this.gradient, offset);
        entry.color = rgba.color;

        return entry;
    }

    /**
     * Intentional double-underscore to prevent collision with Slider internal.
     */
    private __thumbFactory = (index: number): ISliderThumb => {
        //console.log('new thumb: ' + index);
        var colorThumb = new ColorThumb();

        var rgba: RGBA = colorThumb.rgba = new RGBA();

        // update whenever the entry changes
        // entry is obtained when thumbIndex is set below
        var entry: GradientEntry;
        var entryChangeHandler = () => {
            //console.log("entryChange to color: " + entry.color);
            rgba.color = entry.color;

            // could do this in RGBA#a setter, but would introduce performance cost everywhere
            var defaultAlpha = 1;
            var a = entry.alpha;
            rgba.a = (typeof a !== 'undefined') ? a : defaultAlpha;
        };

        // a change to the thumb index means it is being used for a different stop (reorder, remove, etc.)
        var thumbIndexChangeHandler = (event: PropertyChangeEvent): void => {
            if (event.propertyName === 'value') {       // ISliderThumb.value
                if (entry) {
                    entry.off(PropertyChangeEvent.PROPERTY_CHANGE, entryChangeHandler);
                }
                entry = event.value;
                if (entry) {
                    entry.on(PropertyChangeEvent.PROPERTY_CHANGE, entryChangeHandler);
                    entryChangeHandler();

                    // the ColorThumb is not picking up the PROPERTY_CHANGE events in entryChangeHandler for reasons unknown
                    // force apply of color
                    colorThumb.rgba = rgba;
                }
            }
        };
        colorThumb.on(PropertyChangeEvent.PROPERTY_CHANGE, thumbIndexChangeHandler);
        
        // hit existing handler via fake event
        var propertyChangeEvent = new PropertyChangeEvent();
        propertyChangeEvent.value = index;
        propertyChangeEvent.propertyName = 'index';     // ISliderThumb.index
        thumbIndexChangeHandler(propertyChangeEvent);

        colorThumb.on(View.EVENT_DESTROY,() => {
            if (entry) {
                entry.off(PropertyChangeEvent.PROPERTY_CHANGE, entryChangeHandler);
            }
            colorThumb.off(PropertyChangeEvent.PROPERTY_CHANGE, thumbIndexChangeHandler);
        });

        return colorThumb;
    }

    /**
     * Intentional double-underscore to prevent collision with Slider internal.
     */
    //private __thumbFactory = (index: number): ISliderThumb => {
    //    var colorThumb = new ColorThumb();

    //    var entry: GradientEntry = GradientUtil.flattenGradient(this.gradient).getItemAt(index);
    //    colorThumb.rgba = new RGBA(entry.color, entry.alpha);

    //    return colorThumb;
    //}
}
export = GradientEditor;