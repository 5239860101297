import _ = require('underscore');

import BindingUtil = require('utils/binding-util');
import EventDispatcher = require('events/event-dispatcher');
import PropertyChangeEvent = require('events/property-change-event');

var constructorToken = {};

/**
 * Model providing user preferences. 
 */
class Preferences extends EventDispatcher {
    
    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------

    static STORAGE_KEY: string = 'preferences';
    
    //--------------------------------------------------------------------------
    //
    //  Class methods
    //
    //--------------------------------------------------------------------------

    private static _instance: Preferences;

    /**
     * Returns the singleton instance.
     */
    static getInstance(): Preferences {
        if (!Preferences._instance) {
            Preferences._instance = new Preferences(constructorToken);
        }
        
        return Preferences._instance;
    }
    
    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------
    
    private _debouncedPersist: Function;
    
    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor(token) {
        super();
        
        if (token !== constructorToken) {
            throw new Error("This class cannot be instantiated.  Use the static getInstance method.");
        }
        
        var json: string = window.localStorage.getItem(Preferences.STORAGE_KEY);
        if (json) {
            var o: Preferences = JSON.parse(json);
            this.blendExportHeight = o.blendExportHeight;
            this.blendExportWidth = o.blendExportWidth;
            this.declinedColorPickerLogin = o.declinedColorPickerLogin;
            this.fill = o.fill;
            this.detailsCollapsed = o.detailsCollapsed;
            this._lastVersion = o.version;
        }
        else {
        }
        

        this._debouncedPersist = _.debounce(this._persist, 500);
        
        // force persistence once to save current version
        this._persist();
        
        this.on(PropertyChangeEvent.PROPERTY_CHANGE, this._debouncedPersist);
    }
    
    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------
    
    //----------------------------------
    //  lastVersion
    //----------------------------------
    
    private _lastVersion: number;
    
    /**
     * The last version that was installed.
     * Useful in determining migrations to perform.
     */
    get lastVersion() {
        return this._lastVersion;
    }
    
    //----------------------------------
    //  version
    //----------------------------------
    
    private _version: number = 1;
    
    /**
     * The version of the app.
     */
    get version(): number { 
        return this._version;
    }
    
    //----------------------------------
    //  declinedColorPickerLogin
    //----------------------------------
    
    private static _bindableDeclinedColorPickerLogin = BindingUtil.bindable(Preferences, 'declinedColorPickerLogin');
    
    /**
     * Whether or not the user declined logging into Creative Cloud 
     * when prompted from the Color Picker.
     */
    declinedColorPickerLogin: boolean = false;
    
    //----------------------------------
    //  blendExportWidth
    //----------------------------------
    
    private static _bindableBlendExportWidth = BindingUtil.bindable(Preferences, 'blendExportWidth');
    
    /**
     * Width of last blend export.
     */
    blendExportWidth: number;
    
    //----------------------------------
    //  blendExportHeight
    //----------------------------------
    
    private static _bindableBlendExportHeight = BindingUtil.bindable(Preferences, 'blendExportHeight');
    
    /**
     * Height of last blend export.
     */
    blendExportHeight: number;

    //----------------------------------
    //  fill
    //----------------------------------

    private static _bindableFill = BindingUtil.bindable(Preferences, 'fill');

    /**
     * Whether or not the add-on should fill the entire document/selection.
     */
    fill: boolean = true;
    
    //----------------------------------
    //  detailsCollapsed
    //----------------------------------

    private static _bindableDetailsCollapsed = BindingUtil.bindable(Preferences, 'detailsCollapsed');

    /**
     * Whether or not the Details (HSB/RGB Color Pickers, Alpha Slider) should be collapsed.
     */
    detailsCollapsed: boolean = false;

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------
    
    /**
     * Resets all preferences back to their defaults.
     */
    reset(): void {
        this.declinedColorPickerLogin = false;
        this.blendExportWidth = null;
        this.blendExportHeight = null;
        this.fill = true;
        this.detailsCollapsed = false;
    }
    
    /**
     * @inheritDoc
     */
    serialize(): any {
        var o: any = {};
        o.declinedColorPickerLogin = this.declinedColorPickerLogin;
        o.blendExportWidth = this.blendExportWidth;
        o.blendExportHeight = this.blendExportHeight;
        o.fill = this.fill;
        o.detailsCollapsed = this.detailsCollapsed;
        o.version = this.version;
        return o;
    }
    
    /**
     * Persist to disk.
     */
    private _persist(): void {
        var serialized = this.serialize();
        var json: string = JSON.stringify(serialized);
        window.localStorage.setItem(Preferences.STORAGE_KEY, json);
    }
}
export = Preferences;