class SortUtil {
    static numericCompareFunction(a: number, b: number): number {
        if (a < b) {
            return -1;
        }
        else if (a > b) {
            return 1;
        }
        else {
            return 0;
        }
    }

    static compareBy(propName: string): (a: any, b: any) => number {
        return function (a: number, b: number) {
            const aValue: any = a[propName];
            const bValue: any = b[propName];
            if (aValue < bValue) {
                return -1;
            }
            else if (aValue > bValue) {
                return 1;
            }
            else {
                return 0;
            }
        };
    }
}
export = SortUtil;
