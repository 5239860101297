/**
 * Holds information regarding neighbors along a line.
 * Currently used as return type for GradientUtil.neighbors
 */
class NeighborInfo {

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  previous
    //----------------------------------

    /**
     * The previous neighbor.
     */
    previous: any;

    //----------------------------------
    //  next
    //----------------------------------

    /**
     * The next neighbor.
     */
    next: any;

    //----------------------------------
    //  normalizedOffset
    //----------------------------------

    /**
     * Number indicating the normalized offset (from 0 to 1) between
     * the <code>previous</code> and <code>next</code> neighbors.
     * E.g. The <code>NeighborInfo</code> for something that sits halfway
     * between two neighbors would have a <code>normalizedOffset</code> of 0.5.
     */
    normalizedOffset: number;
}
export = NeighborInfo;
