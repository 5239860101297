class Environment {

    static CONTEXT_ADDON: string = 'Addon';
    static CONTEXT_APP: string = 'App';
    static CONTEXT_WEB: string = 'Web';
    static CONTEXT_BLENDS: string = 'Blends';

    static context: string;

    static platform: string;

    static demoMode: boolean;
}
export = Environment;