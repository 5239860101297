import Event = require('events/event');
import EventListener = require('events/event-listener');
import IEventDispatcher = require('events/i-event-dispatcher');
import Invalidating = require('core/invalidating');

declare class CustomEvent {
    constructor(eventType);
    cancelable: boolean;
    bubbles: boolean;
    args: any;
}

class EventDispatcher extends Invalidating implements IEventDispatcher {

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    private _eventListeners: { [s: string]: EventListener[] } = {};

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor() {
        super();
    }

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * Adds an event listener to the specified eventName.
     */
    on(eventType: string, callback: Function): void {
        let eventListenersForEventType: EventListener[] = this._eventListeners[eventType];
        if (!eventListenersForEventType) {
            eventListenersForEventType = this._eventListeners[eventType] = [];
        }
        const eventListener: EventListener = new EventListener();
        eventListener.callback = callback;
        eventListenersForEventType.push(eventListener);
    }

    /**
     * Removes event listeners.
     * Either all listeners, all listeners for a particular event, or a particular listener
     * may be removed depending on the arguments.
     */
    off(eventType?: string, callback?: Function): void {
        if (callback && !eventType) {
            throw new Error('Specify eventType from which to remove callback');
        }

        // remove all listeners
        if (!eventType) {
            this._eventListeners = {};
            return;
        }

        // remove all listeners for particular eventType
        if (!callback) {
            delete this._eventListeners[eventType];
            return;
        }

        // remove specific listener
        const eventListenersForEventType: EventListener[] = this._eventListeners[eventType];
        const numListeners: number = eventListenersForEventType ? eventListenersForEventType.length : 0;
        for (let i: number = numListeners - 1; i >= 0; i--) {     // walk backward to avoid affecting indices on removal
            const listener: EventListener = eventListenersForEventType[i];
            if (listener.callback === callback) {
                eventListenersForEventType.splice(i, 1);
            }
        }
    }

    /**
     * Triggers an event with the specified type.
     */
    trigger(eventType: string, ...args): void {
        // inject Event with target into arguments if not already an Event
        // this provides standard target to all handlers
        if (args) {
            if (args[0] instanceof Event) {
                (<Event>args[0]).target = this;
            }
            else {
                const event: Event = new Event();
                event.target = this;
                args = [event].concat(args);
            }
        }
        else {
            const event: Event = new Event();
            event.target = this;
            args = [event];
        }

        const eventListenersForEventType: EventListener[] = this._eventListeners ? this._eventListeners[eventType] : null;
        const numListeners: number = eventListenersForEventType ? eventListenersForEventType.length : 0;
        for (let i: number = 0; i < numListeners; i++) {
            const listener: EventListener = eventListenersForEventType[i];
            listener.callback.apply(this, args);
        }

        // trigger element events for "on-${EVENT_TYPE}" listeners
        // TODO: type as PolymerElement
        if ((<any>this)._elementPrepared) {
            const e = new CustomEvent(eventType);
            // e.cancelable = true;
            // e.bubbles = false;

            e.args = args;
            (<any>this).dispatchEvent(e);
        }
    }

    destroy(): void {
        this._eventListeners = null;
    }
}
export = EventDispatcher;
