class Rectangle {
    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  top
    //----------------------------------

    private _top: number = 0;

    set top(value: number) {
        this._top = this._y = value;
    }
    get top(): number {
        return this._top;
    }

    //----------------------------------
    //  left
    //----------------------------------

    private _left: number = 0;

    set left(value: number) {
        this._left = this._x = value;
    }
    get left(): number {
        return this._left;
    }

    //----------------------------------
    //  bottom
    //----------------------------------

    /**
     * The bottom edge of the Rectangle.
     * Setting this property modifies the <code>height</code> property.
     */
    set bottom(value: number) {
        this._height = value - this._y;
    }
    get bottom(): number {
        return this._y + this._height;
    }

    //----------------------------------
    //  right
    //----------------------------------

    /**
     * The right edge of the Rectangle.
     * Setting this property modifies the <code>width</code> property.
     */
    set right(value: number) {
        this._width = value - this._x;
    }
    get right(): number {
        return this._x + this._width;
    }

    //----------------------------------
    //  x
    //----------------------------------

    private _x: number = 0;

    set x(value: number) {
        this._x = this._left = value;
    }
    get x(): number {
        return this._x;
    }

    //----------------------------------
    //  y
    //----------------------------------
    
    private _y: number = 0;

    set y(value: number) {
        this._y = this._top = value;
    }
    get y(): number {
        return this._y;
    }

    //----------------------------------
    //  width
    //----------------------------------

    private _width: number;

    set width(value: number) {
        this._width = value;
    }
    get width(): number {
        return this._width;
    }

    //----------------------------------
    //  height
    //----------------------------------

    private _height: number;
    
    /**
     * The height of the Rectangle.
     * Setting this property modifies the <code>bottom</code> property.
     */
    set height(value: number) {
        this._height = value;
    }
    get height(): number {
        return this._height;
    }

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * Determines whether the Rectangle intersects with the specified Rectangle.
     */
    intersects(rect: Rectangle): boolean {
        if (this.intersectsX(rect) && this.intersectsY(rect)) {
            return true;
        }
        return false;
    }

    intersectsX(rect: Rectangle): boolean {
        var intersectionX = this.intersectionX(rect);
        if (intersectionX) {
            return true;
        }
        return false;
    }

    intersectsY(rect: Rectangle): boolean {
        var intersectionY = this.intersectionY(rect);
        if (intersectionY) {
            return true;
        }
        return false;
    }

    /**
     * Returns the rectangle of intersection between two rectangles;
     */
    intersection(rect: Rectangle): Rectangle {
        let intersection: Rectangle = null;
        let intersectionX: Rectangle = this.intersectionX(rect);
        if (intersectionX) {
            let intersectionY: Rectangle = this.intersectionY(rect);
            if (intersectionY) {
                intersection = new Rectangle();
                intersection.x = intersectionX.x;
                intersection.width = intersectionX.width;
                intersection.y = intersectionY.y;
                intersection.height = intersectionY.height;
            }
        }

        return intersection;
    }

    intersectionX(rect: Rectangle): Rectangle {
        let intersection: Rectangle = null;
        if ((rect.right >= this.left) && (rect.left <= this.left)) {
            // crosses left edge
            intersection = new Rectangle();
            intersection.x = this.left;
            intersection.width = rect.right - this.left;
        }
        else if ((rect.left <= this.right) && (rect.right >= this.right)) {
            // crosses right edge
            intersection = new Rectangle();
            intersection.x = rect.left;
            intersection.width = this.right - rect.left;
        }
        else if ((rect.left >= this.left) && (rect.right <= this.right)) {
            // crosses neither horizontal edge, but contained entirely within
            intersection = new Rectangle();
            intersection.x = rect.left;
            intersection.width = rect.width;
        }

        return intersection;
    }

    intersectionY(rect: Rectangle): Rectangle {
        let intersection: Rectangle = null;
        if ((rect.bottom >= this.top) && (rect.top <= this.top)) {
            // crosses top edge
            intersection = new Rectangle();
            intersection.y = this.top;
            intersection.height = rect.bottom - this.top;
        }
        else if ((rect.top <= this.bottom) && (rect.bottom >= this.bottom)) {
            // crosses bottom edge
            intersection = new Rectangle();
            intersection.y = rect.top;
            intersection.height = this.bottom - rect.top;
        }
        else if ((rect.top >= this.top) && (rect.bottom <= this.bottom)) {
            // crosses neither vertical edge, but contained entirely within
            intersection = new Rectangle();
            intersection.y = rect.top;
            intersection.height = rect.height;
        }

        return intersection;
    }
}
export = Rectangle;