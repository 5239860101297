import ArrayList = require('collections/array-list');
import CollectionEvent = require('events/collection-event');
import Event = require('events/event');
import EventDispatcher = require('events/event-dispatcher');
import GradientEntry = require('graphics/gradient-entry');
import GradientEntryType = require('graphics/gradient-entry-type');
import IList = require('collections/i-list');
import ISerializable = require('core/i-serializable');
import PropertyChangeEvent = require('events/property-change-event');
import SortUtil = require('utils/sort-util');

class Gradient extends EventDispatcher implements ISerializable {

    //--------------------------------------------------------------------------
    //
    //  Class methods
    //
    //--------------------------------------------------------------------------

    /**
     * Deserializes the plain object into an instance.
     */
    static deserialize(o: any): Gradient {
        const instance = new Gradient();

        instance.alphaEntries = ArrayList.deserialize(o.alphaEntries, GradientEntry);
        instance.colorEntries = ArrayList.deserialize(o.colorEntries, GradientEntry);

        return instance;
    }

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  entries
    //----------------------------------

    /*
    private _entries: IList<GradientEntry>;

    set entries(value: IList<GradientEntry>) {
        if (this._entries) {
            this._entries.off(CollectionEvent.COLLECTION_CHANGE, this._entriesCollectionChangeHandler);
        }

        this._entries = value;

        this._entries.on(CollectionEvent.COLLECTION_CHANGE, this._entriesCollectionChangeHandler);
    }
    get entries(): IList<GradientEntry> {
        return this._entries;
    }
    */

    /**
     * Returns a flattened, ordered set of entries resulting from merging the alpha and color entries.
     */
    /*
    get entries(): IList<GradientEntry> {
        //private static mergeOffsets(entries: IList<GradientEntry>, offsets: number[]): void {

        var combinedOffsets: number[] = [];

        var numEntries: number = entries ? entries.length : 0;
        for (var i: number = 0; i < numEntries; i++) {
            var entry: GradientEntry = entries.getItemAt(i);
            var offset: number = entry.offset;
            if (offsets.indexOf(offset) === -1) {
                offsets.push(offset);
            }
        }
        combinedOffsets.sort(SortUtil.numericCompareFunction);

        var offset: number = combinedOffsets[j];

        var topLeftRGBA = topRightRGBA;
        topRightRGBA = ColorUtil.interpolateGradientRGBA(startGradient, offset);

        var bottomLeftRGBA = bottomRightRGBA;
        bottomRightRGBA = ColorUtil.interpolateGradientRGBA(endGradient, offset);

        //return this._entries;
    }
    */

    //----------------------------------
    //  alphaEntries
    //----------------------------------

    private _alphaEntries: IList<GradientEntry>;

    set alphaEntries(value: IList<GradientEntry>) {
        if (this._alphaEntries) {
            this._alphaEntries.off(CollectionEvent.COLLECTION_CHANGE, this._alphaEntriesCollectionChangeHandler);
        }

        this._alphaEntries = value;

        if (this._alphaEntries) {
            this._alphaEntries.on(CollectionEvent.COLLECTION_CHANGE, this._alphaEntriesCollectionChangeHandler);
        }
    }
    get alphaEntries(): IList<GradientEntry> {
        return this._alphaEntries;
    }

    //----------------------------------
    //  colorEntries
    //----------------------------------

    private _colorEntries: IList<GradientEntry>;

    set colorEntries(value: IList<GradientEntry>) {
        if (this._colorEntries) {
            this._colorEntries.off(CollectionEvent.COLLECTION_CHANGE, this._colorEntriesCollectionChangeHandler);
        }

        this._colorEntries = value;

        if (this._colorEntries) {
            this._colorEntries.on(CollectionEvent.COLLECTION_CHANGE, this._colorEntriesCollectionChangeHandler);
        }
    }
    get colorEntries(): IList<GradientEntry> {
        return this._colorEntries;
    }

    //----------------------------------
    //  type
    //----------------------------------

    /**
     * The current "type" of this Gradient.
     * This is determined based on the number of alpha and color entries.
     * @see GradientEntryType
     */
    get type(): string {
        let type: string;

        const numAlphaEntries: number = this.alphaEntries ? this.alphaEntries.length : 0;
        const numColorEntries: number = this.colorEntries ? this.colorEntries.length : 0;

        if ((numAlphaEntries > 0) && (numColorEntries > 0)) {
            type = GradientEntryType.TYPE_GRADIENT;
        }
        else if (numAlphaEntries > 0) {
            if (numAlphaEntries > 1) {
                type = GradientEntryType.TYPE_ALPHA_GRADIENT;
            }
            else {
                type = GradientEntryType.TYPE_ALPHA;
            }
        }
        else {
            if (numColorEntries > 1) {
                type = GradientEntryType.TYPE_COLOR_GRADIENT;
            }
            else {
                type = GradientEntryType.TYPE_COLOR;
            }
        }

        return type;
    }

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    /**
     * Note: this does not clone the individual entries
     * TODO: support deep option
     */
    clone(deep: boolean = true, bubbleChanges: boolean = false): Gradient {
        const g: Gradient = new Gradient();

        // Note: Gradient does not explicitly use ArrayList for alphaEntries and colorEntries
        if (this.alphaEntries) {
            const alphaEntries: ArrayList<GradientEntry> = new ArrayList<GradientEntry>();
            alphaEntries.bubbleChanges = bubbleChanges;
            alphaEntries.source = this.alphaEntries.toArray();
            g.alphaEntries = alphaEntries;
        }

        if (this.colorEntries) {
            const colorEntries: ArrayList<GradientEntry> = new ArrayList<GradientEntry>();
            colorEntries.bubbleChanges = bubbleChanges;
            colorEntries.source = this.colorEntries.toArray();
            g.colorEntries = colorEntries;
        }

        return g;
    }

    //-- ISerializable

    /**
     * @inheritDoc
     */
    serialize(): any {
        const o: any = {};

        o.alphaEntries = this.alphaEntries ? this.alphaEntries.serialize() : null;
        o.colorEntries = this.colorEntries ? this.colorEntries.serialize() : null;

        return o;
    }

    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------

    private _genericCollectionChangeHandler = (event: Event, name: string): void => {
        const propertyChangeEvent = new PropertyChangeEvent();
        propertyChangeEvent.propertyName = name;
        propertyChangeEvent.value = event.target;
        this.trigger(PropertyChangeEvent.PROPERTY_CHANGE, propertyChangeEvent);
    }

    /*
    private _entriesCollectionChangeHandler = (event: Event): void => {
        this._genericCollectionChangeHandler(event, 'entries');
    }
    */
    private _alphaEntriesCollectionChangeHandler = (event: Event): void => {
        this._genericCollectionChangeHandler(event, 'alphaEntries');
    }
    private _colorEntriesCollectionChangeHandler = (event: Event): void => {
        this._genericCollectionChangeHandler(event, 'colorEntries');
    }
}
export = Gradient;
