import CollectionEvent = require('events/collection-event');
import IList = require('collections/i-list');
import IView = require('ui/i-view');
import Point = require('geom/point');
import PointerUtil = require('utils/pointer-util');
import PropertyChangeEvent = require('events/property-change-event');
import Slider = require('controls/slider');

class XYSlider extends Slider {
	
    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------
    
    private _rangeY: number;
    
    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  minimumY
    //----------------------------------

    private _minimumY: number = 0;

    /**
     * The minimum value of the y dimension.
     */
    set minimumY(value: number) {
        this._minimumY = value;
        this.invalidateProperty('minimumY');
    }
    get minimumY(): number {
        return this._minimumY;
    }
    
    //----------------------------------
    //  maximumY
    //----------------------------------

    private _maximumY: number = 100;

    /**
     * The maximum value of the y dimension.
     */
    set maximumY(value: number) {
        this._maximumY = value;
        this.invalidateProperty('maximumY');
    }
    get maximumY(): number {
        return this._maximumY;
    }
    
    //----------------------------------
    //  valueY
    //----------------------------------

    private _valueY: number = 0;

    /**
     * The value of the y dimension.
     */
    set valueY(value: number) {
        if (this._valueY === value) {
            return;
        }

        var oldValue = this._valueY;
        this._valueY = value;
        this._setValueYAt(0, value);
        this.invalidateProperty('valueY');

        // if (this.silent !== true) {
        //     this._triggerValueYChange(value, oldValue);
        // }
    }
    get valueY(): number {
        return this._valueY;
    }
    // // HACK: workaround for Polymer binding name conversion "valueY" -> "valueY"
    // // https://www.polymer-project.org/1.0/docs/devguide/properties.html#property-name-mapping
    // set valuey(value: number) {
    //     this.valueY = value;
    // }
    // get valuey(): number {
    //     return this.valueY;
    // }
    
    //----------------------------------
    //  valuesYProperty
    //----------------------------------

    private _valuesYProperty: string;

    /**
     * Name of the property to modify within the items in the <code>values</code>.
     * This property must be defined to support multiple values.
     * A standard scenario would use a valuesProperty of "x" and valuesYProperty of "y"
     * to manipulate Point objects.
     * 
     * Could also support valuesY List.
     */
    set valuesYProperty(value: string) {
        this._valuesYProperty = value;
    }
    get valuesYProperty(): string {
        return this._valuesYProperty;
    }
    
    //----------------------------------
    //  stepY
    //----------------------------------

    private _stepY: number;

    /**
     * The amount to round valueY to, such as rounding to the nearest 1 or nearest 10.
     * Defaults to undefined such that valueY is not rounded.
     */
    set stepY(value: number) {
        this._stepY = value;
    }
    get stepY(): number {
        return this._stepY;
    }
    
    //----------------------------------
    //  thumbInsetY
    //----------------------------------
    
    /**
     * Amount to inset the extreme positions of the thumbs along the Y axis.
     * Defaults to zero such that thumbs are positioned at the edges.
     * Set to half the height of the thumbs to keep thumbs from overflowing.
     */
    thumbInsetY: number = 0;
    
    //----------------------------------
    //  vertical
    //----------------------------------
    
    /**
     * @deprecated
     */
    set vertical(value: boolean) {
        console.warn('The "vertical" property is not supported by XY slider.');
    }
    
    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------
    
    /**
     * @inheritDoc
     */
    initialize(): void {
        super.initialize();
        
        this.on(Slider.EVENT_THUMB_UP, this.__upHandler);
    }
    
    /**
     * PolymerJS
     */
    created(): void {
        super.created();

        // apply the constructor manually to the instance
        XYSlider.apply(this);

        this.applyAttributes();
    }
    
    /**
     * Polymer data bindings.
     * Should move this into View, but doing so is breaking something that will take some investigation.
     */
    bind(name, observable, oneTime) {
        if (observable.setValue) {
            this.on(PropertyChangeEvent.PROPERTY_CHANGE, (propertyChangeEvent: PropertyChangeEvent) => {
                if (propertyChangeEvent.propertyName === name) {
                    observable.setValue(propertyChangeEvent.value);
                }
            });
        }

        observable.open((newValue, oldValue) => {
            this[name] = newValue;
        });
    }
    
     /**
     * @inheritDoc
     */
    validateProperties(changed?: any): void {
        super.validateProperties(changed);

        var allChanged: boolean = (changed === null);

        // if (allChanged || changed.minimumY || changed.maximumY) {
            this._rangeY = this._maximumY - this._minimumY;
        // }
        
        var i: number;
        var thumbs = this._thumbs;
        var numThumbs: number = thumbs ? thumbs.length : 0;
        if (allChanged || changed.minimumY || changed.maximumY || changed.valueY || changed.values) {
            for (i = 0; i < numThumbs; i++) {
                this._positionThumbForValueY(this._thumbs[i], this._getValueYAt(i));
            }
        }
    }
    
    /**
     * Determines the value for a particular position along the Slider.
     */
    protected _valueYForPosition(xPosition: number, yPosition: number, round: boolean= true): number {
        var ratio: number;
        var relativeY: number = yPosition - this.thumbInsetY - this._offsetTop + this._downOffsetTop;
        var h: number = this.pixelHeight - (this.thumbInsetY * 2);
        ratio = relativeY / h;
        
        var value: number = this._minimumY + (ratio * this._rangeY);

        // keep within bounds
        value = Math.max(value, this._minimumY);
        value = Math.min(value, this._maximumY);

        if (round && this._stepY) {
            value = this._roundValue(value);
        }
        
        return value;
    }
    
    /**
     * Positions the specified thumb according to the specified value.
     */
    private _positionThumbForValueY(thumb: IView, value: number): void {
        // keep within bounds
        if (value > this._maximumY) {
            value = this._maximumY;
        }
        if (value < this._minimumY) {
            value = this._minimumY;
        }
        // var percent: number = (value / this._range) * 100;
        var ratio: number = value / this._rangeY;
        var offset: number;
        // $(thumb.el).css('top', percent + '%');
        var h: number = this.pixelHeight - (this.thumbInsetY * 2);
        offset = this.thumbInsetY + (h * ratio);
        thumb.y = offset;
    }
    
    /**
     * Rounds the value to nearest increment within bounds or pegs at the bounds.
     * Note: This may provide values that are not rounded if the bounds themselves are not round (e.g. maximum of 8 rounded to 10)
     * TODO: keep rounded 
     */
    protected _roundValueY(value: number) {
        value = Math.round(value / this._stepY) * this._stepY;

        // keep within bounds
        if (value > this._maximumY) {
            value = this._maximumY;
        }
        if (value < this._minimumY) {
            value = this._minimumY;
        }
        return value;
    }
    
    /**
     * Helper method for setting the value at a particular location.
     * Behavior differs depending on whether <code>valuesProperty</code> is set.
     */
    protected _setValueYAt(index: number, value: number): void {
        //console.log('set value at: '+index+', value: '+value);
        this._changing = true;
        if (this._valuesYProperty) {
            var item = this.values.getItemAt(index);
            item[this._valuesYProperty] = value;
        }
        else {
            if (index !== 0) {
                console.error("Must set valuesYProperty");
            }
        }
        
        if (index === 0) {
            this._valueY = value;
            if (this.silent !== true) {
                this._triggerValueYChange(value, null);
            }
        }
        
        this._changing = false;
    }
    
    /**
     * Helper method for getting the value at a particular location.
     * Behavior differs depending on whether <code>valuesProperty</code> is set.
     */
    private _getValueYAt(index: number): number {
        if (this._valuesYProperty) {
            var item = this.values ? this.values.getItemAt(index) : null;
            return item ? item[this._valuesYProperty] : null;
        }
        else {
            if (index === 0) {
                return this._valueY;
            }
            else {
                console.error("Must set valuesYProperty");
            }
        }
    }
    
    private _triggerValueYChange(newValue, oldValue): void {
        var propertyChangeEvent = new PropertyChangeEvent();
        propertyChangeEvent.propertyName = 'valueY';
        propertyChangeEvent.value = newValue;
        propertyChangeEvent.oldValue = oldValue;
        this.trigger(PropertyChangeEvent.PROPERTY_CHANGE, propertyChangeEvent);

        // Polymer
        this.trigger(Slider.EVENT_CHANGE);
    }
    
    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------
    
    /**
     * Handler for when a thumb is moved.
     * Modifies the corresponding value accordingly.
     * Pushes / restores the values of the thumbs around it.
     * Implemented as two methods to allow for override of _moveHandler.
     */
    protected _moveHandler(event: BaseJQueryEventObject): void {
        super._moveHandler(event);
        
        var activeThumbIndex: number = this._activeThumbIndex;
        var thumb: IView = this._thumbs[activeThumbIndex];

        var offset: Point = new Point(this._offsetLeft, this._offsetTop);
        var p: Point = PointerUtil.normalizeJQueryEvent(event, false);//true, offset);
        var valueY: number = this._valueYForPosition(p.x, p.y, false);
        
        this._positionThumbForValueY(thumb, valueY);

        // value wasn't rounded above so that thumb would drag smoothly
        var roundValue: number = valueY;
        if (this.stepY) {
            roundValue = Math.round(valueY / this.stepY) * this.stepY;
        }

        // TODO: display round value somewhere
        
        if (this.liveDragging) {
            // use internals to avoid triggering repaint on ourselves which would move thumb to rounded value and break smooth dragging
            this._setValueYAt(this._activeThumbIndex, roundValue);
        }
    }
    
    /**
     * Handler for when a thumb is released.
     * Snapst the thumb to its rounded value and stops dragging.
     */
    protected __upHandler = (event: BaseJQueryEventObject): void => {
        // events are dispatched immediately when liveDragging
        if (!this.liveDragging) {
            var value: number = this._valueYForPosition(event.pageX, event.pageY);
            if (this.stepY) {
                value = this._roundValueY(value);
            }

            var propertyChangeEvent: PropertyChangeEvent;
            if (this._activeThumbIndex === 0) {
                var oldValue = this._value;
                this._value = value;

                this._triggerValueYChange(value, oldValue);
            }

            this._setValueYAt(this._activeThumbIndex, value);
        }
    }
}
export = XYSlider;