import Event = require('events/event');

class CollectionEvent extends Event {

    //--------------------------------------------------------------------------
    //
    //  Constructor
    //
    //--------------------------------------------------------------------------

    /**
     * @constructor
     */
    constructor(kind?: string) {
        super();

        this.kind = kind;
    }

    //--------------------------------------------------------------------------
    //
    //  Class constants
    //
    //--------------------------------------------------------------------------

    static COLLECTION_CHANGE: string = 'collectionChange';

    static KIND_UPDATE: string = 'update';

    static KIND_REMOVE: string = 'remove';

    static KIND_ADD: string = 'add';

    static KIND_RESET: string = 'reset';

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------

    //----------------------------------
    //  locations
    //----------------------------------

    /**
     * Locations of the items that changed.
     */
    locations: number[];

    //----------------------------------
    //  items
    //----------------------------------

    /**
     * The items that were affected.
     */
    items: any[];

    //----------------------------------
    //  kind
    //----------------------------------

    /**
     * The kind of CollectionEvent.
     * Use the KIND_ constants.
     */
    kind: string;
}
export = CollectionEvent;
