import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import {
  query,
  trigger,
  stagger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

import BindingUtil = require('utils/binding-util');
import PropertyChangeEvent = require('events/property-change-event');
import RGBA = require('graphics/rgba');
import View = require('ui/view');
import ColorUtil = require('utils/color-util');


@Component({
  selector: 'stop-details',
  templateUrl: './stop-details.component.html',
  styleUrls: ['./stop-details.component.scss'],
  animations: [
    trigger('_iconState', [
      state('collapsed', style({
        transform: 'rotateZ(-180deg)'
      })),
      state('expanded', style({
        transform: 'rotateZ(0deg)'
      })),
      transition('* <=> *', animate('300ms ease-out'))
    ]),
    trigger('_dropDownState', [
      state('collapsed', style({
        opacity: 0,
        pointerEvents: 'none'
      })),
      state('expanded', style({
        opacity: 1,
        pointerEvents: 'auto'
      })),
      transition('* <=> *', animate('200ms ease-out'))
    ]),
    trigger('_headerState', [
      state('collapsed', style({
          transform: 'translateY(130px)'        // HACK: must match height - header.height - footer.height
      })),
      state('expanded', style({
        transform: 'translateY(0px)'
      })),
      transition('* <=> *', animate('200ms ease-out'))
    ]),
    trigger('_contentsState', [
      state('collapsed', style({
          transform: 'translateY(130px)'        // HACK: must match height - header.height - footer.height
      })),
      state('expanded', style({
        transform: 'translateY(0px)'
      })),
      transition('* <=> *', animate('200ms ease-out'))
    ]),
    trigger('_colorDetailsState', [
      state('ALPHA', style({
        opacity: 0,
        visibility: 'hidden'
      })),
      state('COLOR', style({
        opacity: 1,
        visibility: 'visible'
      })),
      transition('* <=> *', animate('200ms ease-out'))
    ]),
    trigger('_alphaDetailsState', [
      state('ALPHA', style({
        opacity: 1,
        visibility: 'visible'
      })),
      state('COLOR', style({
        opacity: 0,
        visibility: 'hidden'
      })),
      transition('* <=> *', animate('200ms ease-out'))
    ])
  ]
})
export class StopDetailsComponent extends View {

    @Output()
    collapseChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    remove: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    propertyChange: EventEmitter<PropertyChangeEvent> = new EventEmitter<PropertyChangeEvent>();

    constructor() {
      super();
    }

    initialize(): void {
      super.initialize();
    }

    //--------------------------------------------------------------------------
    //
    //  Variables
    //
    //--------------------------------------------------------------------------

    @ViewChild('colorPicker') colorPicker;
    @ViewChild('alphaSlider') alphaSlider;
    @ViewChild('colorPickerTypeMenu') colorPickerTypeMenu;
    
    private _internalChange: boolean = false;
    /* private (Angular-CLI #5621) */ _cssColor: string;
    private _hostColorPickerShown: boolean = false;
    /* private (Angular-CLI #5621) */ _colorChipVisible: boolean = true;
    private _hex: string;

    //--------------------------------------------------------------------------
    //
    //  Properties
    //
    //--------------------------------------------------------------------------
    
    //----------------------------------
    //  color
    //----------------------------------
    
    private _color: number;
    
    private static _bindableColor = BindingUtil.bindable(StopDetailsComponent, 'color');
    
    /**
     * The color of the stop(s).
     */
    set color(value: number) {
        if (this._color !== value) {
            this._color = value;

            this._cssColor = new RGBA(this.color).toCSS();

            this._hex = ColorUtil.colorToHex(this.color);

            if (this.colorPicker) {
              this.colorPicker.dispatch = false;
              this.colorPicker.color = this._color;
              this.colorPicker.dispatch = true;
            }

            // if (!this._internalChange) {
            //     this.invalidateProperty('color');
            // }
        }
    }
    get color(): number {
        return this._color;
    }

    //----------------------------------
    //  live
    //----------------------------------
    
    /**
     * Whether or not changes are triggered live as the user makes modifications,
     * or deferred until a more explicit commit (e.g. enter in input field, release of thumb)
     */
    live: boolean = true;

    //----------------------------------
    //  collapsed
    //----------------------------------
    
    _collapsed: boolean = false;
    
    /**
     * Whether or not the Details are in a collapsed state.
     */
    @Input()
    set collapsed(value: boolean) {
        if (this._collapsed !== value) {
            this._collapsed = value;
        }
    }
    get collapsed(): boolean {
        return this._collapsed;
    }

    //----------------------------------
    //  colorPickerType
    //----------------------------------

    colorPickerType: string = 'hsb';

    //----------------------------------
    //  horizontal
    //----------------------------------

    @Input()
    horizontal: number;

    //----------------------------------
    //  vertical
    //----------------------------------

    @Input()
    vertical: number;

    //----------------------------------
    //  stopType
    //----------------------------------

    @Input()
    stopType: string;

    //----------------------------------
    //  alpha
    //----------------------------------

    /* private */ _alpha: number = 1;

    @Input()
    set alpha(value: number) {
      if (this._alpha !== value) {
        this._alpha = value;

        let event = new PropertyChangeEvent();
        event.propertyName = 'alpha';
        event.value = value;

        this.propertyChange.emit(event);
      }
    }
    get alpha(): number {
      return this._alpha;
    }

    //--------------------------------------------------------------------------
    //
    //  Methods
    //
    //--------------------------------------------------------------------------

    layout(): void {
      super.layout();

      this.layoutChild(this.colorPicker);
      this.layoutChild(this.alphaSlider);
    }

    _openColorPickerTypeMenu = (): void => {
      let menuElement = this.colorPickerTypeMenu.nativeElement;
      menuElement.classList.add('open');
  
      // setTimeout to avoid triggering immediately
      window.setTimeout(() => {
        // prevent listening for click when already hidden
        // repro by tapping button twice (second tap both dismisses and sets up timeout)
        if (menuElement.classList.contains('open')) {
          document.removeEventListener('click', this._closeColorPickerTypeMenu);
          document.addEventListener('click', this._closeColorPickerTypeMenu);
        }
      }, 100);
    }
  
    _closeColorPickerTypeMenu = (): void => {
      let menuElement = this.colorPickerTypeMenu.nativeElement;
      menuElement.classList.remove('open');
      document.removeEventListener('click', this._closeColorPickerTypeMenu);
    }

    //--------------------------------------------------------------------------
    //
    //  Event handlers
    //
    //--------------------------------------------------------------------------
    
    _removeStopHandler = () => {
      this.remove.emit();
    };

    /**
     * Handler for when the user taps the color chip from within the Extension.
     * Presents the host color picker and applies the selected color.
     */
    /* private */ _colorChipHandler = (): void => {
      if (window.creativeCloudExtension) {
          if (this._hostColorPickerShown) {
              return;
          }
          this._hostColorPickerShown = true;
          window.creativeCloudExtension.showColorPicker(this.color)
              .then(
                  (color) => {
                      this._hostColorPickerShown = false;
                      this.color = color;
                  }, 
                  () => {
                      this._hostColorPickerShown = false;
                  }
              );
      }
    };

    _collapseOrCloseHandler = () => {
      
      // if (window.creativeCloudExtension) {
        if (true) {
          this._collapsed = !this._collapsed;
          this.collapseChange.emit(this._collapsed);
        }
      else {
        // this._closeHandler();
      }
    }
    
    _horizontalChange = () => {
      let event = new PropertyChangeEvent();
      this.horizontal = Math.round(this.horizontal);
      if (this.horizontal < 0) {
        this.horizontal = 0;
      }
      if (this.horizontal > 100) {
        this.horizontal = 100;
      }
      event.propertyName = 'horizontal';
      event.value = this.horizontal;
      this.propertyChange.emit(event);
    }

    _verticalChange = () => {
      let event = new PropertyChangeEvent();
      this.vertical = Math.round(this.vertical);
      if (this.vertical < 0) {
        this.vertical = 0;
      }
      if (this.vertical > 100) {
        this.vertical = 100;
      }
      event.propertyName = 'vertical';
      event.value = this.vertical;
      this.propertyChange.emit(event);
    }

    _colorChange = () => {
      let event = new PropertyChangeEvent();
      event.propertyName = 'color';
      event.value = this.colorPicker.color;
      this.propertyChange.emit(event);
    }

    // TODO: use validateInput for _horizontalChange, _verticalChange, _colorChange
    /* private (Angular-CLI #5621) */ _validateInput = (event, propertyName: string, min: number = 0, max: number = 100, factor: number = null) => {
      let value = event.target.value;
      let originalValue = value * factor;
      
      value = Math.round(value);

      // apply prior to enforcing min/max to minimize floating point issues
      // this means that the min/max should also be set in relation to the factor
      // e.g. an input that allows a user to enter values between 0 and 100 
      // though internally should result in values between 0.0 and 1.0
      // would have a factor of 100, min of 0, and max of 100.

      if (factor) {
        value = value * factor;
      }

      if (value < min) {
        value = min;  
      }
      else if (value > max) {
        value = max;
      }

      // force setting of input
      // otherwise the input element may not get updated since the underlying value may have already been clamped
      if (value !== originalValue) {
        let stringValue = factor ? value / factor : value;
        event.target.value = String(stringValue);
      }

      this[propertyName] = value;
    }

    /** private  */ _validateHex = (event) => {
        const input: HTMLInputElement = event.target;
        const value: string = input.value;

        if (input.validity.patternMismatch) {
            input.value = this._hex;
        }
        else {
            this.color = ColorUtil.hexToRGBA(value).color;
            let event = new PropertyChangeEvent();
            event.propertyName = 'color';
            event.value = this.color;
            this.propertyChange.emit(event);
        }
    }


    /**
     * Handler for when pan event occurs on alpha drag button.
     */
    _onPanAlpha = (event) => {
      var d = event.velocityX / 1.5;
      var alpha = this.alpha += d;
      alpha = Math.max(alpha, 0);
      alpha = Math.min(alpha, 1);
      this.alpha = alpha;
    }

    _onHSB = (event?) => {
      this._closeColorPickerTypeMenu();
      this.colorPickerType = 'hsb';
    }

    _onRGB = (event?) => {
      this._closeColorPickerTypeMenu();
      this.colorPickerType = 'rgb';
    }
}