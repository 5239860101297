<div class="page">
    <app-nav-bar></app-nav-bar>
    <div class="body background">
        <div class="content">
            <label class="title">MAKE SOMETHING BEAUTIFUL</label>
            <p>Whip up a blend, give it a proper name, and share it with the world.</p>
            <div class="blend-view-controller-holder">
                <blend-view-controller #blendViewController class="blend-view-controller fit"></blend-view-controller>
                <!-- separate border used to hide visual artifact that shows a small line around the blend when a border-radius is applied -->
                <div class="border"></div>
            </div>
            <button class="submit-button" mat-stroked-button color="accent" [disabled]="_publishing" (click)="_submit();">
                <div style="display: flex; align-items: center;">
                    Publish
                    <mat-spinner *ngIf="_publishing" diameter="20" style="margin-left: 10px;"></mat-spinner>
                </div>
            </button>
        </div>
    </div>
    <app-footer></app-footer>
</div>